import deepmerge from 'deepmerge';
import { DeepPartial } from 'redux';
import { useDispatch } from 'react-redux';

const qs = require('qs')

export function deepmergeHelper<T>(orig: T, newObj: DeepPartial<T>): T {
    return deepmerge<T, typeof newObj>(orig, newObj)
}

export const checkTypeFromArrayBuilder = <T extends string>(array: ReadonlyArray<string>) => {
    return (v: string): v is T => array.includes(v)
}

export function getQueryStringParams(): Record<string, unknown> {
    const parsed = qs.parse(window.location.search, { ignoreQueryPrefix: true })
    return parsed
}

export function generateRandomInts(length: number): string {
    return new Array(length).fill(undefined).map(_ => getRandomInt(10)).join('')
}

export function getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max))
}

export const preventDefault = (event: React.SyntheticEvent) => event.preventDefault();

export function useReduxDispatch(): ReduxThunkDispatch {
    return useDispatch<ReduxThunkDispatch>()
}

export function wait(seconds: number): Promise<void> {
    return new Promise(resolve => {
        setTimeout(() => resolve(), seconds * 1000)
    })
}

export function checkIsMacBookChromeSafariFirefox() {
    const navigator = window.navigator as any;
    const platform: string = navigator.userAgentData?.platform ?? navigator.platform ?? "";
    const isMacBook = platform.includes('Mac') || platform.includes('mac');
    const isChrome = navigator.userAgent.indexOf("Chrome") !== -1;
    const isSafari = navigator.userAgent.indexOf("Safari") !== -1;
    const isFirefox = navigator.userAgent.indexOf("Firefox") !== -1;

    return isMacBook && (isChrome || isSafari || isFirefox);
}

export function checkIsIntercept() {
    return window.location.href.includes('INT');
}