import { checkTypeFromArrayBuilder } from '../../utils';
import { LandingPageConfigBuilder } from '../../LandingPage/landingPageConfigBuilder';
import { LandingPageStep } from '../../LandingPage/reducer';
import { MTurkParams } from '../../queryParams';
import { hasCompletedSurvey, isPreview } from '../../MTurk/mturkParams';
import { checkIsMacBookChromeSafariFirefox } from '../../utils';

/**
 * Group Ids and various SubGroupId types
 */
export const MTURK_GAZE_GROUP_IDS = [
    "MTURK-TEST-GROUP",
    "GAZE-119-0422-MTURK-GAZE",
] as const;

export const MTURK_MAKEUP_HIT_GROUP_IDS = [
    "MTURK-MAKEUP"
] as const;

export const MTURK_GAZE_DESKTOP_GROUP_IDS = [
    "DSTD-261-0223-MTURK-GAZE-DESKTOP"
] as const;

export const MTURK_GROUP_IDS = [
    ...MTURK_GAZE_GROUP_IDS,
    ...MTURK_MAKEUP_HIT_GROUP_IDS,
    ...MTURK_GAZE_DESKTOP_GROUP_IDS
]

export type MturkGazeGroupId = (typeof MTURK_GAZE_GROUP_IDS)[number]
export type MturkMakeupHitGroupId = (typeof MTURK_MAKEUP_HIT_GROUP_IDS)[number]
export type MturkGazeDesktopGroupId = (typeof MTURK_GAZE_DESKTOP_GROUP_IDS)[number]

export type MturkGroupId = MturkGazeGroupId | MturkMakeupHitGroupId | MturkGazeDesktopGroupId
export type MturkGroupIdRequestable = MturkGazeGroupId

/**
 * Type checkers
 */
const isMturkGazeGroupId = checkTypeFromArrayBuilder<MturkGazeGroupId>(MTURK_GAZE_GROUP_IDS)
export const isMturkMakeupHitGroupId = checkTypeFromArrayBuilder<MturkMakeupHitGroupId>(MTURK_MAKEUP_HIT_GROUP_IDS)
export const isMturkGazeDesktopGroupId = checkTypeFromArrayBuilder<MturkGazeDesktopGroupId>(MTURK_GAZE_DESKTOP_GROUP_IDS)
export const isMturkGroupId = (str: string): str is MturkGroupId => {
    return isMturkGazeGroupId(str) || isMturkMakeupHitGroupId(str) || isMturkGazeDesktopGroupId(str)
}

export const configureMturkPage = (
    params: MTurkParams,
    landingPageBuilder: LandingPageConfigBuilder,
    completionCode: string
): void => {
    landingPageBuilder.setSubmitToMturk(true)


    const isMobileOrDesktopGaze = isMturkGazeGroupId(params.G) || isMturkGazeDesktopGroupId(params.G)
    const needsSurveyRedirect = (
            isMobileOrDesktopGaze && 
            checkIsMacBookChromeSafariFirefox() &&
            !isPreview(params) &&
            !hasCompletedSurvey(params) 
    )


    if (needsSurveyRedirect) {
        const steps: Array<LandingPageStep> = [
            { stepComponentKey: 'MturkSurveyRedirect', titleL18nKey: 'Instructions', props: {} }
        ];

        landingPageBuilder.setSteps(steps);
        if(isMturkGazeDesktopGroupId(params.G)) {
            landingPageBuilder.setPageTitle('MacBook Gaze Collection HIT');
        } 
        else if (isMturkGazeGroupId(params.G)) {
            landingPageBuilder.setPageTitle('Mobile Gaze Collection HIT');
        }
        else {
            landingPageBuilder.setPageTitle('Desktop Gaze Collection HIT');
        }
        return
    }

    if (isMturkMakeupHitGroupId(params.G)) {
        const steps: Array<LandingPageStep> = [
            { stepComponentKey: 'MakeupHitInstructions', titleL18nKey: 'Instructions', props: {} }
        ];

        landingPageBuilder.setSteps(steps);
        landingPageBuilder.setPageTitle('Gaze Collection Makeup HIT');
    } else if (isMturkGazeDesktopGroupId(params.G)) {
        const steps: Array<LandingPageStep> = [
            { stepComponentKey: 'MturkGazeDesktopInstructions', titleL18nKey: 'Instructions', props: {} },
            { stepComponentKey: 'MturkGazeDesktopRequirements', titleL18nKey: 'Requirements', props: {} },
            { stepComponentKey: 'MturkGazeDesktopCompletion', titleL18nKey: 'MturkGazeDesktopCompletion', props: { _type: 'completionCode', completionCode } },
        ];

        landingPageBuilder.setSteps(steps);
        if(isMturkGazeDesktopGroupId(params.G)) {
            landingPageBuilder.setPageTitle('MacBook Gaze Collection HIT');
        } else {
            landingPageBuilder.setPageTitle('Desktop Gaze Collection HIT');
        }
    } else {
        const steps: Array<LandingPageStep> = [
            { stepComponentKey: 'MturkGazeInstructions', titleL18nKey: 'Instructions', props: {} },
            { stepComponentKey: 'MturkGazeRequirements', titleL18nKey: 'Requirements', props: {} },
            { stepComponentKey: 'AppDownload', titleL18nKey: 'AppDownload', props: {} },
            { stepComponentKey: 'LoginAndCompletionCode', titleL18nKey: 'LoginAndCompletionCode', props: { _type: 'completionCode', completionCode } },
        ];

        landingPageBuilder.setSteps(steps);
        landingPageBuilder.setPageTitle('Mobile Gaze Collection HIT');
    }
}
