import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateWebPlatformTask } from "../tasks";
import { WebPlatform } from "../types";

const FINISH_MESSAGE = "Herzlichen Glückwunsch!\nSie haben nun alle erforderlichen Aufgaben abgeschlossen und Ihre Daten wurden hochgeladen. Ihr Panel-Konto wurde incentiviert. Vielen Dank für Ihre Zeit."

export const ODEU0422CollGroupIds = [
    "ODEU-113-0422-DEU-FACEBOOK-INFEED",
    "ODEU-114-0422-DEU-FACEBOOK-INSTREAM",
    "ODEU-115-0422-DEU-FACEBOOK-STORIES",
    "ODEU-116-0422-DEU-YOUTUBE-PREROLL",
    "ODEU-117-0422-DEU-YOUTUBE-BONUS",
    "ODEU-118-0422-DEU-TWITTER-INFEED"
] as const

export type ODEU0422CollGroupId = typeof ODEU0422CollGroupIds[number]

type ODEU0422CollConfig = {
    groupId: ODEU0422CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getWebPlatformConfigUrl = (groupId: ODEU0422CollGroupId): string => {
    switch (groupId) {
        case "ODEU-113-0422-DEU-FACEBOOK-INFEED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ODEU-0422/ODEU-113-0422-DEU-FACEBOOK-INFEED.json";
        case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ODEU-0422/ODEU-114-0422-DEU-FACEBOOK-INSTREAM.json";
        case "ODEU-115-0422-DEU-FACEBOOK-STORIES": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ODEU-0422/ODEU-115-0422-DEU-FACEBOOK-STORIES.json";

        case "ODEU-116-0422-DEU-YOUTUBE-PREROLL": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ODEU-0422/ODEU-116-0422-DEU-YOUTUBE-PREROLL.json";
        case "ODEU-117-0422-DEU-YOUTUBE-BONUS": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ODEU-0422/ODEU-117-0422-DEU-YOUTUBE-BONUS.json";

        case "ODEU-118-0422-DEU-TWITTER-INFEED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ODEU-0422/ODEU-118-0422-DEU-TWITTER-INFEED.json";

        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: ODEU0422CollGroupId): WebPlatform => {
    switch (groupId) {
        case "ODEU-113-0422-DEU-FACEBOOK-INFEED":
        case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM":
        case "ODEU-115-0422-DEU-FACEBOOK-STORIES": {
            return "Facebook";
        }

        case "ODEU-116-0422-DEU-YOUTUBE-PREROLL":
        case "ODEU-117-0422-DEU-YOUTUBE-BONUS": {
            return "Youtube";
        }

        case "ODEU-118-0422-DEU-TWITTER-INFEED":{
            return "Twitter";
        }

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: ODEU0422CollGroupId): string => {
    switch (groupId) {

        case "ODEU-113-0422-DEU-FACEBOOK-INFEED":
        case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM":
        case "ODEU-115-0422-DEU-FACEBOOK-STORIES": {
            return "https://survey.au1.qualtrics.com/jfe/form/SV_5b5oRH3EBFPqdee";
        }

        case "ODEU-116-0422-DEU-YOUTUBE-PREROLL":
        case "ODEU-117-0422-DEU-YOUTUBE-BONUS": {
            return "https://survey.au1.qualtrics.com/jfe/form/SV_6JZxaYU8T1XQh4W";
        }

        case "ODEU-118-0422-DEU-TWITTER-INFEED":{
            return "https://survey.au1.qualtrics.com/jfe/form/SV_cTpV70gDnXNdUYS";
        }

        default:
            assertNever(groupId);
    }
}

const getTaskLengthMinutesString = (groupId: ODEU0422CollGroupId): string => {
    switch (groupId) {

        case "ODEU-113-0422-DEU-FACEBOOK-INFEED":
        case "ODEU-115-0422-DEU-FACEBOOK-STORIES":
        case "ODEU-118-0422-DEU-TWITTER-INFEED": {
            return "8"
        }

        case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM":
            return "15"

        case "ODEU-116-0422-DEU-YOUTUBE-PREROLL":
        case "ODEU-117-0422-DEU-YOUTUBE-BONUS": {
            return "20";
        }

        default:
            assertNever(groupId);
    }
}

export const addOdeu0422CollConfig = (
    config: ODEU0422CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)



    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: config.groupId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: getPlatformForGroup(config.groupId),
        taskLengthMinutes: getTaskLengthMinutesString(config.groupId),
        langStrings: langStrings,
    });
    builder.addTask(webPlatformTask);

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });
    builder.addTask(surveyTask);

    return builder;
}