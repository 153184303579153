import { LangStrings } from './LangStrings';

const FrLangStrings: LangStrings = {
    "Common": {
        "survey": "Enquête",
        "back": "Dos",
        "next": "Ensuite",
        "loading": "Chargement en cours",
        "go": "Allons-y"
    },
    "Survey": {
        "description": "A 5 minute survey on your shopping preferences"
    },
    "WebPlatform": {
        "facebook": (minutes: string) => `Scroll Facebook for ${minutes} minutes`,
        "youtube": (minutes: string) => `Scroll YouTube for ${minutes} minutes`,
        "instagram": (minutes: string) => `Scroll Instagram for ${minutes} minutes`,
        "tiktok": (minutes: string) => `Scroll TikTok for ${minutes} minutes`,
        "snapar": () => 'Explorez les objectifs fournis en appuyant simplement sur chacun d\'eux. Vous pouvez jouer à des jeux, essayer des accessoires et prendre des photos avec des personnages. Nous vous informerons lorsque la séance sera terminée.'
    },
    "LandingPage": {
        "Title": "Laissez-vous préparer pour gagner !",
    },
    "Decay": {
        "Surveys": {
            "Initial": "Shopping Survey",
            "TwoWeek": "2 Week Shopping Survey",
            "FourWeek": "4 Week Shopping Survey"
        }
    },
    "BvodInApp": {
        "title": "TV Show Viewing",
        "description": "Watch a TV show on your phone",
    },
    "Steps": {
        "StepLabel": {
            "Instructions": "Bienvenue",
            "ViewingInstructionsDeskSolution": "Instructions",
            "Requirements": "Exigences",
            "AppDownload": "Téléchargement de l'application",
            "AppDownloadDeskSolution": "",
            "LoginAndCompletionCode": "Login",
            "MturkGazeDesktopCompletion": "Login",
            "LoginAndCompletionDeskSolution": "",
            "SessionInformationDeskSolution": "",
            "Setup": "Installation",
            StartEarning: "Commencer a gagner"
        },
        ViewingInstructions: {
            title: 'Comme c’est excitant, vous êtes prêt à commencer votre première aventure Blinq.',
            subtitle:
            `Suivez ces étapes simples et vous serez prêt à gagner des récompenses en quelques minutes.<br/><br/>
            Tout d’abord, il est temps de télécharger notre application et de recevoir un identifiant de connexion unique.<br/><br/>
            <b>Rappel:</b> Il s'agit d'une étude exclusivement mobile, alors assurez-vous d'avoir votre téléphone à portée de main pour terminer cette configuration et participer.<br/><br/>
            Cliquez sur Allons-y pour continuer.`,
        },
        ViewingInstructionsDeskSolution: {
            title: "",
            subtitle: "",
        },
        "AppDownload": {
            "subtitle": 'Dans l’App Store d’Apple, cherchez “attentionTRACE Research“',
            "or": "OU",
            "downloadLink": "Cliquez ici pour télécharger"
        },
        "AppDownloadDeskSolution": {
            "subtitle": "",
            "downloadLink": "",
        },
        "LoginAndCompletionCode": {
            "subtitle": "Utilisez ce code pour vous connecter à attentionTRACE:",
            platformInstructions: {
                facebookNewsfeed: "Connectez-vous sur Facebook et faites défiler le fil d'actualité. Faites ce que vous faites d’habitude, faites défiler, faites quelques commentaires et regardez des histoires jusqu’au moment où sonne le chronomètre. Vous aurez besoin de vos détails de connexion de Facebook avant de commencer.",
                facebookWatch: "",
                facebookStories: "",
                instagram: `Une fois connecté, nous chargerons votre application de médias sociaux et vous demanderons de vous connecter à votre compte personnel.\nPour gagner, vous devrez suivre les instructions, faire défiler le contenu, regarder des vidéos, aimer les publications, vous amuser et attendre la fin du chronomètre. 😎`,
                instagramNewsfeed: "Connectez-vous sur Instagram et faites défiler le fil d'actualité. Faites ce que vous faites d’habitude, faites des commentaires et regardez des histoires jusqu’au moment où sonne le chronomètre.\nVous aurez besoin de vos détails de connexion d’Instagram avant de commencer.",
                youtube: `Une fois connecté, nous chargerons votre application de médias sociaux et vous demanderons de vous connecter à votre compte personnel.\nPour gagner, vous devrez suivre les instructions, faire défiler le contenu, regarder des vidéos, aimer les publications, vous amuser et attendre la fin du chronomètre. 😎`,
                twitter: "Vous aurez besoin de votre compte Twitter pour y accéder. Une fois que vous y êtes, utilisez Twitter comme vous le faites.\nFaites défiler le fil d'actualités pour voir ce qui est le plus courant jusqu’au moment ou le chronomètre vous signale que c’est terminé.",
                tiktok: `Une fois connecté, nous chargerons votre application de médias sociaux et vous demanderons de vous connecter à votre compte personnel.\nPour gagner, vous devrez suivre les instructions, faire défiler le contenu, regarder des vidéos, aimer les publications, vous amuser et attendre la fin du chronomètre. 😎`,
                webplatformSynergy: "Vous aurez besoin de vos détails de connexion pour Twitter prêt pour accéder. Une fois que vous y êtes, utilisez ‘Twitter’ comme vous le faites d’habitude. Faites défiler le fil d’information pour voir ce qui est le plus courant jusqu’au moment où l'appli vous signale que c’est terminé.\nUne fois que c’est fini, l’appli va charger automatiquement la prochaine plateforme, soit Instagram, soit Facebook, soit TikTok. Donc, veuillez avoir prêt ces détails aussi.",
                pinterest: "Veuillez vous connecter à l'application attentionTRACE en utilisant le code de connexion unique ci-dessus.\nUne fois connecté, la plateforme Pinterest vous sera présentée et vous serez invité à vous connecter à votre compte personnel.\nParcourez et explorez librement Pinterest en suivant les invites (consultez la page d'exploration, parcourez les catégories suggérées et interagissez avec le contenu) jusqu'à ce que le minuteur soit écoulé.\nVous serez redirigé vers une enquête, complétez-la simplement et le tour est joué !",
                snapar: "Veuillez vous connecter à l'application attentionTRACE en utilisant le code de connexion unique ci-dessus.\nUne fois connecté, une collection de lentilles Snapchat AR vous sera présentée. \nVeuillez appuyer sur chaque objectif pour l'utiliser et interagir avec l'objectif comme vous le feriez normalement jusqu'à ce que le minuteur soit écoulé.",
                snapchat: `Une fois connecté, nous chargerons Snapchat et tout ce que vous aurez à faire est de sauter sur la page Découvrir et de découvrir tous les contenus drôles, fous, tristes et impressionnants que vous pouvez gérer jusqu'à la fin du temps imparti.\nIl ne pourrait pas être plus simple de gagner des récompenses avec Blinq. Alors, commençons!`
            }
        },
        "MturkGazeDesktopCompletion": {
            "subtitle": "Utilisez ce code pour vous connecter à attentionTRACE:"
        },
        "LoginAndCompletionDeskSolution": {
            "subtitle": ""
        },
        "SessionInformationDeskSolution": {
            "description": ""
        },
        "Error": {
            title: "Ah non!",
            subTitle: "Une erreur inattendue s’est produite s’il vous plait essayez plus tard",
        },
        Setup: [
            {
                key: "loginCode",
                title: "Étape 1 : Enregistrez votre identifiant de connexion",
                description: "Ce code sera votre clé pour vous connecter à l'application Blinq Research et commencer à gagner. Conservez-le dans un endroit sûr, au cas où."
            },
            {
                key: "appDownload",
                title: "Étape 2 : Téléchargez l'application Blinq Research",
                description: "Scannez le code QR à l'aide de l'appareil photo de votre téléphone ou cliquez sur le logo App Store pour le télécharger directement."
            }
        ],
        StartEarning: `Une fois que vous avez téléchargé l'application, il est temps de saisir votre identifiant de connexion.`
    },
    StepsFooter: {
        description: "Si vous rencontrez des problèmes, vous pouvez {contactUs}. Veuillez inclure votre {loginIdString} dans votre requête par e-mail.",
        contactUs: "nous contacter",
        privacyPolicy: "Politique de confidentialité"
    }
}
export default FrLangStrings