import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsDeskSolutionKey } from "../../LandingPage/Components/Steps/LoginAndCompletionDeskSolution";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { TWIH0123CollGroupId } from "respondent_config_generator/src/projects/TWIH-0123";

export type { TWIH0123CollGroupId } from "respondent_config_generator/src/projects/TWIH-0123";
export { TWIH0123CollGroupIds } from "respondent_config_generator/src/projects/TWIH-0123";

const getDeskPlatformForGroupId = (groupId: TWIH0123CollGroupId): PlatformInstructionsDeskSolutionKey => {
    switch (groupId) {
        case "TWIH-268-0123-US-DESKTOP-ITW":
        case "TWIH-269-0123-UK-DESKTOP-ITW":
        case "TWIH-270-0123-AU-DESKTOP-ITW":
        case "TWIH-271-0123-US-DESKTOP-INT":
        case "TWIH-272-0123-UK-DESKTOP-INT":
        case "TWIH-273-0123-AU-DESKTOP-INT":

            return "twitch-desktop";

        default:
            assertNever(groupId)
    }
}

export const configureTWIH0123Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<TWIH0123CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getDeskStandardViewingSurveySteps(getDeskPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}