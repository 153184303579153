import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateExternalSurveyTask } from "../tasks";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const SPOT0123CollGroupIds = [
    "SPOT-264-0123-AU-MUSIC",
    "SPOT-265-0123-AU-PODCAST",
    "SPOT-266-0123-NZ-MUSIC",
    "SPOT-267-0123-NZ-PODCAST"
] as const;

export type SPOT0123CollGroupId = typeof SPOT0123CollGroupIds[number];

export const SPOT0123CollWebPlatformIds = [
    "SPOT-0123-AU-MUSIC",
    "SPOT-0123-AU-PODCAST",
    "SPOT-0123-NZ-MUSIC",
    "SPOT-0123-NZ-PODCAST"
] as const;

export type SPOT0123CollWebPlatformId = typeof SPOT0123CollWebPlatformIds[number]

type SPOT0123CollConfig = {
    groupId: SPOT0123CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: SPOT0123CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "SPOT-0123-AU-MUSIC":
            return "13"
        case "SPOT-0123-AU-PODCAST":
            return "9"
        case "SPOT-0123-NZ-MUSIC":
            return "12"
        case "SPOT-0123-NZ-PODCAST":
            return "10";

        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: SPOT0123CollGroupId): string => {
    switch (groupId) {
        case "SPOT-264-0123-AU-MUSIC":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPOT-0123/SPOT-0123-AU-MUSIC.json"

        case "SPOT-265-0123-AU-PODCAST":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPOT-0123/SPOT-0123-AU-PODCAST.json"

        case "SPOT-266-0123-NZ-MUSIC":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPOT-0123/SPOT-0123-NZ-MUSIC.json"

        case "SPOT-267-0123-NZ-PODCAST":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPOT-0123/SPOT-0123-NZ-PODCAST.json"

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: SPOT0123CollGroupId): SPOT0123CollWebPlatformId => {
    switch (groupId) {
        case "SPOT-264-0123-AU-MUSIC": return "SPOT-0123-AU-MUSIC";
        case "SPOT-265-0123-AU-PODCAST": return "SPOT-0123-AU-PODCAST";
        case "SPOT-266-0123-NZ-MUSIC": return "SPOT-0123-NZ-MUSIC";
        case "SPOT-267-0123-NZ-PODCAST": return "SPOT-0123-NZ-PODCAST";
        default:
            assertNever(groupId);
    }
}


const getSurveyUrl = (groupId: SPOT0123CollGroupId): string => {
    switch (groupId) {
        case "SPOT-264-0123-AU-MUSIC": return 'https://survey.au1.qualtrics.com/jfe/form/SV_aa4um4EW9RjPffM';
        case "SPOT-265-0123-AU-PODCAST": return 'https://survey.au1.qualtrics.com/jfe/form/SV_6JB6FNB79luN1s2';
        case "SPOT-266-0123-NZ-MUSIC": return 'https://survey.au1.qualtrics.com/jfe/form/SV_e3fHNc3QeotkVnw';
        case "SPOT-267-0123-NZ-PODCAST": return 'https://survey.au1.qualtrics.com/jfe/form/SV_8k5BVWav9Opl9Ii';

        default:
            assertNever(groupId);
    }
}

export const addSPOT0123CollConfig = (
    config: SPOT0123CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .setFlag('noPermissionsRequired', true);

    let webplatformId = getWebPlatformId(config.groupId);
    const webTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: "General",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: [...config.metaFieldNames, "webPlatform_playListID"],
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });
    
    builder
        .addTask(webTask)
        .addTask(surveyTask)
        .addTask(uploadsTask);

    return builder;
}