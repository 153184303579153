import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateExternalSurveyTask } from "../tasks";
import { OMME1022WebGroupId } from "./OMME-1022";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const TEAD1222CollGroupIds = [
    "TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED",
    "TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED",
    "TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED",
    "TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED",
    "TEAD-252-1222-US-ESPN-DELISH-EXPOSED"
] as const;

export type TEAD1222CollGroupId = typeof TEAD1222CollGroupIds[number]

export const TEAD1222CollWebPlatformIds = [
    "TEAD-1222-US-PIONEER-EXPOSED",
    "TEAD-1222-US-COSMOPOLITAN-EXPOSED",
    "TEAD-1222-US-TASTY-EXPOSED",
    "TEAD-1222-US-COUNTRYLIVING-EXPOSED",
    "TEAD-1222-US-HOUSEBEAUTIFUL-EXPOSED",
    "TEAD-1222-US-HEALTHLINE-EXPOSED",
    "TEAD-1222-US-GOODHOUSEKEEPING-EXPOSED",
    "TEAD-1222-US-THEKITCHN-EXPOSED",
    "TEAD-1222-US-ESPN-EXPOSED",
    "TEAD-1222-US-DELISH-EXPOSED",
] as const;

export type TEAD1222CollWebPlatformId = typeof TEAD1222CollWebPlatformIds[number]

type TEAD1222CollConfig = {
    groupId: TEAD1222CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: TEAD1222CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "TEAD-1222-US-PIONEER-EXPOSED":
        case "TEAD-1222-US-COSMOPOLITAN-EXPOSED":
        case "TEAD-1222-US-TASTY-EXPOSED":
        case "TEAD-1222-US-COUNTRYLIVING-EXPOSED":
        case "TEAD-1222-US-HOUSEBEAUTIFUL-EXPOSED":
        case "TEAD-1222-US-HEALTHLINE-EXPOSED":
        case "TEAD-1222-US-GOODHOUSEKEEPING-EXPOSED":
        case "TEAD-1222-US-THEKITCHN-EXPOSED":
        case "TEAD-1222-US-ESPN-EXPOSED":
        case "TEAD-1222-US-DELISH-EXPOSED":
            return "7";

        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: TEAD1222CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-PIONEER-EXPOSED.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-COSMOPOLITAN-EXPOSED.json";

        case "TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-TASTY-EXPOSED.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-COUNTRYLIVING-EXPOSED.json";

        case "TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-HOUSEBEAUTIFUL-EXPOSED.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-HEALTHLINE-EXPOSED.json";

        case "TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-GOODHOUSEKEEPING-EXPOSED.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-THEKITCHN-EXPOSED.json";

        case "TEAD-252-1222-US-ESPN-DELISH-EXPOSED":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-ESPN-EXPOSED.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TEAD-1222/TEAD-1222-US-DELISH-EXPOSED.json";

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: TEAD1222CollGroupId, taskIndex: 0 | 1): TEAD1222CollWebPlatformId => {
    switch (groupId) {
        case "TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED":
            return taskIndex === 0
                ? "TEAD-1222-US-PIONEER-EXPOSED"
                : "TEAD-1222-US-COSMOPOLITAN-EXPOSED";
            
        case "TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED":
            return taskIndex === 0
                ? "TEAD-1222-US-TASTY-EXPOSED"
                : "TEAD-1222-US-COUNTRYLIVING-EXPOSED";
            
        case "TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED":
            return taskIndex === 0
                ? "TEAD-1222-US-HOUSEBEAUTIFUL-EXPOSED"
                : "TEAD-1222-US-HEALTHLINE-EXPOSED";
            
        case "TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED":
            return taskIndex === 0
                ? "TEAD-1222-US-GOODHOUSEKEEPING-EXPOSED"
                : "TEAD-1222-US-THEKITCHN-EXPOSED";
            
        case "TEAD-252-1222-US-ESPN-DELISH-EXPOSED":
            return taskIndex === 0
                ? "TEAD-1222-US-ESPN-EXPOSED"
                : "TEAD-1222-US-DELISH-EXPOSED";

        default:
            assertNever(groupId);
    }
}


const getSurveyUrl = (groupId: TEAD1222CollGroupId): string => {
    switch (groupId) {
        case 'TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_1ZfqDZuLZMh2CIS';
        case 'TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_0oDfPpWVXB7htGe';
        case 'TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED': return "https://survey.au1.qualtrics.com/jfe/form/SV_9GdVfGV6z806VYW";
        case 'TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED': return "https://survey.au1.qualtrics.com/jfe/form/SV_bl11btaUDR8I3sy";
        case 'TEAD-252-1222-US-ESPN-DELISH-EXPOSED': return "https://survey.au1.qualtrics.com/jfe/form/SV_dp7gqoOQFLTr88K";

        default:
            assertNever(groupId);
    }
}

export const addTEAD1222CollConfig = (
    config: TEAD1222CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE);

    let webplatformId = getWebPlatformId(config.groupId, 0);
    const webTask1 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: "General",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });
    builder.addTask(webTask1);

    webplatformId = getWebPlatformId(config.groupId, 1);
    const webTask2 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 1),
        platform: "General",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    // Modify task Id to make it unique.
    webTask2.meta.taskID = `${webTask2.meta.taskID}-2`;
    builder.addTask(webTask2);

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });
    builder
        .addTask(surveyTask)
        .addTask(uploadsTask);

    return builder;
}