import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { ConfigKey } from "../../configKey";

import { TWIT1221CollAllGroupIds, TWIT1221CollGroupId } from "respondent_config_generator/src/projects/TWIT-1221";
export { TWIT1221CollAllGroupIds } from 'respondent_config_generator/src/projects/TWIT-1221'

export type { TWIT1221CollGroupId } from 'respondent_config_generator/src/projects/TWIT-1221'

export function isTwit1221GroupParams(params: CAIParams<ConfigKey>): params is CAIParams<TWIT1221CollGroupId> {
    return (TWIT1221CollAllGroupIds as ReadonlyArray<string>).includes(params.G)
}

const getPlatformForGroupId = (groupId: TWIT1221CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "TWIT-30-1221-UK-TWITTER":
        case "TWIT-38-1221-CAN-TWITTER":
        case "TWIT-46-1221-FRA-TWITTER":
        case "TWIT-54-1221-MEX-TWITTER":
            return "twitter";

        case "TWIT-31-1221-UK-INSTAGRAM":
        case "TWIT-39-1221-CAN-INSTAGRAM":
        case "TWIT-47-1221-FRA-INSTAGRAM":
        case "TWIT-55-1221-MEX-INSTAGRAM":
            return "instagram-newsfeed";

        case "TWIT-32-1221-UK-FACEBOOK":
        case "TWIT-40-1221-CAN-FACEBOOK":
        case "TWIT-48-1221-FRA-FACEBOOK":
        case "TWIT-56-1221-MEX-FACEBOOK":
            return "facebook-newsfeed";

        case "TWIT-34-1221-UK-YOUTUBE":
        case "TWIT-42-1221-CAN-YOUTUBE":
        case "TWIT-50-1221-FRA-YOUTUBE":
        case "TWIT-58-1221-MEX-YOUTUBE":
            return "youtube";

        case "TWIT-33-1221-UK-TIKTOK":
        case "TWIT-41-1221-CAN-TIKTOK":
        case "TWIT-49-1221-FRA-TIKTOK":
        case "TWIT-57-1221-MEX-TIKTOK":
            return "tiktok"

        case "TWIT-35-1221-UK-TWITTER-TIKTOK":
        case "TWIT-43-1221-CAN-TWITTER-TIKTOK":
        case "TWIT-51-1221-FRA-TWITTER-TIKTOK":
        case "TWIT-59-1221-MEX-TWITTER-TIKTOK":
        case "TWIT-36-1221-UK-TWITTER-INSTAGRAM":
        case "TWIT-37-1221-UK-TWITTER-FACEBOOK":
        case "TWIT-44-1221-CAN-TWITTER-INSTAGRAM":
        case "TWIT-45-1221-CAN-TWITTER-FACEBOOK":
        case "TWIT-52-1221-FRA-TWITTER-INSTAGRAM":
        case "TWIT-53-1221-FRA-TWITTER-FACEBOOK":
        case "TWIT-60-1221-MEX-TWITTER-INSTAGRAM":
        case "TWIT-61-1221-MEX-TWITTER-FACEBOOK":
            return "webplatform-synergy"

        default:
            assertNever(groupId)
    }
}

const getLanguageForGroupId = (groupId: TWIT1221CollGroupId): AppLanguage => {
    switch (groupId) {
        case "TWIT-30-1221-UK-TWITTER":
        case "TWIT-31-1221-UK-INSTAGRAM":
        case "TWIT-32-1221-UK-FACEBOOK":
        case "TWIT-34-1221-UK-YOUTUBE":
        case "TWIT-36-1221-UK-TWITTER-INSTAGRAM":
        case "TWIT-37-1221-UK-TWITTER-FACEBOOK":
        case "TWIT-38-1221-CAN-TWITTER":
        case "TWIT-39-1221-CAN-INSTAGRAM":
        case "TWIT-40-1221-CAN-FACEBOOK":
        case "TWIT-42-1221-CAN-YOUTUBE":
        case "TWIT-44-1221-CAN-TWITTER-INSTAGRAM":
        case "TWIT-45-1221-CAN-TWITTER-FACEBOOK":
        case "TWIT-33-1221-UK-TIKTOK":
        case "TWIT-41-1221-CAN-TIKTOK":
        case "TWIT-35-1221-UK-TWITTER-TIKTOK":
        case "TWIT-43-1221-CAN-TWITTER-TIKTOK":
            return AppLanguage.en;

        case "TWIT-46-1221-FRA-TWITTER":
        case "TWIT-47-1221-FRA-INSTAGRAM":
        case "TWIT-48-1221-FRA-FACEBOOK":
        case "TWIT-50-1221-FRA-YOUTUBE":
        case "TWIT-52-1221-FRA-TWITTER-INSTAGRAM":
        case "TWIT-53-1221-FRA-TWITTER-FACEBOOK":
        case "TWIT-49-1221-FRA-TIKTOK":
        case "TWIT-51-1221-FRA-TWITTER-TIKTOK":
            return AppLanguage.fr;

        case "TWIT-54-1221-MEX-TWITTER":
        case "TWIT-55-1221-MEX-INSTAGRAM":
        case "TWIT-56-1221-MEX-FACEBOOK":
        case "TWIT-58-1221-MEX-YOUTUBE":
        case "TWIT-60-1221-MEX-TWITTER-INSTAGRAM":
        case "TWIT-61-1221-MEX-TWITTER-FACEBOOK":
        case "TWIT-57-1221-MEX-TIKTOK":
        case "TWIT-59-1221-MEX-TWITTER-TIKTOK":
            return AppLanguage.es;

        default:
            assertNever(groupId)
    }
}

export const configureTWIT1221Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<TWIT1221CollGroupId>
): void => {
    setCurrentLang(getLanguageForGroupId(params.G))
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}