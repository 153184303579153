import EnLangStrings from "internationalisation/en";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import {
    generateBvodTask,
    generateExternalSurveyTask,
    generateLinearTvTask,
    generateWebRequestTask,
    generateUploadsTask
} from "../tasks";

export type FXTL0722CollTvGroupId =
    | 'FXTL-140-FOXTEL-LIVE'
    | 'FXTL-141-KAYO-LIVE'
    | 'FXTL-142-FTA-LIVE'
    | 'FXTL-143-BVOD-TV'

export type FXTL0722CollBvodGroupId =
    | 'FXTL-145-BVOD-M'

export type FXTL0722CollGroupId =
    | FXTL0722CollTvGroupId
    | FXTL0722CollBvodGroupId


type FXTL0722TvCollConfig = {
    groupId: FXTL0722CollGroupId,
    viewingCount: number,
    metaFieldNames: ReadonlyArray<string>,
    user: string,
    email: string
}

type FXTL0722BvodMobileConfig = {
    groupId: FXTL0722CollBvodGroupId,
    metaFieldNames: ReadonlyArray<string>
}

type FXTL0722CollConfig =
    | FXTL0722TvCollConfig
    | FXTL0722BvodMobileConfig


/**
 *
 * @param groupId
 *
 *  the BVOD-TV logins 1, 2, 3 and BVOD mobile groups get an external survey
 *  Foxtel, Kayo, FTA and BVOD-TV login 4 get web requests
 */
function getTVSurveyURL (
    viewingCount: number
): string {
    switch (viewingCount) {
        case 1: 
            return "https://survey.au1.qualtrics.com/jfe/form/SV_eKiwHKLrxu4vFC6"
        case 2: 
            return "https://survey.au1.qualtrics.com/jfe/form/SV_8idAlXEE7oSmDNY"
        case 3:
            return "https://survey.au1.qualtrics.com/jfe/form/SV_b11NAOcsnlYIm5o"
        
        default: throw new RangeError('BVOD TV viewing count must be between 1 and 3 (inclusive) for external survey task!')
    }
}

function getBVODMobileSurveyURL (): string {
    return "https://survey.au1.qualtrics.com/jfe/form/SV_cZVKNOdntCbM1oy";
}

function addLinearTvConfig(
    builder: RespondentConfigBuilder,
    groupId: FXTL0722CollTvGroupId,
    email: string,
    user: string
) {
    /** tangocard web request */
    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/FXTL-0722/${groupId}_INCENTIVE.json`,
        params: {
            r_user: user,
            r_email: email
        },
        taskID: groupId + '-WEBREQUEST'
    });

    const linearTvTask = generateLinearTvTask({
        title: '',
        description: '',
        taskID: groupId + '-LINEARTV',
        viewingLengthOptions: [30, 60, 90, 120]
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: groupId + '-UPLOADS',
    });

    builder
        .addTask(linearTvTask)
        .addTask(webRequestTask)
        .addTask(uploadsTask)
}

function getBvodChannelUrl(
    viewingCount: number
): string {
    switch(viewingCount) {
        case 1:
            return "https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/FXTL-0722-VIEW1.json";
        case 2:
            return "https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/FXTL-0722-VIEW2.json"
        case 3:
            return "https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/FXTL-0722-VIEW3.json"
        case 4:
            return "https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/FXTL-0722-VIEWOPEN.json";

        default: throw new RangeError('BVOD TV viewing count must be between 1 and 4 (inclusive)!')
    }
}

function addBvodTVConfig(
    builder: RespondentConfigBuilder,
    groupId: FXTL0722CollTvGroupId,
    viewingCount: number,
    user: string,
    email: string,
    metaFieldNames: ReadonlyArray<string>
): void {
    const bvodTvTask = generateBvodTask({
        taskID: groupId + '-BVOD',
        channelsJsonUrl: getBvodChannelUrl(viewingCount),
        isTv: true,
        langStrings: EnLangStrings,
        isRadio: false
    });

    builder.addTask(bvodTvTask)

    // login A, B and C get external surveys
    if (viewingCount <= 3) {
        const externalSurveyTask = generateExternalSurveyTask({
            title: '',
            description: '',
            surveyUrl: getTVSurveyURL(viewingCount),
            taskID: groupId + '-SURVEY',
            extraMetaFields: metaFieldNames
        });

        builder.addTask(externalSurveyTask);
    }
    // login D gets a web request
    else if (viewingCount === 4) {
        /** tangocard web request for bvod TV login 4 */
        const webRequestTask = generateWebRequestTask({
            webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/FXTL-0722/${groupId}_INCENTIVE.json`,
            params: {
                r_user: user,
                r_email: email
            },
            taskID: groupId + '-WEBREQUEST'
        });
        
        builder.addTask(webRequestTask);
    }
    else {
        throw new RangeError("BVOD-TV groups are limited to 4 login IDs")
    }

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: groupId + '-UPLOADS',
    });

    builder.addTask(uploadsTask)
}

function addBvodMobileConfig(
    builder: RespondentConfigBuilder,
    groupId: FXTL0722CollBvodGroupId,
    metaFieldNames: ReadonlyArray<string>
): void {
    const bvodMobileTask = generateBvodTask({
        taskID: groupId + '-BVOD',
        channelsJsonUrl: 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/FXTL-0722-BVODMOBILE.json',
        langStrings: EnLangStrings,
        isTv: false,
        isRadio: false
    });

    const externalSurveyTask = generateExternalSurveyTask({
        title: '',
        description: '',
        surveyUrl: getBVODMobileSurveyURL(),
        taskID: groupId + '-SURVEY',
        extraMetaFields: [...metaFieldNames, "bvodTV_selectedChannel"]
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: groupId + '-UPLOADS',
    });

    builder
        .addTask(bvodMobileTask)
        .addTask(externalSurveyTask)
        .addTask(uploadsTask)
}


const TV_FINISH_MESSAGE = 'Thank you for your participation today. Your reward will be emailed to you shortly. Please leave the device charging & locked with this app screen open to ensure your response is recorded. Keep your eyes peeled on your e-mail for further instructions.';

export const addFoxtelCollConfig = (
    config: FXTL0722CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('minimumIOSVersion', '15.0')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', TV_FINISH_MESSAGE)
        .setFlag('preventTaskProgress', true)

    switch (config.groupId) {
        case 'FXTL-141-KAYO-LIVE':
        case 'FXTL-142-FTA-LIVE':
        case 'FXTL-140-FOXTEL-LIVE': {
            const tvConfig = config as FXTL0722TvCollConfig;

            addLinearTvConfig(
                builder,
                config.groupId,
                tvConfig.email,
                tvConfig.user
            );
            break;
        }

        /** BVOD mobile is limited to 1 view */
        case 'FXTL-145-BVOD-M': {
            const meta = config as FXTL0722TvCollConfig;

            addBvodMobileConfig(builder, config.groupId, meta.metaFieldNames);
            break;
        }

        case 'FXTL-143-BVOD-TV': {
            const meta = config as FXTL0722TvCollConfig;

            addBvodTVConfig(
                builder,
                config.groupId,
                meta.viewingCount,
                meta.user,
                meta.email,
                meta.metaFieldNames
            );
            break;
        }
    }

    return builder;
}
