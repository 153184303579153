import { GroupId } from 'respondent_config_generator/src/types';
import { LoginIdApiStage } from '.';
/**
 * Splits a valid loginId into GroupChar & LoginIdInt
 * @example
 * const loginId = "DEV123"
 * const [groupChar, loginIdInt] = loginIdSplitter(loginId) // returns ['DEV', '123']
 * @param loginId loginId to split
 * @returns An array of 2 strings, GroupChar the
 */
export const loginIdSplitter = (loginId: string): [string, string] => {
    const result = /(\D+)(\d+)/gi.exec(loginId)
    if (!result) {
        throw new Error(`Invalid loginID. LoginId=${loginId}.`);
    }
    const [, groupKey, loginIdInt] = result

    return [groupKey, loginIdInt]
}

export const getLoginIdGroupChar = (groupId: GroupId): string => {
    switch (groupId) {
        case 'OMDG-1A':
            return 'D'
        case 'OMDG-1B':
            return 'E'
        case 'OMDG-1C':
            return 'F'
        case 'OMDG-2A':
            return 'G'
        case 'OMDG-2B':
            return 'H'
        case 'OMDG-2C':
            return 'I'
        case 'OMDG-3A':
            return 'J'
        case 'OMDG-3B':
            return 'K'
        case 'OMDG-3C':
            return 'L'
        case 'OMDG-4':
            return 'M'
        case 'OMDG-5':
            return 'N'

        case 'AREM-16-HTL':
            return 'Q'
        case 'AREM-15-MC':
            return 'P'
        case 'AREM-17-NTL':
            return 'R'

        case 'AUST-18-NEWSFEED':
            return 'S'
        case 'AUST-19-WATCH':
            return 'T'
        case 'AUST-20-STORIES':
            return 'U'
        case 'AUST-21-NEWSFEED':
            return 'V'
        case 'AUST-22-STORIES':
            return 'W'
        case 'AUST-23':
            return 'X'
        case 'AUST-24':
            return 'Y'

        case 'C9AU-25-1021-LINEARTV':
            return 'Z'
        case 'C9AU-26-1021-BVODTV':
            return 'BA'
        case 'C9AU-27-1021-BVOD':
            return 'BB'

        case 'FINE-28-1121-LINEARTV':
        case 'FINE-1121-UPLOAD':
            return 'BC'

        case 'FINE-29-1121-BVODTV':
            return 'BD'

        case 'TWIT-30-1221-UK-TWITTER': return 'BE'
        case 'TWIT-31-1221-UK-INSTAGRAM': return 'BF'
        case 'TWIT-32-1221-UK-FACEBOOK': return 'BG'
        case 'TWIT-34-1221-UK-YOUTUBE': return 'BI'
        case 'TWIT-36-1221-UK-TWITTER-INSTAGRAM': return 'BK'
        case 'TWIT-37-1221-UK-TWITTER-FACEBOOK': return 'BL'

        case 'TWIT-38-1221-CAN-TWITTER': return 'BM'
        case 'TWIT-39-1221-CAN-INSTAGRAM': return 'BN'
        case 'TWIT-40-1221-CAN-FACEBOOK': return 'BO'
        case 'TWIT-42-1221-CAN-YOUTUBE': return 'BQ'
        case 'TWIT-44-1221-CAN-TWITTER-INSTAGRAM': return 'BS'
        case 'TWIT-45-1221-CAN-TWITTER-FACEBOOK': return 'BT'

        case 'TWIT-46-1221-FRA-TWITTER': return 'BU'
        case 'TWIT-47-1221-FRA-INSTAGRAM': return 'BV'
        case 'TWIT-48-1221-FRA-FACEBOOK': return 'BW'
        case 'TWIT-50-1221-FRA-YOUTUBE': return 'BY'
        case 'TWIT-52-1221-FRA-TWITTER-INSTAGRAM': return 'CA'
        case 'TWIT-53-1221-FRA-TWITTER-FACEBOOK': return 'CB'

        case 'TWIT-54-1221-MEX-TWITTER': return 'CC'
        case 'TWIT-55-1221-MEX-INSTAGRAM': return 'CD'
        case 'TWIT-56-1221-MEX-FACEBOOK': return 'CE'
        case 'TWIT-58-1221-MEX-YOUTUBE': return 'CG'
        case 'TWIT-60-1221-MEX-TWITTER-INSTAGRAM': return 'CI'
        case 'TWIT-61-1221-MEX-TWITTER-FACEBOOK': return 'CJ'

        case "TWIT-33-1221-UK-TIKTOK": return "BH"
        case "TWIT-41-1221-CAN-TIKTOK": return "BP"
        case "TWIT-49-1221-FRA-TIKTOK": return "BX"
        case "TWIT-57-1221-MEX-TIKTOK": return "CF"

        case "TWIT-35-1221-UK-TWITTER-TIKTOK": return "BJ"
        case "TWIT-43-1221-CAN-TWITTER-TIKTOK": return "BR"
        case "TWIT-51-1221-FRA-TWITTER-TIKTOK": return "BZ"
        case "TWIT-59-1221-MEX-TWITTER-TIKTOK": return "CH"

        case 'MTURK-TEST-GROUP':
            throw new Error(`LoginIdGroupChar requested for groupID "${groupId}"`)

        case 'C7AU-62-0122-LINEARTV':
            return 'CK'
        case 'C7AU-63-0122-CTV':
            return 'CL'

        case "REDC-64-0122-IRL-TWITTER-INFEEDVIDEO": return "CM"
        case "REDC-65-0122-IRL-TWITTER-INFEEDSTATIC": return "CN"

        case "REDC-66-0122-IRL-INSTAGRAM-INFEED": return "CO"
        case "REDC-67-0122-IRL-INSTAGRAM-STORIES": return "CP"

        case "REDC-68-0122-IRL-YOUTUBE-PREROLL": return "CQ"
        case "REDC-69-0122-IRL-YOUTUBE-EXTRAS": return "CR"

        case "REDC-70-0122-IRL-FACEBOOK-INFEED": return "CS"
        case "REDC-71-0122-IRL-FACEBOOK-STORIES": return "CT"

        case "YAHO-72-0422-USA-YAHOO-ITW": return "CU"
        case "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW": return "CV"
        case "YAHO-74-0422-USA-FINANCE-ITW": return "CW"
        case "YAHO-75-0422-USA-ENTERTAINMENT-ITW": return "CX"
        case "YAHO-76-0422-USA-LIFESTYLE-ITW": return "CY"
        case "YAHO-83-0422-CAN-YAHOO-ITW": return "DF"
        case "YAHO-84-0422-CAN-SPORTS-ITW": return "DG"
        case "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW": return "DH"
        case "YAHO-86-0422-CAN-FINANCE-ITW": return "DI"
        case "YAHO-87-0422-CAN-AOL-ITW": return "DJ"
        case "YAHO-93-0422-AUS-LIFESTYLE-ITW": return "DP"
        case "YAHO-94-0422-AUS-YAHOO-ITW": return "DQ"
        case "YAHO-95-0422-AUS-FINANCE-ITW": return "DR"
        case "YAHO-96-0422-AUS-SPORTS-ITW": return "DS"
        case "YAHO-102-0422-UK-YAHOO-ITW": return "DY"
        case "YAHO-103-0422-UK-AOL-ITW": return "DZ"
        case "YAHO-104-0422-UK-FINANCE-ITW": return "EA"
        case "YAHO-105-0422-UK-STYLE-ITW": return "EB"
        case "YAHO-106-0422-UK-SPORTS-ITW": return "EC"

        case "YAHO-77-0422-USA-YAHOO-EXPOSED": return "CZ"
        case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED": return "DA"
        case "YAHO-79-0422-USA-FINANCE-EXPOSED": return "DB"
        case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED": return "DC"
        case "YAHO-81-0422-USA-MSN-EXPOSED": return "DD"
        case "YAHO-88-0422-CAN-YAHOO-EXPOSED": return "DK"
        case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED": return "DL"
        case "YAHO-90-0422-CAN-SPORTS-EXPOSED": return "DM"
        case "YAHO-91-0422-CAN-FINANCE-EXPOSED": return "DN"
        case "YAHO-92-0422-CAN-MSN-EXPOSED": return "DO"
        case "YAHO-97-0422-AUS-YAHOO-EXPOSED": return "DT"
        case "YAHO-98-0422-AUS-FINANCE-EXPOSED": return "DU"
        case "YAHO-99-0422-AUS-MSN-EXPOSED": return "DV"
        case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED": return "DW"
        case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED": return "DX"
        case "YAHO-107-0422-UK-YAHOO-EXPOSED": return "ED"
        case "YAHO-108-0422-UK-AOL-EXPOSED": return "EE"
        case "YAHO-109-0422-UK-MSN-EXPOSED": return "EF"
        case "YAHO-110-0422-UK-FINANCE-EXPOSED": return "EG"
        case "YAHO-111-0422-UK-SPORTS-EXPOSED": return "EH"
        case "YAHO-112-0422-AUS-SPORTS-EXPOSED": return "EI"
        case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED": return "FS"

        case "ODEU-113-0422-DEU-FACEBOOK-INFEED": return "EJ"
        case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM": return "EK"
        case "ODEU-115-0422-DEU-FACEBOOK-STORIES": return "EL"
        case "ODEU-116-0422-DEU-YOUTUBE-PREROLL": return "EM"
        case "ODEU-117-0422-DEU-YOUTUBE-BONUS": return "EN"
        case "ODEU-118-0422-DEU-TWITTER-INFEED": return "EO"

        case "GAZE-119-0422-MTURK-GAZE": return "EP"

        case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP": return "EQ"
        case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV": return "ER"
        case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP": return "ES"
        case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV": return "ET"
        case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP": return "EU"
        case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV": return "EV"
        case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP": return "EW"
        case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV": return "EX"
        case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP": return "EY"
        case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV": return "EZ"
        case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP": return "FA"
        case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV": return "FB"
        case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP": return "FC"
        case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV": return "FD"
        case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP": return "FE"
        case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV": return "FF"
        case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP": return "FG"
        case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV": return "FH"
        case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP": return "FI"
        case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV": return "FJ"
        case "FXTL-140-FOXTEL-LIVE": return "FK"
        case "FXTL-141-KAYO-LIVE": return "FL"
        case "FXTL-142-FTA-LIVE": return "FM"
        case "FXTL-143-BVOD-TV": return "FN"
        case "FXTL-145-BVOD-M": return "FP"
        case "ADCO-146-0622-UK-ADCOLONY": return "FQ"

        case "CRAA-152-0822-SCA-ADL-BREAKFAST": return "FW"
        case "CRAA-153-0822-SCA-SYD-BREAKFAST": return "FX"
        case "CRAA-154-0822-SCA-MEL-BREAKFAST": return "FY"
        case "CRAA-155-0822-SCA-BNE-BREAKFAST": return "FZ"
        case "CRAA-156-0822-SCA-PER-BREAKFAST": return "GA"
        case "CRAA-157-0822-SCA-ALL-MORNAFT": return "GB"
        case "CRAA-158-0822-SCA-ALL-DRIVE": return "GC"
        case "CRAA-159-0822-SCA-ALL-EVENINGS": return "GD"
        case "CRAA-160-0822-SCA-PODCAST-THEBRIEFING": return "GE"
        case "CRAA-161-0822-SCA-PODCAST-HAMISHANDY": return "GF"
        case "CRAA-162-0822-ARN-ADL-BREAKFAST": return "GG"
        case "CRAA-163-0822-ARN-SYD-BREAKFAST": return "GH"
        case "CRAA-164-0822-ARN-MEL-BREAKFAST": return "GI"
        case "CRAA-165-0822-ARN-BNE-BREAKFAST": return "GJ"
        case "CRAA-166-0822-ARN-PER-BREAKFAST": return "GK"
        case "CRAA-167-0822-ARN-ALL-MORNAFT": return "GL"
        case "CRAA-168-0822-ARN-ALL-DRIVE": return "GM"
        case "CRAA-169-0822-ARN-ADL-EVENING": return "GN"
        case "CRAA-170-0822-ARN-SYD-EVENING": return "GO"
        case "CRAA-171-0822-ARN-MEL-EVENING": return "GP"
        case "CRAA-172-0822-ARN-BNE-EVENING": return "GQ"
        case "CRAA-173-0822-ARN-PER-EVENING": return "GR"
        case "CRAA-174-0822-ARN-PODCAST-IMPERFECTS": return "GS"
        case "CRAA-175-0822-ARN-PODCAST-KYLEJACKIE": return "GT"
        case "CRAA-176-0822-ARN-PODCAST-LIFEUNCUT": return "GU"
        case "CRAA-177-0822-NINE-SYD-BREAKFAST": return "GV"
        case "CRAA-178-0822-NINE-MEL-BREAKFAST": return "GW"
        case "CRAA-179-0822-NINE-BNE-BREAKFAST": return "GX"
        case "CRAA-180-0822-NINE-PER-BREAKFAST": return "GY"
        case "CRAA-181-0822-NINE-SYD-MORNAFT": return "GZ"
        case "CRAA-182-0822-NINE-MEL-MORNAFT": return "HA"
        case "CRAA-183-0822-NINE-BNE-MORNAFT": return "HB"
        case "CRAA-184-0822-NINE-PER-MORNAFT": return "HC"
        case "CRAA-185-0822-NINE-SYD-DRIVE": return "HD"
        case "CRAA-186-0822-NINE-MEL-DRIVE": return "HE"
        case "CRAA-187-0822-NINE-BNE-DRIVE": return "HF"
        case "CRAA-188-0822-NINE-PER-DRIVE": return "HG"
        case "CRAA-189-0822-NINE-SYD-EVENING": return "HH"
        case "CRAA-190-0822-NINE-MEL-EVENING": return "HI"
        case "CRAA-191-0822-NINE-BNE-EVENING": return "HJ"
        case "CRAA-192-0822-NINE-PER-EVENING": return "HK"
        case "CRAA-193-0822-NINE-PODCAST-MONEYNEWS": return "HL"
        case "CRAA-194-0822-NOVA-ADL-BREAKFAST": return "HM"
        case "CRAA-195-0822-NOVA-SYD-BREAKFAST": return "HN"
        case "CRAA-196-0822-NOVA-MEL-BREAKFAST": return "HO"
        case "CRAA-197-0822-NOVA-BNE-BREAKFAST": return "HP"
        case "CRAA-198-0822-NOVA-PER-BREAKFAST": return "HQ"
        case "CRAA-199-0822-NOVA-AMBP-MORNAFT": return "HR"
        case "CRAA-200-0822-NOVA-SYD-MORNAFT": return "HS"
        case "CRAA-201-0822-NOVA-ALL-DRIVE": return "HT"
        case "CRAA-202-0822-NOVA-ALL-EVENING": return "HU"
        case "CRAA-203-0822-NOVA-PODCAST-HENNESSYS": return "HV"
        case "CRAA-204-0822-NOVA-PODCAST-KTJ": return "HW"
        case "CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE": return "HX"

        case "VIAB-206-0822-BE-NORTH-BVOD-M": return "HY"
        case "VIAB-207-0822-BE-SOUTH-BVOD-M": return "HZ"
        case "VIAB-149-0822-BE-NORTH-YOUTUBE": return "FT"
        case "VIAB-150-0822-BE-SOUTH-YOUTUBE": return "FU"
        case "VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2": return "IF"
        case "VIAB-214-0822-BE-SOUTH-BVOD-M-ROUND2": return "IG"

        case "ACAY-151-0822-AUS-YOUTUBE": return "FV"

        case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT": return "IA"
        case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT": return "IB"
        case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT": return "IC"
        case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT": return "ID"
        case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT": return "IE"

        case 'OMNZ-215-TVNZ-LIVE': return 'IH'
        case 'OMNZ-216-BVOD-TV': return 'II'
        case 'OMNZ-217-NZ-FACEBOOK': return 'IJ'
        case 'OMNZ-218-NZ-INSTAGRAM': return 'IK'
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL': return 'IL'
        case 'OMNZ-220-NZ-TWITTER-FEED': return 'IM'
        case 'OMNZ-221-NZ-TIKTOK-FEED': return 'IN'
        case 'OMNZ-222-NZ-STUFF-EXPOSED': return 'IO'
        case 'OMNZ-223-NZ-NZME-EXPOSED': return 'IP'
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT': return 'JN'

        case 'OMME-234-SAU-FACEBOOK': return 'JA'
        case 'OMME-235-SAU-INSTAGRAM': return 'JB'
        case 'OMME-236-SAU-YOUTUBE-PREROLL': return 'JC'
        case 'OMME-237-SAU-TWITTER-FEED': return 'JD'
        case 'OMME-238-SAU-TIKTOK-FEED': return 'JE'
        case 'OMME-239-SAU-ALMARSD-EXPOSED': return 'JF'
        case 'OMME-240-SAU-ALARABIYA-EXPOSED': return 'JG'
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED': return 'JH'
        case 'OMME-242-SAU-MOTORY-EXPOSED': return 'JI'
        case 'OMME-243-SAU-SO3ODY-EXPOSED': return 'JJ'
        case 'OMME-253-UAE-FACEBOOK': return 'JT'
        case 'OMME-254-UAE-INSTAGRAM': return 'JU'
        case 'OMME-255-UAE-YOUTUBE-PREROLL': return 'JV'
        case 'OMME-256-UAE-TWITTER-FEED': return 'JW'
        case 'OMME-257-UAE-TIKTOK-FEED': return 'JX'

        case "NEAU-225-1022-NEWS-HERALDSUN-BASELINE": return 'IR'
        case "NEAU-226-1022-NEWS-HERALDSUN-FREQUENCY": return 'IS'
        case "NEAU-227-1022-VOGUE-AUSTRALIAN-BASELINE": return 'IT'
        case "NEAU-228-1022-VOGUE-AUSTRALIAN-FREQUENCY": return 'IU'
        case "NEAU-229-1022-KIDSPOT-ESCAPE-BASELINE": return 'IV'
        case "NEAU-230-1022-KIDSPOT-ESCAPE-FREQUENCY": return 'IW'
        case "NEAU-231-1022-TASTE-DAILYTELEGRAPH-BASELINE": return 'IX'
        case "NEAU-232-1022-TASTE-DAILYTELEGRAPH-FREQUENCY": return 'IY'
        case "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD": return 'IZ'

        case "VIAB-245-0822-BE-NORTH-LINEARTV": return "JL"
        case "VIAB-246-0822-BE-SOUTH-LINEARTV": return "JM"

        case "TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED": return "JO"
        case "TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED": return "JP"
        case "TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED": return "JQ"
        case "TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED": return "JR"
        case "TEAD-252-1222-US-ESPN-DELISH-EXPOSED": return "JS"

        case "TWIC-258-1222-US-MOBILE-ITW": return "JY"
        case "TWIC-259-1222-UK-MOBILE-ITW": return "JZ"
        case "TWIC-260-1222-AU-MOBILE-ITW": return "KA"

        case 'DSTD-261-0223-MTURK-GAZE-DESKTOP': return 'KC'

        case "TWIH-268-0123-US-DESKTOP-ITW": return "KI"
        case "TWIH-269-0123-UK-DESKTOP-ITW": return "KJ"
        case "TWIH-270-0123-AU-DESKTOP-ITW": return "KK"
        case "TWIH-271-0123-US-DESKTOP-INT": return "KL"
        case "TWIH-272-0123-UK-DESKTOP-INT": return "KM"
        case "TWIH-273-0123-AU-DESKTOP-INT": return "KN"
        case "SPOT-264-0123-AU-MUSIC": return 'KE'
        case "SPOT-265-0123-AU-PODCAST": return 'KF'
        case "SPOT-266-0123-NZ-MUSIC": return 'KG'
        case "SPOT-267-0123-NZ-PODCAST": return 'KH'

        case "PEPS-274-0523-FACEBOOK-ESTABLISHED": return 'KO'
        case "PEPS-275-0523-FACEBOOK-NON": return 'KP'
        case "PEPS-276-0523-INSTAGRAM-ESTABLISHED": return 'KQ'
        case "PEPS-277-0523-INSTAGRAM-NON": return 'KR'
        case "PEPS-278-0523-YOUTUBE-ESTABLISHED": return 'KS'
        case "PEPS-279-0523-YOUTUBE-NON": return 'KT'
        case "PEPS-280-0523-TIKTOK-ESTABLISHED": return 'KU'
        case "PEPS-281-0523-TIKTOK-NON": return 'KV'

        case "GYGG-282-0623-NY-FACEBOOK": return 'KW'
        case "GYGG-283-0623-LA-FACEBOOK": return 'KX'
        case "GYGG-284-0623-NY-INSTAGRAM": return 'KY'
        case "GYGG-285-0623-LA-INSTAGRAM": return 'KZ'
        case "GYGG-286-0623-NY-YOUTUBE": return 'LA'
        case "GYGG-287-0623-LA-YOUTUBE": return 'LB'

        case "SPIN-288-0823-SPOTIFY": return 'LC'
        case "SPIN-289-0823-FACEBOOK": return 'LD'
        case "SPIN-290-0823-INSTAGRAM": return 'LE'
        case "SPIN-291-0823-YOUTUBE": return 'LF'
        case "SPIN-292-0823-FB-SPOT": return 'LG'
        case "SPIN-293-0823-IG-SPOT": return 'LH'
        case "SPIN-294-0823-YT-SPOT": return 'LI'

        case "SNAR-295-0823-VALIDATION": return 'LJ'

        case "PINT-326-1123-US-GROUP1": return 'MO'
        case "PINT-327-1123-US-GROUP2": return 'MP'
        case "PINT-328-1123-US-GROUP3": return 'MQ'
        case "PINT-329-1123-US-GROUP4": return 'MR'
        case "PINT-330-1123-UK-GROUP1": return 'MS'
        case "PINT-331-1123-UK-GROUP2": return 'MT'
        case "PINT-332-1123-UK-GROUP3": return 'MU'
        case "PINT-333-1123-UK-GROUP4": return 'MV'
        case "PINT-334-1123-AU-GROUP1": return 'MW'
        case "PINT-335-1123-AU-GROUP2": return 'MX'
        case "PINT-336-1123-AU-GROUP3": return 'MY'
        case "PINT-337-1123-AU-GROUP4": return 'MZ'
        case "PINT-338-1123-FR-GROUP1": return 'NA'
        case "PINT-339-1123-FR-GROUP2": return 'NB'
        case "PINT-340-1123-FR-GROUP3": return 'NC'
        case "PINT-341-1123-FR-GROUP4": return 'ND'
        case "PINT-342-1123-DE-GROUP1": return 'NE'
        case "PINT-343-1123-DE-GROUP2": return 'NF'
        case "PINT-344-1123-DE-GROUP3": return 'NG'
        case "PINT-345-1123-DE-GROUP4": return 'NH'
        case "PINT-346-1123-CA-GROUP1": return 'NI'
        case "PINT-347-1123-CA-GROUP2": return 'NJ'
        case "PINT-348-1123-CA-GROUP3": return 'NK'
        case "PINT-349-1123-CA-GROUP4": return 'NL'
        case "SKYN-296-0723-BVOD-MOBILE": return 'LK'

        case "RCTV-297-0523-BVODMOBILE": return 'LL'

        case "YAHD-299-0823-USA-YAHOO-DESKTOP": return 'LN'
        case "YAHD-300-0823-USA-SPORTS-DESKTOP": return 'LO'
        case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP": return 'LP'
        case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP": return 'LQ'
        case "YAHD-303-0823-USA-YAHOO-MOBILE": return 'LR'
        case "YAHD-304-0823-USA-SPORTS-MOBILE": return 'LS'
        case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE": return 'LT'
        case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE": return 'LU'
        case "YAHD-307-0823-AUS-YAHOO-MOBILE": return 'LV'
        case "YAHD-308-0823-AUS-SPORTS-MOBILE": return 'LW'
        case "YAHD-309-0823-AUS-FINANCE-MOBILE": return 'LX'
        case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE": return 'LY'
        case "YAHD-311-0823-AUS-YAHOO-DESKTOP": return 'LZ'
        case "YAHD-312-0823-AUS-SPORTS-DESKTOP": return 'MA'
        case "YAHD-313-0823-AUS-FINANCE-DESKTOP": return 'MB'
        case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP": return 'MC'
        
        case "SNAR-316-0823-UK-SCALED-1": return 'ME'
        case "SNAR-317-0823-UK-SCALED-2": return 'MF'
        case "SNAR-318-0823-US-SCALED-1": return 'MG'
        case "SNAR-319-0823-US-SCALED-2": return 'MH'

        case "GYGG-350-0124-US-YOUTUBE-1": return 'NM'
        case "GYGG-351-0124-US-YOUTUBE-2": return 'NN'

        case "SNAR-355-0823-FR-SCALED-1": return "NO"
        case "SNAR-356-0823-FR-SCALED-2": return "NP"
        case "SNAR-357-0823-SA-SCALED-1": return "NQ"
        case "SNAR-358-0823-SA-SCALED-2": return "NR"
        case "SNAR-359-0823-CA-SCALED-1": return "NS"
        case "SNAR-360-0823-CA-SCALED-2": return "NT"
        case "SNAR-361-0823-CA-SCALED-3": return "NU"
        case "SNAR-362-0823-AU-SCALED-1": return "NV"
        case "SNAR-363-0823-AU-SCALED-2": return "NW"

        case "SPAU-369-0324-SPOTIFY": return 'NX'

        case "GYGG-364-0124-US-FACEBOOK": return "NY"
        case "GYGG-365-0124-US-INSTAGRAM": return "NZ"
        case "GYGG-366-0124-US-TIKTOK": return "OA"
        case "GYGG-367-0124-US-YOUTUBE-3": return "OB"
        case "GYGG-368-0124-UK-YOUTUBE-4": return "OC"
        case "GYGG-370-0124-US-YOUTUBE-5": return "OD"
        case "GYGG-371-0124-UK-YOUTUBE-6": return "OE"

        case "ALLW-372-0424-UK-LOTTO-FACEBOOK": return "OF"
        case "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK": return "OG"
        case "ALLW-374-0424-UK-LOTTO-INSTAGRAM": return "OH"
        case "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM": return "OI"
        case "ALLW-376-0424-UK-LOTTO-TIKTOK": return "OJ"
        case "ALLW-377-0424-UK-LOTTO-YOUTUBE": return "OK"
        case "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE": return "OL"

        case "SNEM-379-0524-AU-SNAP-HIGH": return "OM"
        case "SNEM-380-0524-AU-SNAP-MED": return "ON"
        case "SNEM-381-0524-AU-SNAP-LOW": return "OO"
        case "SNEM-382-0524-AU-YOUTUBE": return "OP"
        case "SNEM-383-0524-AU-TIKTOK-HIGH": return "OQ"
        case "SNEM-384-0524-AU-TIKTOK-MED": return "OR"
        case "SNEM-385-0524-AU-TIKTOK-LOW": return "OS"
        case "SNEM-386-0524-AU-INSTA-HIGH": return "OT"
        case "SNEM-387-0524-AU-INSTA-MED": return "OU"
        case "SNEM-388-0524-AU-INSTA-LOW": return "OV"
        case "SNEM-389-0524-FR-SNAP-HIGH": return "OW"
        case "SNEM-390-0524-FR-SNAP-MED": return "OX"
        case "SNEM-391-0524-FR-SNAP-LOW": return "OY"
        case "SNEM-392-0524-FR-YOUTUBE": return "OZ"
        case "SNEM-393-0524-FR-TIKTOK-HIGH": return "PA"
        case "SNEM-394-0524-FR-TIKTOK-MED": return "PB"
        case "SNEM-395-0524-FR-TIKTOK-LOW": return "PC"
        case "SNEM-396-0524-FR-INSTA-HIGH": return "PD"
        case "SNEM-397-0524-FR-INSTA-MED": return "PE"
        case "SNEM-398-0524-FR-INSTA-LOW": return "PF"
        case "SNEM-399-0524-SA-SNAP-HIGH": return "PG"
        case "SNEM-400-0524-SA-SNAP-MED": return "PH"
        case "SNEM-401-0524-SA-SNAP-LOW": return "PI"
        case "SNEM-402-0524-SA-YOUTUBE": return "PJ"
        case "SNEM-403-0524-SA-TIKTOK-HIGH": return "PK"
        case "SNEM-404-0524-SA-TIKTOK-MED": return "PL"
        case "SNEM-405-0524-SA-TIKTOK-LOW": return "PM"
        case "SNEM-406-0524-SA-INSTA-HIGH": return "PN"
        case "SNEM-407-0524-SA-INSTA-MED": return "PO"
        case "SNEM-408-0524-SA-INSTA-LOW": return "PP"
        case "SNEM-409-0524-UK-SNAP-HIGH": return "PQ"
        case "SNEM-410-0524-UK-SNAP-MED": return "PR"
        case "SNEM-411-0524-UK-SNAP-LOW": return "PS"
        case "SNEM-412-0524-UK-YOUTUBE": return "PT"
        case "SNEM-413-0524-UK-TIKTOK-HIGH": return "PU"
        case "SNEM-414-0524-UK-TIKTOK-MED": return "PV"
        case "SNEM-415-0524-UK-TIKTOK-LOW": return "PW"
        case "SNEM-416-0524-UK-INSTA-HIGH": return "PX"
        case "SNEM-417-0524-UK-INSTA-MED": return "PY"
        case "SNEM-418-0524-UK-INSTA-LOW": return "PZ"
        case "SNEM-419-0524-US-SNAP-HIGH": return "QA"
        case "SNEM-420-0524-US-SNAP-MED": return "QB"
        case "SNEM-421-0524-US-SNAP-LOW": return "QC"
        case "SNEM-422-0524-US-YOUTUBE": return "QD"
        case "SNEM-423-0524-US-TIKTOK-HIGH": return "QE"
        case "SNEM-424-0524-US-TIKTOK-MED": return "QF"
        case "SNEM-425-0524-US-TIKTOK-LOW": return "QG"
        case "SNEM-426-0524-US-INSTA-HIGH": return "QH"
        case "SNEM-427-0524-US-INSTA-MED": return "QI"
        case "SNEM-428-0524-US-INSTA-LOW": return "QJ"
        case "GCPL-429-0624-FB-IG": return "QK"
        case "GCPL-430-0624-IG-YT": return "QL"
        case "GCPL-431-0624-FB-YT": return "QM"
        
        default: assertNever(groupId);
    }
}

export function stageToBucket(stage: LoginIdApiStage): string {
    switch (stage) {
        case 'DEV': return 'atrace-respondent-configs-dev';
        case 'PROD': return 'atrace-respondent-configs';
    }
}

export function assertNever(x: never): never {
    throw new Error("Exhaustive check failed, object received: " + x);
}