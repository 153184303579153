import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateExternalSurveyTask, generateWebRequestTask } from "../tasks";
import { WebPlatform } from "../types";

const FINISH_MESSAGE = "You have successfully completed your Blinq adventure and your rewards will be processed by your panel provider. This could take up to 10 business days, so please touch base with them directly if you have any questions. We thank you for being a part of the Blinq family, and hope to see you again soon.";

export const GCPL0624CollGroupIds = [
    "GCPL-429-0624-FB-IG",
    "GCPL-430-0624-IG-YT",
    "GCPL-431-0624-FB-YT"
] as const

export type GCPL0624CollGroupId = typeof GCPL0624CollGroupIds[number];

export const GCPL0624CollWebPlatformIds = [
    "GCPL-0624-IN-FACEBOOK",
    "GCPL-0624-IN-INSTAGRAM",
    "GCPL-0624-IN-YOUTUBE"
] as const;

export type GCPL0624CollWebPlatformId = typeof GCPL0624CollWebPlatformIds[number]

type GCPL0624CollConfig = {
    groupId: GCPL0624CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (groupId: GCPL0624CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "GCPL-429-0624-FB-IG":
            return "7"

        case "GCPL-430-0624-IG-YT":
            return taskIndex === 0 ? "7" : "10"

        case "GCPL-431-0624-FB-YT":
            return taskIndex === 0 ? "7" : "10"

        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: GCPL0624CollGroupId, taskIndex: 0 | 1): WebPlatform => {
    switch (groupId) {
        case "GCPL-429-0624-FB-IG":
            return taskIndex === 0 ? "Facebook" : "Instagram"

        case "GCPL-430-0624-IG-YT":
            return taskIndex === 0 ? "Instagram" : "Youtube"

        case "GCPL-431-0624-FB-YT":
            return taskIndex === 0 ? "Facebook" : "Youtube"

        default:
            assertNever(groupId);
    }
}

const getWebPlatformConfigUrl = (groupId: GCPL0624CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "GCPL-429-0624-FB-IG":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GCPL-0624/GCPL-0624-IN-FACEBOOK.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GCPL-0624/GCPL-0624-IN-INSTAGRAM.json";

        case "GCPL-430-0624-IG-YT":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GCPL-0624/GCPL-0624-IN-INSTAGRAM.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GCPL-0624/GCPL-0624-IN-YOUTUBE.json";

        case "GCPL-431-0624-FB-YT":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GCPL-0624/GCPL-0624-IN-FACEBOOK.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GCPL-0624/GCPL-0624-IN-YOUTUBE.json";

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: GCPL0624CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "GCPL-429-0624-FB-IG":
            return taskIndex === 0
                ? "GCPL-0624-IN-FACEBOOK"
                : "GCPL-0624-IN-INSTAGRAM"

        case "GCPL-430-0624-IG-YT":
            return taskIndex === 0
                ? "GCPL-0624-IN-INSTAGRAM"
                : "GCPL-0624-IN-YOUTUBE"

        case "GCPL-431-0624-FB-YT":
            return taskIndex === 0
                ? "GCPL-0624-IN-FACEBOOK"
                : "GCPL-0624-IN-YOUTUBE"

        default: assertNever(groupId);
    }
}

export const addGCPL0624CollConfig = (
    config: GCPL0624CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder.setFlag('deviceLock', 'mobile')
    .setFlag('requireMinimumBattery', 0.3)
    .setFlag('preventTaskProgress', false)
    .setFlag('finishMessageOverride', FINISH_MESSAGE);

    const webTask1 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId, 0),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: getPlatformForGroup(config.groupId, 0),
        taskLengthMinutes: getTaskLengthMinutes(config.groupId, 0),
        langStrings,
    })
    builder.addTask(webTask1)

    const webTask2 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId, 1),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 1),
        platform: getPlatformForGroup(config.groupId, 1),
        taskLengthMinutes: getTaskLengthMinutes(config.groupId, 1),
        langStrings,
    })
    builder.addTask(webTask2)

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/GCPL-0624/${config.groupId}_INCENTIVE.json`,
        params: {},
        taskID: config.groupId + '-WEBREQUEST',
    })
    builder.addTask(webRequestTask)

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder.addTask(uploadsTask)

    return builder
}