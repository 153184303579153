import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { VIAB0822CollWebplatformGroupId, VIAB0822CintGroupId } from "respondent_config_generator/src/projects/VIAB-0822";

export { VIAB0822CintGroupIds } from "respondent_config_generator/src/projects/VIAB-0822";
export type { VIAB0822CintGroupId } from "respondent_config_generator/src/projects/VIAB-0822";

const getPlatformForGroupId = (groupId: VIAB0822CollWebplatformGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "VIAB-149-0822-BE-NORTH-YOUTUBE":
        case "VIAB-150-0822-BE-SOUTH-YOUTUBE":
            return "youtube";

        default:
            assertNever(groupId)
    }
}

export const configureVIAB0822Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<VIAB0822CintGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    if (params.G === 'VIAB-149-0822-BE-NORTH-YOUTUBE' || params.G === 'VIAB-150-0822-BE-SOUTH-YOUTUBE') {
        // Web platform.
        landingPageBuilder.setSteps(
            LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G as VIAB0822CollWebplatformGroupId))
        )
    } else {
        // BVOD.
        landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps('bvod'))
    }
    
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}