import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { ADCO0622CollGroupId } from "respondent_config_generator/src/projects/ADCO-0622";

export { ADCO0622CollGroupIds } from "respondent_config_generator/src/projects/ADCO-0622";
export type { ADCO0622CollGroupId } from "respondent_config_generator/src/projects/ADCO-0622";

const getPlatformForGroupId = (groupId: ADCO0622CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "ADCO-146-0622-UK-ADCOLONY":
            return "adColony";

        default:
            assertNever(groupId)
    }
}

export const configureADCO0622Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<ADCO0622CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}