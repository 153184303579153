import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { ConfigKey } from "../../configKey";

export const OMME1022CollGroupIds = [
    'OMME-234-SAU-FACEBOOK',
    'OMME-235-SAU-INSTAGRAM',
    'OMME-236-SAU-YOUTUBE-PREROLL',
    'OMME-237-SAU-TWITTER-FEED',
    'OMME-238-SAU-TIKTOK-FEED',
    'OMME-239-SAU-ALMARSD-EXPOSED',
    'OMME-240-SAU-ALARABIYA-EXPOSED',
    'OMME-241-SAU-SPORT-ALMARSD-EXPOSED',
    'OMME-242-SAU-MOTORY-EXPOSED',
    'OMME-243-SAU-SO3ODY-EXPOSED',
    'OMME-253-UAE-FACEBOOK',
    'OMME-254-UAE-INSTAGRAM',
    'OMME-255-UAE-YOUTUBE-PREROLL',
    'OMME-256-UAE-TWITTER-FEED',
    'OMME-257-UAE-TIKTOK-FEED'
] as const

export type OMME1022CollGroupId = (typeof OMME1022CollGroupIds)[number]

export function isOMDGGroupParams(params: CAIParams<ConfigKey>): params is CAIParams<OMME1022CollGroupId> {
    return (OMME1022CollGroupIds as ReadonlyArray<string>).includes(params.G)
}

const getPlatformForGroupId = (groupId: OMME1022CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case 'OMME-234-SAU-FACEBOOK':
        case 'OMME-253-UAE-FACEBOOK':
            return "facebook";

        case 'OMME-235-SAU-INSTAGRAM':
        case 'OMME-254-UAE-INSTAGRAM':
            return "instagram";

        case 'OMME-236-SAU-YOUTUBE-PREROLL':
        case 'OMME-255-UAE-YOUTUBE-PREROLL':
            return "youtube";

        case 'OMME-237-SAU-TWITTER-FEED':
        case 'OMME-256-UAE-TWITTER-FEED':
            return "twitter"

        case 'OMME-238-SAU-TIKTOK-FEED':
        case 'OMME-257-UAE-TIKTOK-FEED':
            return "tiktok"
            
        case 'OMME-239-SAU-ALMARSD-EXPOSED':
        case 'OMME-240-SAU-ALARABIYA-EXPOSED':
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED':
        case 'OMME-242-SAU-MOTORY-EXPOSED':
        case 'OMME-243-SAU-SO3ODY-EXPOSED':
            return "omgmena";

        default:
            assertNever(groupId)
    }
}

export const configureOMMEPage = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<OMME1022CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}