import { LangStrings } from "internationalisation/LangStrings";
import { assertNever } from "./helper/assertNever";
import { InterfaceOrientation } from './interfaceOrientation';
import { GroupId, LumenGazeCalibrationTask, RespondentBvodInAppTask, RespondentCompletionCodeTask, RespondentExternalLinkTask, RespondentExternalSurveyTask, RespondentGazeCalibrationTask, RespondentGazeCalibrationTaskConfig, RespondentGazeCalibrationDesktopTask, RespondentGazeCalibrationDesktopTaskConfig,RespondentLinearTvTask, RespondentWebPlatformTask, RespondentWebPlatformTaskConfig, RespondentWebRequestTask, WebPlatform, 
    RespondentUploadsTask, RespondentUploadsTaskConfig, RespondentSnapARTask } from "./types";

export const generateBvodTask = (params: {
    taskID: string,
    channelsJsonUrl: string,
    isTv: boolean,
    langStrings: LangStrings,
    isRadio: boolean
}): RespondentBvodInAppTask => {
    let config = params.isRadio ? {
        channelsJsonUrl: params.channelsJsonUrl,
        title: "Broadcaster",
        videoOrientation: "portrait"
    } : {
        channelsJsonUrl: params.channelsJsonUrl
    }
    let task: RespondentBvodInAppTask = {
        type: 'bvodInApp',
        meta: {
            title: params.langStrings.BvodInApp.title,
            description: params.langStrings.BvodInApp.description,
            taskID: params.taskID
        },
        config: config
    }

    if (params.isTv) {
        task.meta.tv = {}
    }

    return task
}

/**
 * Generate External Link task.
 *
 * @param title Task Title.
 * @param description A simple respondent friendly description of the task.
 * @param url URL of the link to open in the respondents browser.
 * @param taskId Task ID.
 */
 export const generateExternalLinkTask = (params: {
    title: string,
    description: string,
    url: string,
    taskID: string,
}): RespondentExternalLinkTask => {
    let task: RespondentExternalLinkTask = {
        type: 'externalLink',
        meta: {
            title: params.title,
            taskID: params.taskID,
            description: params.description,
        },
        config: {
            url: params.url
        }
    }

    return task;
};

/**
 * Generate a Web Request task.
 *
 * @param url URL of the web request end point.
 * @param method The web request method.
 * @param headers The web request headers.
 * @param body The web request body.
 * @param maxRetries The max number of retries to send the web request.
 * @param taskId Task ID.
 */
 export const generateWebRequestTask = (params: {
    webRequestConfigUrl: string,
    params: Record<string, string>,
    taskID: string,
}): RespondentWebRequestTask => {
    let task: RespondentWebRequestTask = {
        type: 'webRequest',
        meta: {
            title: "",
            taskID: params.taskID,
            description: "",
        },
        config: {
            webRequestConfigUrl: params.webRequestConfigUrl,
            params: params.params,
        }
    }

    return task;
};

/**
 * Generate Linear TV task.
 *
 * @param title Task Title.
 * @param description A simple respondent friendly description of the task.
 * @param taskId Task ID.
 */
 export const generateLinearTvTask = (params: {
    title: string,
    description: string,
    taskID: string,
    viewingLengthOptions?: ReadonlyArray<number>
}): RespondentLinearTvTask => {
    let task: RespondentLinearTvTask = {
        type: 'linearTv',
        meta: {
            title: params.title,
            taskID: params.taskID,
            description: params.description,
        },
        config: {
            viewingLengthOptions: params.viewingLengthOptions
        }
    }

    return task;
};

/**
 * Generate External Survey task.
 *
 * @param title Task Title.
 * @param taskID Task ID.
 * @param configKey Configuration key to determine urls to provide.
 * @param extraMetaFields List of extra meta fields that the config should index in.
 */
export const generateExternalSurveyTask = (params: {
    title: string,
    description: string,
    surveyUrl: string,
    taskID: string,
    extraMetaFields: ReadonlyArray<string>
}): RespondentExternalSurveyTask => {

    let task: RespondentExternalSurveyTask = {
        type: 'externalSurvey',
        meta: {
            title: params.title,
            taskID: params.taskID,
            description: params.description,
        },
        config: {
            surveyUrl: params.surveyUrl,
            extraMetaFields: params.extraMetaFields
        }
    }

    return task;
};

const getWebPlatformTaskId = (
    groupId: string,
    platform: WebPlatform
): string => {
    let platformText: string | undefined;
    switch (platform) {
        case 'Facebook':
            platformText = 'FACEBOOK';
            break;

        case 'Instagram':
            platformText = 'INSTAGRAM';
            break;

        case 'Youtube':
            platformText = 'YOUTUBE';
            break;

        case 'Twitter':
            platformText = 'TWITTER'
            break;

        case 'Homes To Love':
            platformText = "HOMESTOLOVE"
            break;

        case 'Marie Claire':
            platformText = "MARIECLAIRE"
            break;

        case 'Now To Love':
            platformText = "NOWTOLOVE"
            break;

        case 'TikTok':
            platformText = 'TIKTOK'
            break;

        case 'Yahoo':
            platformText = 'YAHOO'
            break;

        case 'Snapchat':
            platformText = 'SNAPCHAT'
            break;

        case 'AdColony':
            platformText = 'ADCOLONY'
            break;

        case 'Stuff':
            platformText = 'STUFF'
            break;

        case 'NZ Herald':
            platformText = 'NZHERALD'
            break;

        case 'OneRoof':
            platformText = 'ONEROOF'
            break;

        case 'General':
            platformText = 'GENERAL'
            break;

        case 'Twitch':
            platformText = 'TWITCH'
            break;

        case 'Spotify':
            platformText = 'SPOTIFY'
            break;

        case 'Pinterest':
            platformText = 'PINTEREST'
            break;

        case 'SnapAR':
            platformText = 'SNAPAR'
            break;

        default: assertNever(platform);
    }

    return `${groupId}-${platformText}`;
};

const getDescription = (
    platform: WebPlatform,
    lang: LangStrings,
    taskLengthMinutes: string
): string => {
    switch (platform) {
        case 'Facebook':
            return lang.WebPlatform.facebook(taskLengthMinutes)
        case 'Instagram':
            return lang.WebPlatform.instagram(taskLengthMinutes)
        case 'Youtube':
            return lang.WebPlatform.youtube(taskLengthMinutes)
        case 'Twitter':
            return `Scroll Twitter for ${taskLengthMinutes} Minutes`
        case 'Homes To Love':
            return `Browse Homes To Love for ${taskLengthMinutes} Minutes`
        case 'Marie Claire':
            return `Browse Marie Claire for ${taskLengthMinutes} Minutes`
        case 'Now To Love':
            return `Browse Now To Love for ${taskLengthMinutes} Minutes`
        case 'TikTok':
            return lang.WebPlatform.tiktok(taskLengthMinutes)
        case 'Yahoo':
            return `Scroll Yahoo for ${taskLengthMinutes} Minutes`
        case 'Snapchat':
            return `Scroll Snapchat for ${taskLengthMinutes} Minutes`
        case 'AdColony':
            return `Play games from AdColony for ${taskLengthMinutes} Minutes`
        case 'Stuff':
            return `Browse Stuff for ${taskLengthMinutes} Minutes`
        case 'NZ Herald':
            return `Browse NZ Herald for ${taskLengthMinutes} Minutes`
        case 'OneRoof':
            return `Browse OneRoof for ${taskLengthMinutes} Minutes`
        case 'General':
            return `Browse website for ${taskLengthMinutes} Minutes`
        case 'Twitch':
            return `Watch Twitch for ${taskLengthMinutes} minutes`
        case 'Spotify':
            return `Listen to Spotify for ${taskLengthMinutes} minutes`
        case 'Pinterest':
            return `Browse Pinterest for ${taskLengthMinutes} minutes`
        case 'SnapAR':
            return lang.WebPlatform.snapar()
        default:
            assertNever(platform);
    }
};


export const generateWebPlatformTask = (params: {
    groupId: GroupId,
    platform: WebPlatform,
    webPlatformConfigUrl: string,
    webPlatformID: string,
    langStrings: LangStrings,
    taskLengthMinutes: string,
    useWebPlatformIDForTaskID?: boolean
}): RespondentWebPlatformTask => {
    const webTaskConfig: RespondentWebPlatformTaskConfig = {
        webPlatformID: params.webPlatformID,
        webPlatformConfig: params.webPlatformConfigUrl
    }

    const description = getDescription(params.platform, params.langStrings, params.taskLengthMinutes);

    const taskID = params.useWebPlatformIDForTaskID ? getWebPlatformTaskId(params.webPlatformID, params.platform) : getWebPlatformTaskId(params.groupId, params.platform);
    return {
        type: 'webPlatform',
        config: webTaskConfig,
        meta: {
            title: params.platform,
            description,
            taskID: taskID
        }
    };
};

export const generateGazeCalibrationTask = (params: {
    config: RespondentGazeCalibrationTaskConfig,
    groupId: GroupId,
}): RespondentGazeCalibrationTask => {
    let task: RespondentGazeCalibrationTask = {
        type: "gazeCalibration",
        config: params.config,
        meta: {
            title: 'Gaze Calibration',
            description: 'Task to help calibrate the gaze technology.',
            taskID: `${params.groupId}-GAZE-CALIBRATION`
        }
    }

    return task
}

export const generateGazeCalibrationDesktopTask = (params: {
    config: RespondentGazeCalibrationDesktopTaskConfig,
    groupId: GroupId,
}): RespondentGazeCalibrationDesktopTask => {
    let task: RespondentGazeCalibrationDesktopTask = {
        type: "gazeCalibrationDesktop",
        config: params.config,
        meta: {
            title: 'Gaze Calibration Desktop',
            description: 'Task to help calibrate the gaze technology on desktop.',
            taskID: `${params.groupId}-GAZE-CALIBRATION-DESKTOP`
        }
    }

    return task
}

export const generateLumenGazeCalibrationTask = (params: {
    orientations: ReadonlyArray<InterfaceOrientation>,
    groupID: GroupId,
}): LumenGazeCalibrationTask => ({
    type: "lumenGazeCalibration",
    config: {
        orientations: params.orientations
    },
    meta: {
        title: 'Lumen Gaze Calibration',
        description: 'Task to help calibrate the gaze technology.',
        taskID: `${params.groupID}-GAZE-CALIBRATION`
    }
});

function getRandomInt(max: number): number {
    return Math.floor(Math.random() * Math.floor(max))
}

function generateRandomInts(length: number): string {
    return new Array(length).fill(undefined).map(_ => getRandomInt(10)).join('')
}

export const generateCompletionCodeTask = (params: {
    groupId: GroupId
}): RespondentCompletionCodeTask => {
    let completionCode = generateRandomInts(5)

    return {
        type: "completionCode",
        config: {
            completionCode
        },
        meta: {
            title: 'Completion Code',
            description: 'Your completion code to validate that you finished your task correctly',
            taskID: `${params.groupId}-COMPLETION-CODE`,
        }
    }
};

/**
 * Generate a Uploads task.
 * @param config The RespondentUploadsTaskConfig, includes title and subtitle to display in the uploads screen
 * @param taskId Task ID
 */
export const generateUploadsTask = (params: {
    config: RespondentUploadsTaskConfig, taskId: string
}): RespondentUploadsTask => {
    let task: RespondentUploadsTask = {
        type: "uploads",
        config: params.config,
        meta: {
            title: 'Uploads',
            description: 'Task to configure upload screen',
            taskID: params.taskId
        }
    }

    return task
};

export const generateSnapARTask = (params: {
    groupId: GroupId,
    lensGroupID: string,
    sessionLength: number,
    platform: WebPlatform,
    langStrings: LangStrings,
    title: string
}): RespondentSnapARTask => {
    const description = getDescription(params.platform, params.langStrings, params.sessionLength.toString());
    const title = params.title;

    return {
        type: "snapAR",
        config: {
            lensGroupID: params.lensGroupID,
            sessionLength: params.sessionLength
        },
        meta: {
            title: title,
            description,
            taskID: getWebPlatformTaskId(params.groupId, params.platform)
        }
    }
};

