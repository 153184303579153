import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateWebPlatformTask } from "../tasks";
import { WebPlatform } from "../types";

export type OmdGladeGroupId =
    // Facebook Intercept Groups
    | 'OMDG-1A'
    | 'OMDG-1B'
    | 'OMDG-1C'

    // Instagram Intercept Groups
    | 'OMDG-2A'
    | 'OMDG-2B'
    | 'OMDG-2C'

    // Youtube Intercept Groups
    | 'OMDG-3A'
    | 'OMDG-3B'
    | 'OMDG-3C'

    // Facebook & Instagram 2nd ITW groups
    | 'OMDG-4'
    | 'OMDG-5'

type OmdGladeConfig = {
    groupId: OmdGladeGroupId,
    metaFieldNames: ReadonlyArray<string>
};

/**
 * Get the length of the webPlatform Task for a given groupId in minutes
 */
function getTaskLength(groupId: OmdGladeGroupId): string {
    switch (groupId) {
        case 'OMDG-1A':
        case 'OMDG-1B':
        case 'OMDG-1C':
        case 'OMDG-2A':
        case 'OMDG-2B':
        case 'OMDG-2C':
        case 'OMDG-4':
        case 'OMDG-5':
            return "5"

        case 'OMDG-3A':
        case 'OMDG-3B':
        case 'OMDG-3C':
            return "20"
    }
}

export const addOmdGladeConfig = (
    config: OmdGladeConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder.setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3);

    let platform: WebPlatform
    let surveyUrl: string
    switch (config.groupId) {
        case 'OMDG-1A':
        case 'OMDG-2A':
        case 'OMDG-3A':
            surveyUrl = 'https://survey.au1.qualtrics.com/jfe/form/SV_09rZ323QmYH8fCC'
            break;

        case 'OMDG-1B':
        case 'OMDG-2B':
        case 'OMDG-3B':
            surveyUrl = 'https://survey.au1.qualtrics.com/jfe/form/SV_egJW8LJY0Fp3oCq'
            break;

        case 'OMDG-1C':
        case 'OMDG-2C':
        case 'OMDG-3C':
            surveyUrl = 'https://survey.au1.qualtrics.com/jfe/form/SV_54lDzEigFa8OcN8'
            break;

        case 'OMDG-4':
        case 'OMDG-5':
            surveyUrl = 'https://survey.au1.qualtrics.com/jfe/form/SV_9uW4R3CkilsZVH0'
            break;

        default:
            return assertNever(config.groupId)
    }

    switch (config.groupId) {
        case 'OMDG-1A':
        case 'OMDG-1B':
        case 'OMDG-1C':
        case 'OMDG-4':
            platform = 'Facebook'
            break;

        case 'OMDG-2A':
        case 'OMDG-2B':
        case 'OMDG-2C':
        case 'OMDG-5':
            platform = 'Instagram'
            break;

        case 'OMDG-3A':
        case 'OMDG-3B':
        case 'OMDG-3C':
            platform = 'Youtube'
            break;

        default:
            return assertNever(config.groupId)
    }

    const webTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: config.groupId,
        webPlatformConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/${config.groupId}.json`,
        platform,
        taskLengthMinutes: getTaskLength(config.groupId),
        langStrings,
    })

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl,
    })

    builder
        .addTask(webTask)
        .addTask(surveyTask)

    return builder
}