import * as React from "react";

import { useStyles } from "./styles";
import { Button } from "@mui/material";
import { getCurrentLangStrings } from "../../i18n";
import { checkIsMacBookChromeSafariFirefox } from '../../utils';

interface NavButtonProps {
    activeStep: number;
    numOfSteps: number;
    disableSubmit: boolean;
    hideSubmit: boolean;

    onNext: () => void;
    onPrev: () => void;
    onSubmit: () => void;
}

const NavButtons: React.FC<NavButtonProps> = ({
    disableSubmit,
    hideSubmit,
    activeStep,
    numOfSteps,
    onNext,
    onPrev,
    onSubmit,
}) => {
    const { classes } = useStyles();
    const lang = getCurrentLangStrings();

    // If there is only one step then we don't need to show a previous button
    const backButtonVisible = numOfSteps > 1;
    const backButtonDisabled = activeStep === 0;

    const isFirstStep = activeStep === 0;
    const isLastStep = activeStep === numOfSteps - 1;
    const nextButtonVisible = !(isLastStep && hideSubmit);
    let nextButtonDisabled;

    nextButtonDisabled = isLastStep && disableSubmit;
    if (window.location.href.includes('DSTD-261-0223-MTURK-GAZE-DESKTOP') && !checkIsMacBookChromeSafariFirefox()) {
        nextButtonDisabled = true;
    }

    return (
        <>
            {backButtonVisible && (
                <Button
                    variant="contained"
                    color="secondary"
                    disabled={backButtonDisabled}
                    onClick={onPrev}
                    className={backButtonDisabled ? `${classes.buttonSecondary} ${classes.buttonDisabled}` : classes.buttonSecondary}
                >
                    {lang.Common.back}
                </Button>
            )}

            {nextButtonVisible && (
                <Button
                    variant="contained"
                    color="primary"
                    disabled={nextButtonDisabled}
                    onClick={isLastStep ? onSubmit : onNext}
                    className={classes.buttonPrimary}
                >
                    {isFirstStep 
                    ? `${lang.Common.go}`
                    : !isLastStep || hideSubmit ? `${lang.Common.next}` : "Submit"}
                </Button>
            )}
        </>
    );
};

export default NavButtons;
