import React, { useState } from "react";
import { useSelector } from "react-redux";
import { Typography } from "@mui/material";

import { LandingPageLoadingState } from "../reducer";
import { RootState } from "../../Store";
import PageError from "../Components/PageError";
import PageLoading from "../Components/PageLoading";
import PageWrapper from "../Components/PageWrapper";
import StepsRenderer from "../Components/StepsRenderer";
import PageRedirecting from "../Components/PageRedirecting";
import { getQueryStringParams } from "../../utils";
import { parseQueryParams } from "../../queryParams";
import {
    isMturkGroupId,
    isMturkGazeDesktopGroupId,
} from "../../Respondent/ConfigDataStores/mturk";

const LandingPageContainer: React.FC = () => {
    const [isRedirecting, setIsRedirecting] = useState(false);
    const landingPage = useSelector((state: RootState) => state.landingPage);

    if (isRedirecting) {
        return (
            <PageWrapper>
                <PageRedirecting />
            </PageWrapper>
        );
    }

    let child: JSX.Element;
    let iframe: JSX.Element | undefined;
    switch (landingPage.loadingState) {
        case LandingPageLoadingState.error:
            const params = parseQueryParams(getQueryStringParams());

            child = (
                <PageError
                    isMturk={isMturkGroupId(params.G)}
                    isMturkDesktop={isMturkGazeDesktopGroupId(params.G)}
                />
            );

            break;
        case LandingPageLoadingState.loading:
            child = <PageLoading />;
            break;
        case LandingPageLoadingState.loaded:
            const pageConfig = landingPage.config;

            child = (
                <>
                    <Typography
                        component="h1"
                        variant="h5"
                        align="center"
                        gutterBottom
                        fontFamily="OfeliaBold"
                    >
                        {pageConfig.pageTitle}
                    </Typography>
                    <StepsRenderer
                        config={pageConfig}
                        onSubmit={() => setIsRedirecting(true)}
                    />
                </>
            );

            if (pageConfig.iframeUrl) {
                iframe = (
                    <iframe
                        title={"_"} // Eslint complains if title is missing
                        src={pageConfig.iframeUrl}
                        width={0}
                        height={0}
                        frameBorder={"0"}
                    ></iframe>
                );
            }

            break;
        default:
            assertNever(landingPage);
    }

    return (
        <PageWrapper>
            {child}
            {iframe}
        </PageWrapper>
    );
};

export default LandingPageContainer;
