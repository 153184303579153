import { AppLanguage } from "internationalisation";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { ConfigKey } from "../../configKey";

import { PINT1123CollGroupIds, PINT1123CollGroupId } from "respondent_config_generator/src/projects/PINT-1123";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
export { PINT1123CollGroupIds } from 'respondent_config_generator/src/projects/PINT-1123'

export type { PINT1123CollGroupId } from 'respondent_config_generator/src/projects/PINT-1123'

export function isPINT1123GroupParams(params: CAIParams<ConfigKey>): params is CAIParams<PINT1123CollGroupId> {
    return (PINT1123CollGroupIds as ReadonlyArray<string>).includes(params.G)
}

const getLanguageForGroupId = (groupId: PINT1123CollGroupId): AppLanguage => {
    switch (groupId) {
        case "PINT-326-1123-US-GROUP1":
        case "PINT-327-1123-US-GROUP2":
        case "PINT-329-1123-US-GROUP4":
        case "PINT-328-1123-US-GROUP3":
        case "PINT-331-1123-UK-GROUP2":
        case "PINT-330-1123-UK-GROUP1":
        case "PINT-333-1123-UK-GROUP4":
        case "PINT-332-1123-UK-GROUP3":
        case "PINT-335-1123-AU-GROUP2":
        case "PINT-334-1123-AU-GROUP1":
        case "PINT-337-1123-AU-GROUP4":
        case "PINT-336-1123-AU-GROUP3":
        case "PINT-347-1123-CA-GROUP2":
        case "PINT-346-1123-CA-GROUP1":
        case "PINT-349-1123-CA-GROUP4":
        case "PINT-348-1123-CA-GROUP3":
            return AppLanguage.en;

        case "PINT-339-1123-FR-GROUP2":
        case "PINT-338-1123-FR-GROUP1":
        case "PINT-341-1123-FR-GROUP4":
        case "PINT-340-1123-FR-GROUP3":
            return AppLanguage.fr;

        case "PINT-343-1123-DE-GROUP2":
        case "PINT-342-1123-DE-GROUP1":
        case "PINT-345-1123-DE-GROUP4":
        case "PINT-344-1123-DE-GROUP3":
            return AppLanguage.de;

        default:
            assertNever(groupId)
    }
}

export const configurePINT1123Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<PINT1123CollGroupId>
): void => {
    setCurrentLang(getLanguageForGroupId(params.G))
    const lang = getCurrentLangStrings()
    

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps('pinterest'))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}