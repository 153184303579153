import * as React from 'react'
import { Grid, Typography } from '@mui/material'
import { PlatformInstructionsDeskSolutionKey } from './LoginAndCompletionDeskSolution';
interface Props {
    loginId: string;
    platform: PlatformInstructionsDeskSolutionKey;
}

const SessionInformationDeskSolution: React.FC <Props> = (props: Props) => {
    const { platform } =
        props;

    const getSessionInformationForPlatform = (
        platform: PlatformInstructionsDeskSolutionKey
    ): string => {
        switch (platform) {
            case "twitch-desktop":
                return `1. Download Chrome extension. Instructions are found on the next page.
                        2. Enter your unique login ID into the space provided 
                        3. Read the guidelines for a successful session and press next 
                        4. Press ‘start session’ to commence your viewing session 
                        5. The extension will automatically redirect you to Twitch and start the countdown timer (10 minutes) 
                        6. Spend your time reading articles, watching videos and engaging with content from across the Twitch website
                        7. As you reach the end of your session, a pop up will appear at the top of your screen to let you know how much time is remaining 
                        8. At the end of your session, you may reopen the extension to remove it from your browser 
                        9. Your reward will be credited to your account by your panel provider. This may take up to 10 business days`;
            case "yahoo-desktop":
                return `1. Download Chrome extension. Instructions are found on the next page.
                        2. Enter your unique login ID into the space provided 
                        3. Read the guidelines for a successful session and press next 
                        4. Press ‘start session’ to commence your viewing session 
                        5. The extension will automatically redirect you to Yahoo and start the countdown timer (10 minutes) 
                        6. Spend your time reading articles, watching videos and engaging with content from across the Yahoo website
                        7. As you reach the end of your session, a pop up will appear at the top of your screen to let you know how much time is remaining 
                        8. At the end of your session, you may reopen the extension to remove it from your browser 
                        9. Your reward will be credited to your account by your panel provider. This may take up to 10 business days`;
            default:
                assertNever(platform);
        }
    };

    return <div>
        <Grid container direction="column" justifyContent="center" alignItems="center" alignContent="center">
            <Typography align="left" gutterBottom component="p" whiteSpace="pre-line">{getSessionInformationForPlatform(platform)}</Typography>
        </Grid>
    </div>
}

export default SessionInformationDeskSolution
