import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { GYGG0623CollGroupId } from "respondent_config_generator/src/projects/GYGG-0623";

export type { GYGG0623CollGroupId } from "respondent_config_generator/src/projects/GYGG-0623";
export { GYGG0623CollGroupIds } from "respondent_config_generator/src/projects/GYGG-0623";

const getPlatformForGroupId = (groupId: GYGG0623CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "GYGG-282-0623-NY-FACEBOOK":
        case "GYGG-283-0623-LA-FACEBOOK":
            return "facebook";
        case "GYGG-284-0623-NY-INSTAGRAM":
        case "GYGG-285-0623-LA-INSTAGRAM":
            return "instagram";
        case "GYGG-286-0623-NY-YOUTUBE":
        case "GYGG-287-0623-LA-YOUTUBE":
            return "youtube";

        default:
            assertNever(groupId)
    }
}

export const configureGYGG0623Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<GYGG0623CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}