import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";

export type { RCTV0523CollGroupId } from "respondent_config_generator/src/projects/RCTV-0523";
export { RCTV0523CollGroupIds } from "respondent_config_generator/src/projects/RCTV-0523";

export const configureRCTV0523Page = (
    landingPageBuilder: LandingPageConfigBuilder
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps('bvod'))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}