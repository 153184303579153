import EnLangStrings from "internationalisation/en";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import {
    generateBvodTask,
    generateWebPlatformTask,
    generateExternalSurveyTask,
    generateWebRequestTask,
    generateUploadsTask,
    generateLinearTvTask
} from "../tasks";
import { assertNever } from "../helper/assertNever";

const VIAB0822CollWebplatformGroupIds = [
    'VIAB-149-0822-BE-NORTH-YOUTUBE',
    'VIAB-150-0822-BE-SOUTH-YOUTUBE'
] as const

const VIAB0822CollBvodMobileRound2GroupIds = [
    'VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2',
    'VIAB-214-0822-BE-SOUTH-BVOD-M-ROUND2'
]

export const VIAB0822CollBvodMobileGroupIds = [
    'VIAB-206-0822-BE-NORTH-BVOD-M',
    'VIAB-207-0822-BE-SOUTH-BVOD-M',
    'VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2',
    'VIAB-214-0822-BE-SOUTH-BVOD-M-ROUND2'
] as const

export const VIAB0822CintGroupIds= [
    'VIAB-149-0822-BE-NORTH-YOUTUBE',
    'VIAB-150-0822-BE-SOUTH-YOUTUBE',
    'VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2',
    'VIAB-214-0822-BE-SOUTH-BVOD-M-ROUND2'
] as const

export const VIAB0822LinearTvGroupIds = [
    'VIAB-245-0822-BE-NORTH-LINEARTV',
    'VIAB-246-0822-BE-SOUTH-LINEARTV'
] as const;

export type VIAB0822CollBvodMobileGroupId = typeof VIAB0822CollBvodMobileGroupIds[number]
export type VIAB0822CollWebplatformGroupId = typeof VIAB0822CollWebplatformGroupIds[number]
export type VIAB0822CintGroupId = typeof VIAB0822CintGroupIds[number]
export type VIAB0822LinearTvGroupId = typeof VIAB0822LinearTvGroupIds[number]

export type VIAB0822CollGroupId = 
    | VIAB0822CollBvodMobileGroupId
    | VIAB0822CollWebplatformGroupId
    | VIAB0822LinearTvGroupId

type VIAB0822BvodMobileConfig = {
    groupId: VIAB0822CollBvodMobileGroupId,
    viewingCount: number,
    metaFieldNames: ReadonlyArray<string>,
    user: string,
    email: string
}

type VIAB0822LinearTvConfig = {
    groupId: VIAB0822LinearTvGroupId,
    viewingCount: number,
    metaFieldNames: ReadonlyArray<string>,
    user: string,
    email: string
}

type VIAB0822WebplatformConfig = {
    groupId: VIAB0822CollWebplatformGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const BVOD_MOBILE_FINISH_MESSAGE_DUTCH = 'Bedankt voor je deelname, je beloning wordt binnenkort naar je gemaild. Er wordt een extra e-mail verzonden met verdere instructies.';
const BVOD_MOBILE_FINISH_MESSAGE_FRENCH = 'Merci pour votre participation, votre récompense vous sera envoyée par e-mail sous peu. Un e-mail supplémentaire sera envoyé avec des instructions supplémentaires.';
const WEBPLATFORM_FINISH_MESSAGE_DUTCH = "Gefeliciteerd!\nU hebt nu alle vereiste taken voltooid en uw gegevens zijn geüpload. Je wordt direct beloond in je panelaccount. Bedankt voor je tijd.";
const WEBPLATFORM_FINISH_MESSAGE_FRENCH = "Félicitation!\nVous avez maintenant terminé toutes les tâches requises et vos données ont été téléchargées. Vous serez récompensé directement dans votre compte panel. Merci pour votre temps.";
const LINEAR_TV_FINISH_MESSAGE_DUTCH = "Dank u voor uw deelname. Uw beloning is naar u gemaild. Onthoud dat je maximaal 4 sessies per week mag doen. Als je vragen hebt, neem dan contact met ons op via help@amplifiedintelligence.com.au";
const LINEAR_TV_FINISH_MESSAGE_FRENCH = "Merci pour votre participation. Votre récompense vous a été envoyée par e-mail. N'oubliez pas que vous pouvez effectuer jusqu'à 4 séances par semaine. Si vous avez des questions, contactez-nous à help@amplifiedintelligence.com.au";

/**
 * According to Bvod mobile groupId, return external survey Url. 
 * Each Bvod mobile group has one external survey (login 1), and a web request (login 2).
 * @param groupId VIAB-0822 Bvod Mobile groups
 * @returns Survey Url
 */
const getBvodSurveyURL = (
    groupId: VIAB0822CollBvodMobileGroupId
): string => {
    switch (groupId) {
        case 'VIAB-206-0822-BE-NORTH-BVOD-M': 
        case 'VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2': 
            return "https://survey.au1.qualtrics.com/jfe/form/SV_8cb5w5gVsnZcCai"
            
        case 'VIAB-207-0822-BE-SOUTH-BVOD-M': 
        case 'VIAB-214-0822-BE-SOUTH-BVOD-M-ROUND2': 
            return "https://survey.au1.qualtrics.com/jfe/form/SV_4ZSYmDlcGIWv4ii"
        
        default:
            assertNever(groupId);
    }
}

/**
 * According to groupId and viewingCount, return Bvod channel list Url.
 * @param groupId VIAB-0822 Bvod mobile groups
 * @param viewingCount number: 1 or 2
 * @returns Bvod channel list Url
 */
const getBvodChannelUrl = (
    groupId: VIAB0822CollBvodMobileGroupId,
    viewingCount: number
): string => {
    switch(viewingCount) {
        case 1: {
            return (groupId === 'VIAB-206-0822-BE-NORTH-BVOD-M' || groupId === 'VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2') ?
                'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/VIAB-0822-NORTH-VIEW1.json' :
                'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/VIAB-0822-SOUTH-VIEW1.json';
        }
        case 2:
            return (groupId === 'VIAB-206-0822-BE-NORTH-BVOD-M' || groupId === 'VIAB-213-0822-BE-NORTH-BVOD-M-ROUND2') ?
                'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/VIAB-0822-NORTH-VIEWOPEN.json' :
                'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/VIAB-0822-SOUTH-VIEWOPEN.json';
        
        default:
            throw new RangeError('BVOD Mobile viewing count must be between 1 and 2 (inclusive)!')
    }
}

const addBvodMobileConfig = (
    builder: RespondentConfigBuilder,
    groupId: VIAB0822CollBvodMobileGroupId,
    viewingCount: number,
    user: string,
    email: string,
    metaFieldNames: ReadonlyArray<string>
): void => {
    const bvodTvTask = generateBvodTask({
        taskID: groupId + '-BVOD',
        channelsJsonUrl: getBvodChannelUrl(groupId, viewingCount),
        isTv: false,
        langStrings: EnLangStrings,
        isRadio: false
    });

    builder.addTask(bvodTvTask)

    // Login 1 gets external surveys.
    if (viewingCount === 1) {
        const externalSurveyTask = generateExternalSurveyTask({
            title: '',
            description: '',
            surveyUrl: getBvodSurveyURL(groupId),
            taskID: groupId + '-SURVEY',
            extraMetaFields: [...metaFieldNames, "bvodTV_selectedChannel"]
        });

        builder.addTask(externalSurveyTask);
    }
    // Login 2 gets web requests.
    else if (viewingCount === 2) {
        const webRequestTask = generateWebRequestTask({
            webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/VIAB-0822/VIAB-0822-BVOD-M_INCENTIVE.json`,
            params: {
                r_user: user,
                r_email: email
            },
            taskID: groupId + '-WEBREQUEST'
        });
        
        builder.addTask(webRequestTask);
    }
    else {
        throw new RangeError("BVOD-TV groups are limited to 2 login IDs")
    }

    // Add uploads task for BVOD round 2 groups.
    if (VIAB0822CollBvodMobileRound2GroupIds.includes(groupId)) {
        const uploadsTask = generateUploadsTask({
            config: {
                "title": "Uploads"
            },
            taskId: groupId + '-UPLOADS',
        });
    
        builder.addTask(uploadsTask);
    }
}

// VIAB-0822 BVOD mobile groups config.
export const addViab0822CollBvodMobileConfig = (
    config: VIAB0822BvodMobileConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('minimumIOSVersion', '15.0')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag(
            'finishMessageOverride', 
            config.groupId.includes("BE-NORTH") ? BVOD_MOBILE_FINISH_MESSAGE_DUTCH : BVOD_MOBILE_FINISH_MESSAGE_FRENCH
        )
        .setFlag(
            'preventTaskProgress', 
            config.viewingCount === 1 ? false : true
        )
        
    const meta = config as VIAB0822BvodMobileConfig;

    addBvodMobileConfig(
        builder,
        config.groupId,
        meta.viewingCount,
        meta.user,
        meta.email,
        meta.metaFieldNames
    );

    return builder;
}

const getWebPlatformConfigUrl = (groupId: VIAB0822CollWebplatformGroupId): string => {
    switch (groupId) {
        case "VIAB-149-0822-BE-NORTH-YOUTUBE": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/VIAB-0822/VIAB-0822-BE-NORTH-YOUTUBE.json";
        case "VIAB-150-0822-BE-SOUTH-YOUTUBE": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/VIAB-0822/VIAB-0822-BE-SOUTH-YOUTUBE.json";

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: VIAB0822CollWebplatformGroupId): string => {
    switch (groupId) {
        case "VIAB-149-0822-BE-NORTH-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_8uZj19voDtxM7m6";
        case "VIAB-150-0822-BE-SOUTH-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_3wrXVxHjryR4dUO";

        default:
            assertNever(groupId);
    }
}

// VIAB-0822 Youtube groups config.
export const addViab0822CollWebplatformConfig = (
    config: VIAB0822WebplatformConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    builder.setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag(
            'finishMessageOverride', 
            config.groupId === 'VIAB-149-0822-BE-NORTH-YOUTUBE' ? WEBPLATFORM_FINISH_MESSAGE_DUTCH : WEBPLATFORM_FINISH_MESSAGE_FRENCH
        )

    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: config.groupId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: "Youtube",
        taskLengthMinutes: "",
        langStrings: EnLangStrings,
    });

    const surveyTask = generateExternalSurveyTask({
        title: EnLangStrings.Common.survey,
        description: EnLangStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    builder.addTask(webPlatformTask)
        .addTask(surveyTask)

    return builder;
}

// VIAB-0822 Linear TV groups config.
export const addViab0822LinearTVConfig = (
    config: VIAB0822LinearTvConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    builder
        .setFlag(
            'finishMessageOverride', 
            config.groupId.includes("BE-NORTH") ? LINEAR_TV_FINISH_MESSAGE_DUTCH : LINEAR_TV_FINISH_MESSAGE_FRENCH
        )
        .setFlag('minimumAndroidMobileAppVersion', '')
        .setFlag('minimumAndroidTVAppVersion', '1.0.3')
        .setFlag('preventTaskProgress', true);
        
    const meta = config as VIAB0822LinearTvConfig;

    const linearTv = generateLinearTvTask({
        title: '',
        description: '',
        taskID: meta.groupId + '-LINEARTV',
        viewingLengthOptions: [30, 60]
    });

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: "https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/VIAB-0822/VIAB-0822-BE-LINEARTV.json",
        params: {
            r_user: meta.user,
            r_email: meta.email
        },
        taskID: meta.groupId + '-WEBREQUEST'
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: meta.groupId + '-UPLOADS',
    });

    builder
        .addTask(linearTv)
        .addTask(webRequestTask)
        .addTask(uploadsTask);

    return builder;
}