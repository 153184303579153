import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateWebRequestTask } from "../tasks";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const TWIC1222CollGroupIds = [
    "TWIC-258-1222-US-MOBILE-ITW",
    "TWIC-259-1222-UK-MOBILE-ITW",
    "TWIC-260-1222-AU-MOBILE-ITW"
] as const;

export type TWIC1222CollGroupId = typeof TWIC1222CollGroupIds[number]

export const TWIC1222CollWebPlatformIds = [
    "TWIC-1222-US-Mobile-ITW",
    "TWIC-1222-UK-Mobile-ITW",
    "TWIC-1222-AU-Mobile-ITW"
] as const;

export type TWIC1222CollWebPlatformId = typeof TWIC1222CollWebPlatformIds[number]

type TWIC1222CollConfig = {
    groupId: TWIC1222CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getWebPlatformId = (groupId: TWIC1222CollGroupId): TWIC1222CollWebPlatformId => {
    switch (groupId) {
        case "TWIC-258-1222-US-MOBILE-ITW": return "TWIC-1222-US-Mobile-ITW";
        case "TWIC-259-1222-UK-MOBILE-ITW": return "TWIC-1222-UK-Mobile-ITW";
        case "TWIC-260-1222-AU-MOBILE-ITW": return "TWIC-1222-AU-Mobile-ITW";

        default:
            assertNever(groupId);
    }
}

export const addTwic1222CollConfig = (
    config: TWIC1222CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .setFlag('isRecordingAudio', true);

    const webplatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId),
        webPlatformConfigUrl: "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIC-1222/TWIC-1222-Mobile-ITW.json",
        platform: "Twitch",
        taskLengthMinutes: "20",
        langStrings: langStrings,
    });
    builder.addTask(webplatformTask);

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/TWIC-1222/${config.groupId}_INCENTIVE.json`,
        params: {},
        taskID: config.groupId + '-WEBREQUEST',
    })
    builder.addTask(webRequestTask);

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });
    builder.addTask(uploadsTask);

    return builder;
}