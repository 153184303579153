import { LangStrings } from './LangStrings';

import enLang from './en'
import deLang from './de'
import esLang from './es'
import frLang from './fr'
import arLang from './ar'
import poLang from './po'

export enum AppLanguage {
    ar = "ar",
    en = "en",
    de = "de",
    fr = "fr",
    es = "es",
    po = "po"
}

export const getLanguageStrings = (language: AppLanguage): LangStrings => {
    switch(language) {
        case AppLanguage.de:
            return deLang
        case AppLanguage.en:
            return enLang
        case AppLanguage.es:
            return esLang
        case AppLanguage.fr:
            return frLang
        case AppLanguage.ar:
            return arLang
        case AppLanguage.po:
            return poLang
    }
}
