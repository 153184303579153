import EnLangStrings from 'internationalisation/en';
import { RespondentConfigBuilder } from '../respondentConfigBuilder';
import {
    generateWebPlatformTask,
    generateExternalSurveyTask,
    generateUploadsTask
} from '../tasks';
import { assertNever } from '../helper/assertNever';
import { AppLanguage, getLanguageStrings } from 'internationalisation';
import { WebPlatform } from '../types';

const FINISH_MESSAGE = "تهانينا! لقد أكملت الآن جميع المهام المطلوبة. تمت معالجة مكافأتك الآن. يرجى ملاحظة أن هذا قد يستغرق ما يصل إلى 10 أيام عمل للظهور في حساب الفريق الخاص بك. شكرا لوقتك."

const OMME1022WebGroupIds = [
    'OMME-234-SAU-FACEBOOK',
    'OMME-235-SAU-INSTAGRAM',
    'OMME-236-SAU-YOUTUBE-PREROLL',
    'OMME-237-SAU-TWITTER-FEED',
    'OMME-238-SAU-TIKTOK-FEED',
    'OMME-239-SAU-ALMARSD-EXPOSED',
    'OMME-240-SAU-ALARABIYA-EXPOSED',
    'OMME-241-SAU-SPORT-ALMARSD-EXPOSED',
    'OMME-242-SAU-MOTORY-EXPOSED',
    'OMME-243-SAU-SO3ODY-EXPOSED',
    'OMME-253-UAE-FACEBOOK',
    'OMME-254-UAE-INSTAGRAM',
    'OMME-255-UAE-YOUTUBE-PREROLL',
    'OMME-256-UAE-TWITTER-FEED',
    'OMME-257-UAE-TIKTOK-FEED'
] as const;

export const OMME1022CollGroupIds = [
    ...OMME1022WebGroupIds
] as const;

export type OMME1022WebGroupId = typeof OMME1022WebGroupIds[number];

export type OMME1022CollGroupId =
    | OMME1022WebGroupId

type OMME1022WebCollConfig = {
    groupId: OMME1022WebGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getWebPlatformConfigUrl = (groupId: OMME1022WebGroupId): string => {
    switch (groupId) {
        case 'OMME-234-SAU-FACEBOOK': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-FACEBOOK.json';
        case 'OMME-253-UAE-FACEBOOK': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-UAE-FACEBOOK.json';
            
        case 'OMME-235-SAU-INSTAGRAM': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-INSTAGRAM.json';
        case 'OMME-254-UAE-INSTAGRAM': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-UAE-INSTAGRAM.json';
            
        case 'OMME-236-SAU-YOUTUBE-PREROLL': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-YOUTUBE-PREROLL.json';
        case 'OMME-255-UAE-YOUTUBE-PREROLL': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-UAE-YOUTUBE-PREROLL.json';

        case 'OMME-237-SAU-TWITTER-FEED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-TWITTER-FEED.json';
        case 'OMME-256-UAE-TWITTER-FEED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-UAE-TWITTER-FEED.json';

        case 'OMME-238-SAU-TIKTOK-FEED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-TIKTOK-FEED.json';
        case 'OMME-257-UAE-TIKTOK-FEED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-UAE-TIKTOK-FEED.json';
            
        case 'OMME-239-SAU-ALMARSD-EXPOSED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-ALMARSD-EXPOSED.json';
        case 'OMME-240-SAU-ALARABIYA-EXPOSED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-ALARABIYA-EXPOSED.json';
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-SPORTALMARSD-EXPOSED.json';
        case 'OMME-242-SAU-MOTORY-EXPOSED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-MOTORY-EXPOSED.json';
        case 'OMME-243-SAU-SO3ODY-EXPOSED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMME-1022/OMME-1022-SAU-SO3ODY-EXPOSED.json';

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: OMME1022WebGroupId): string => {
    switch (groupId) {
        case 'OMME-234-SAU-FACEBOOK': return 'https://survey.au1.qualtrics.com/jfe/form/SV_00Okh4ZUiLkFKZw';
        case 'OMME-235-SAU-INSTAGRAM': return 'https://survey.au1.qualtrics.com/jfe/form/SV_afnk4OpqbAQpuVU';
        case 'OMME-236-SAU-YOUTUBE-PREROLL': return 'https://survey.au1.qualtrics.com/jfe/form/SV_6Sds1mwX0p1XQjQ';
        case 'OMME-237-SAU-TWITTER-FEED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_2rYRp0fVlKeofk2';
        case 'OMME-238-SAU-TIKTOK-FEED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_7UkLJUinor9y8TA';

        case 'OMME-253-UAE-FACEBOOK': return 'https://survey.au1.qualtrics.com/jfe/form/SV_cUNDIbqS2mOcF8y';
        case 'OMME-254-UAE-INSTAGRAM': return 'https://survey.au1.qualtrics.com/jfe/form/SV_4HeCszk36kLrntY';
        case 'OMME-255-UAE-YOUTUBE-PREROLL': return 'https://survey.au1.qualtrics.com/jfe/form/SV_eXRpmKZpvAMJSNE';
        case 'OMME-256-UAE-TWITTER-FEED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_4ILMTeeaHbRhYpg';
        case 'OMME-257-UAE-TIKTOK-FEED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_6m8W7OjBb8zz9fo';

        case 'OMME-239-SAU-ALMARSD-EXPOSED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_9H4t0jkExe8XDH8';
        case 'OMME-240-SAU-ALARABIYA-EXPOSED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_9H4t0jkExe8XDH8';
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED': return "https://survey.au1.qualtrics.com/jfe/form/SV_9H4t0jkExe8XDH8";
        case 'OMME-242-SAU-MOTORY-EXPOSED': return "https://survey.au1.qualtrics.com/jfe/form/SV_9H4t0jkExe8XDH8";
        case 'OMME-243-SAU-SO3ODY-EXPOSED': return "https://survey.au1.qualtrics.com/jfe/form/SV_9H4t0jkExe8XDH8";

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: OMME1022WebGroupId): string => {
    switch (groupId) {
        case 'OMME-234-SAU-FACEBOOK': return 'OMME-1022-SAU-FACEBOOK';
        case 'OMME-253-UAE-FACEBOOK': return 'OMME-1022-UAE-FACEBOOK';
            
        case 'OMME-235-SAU-INSTAGRAM': return 'OMME-1022-SAU-INSTAGRAM';
        case 'OMME-254-UAE-INSTAGRAM': return 'OMME-1022-UAE-INSTAGRAM';

        case 'OMME-236-SAU-YOUTUBE-PREROLL':return 'OMME-1022-SAU-YOUTUBE-PREROLL';
        case 'OMME-255-UAE-YOUTUBE-PREROLL': return 'OMME-1022-UAE-YOUTUBE-PREROLL';
            
        case 'OMME-237-SAU-TWITTER-FEED': return 'OMME-1022-SAU-TWITTER-FEED';
        case 'OMME-256-UAE-TWITTER-FEED': return 'OMME-1022-UAE-TWITTER-FEED';

        case 'OMME-238-SAU-TIKTOK-FEED': return 'OMME-1022-SAU-TIKTOK-FEED';
        case 'OMME-257-UAE-TIKTOK-FEED': return 'OMME-1022-UAE-TIKTOK-FEED';

        case 'OMME-239-SAU-ALMARSD-EXPOSED': return 'OMME-1022-SAU-ALMARSD-EXPOSED';
        case 'OMME-240-SAU-ALARABIYA-EXPOSED': return 'OMME-1022-SAU-ALARABIYA-EXPOSED';
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED': return 'OMME-1022-SAU-SPORTALMARSD-EXPOSED';
        case 'OMME-242-SAU-MOTORY-EXPOSED': return 'OMME-1022-SAU-MOTORY-EXPOSED';
        case 'OMME-243-SAU-SO3ODY-EXPOSED': return "OMME-1022-SAU-SO3ODY-EXPOSED";

        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: OMME1022WebGroupId): WebPlatform => {
    switch (groupId) {
        case 'OMME-234-SAU-FACEBOOK':
        case 'OMME-253-UAE-FACEBOOK': 
            return 'Facebook';

        case 'OMME-235-SAU-INSTAGRAM':
        case 'OMME-254-UAE-INSTAGRAM': 
            return 'Instagram';

        case 'OMME-236-SAU-YOUTUBE-PREROLL':
        case 'OMME-255-UAE-YOUTUBE-PREROLL': 
            return 'Youtube';

        case 'OMME-237-SAU-TWITTER-FEED':
        case 'OMME-256-UAE-TWITTER-FEED': 
            return 'Twitter';

        case 'OMME-238-SAU-TIKTOK-FEED': 
        case 'OMME-257-UAE-TIKTOK-FEED': 
            return 'TikTok';

        case 'OMME-239-SAU-ALMARSD-EXPOSED':
        case 'OMME-240-SAU-ALARABIYA-EXPOSED':
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED':
        case 'OMME-242-SAU-MOTORY-EXPOSED':
        case 'OMME-243-SAU-SO3ODY-EXPOSED':
            return 'General';

        default:
            assertNever(groupId);
    }
}

function getWebplatformTaskLengthByGroupId(groupId: OMME1022WebGroupId): string{
    switch (groupId){
        case 'OMME-234-SAU-FACEBOOK':
        case 'OMME-253-UAE-FACEBOOK': 
            return '10';

        case 'OMME-235-SAU-INSTAGRAM':
        case 'OMME-254-UAE-INSTAGRAM': 
            return '10';

        case 'OMME-236-SAU-YOUTUBE-PREROLL':
        case 'OMME-255-UAE-YOUTUBE-PREROLL': 
            return '20';

        case 'OMME-237-SAU-TWITTER-FEED':
        case 'OMME-256-UAE-TWITTER-FEED': 
            return '10';
            
        case 'OMME-257-UAE-TIKTOK-FEED': 
        case 'OMME-238-SAU-TIKTOK-FEED':
            return '10';

        case 'OMME-239-SAU-ALMARSD-EXPOSED':
        case 'OMME-240-SAU-ALARABIYA-EXPOSED':
        case 'OMME-241-SAU-SPORT-ALMARSD-EXPOSED':
        case 'OMME-242-SAU-MOTORY-EXPOSED':
        case 'OMME-243-SAU-SO3ODY-EXPOSED':
            return '7';

        default:
            assertNever(groupId);
    }
}

// OMME 1022 web groups config.
export const addOMME1022CollWebplatformConfig = (
    config: OMME1022WebCollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en);

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .setFlag('preventTaskProgress', false);

    const taskLength = getWebplatformTaskLengthByGroupId(config.groupId);

    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: getPlatformForGroup(config.groupId),
        taskLengthMinutes: taskLength,
        langStrings,
    });

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder
        .addTask(webPlatformTask)
        .addTask(surveyTask)
        .addTask(uploadsTask);

    return builder;
}
