import { AppLanguage, getLanguageStrings } from "internationalisation";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateWebPlatformTask } from "../tasks";
import { GroupId, WebPlatform } from "../types";

export type AreMediaGroupId =
    | 'AREM-15-MC'
    | 'AREM-16-HTL'
    | 'AREM-17-NTL'

type AreMediaGroupConfig = {
    groupId: AreMediaGroupId,
    metaFieldNames: ReadonlyArray<string>
};

export const isAreMediaGroupId = (groupId: GroupId): groupId is AreMediaGroupId => {
    return ['AREM-16-MC', 'AREM-15-HTL', 'AREM-17-NTL'].includes(groupId)
}

const getWebPlatformForGroupId = (groupId: AreMediaGroupId): WebPlatform => {
    switch (groupId) {
        case 'AREM-15-MC': return 'Marie Claire';
        case 'AREM-16-HTL': return 'Homes To Love';
        case 'AREM-17-NTL': return 'Now To Love';
    }
}

export const addAreMediaConfig = (
    config: AreMediaGroupConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder.setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3);

    const webTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: config.groupId,
        webPlatformConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/AREM/${config.groupId}.json`,
        platform: getWebPlatformForGroupId(config.groupId),
        taskLengthMinutes: "7",
        langStrings,
    })

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: `https://survey.au1.qualtrics.com/jfe/form/SV_82GEQmMrEGsnjim`,
    })

    builder
        .addTask(webTask)
        .addTask(surveyTask)

    return builder
}