import { AppLanguage, getLanguageStrings } from "internationalisation/i18n"
import { LangStrings } from 'internationalisation/LangStrings'

export type LangStepLabels = "Instructions" | "ViewingInstructionsDeskSolution" | "Requirements" | "AppDownload" | "AppDownloadDeskSolution" | "LoginAndCompletionCode" | "MturkGazeDesktopCompletion" | "LoginAndCompletionDeskSolution" | "SessionInformationDeskSolution" | "Setup" | "StartEarning";

let currentLang: AppLanguage = AppLanguage.en

export const setCurrentLang = (lang: AppLanguage) => {
    currentLang = lang
}

export const getCurrentLang = (): AppLanguage => {
    return currentLang
}

export const getCurrentLangStrings = (): LangStrings => {
    return getLanguageStrings(currentLang)
}
