import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { NEAU1022CollGroupId } from "respondent_config_generator/src/projects/NEAU-1022";

export { NEAU1022CollGroupIds } from "respondent_config_generator/src/projects/NEAU-1022";
export type { NEAU1022CollGroupId } from "respondent_config_generator/src/projects/NEAU-1022";

const getPlatformForGroupId = (groupId: NEAU1022CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "NEAU-225-1022-NEWS-HERALDSUN-BASELINE":
        case "NEAU-226-1022-NEWS-HERALDSUN-FREQUENCY":
        case "NEAU-227-1022-VOGUE-AUSTRALIAN-BASELINE":
        case "NEAU-228-1022-VOGUE-AUSTRALIAN-FREQUENCY":
        case "NEAU-229-1022-KIDSPOT-ESCAPE-BASELINE":
        case "NEAU-230-1022-KIDSPOT-ESCAPE-FREQUENCY":
        case "NEAU-231-1022-TASTE-DAILYTELEGRAPH-BASELINE":
        case "NEAU-232-1022-TASTE-DAILYTELEGRAPH-FREQUENCY":
        case "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD":
            return "newscorp";

        default:
            assertNever(groupId)
    }
}

export const configureNEAU1022Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<NEAU1022CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}