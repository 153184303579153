import React from "react";
import {
    Typography,
    Grid,
    styled,
} from "@mui/material";
import { getCurrentLangStrings } from '../../../i18n'

const NextButton = styled("button")`
    color: #ffffff;
    font-size: 18px;
    font-weight: 600;
    padding: 8px 16px 8px 16px;
    border-radius: 6px;
    background: #007041;
    font-family: "Roboto","Helvetica","Arial",sans-serif;
    border: 1px solid #007041;
`;

const BoldContent = styled("span")`
    font-weight: 500;
`

const AppDownloadDeskSolution: React.FC = () => {
    const langStrings = getCurrentLangStrings();
    
    return (
        <div>
            <Typography align="center" component="p" fontWeight={500}>Download Chrome Extension</Typography>
            <Grid container justifyContent="center" alignItems="center" sx={{paddingTop: 4}}>
                <NextButton onClick={() =>  window.open("https://chrome.google.com/webstore/detail/dhgkipplbcekmkpfipcjciiophjbnkib",'_blank')}>{langStrings.Steps.AppDownloadDeskSolution.downloadLink}</NextButton>
            </Grid>
            <Typography align="left" gutterBottom component="p" sx={{paddingTop: 4}}>Note: If you are switching devices from mobile to laptop, you can search for the extension in the <BoldContent>Chrome webstore</BoldContent> by typing in “attentionTRACE”.</Typography>
        </div>
    );
};

export default AppDownloadDeskSolution