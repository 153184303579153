import { ConfigKey, CONFIG_KEYS, MTurkConfigKey, CAIConfigKey, isCAIConfigKey } from "./configKey";
import { RequestError, ConfigurationError } from "./Errors";
import { isMturkGroupId, MturkGroupId, MturkGroupIdRequestable } from './Respondent/ConfigDataStores/mturk';

type CommonParams = {
    /** Respondent ID override */
    RID?: string
    /** Assignment ID override */
    AID?: string
    /** Environment override */
    env?: string
};

/**
 * Parameters passed from Mturk to our page - including any additional params
 * we may wish to include, when task has been accepted.
 * @template GroupId - GroupIds to include in this type
 */
export type MturkParamsPreview<GroupId extends MturkGroupId> = CommonParams & {
    /** Discriminator */
    __type: 'MTURK_PREVIEW_PARAMS',

    /**
     * Configuration key - identifies behaviour of webpage and determines
     * output configuration structure dictating app behaviour.
     */
    G: GroupId,
    assignmentId: 'ASSIGNMENT_ID_NOT_AVAILABLE',
    hitId: string,
    rmeta: Record<string, string>,
    turkSubmitTo: string,
};

/**
 * Parameters passed from Mturk to our page - including any additional params
 * we may wish to include, when task has been accepted.
 * @template GroupId - GroupIds to include in this type
 */
export type MturkParamsAccepted<GroupId extends MturkGroupId> = CommonParams & {
    /** Discriminator */
    __type: 'MTURK_PARAMS',

    /**
     * Configuration key - identifies behaviour of webpage and determines
     * output configuration structure dictating app behaviour.
     */
    G: GroupId,
    workerId: string,
    assignmentId: string,
    hitId: string,
    turkSubmitTo: string,
    rmeta: Record<string, string>,
    /** Has the respondent been presented with & completed the demographic survey */
    completedDemographicSurvey: boolean
    isMacbook: boolean
};

export type MturkParamsPreviewOrAccepted<GroupId extends MturkGroupId> = MturkParamsPreview<GroupId> | MturkParamsAccepted<GroupId>

/**
 * We differenciate beteween all mTurk groups and mTurk groups that require a loginId using these two types
 */
export type MTurkParams = MturkParamsPreviewOrAccepted<MturkGroupId>
export type MTurkParamsRequestable = MturkParamsAccepted<MturkGroupIdRequestable>
/**
 * Request parameters from our own format dictated to panel providers.
 */
export type CAIParamsPanelIdentifier =
    | 'cint'
    | 'cint-sbox'
    | 'amplified-qualtrics'

const isCAIParamsPanelIdentifier = (s: string): s is CAIParamsPanelIdentifier => ['cint', 'cint-sbox', 'amplified-qualtrics'].includes(s)

export type CAIParams<GroupId = CAIConfigKey> = CommonParams & {
    /** Discriminator */
    __type: 'CAI_PARAMS',

    /**
     * Configuration key - identifies behaviour of webpage and determines
     * output configuration structure dictating app behaviour.
     */
    G: GroupId,
    /** Respondent metadata. Panel providers can provide any additional information they'd like to here */
    rmeta: {
        panelRespondentID: string
        panelIdentifier: CAIParamsPanelIdentifier
    }
};

/**
 * Parsed query parameters containing all necessary fields for a particular
 * vendor. This type contains groupIds that can not be used to request a LoginId
 * If you need to request a loginId, use the `requiresConfiguration` func in
 * `respondentConfig.ts` to narrow the type to `QueryParamsRequestable`
 */
export type QueryParams =
    | MTurkParams
    | CAIParams;

/**
 * Parsed query parameters containing all necessary fields for a particular
 * vendor. This type contains groupIds that can be used to request a LoginId
 */
export type QueryParamsRequestable =
    | MTurkParamsRequestable
    | CAIParams;


/**
 * Takes in all query parameters, ensures all required fields exists and
 * returns a strongly typed MTurk params object.
 *
 * @param configKey MTurk configuration key.
 * @param params Dict of all query parameters for the request to parse.
 */
const validateMTurkParams = (
    configKey: MTurkConfigKey,
    params: Record<string, unknown>
): MTurkParams => {

    const workerId = params['workerId'];
    const assignmentId = params['assignmentId'];
    const hitId = params['hitId'];
    const turkSubmitTo = params['turkSubmitTo'];
    const rmeta = params['rmeta'] as Record<string, string>;

    const RID = params['RID'];
    const AID = params['AID'];
    const env = params['env'];
    const completedDemographicSurvey = params['completedDemographicSurvey'];
    const isMacbook = params['isMacbook'] as boolean | undefined;

    if (typeof assignmentId === 'undefined') {
        throw new RequestError('Query parameters did not contain assignmentId for MTurk configuration.');
    }

    if (typeof hitId === 'undefined') {
        throw new RequestError('Query parameters did not contain hitId for MTurk configuration.');
    }

    if (assignmentId === 'ASSIGNMENT_ID_NOT_AVAILABLE') {
        return {
            __type: 'MTURK_PREVIEW_PARAMS',

            G: configKey,
            assignmentId: assignmentId,
            hitId: hitId as string,
            turkSubmitTo: turkSubmitTo as string,
            RID: RID as string | undefined,
            AID: AID as string | undefined,
            env: env as string | undefined,
            rmeta: {},
        };
    }

    if (typeof workerId === 'undefined') {
        throw new RequestError('Query parameters did not contain workerId for MTurk configuration.');
    }


    return {
        __type: 'MTURK_PARAMS',

        G: configKey,
        workerId: workerId as string,
        assignmentId: assignmentId as string,
        hitId: hitId as string,
        turkSubmitTo: turkSubmitTo as string,
        RID: RID as string | undefined,
        AID: AID as string | undefined,
        env: env as string | undefined,
        rmeta: rmeta ?? {},
        completedDemographicSurvey: completedDemographicSurvey === 'true' || completedDemographicSurvey === true,
        isMacbook: isMacbook ?? false
    };

};

/**
 * Takes in all query parameters, ensures all required fields exists and
 * returns a strongly typed CAI params object.
 *
 * @param configKey CAI configuration key.
 * @param params Dict of all query parameters for the request to parse.
 */
const validateCAIParams = (
    configKey: CAIConfigKey,
    params: Record<string, unknown>
): CAIParams => {
    const rmeta = params['rmeta'] as Record<string, string>;
    const panelRespondentIdKey = 'panelRespondentID';
    const panelIdentifierKey = 'panelIdentifier';

    if (!rmeta) {
        throw new RequestError('Query parameters did not contain rmeta for CAI configuration.');
    }

    const panelRespondentID = rmeta[panelRespondentIdKey]
    if (!panelRespondentID) {
        throw new RequestError(`Query parameter "rmeta" did not contain "${panelRespondentIdKey}" required for CAI configuration.`);
    }

    const panelIdentifier = rmeta[panelIdentifierKey]
    if (!panelIdentifier) {
        throw new RequestError(`Query parameter "rmeta" did not contain "${panelIdentifierKey}" required for CAI configuration.`);
    }

    if (!isCAIParamsPanelIdentifier(panelIdentifier)) {
        throw new RequestError(`Query parameter "rmeta" did not contain a valid "${panelIdentifierKey}" value.`);
    }

    const env = params['env'];

    return {
        __type: 'CAI_PARAMS',

        G: configKey,
        rmeta: {
            panelIdentifier: panelIdentifier,
            panelRespondentID
        },
        env: env as string | undefined
    };

};

/**
 * Ensures that the provided key belongs to a particular group, and casts it
 * to that once validated.
 *
 * @param key Config key from query string params.
 * @param typeValues Array containing all the values of the given type.
 */
const assertIsKeyOfType = <Key extends ConfigKey>(key: string, typeValues: ReadonlyArray<Key>): Key => {
    const isContained = (typeValues as ReadonlyArray<string>).includes(key);
    if (isContained) {
        return key as Key;
    }

    throw new ConfigurationError(`Configuration key "${key}" was not of expected type.`);
};

/**
 * Takes a dictionary of query parameters to validate. Ensures that per the
 * configuration key the respective required fields exist.
 *
 * @param queryParams Dictionary of preprocessed query parameters to parse and
 * validate.
 * @returns Strongly typed query parameter object for the given config id.
 */
export const parseQueryParams = (
    queryParams: Record<string, unknown>
): QueryParams => {
    const _configKey = queryParams['G'] as string;
    if (!_configKey) {
        throw new RequestError('No config key was provided in query string (G).');
    }

    // Use wide typer for exhaustive search.
    const configKey = assertIsKeyOfType(_configKey, CONFIG_KEYS);

    // Type narrow the config key for use within each block.
    if (isMturkGroupId(configKey)) {
        return validateMTurkParams(configKey, queryParams)
    } else if (isCAIConfigKey(configKey)) {
        return validateCAIParams(configKey, queryParams)
    } else {
        // Ensure we're handling all known ConfigKey types
        assertNever(configKey)
    }
};
