import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateExternalSurveyTask, generateUploadsTask } from "../tasks";
import { WebPlatform } from "../types";

const SPIN0823CollBaselineGroupIds = [
    "SPIN-288-0823-SPOTIFY",
    "SPIN-289-0823-FACEBOOK",
    "SPIN-290-0823-INSTAGRAM",
    "SPIN-291-0823-YOUTUBE",
] as const

const SPIN0823CollSynergyGroupIds = [
    "SPIN-292-0823-FB-SPOT",
    "SPIN-293-0823-IG-SPOT",
    "SPIN-294-0823-YT-SPOT"
] as const

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const SPIN0823CollAllGroupIds = [
    ...SPIN0823CollBaselineGroupIds,
    ...SPIN0823CollSynergyGroupIds,
] as const;

type SPIN0823CollBaselineGroupId = typeof SPIN0823CollBaselineGroupIds[number]
type SPIN0823CollSynergyGroupId = typeof SPIN0823CollSynergyGroupIds[number]

export type SPIN0823CollGroupId =
    | SPIN0823CollBaselineGroupId
    | SPIN0823CollSynergyGroupId

const isSynergyGroupId = (groupId: SPIN0823CollGroupId): groupId is SPIN0823CollSynergyGroupId => {
    return (SPIN0823CollSynergyGroupIds as ReadonlyArray<string>).includes(groupId)
}

type SPIN0823CollConfig = {
    groupId: SPIN0823CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (groupId: SPIN0823CollGroupId): string => {
    switch (groupId) {
        case "SPIN-288-0823-SPOTIFY":
        case "SPIN-289-0823-FACEBOOK":
        case "SPIN-290-0823-INSTAGRAM":
            return "10"
        case "SPIN-291-0823-YOUTUBE":
            return "15";
        case "SPIN-292-0823-FB-SPOT":
        case "SPIN-293-0823-IG-SPOT":
        case "SPIN-294-0823-YT-SPOT":
            return "10"                 // The length is 10 for each part
        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: SPIN0823CollGroupId, taskIndex: 0 | 1): WebPlatform => {
    switch (groupId) {
        case "SPIN-288-0823-SPOTIFY": 
            return "Spotify"
        
        case "SPIN-289-0823-FACEBOOK":
            return "Facebook"

        case "SPIN-290-0823-INSTAGRAM":
            return "Instagram"

        case "SPIN-291-0823-YOUTUBE":
            return "Youtube"

        case "SPIN-292-0823-FB-SPOT":
            return taskIndex === 0 ? "Facebook" : "Spotify"

        case "SPIN-293-0823-IG-SPOT":
            return taskIndex === 0 ? "Instagram" : "Spotify"

        case "SPIN-294-0823-YT-SPOT":
            return taskIndex === 0 ? "Youtube" : "Spotify"

        default:
            assertNever(groupId);
    }
}

const getWebPlatformConfigUrl = (groupId: SPIN0823CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "SPIN-288-0823-SPOTIFY": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-SPOTIFY.json"
        case "SPIN-289-0823-FACEBOOK": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-FACEBOOK.json"
        case "SPIN-290-0823-INSTAGRAM": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-INSTAGRAM.json"
        case "SPIN-291-0823-YOUTUBE": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-YOUTUBE.json"

        case "SPIN-292-0823-FB-SPOT":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-S-FACEBOOK.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-SPOTIFY.json";

        case "SPIN-293-0823-IG-SPOT":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-S-INSTAGRAM.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-SPOTIFY.json";

        case "SPIN-294-0823-YT-SPOT":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-S-YOUTUBE.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPIN-0823/SPIN-0823-SPOTIFY.json";

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: SPIN0823CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "SPIN-288-0823-SPOTIFY": return "SPIN-0823-SPOTIFY"
        case "SPIN-289-0823-FACEBOOK": return "SPIN-0823-FACEBOOK"
        case "SPIN-290-0823-INSTAGRAM": return "SPIN-0823-INSTAGRAM"
        case "SPIN-291-0823-YOUTUBE": return "SPIN-0823-YOUTUBE"
        case "SPIN-292-0823-FB-SPOT":
            return taskIndex === 0
                ? "SPIN-0823-FACEBOOK"
                : "SPIN-0823-SPOTIFY"

        case "SPIN-293-0823-IG-SPOT":
            return taskIndex === 0
                ? "SPIN-0823-INSTAGRAM"
                : "SPIN-0823-SPOTIFY"

        case "SPIN-294-0823-YT-SPOT":
            return taskIndex === 0
                ? "SPIN-0823-YOUTUBE"
                : "SPIN-0823-SPOTIFY"

        default: assertNever(groupId);
    }
}

export const addSPIN0823CollConfig = (
    config: SPIN0823CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder.setFlag('deviceLock', 'mobile')
    .setFlag('requireMinimumBattery', 0.3)
    .setFlag('preventTaskProgress', false)
    .setFlag('finishMessageOverride', FINISH_MESSAGE);

    const webTask1 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId, 0),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: getPlatformForGroup(config.groupId, 0),
        taskLengthMinutes: getTaskLengthMinutes(config.groupId),
        langStrings,
    })
    builder.addTask(webTask1)

    if (isSynergyGroupId(config.groupId)) {
        const webTask2 = generateWebPlatformTask({
            groupId: config.groupId,
            webPlatformID: getWebPlatformId(config.groupId, 1),
            webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 1),
            platform: getPlatformForGroup(config.groupId, 1),
            taskLengthMinutes: getTaskLengthMinutes(config.groupId),
            langStrings,
        })
        builder.addTask(webTask2)
    }

    const getSurveyUrl = (groupId: SPIN0823CollGroupId): string => {
        switch (groupId) {
            case "SPIN-288-0823-SPOTIFY": return "https://survey.au1.qualtrics.com/jfe/form/SV_5AqJGK1YX74W1kG";
            case "SPIN-289-0823-FACEBOOK": return "https://survey.au1.qualtrics.com/jfe/form/SV_1AZqyI6WaovpBBA";
            case "SPIN-290-0823-INSTAGRAM": return "https://survey.au1.qualtrics.com/jfe/form/SV_4OQcO5OsiWfeul8";
            case "SPIN-291-0823-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_eX5tFGyAnS0w7Ge";
            case "SPIN-292-0823-FB-SPOT": return "https://survey.au1.qualtrics.com/jfe/form/SV_26kfQIRGWBCmGBE";
            case "SPIN-293-0823-IG-SPOT": return "https://survey.au1.qualtrics.com/jfe/form/SV_1S8o4wORnKXH3D0";
            case "SPIN-294-0823-YT-SPOT": return "https://survey.au1.qualtrics.com/jfe/form/SV_bOR9orcVvTKkLci";

            default:
                assertNever(groupId);
        }
    }

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields:  config.groupId.includes("SPOT") ? [...config.metaFieldNames, "webPlatform_playListID"] : [...config.metaFieldNames],
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    builder.addTask(surveyTask)

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder.addTask(uploadsTask)

    return builder
}