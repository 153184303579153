import { LandingPageConfig, LandingPageStep, LandingPageSupportEmail } from './reducer';
import { QueryParams } from '../queryParams';
import { ConfigurationError } from '../Errors';
import { PlatformInstructionsKey } from './Components/Steps/LoginAndCompletionCode';
import { PlatformInstructionsDeskSolutionKey } from './Components/Steps/LoginAndCompletionDeskSolution';

/**
 * This class exists to build out a LandingPage config before we commit it to Redux Store.
 */
export class LandingPageConfigBuilder {
    private pageTitle?: string
    private loginId?: string
    private params?: QueryParams
    private steps?: ReadonlyArray<LandingPageStep>
    private submitToMturk: boolean = false
    private supportEmail?: LandingPageSupportEmail
    private iframeUrl?: string
    private previewMode: boolean = false

    public setPageTitle(pageTitle: string) {
        this.pageTitle = pageTitle
    }

    public setLoginId(loginId: string) {
        this.loginId = loginId
    }

    public setParams(params: QueryParams) {
        this.params = params
    }

    public setSteps(steps: ReadonlyArray<LandingPageStep>) {
        this.steps = steps
    }

    public setSubmitToMturk(submitToMturk: boolean) {
        this.submitToMturk = submitToMturk
    }

    public setSupportEmail(email: LandingPageSupportEmail) {
        this.supportEmail = email
    }

    public setIFrameUrl(pixelUrl: string) {
        this.iframeUrl = pixelUrl
    }

    public setPreviewMode(previewMode: boolean) {
        this.previewMode = previewMode
    }

    private buildFailed(fieldName: string): never {
        throw new ConfigurationError(`Failed to build landing page config - required field ${fieldName} missing.`);
    }

    public build(): LandingPageConfig {
        const {
            pageTitle,
            loginId,
            params,
            steps,
            submitToMturk,
            supportEmail,
            previewMode
        } = this

        if (pageTitle === undefined) {
            this.buildFailed('pageTitle')
        }

        if (loginId === undefined) {
            this.buildFailed('loginId')
        }

        if (params === undefined) {
            this.buildFailed('params')
        }

        if (steps === undefined) {
            this.buildFailed('steps')
        }

        if (supportEmail === undefined) {
            this.buildFailed('supportEmail')
        }

        let config: LandingPageConfig = {
            pageTitle,
            loginId,
            params,
            previewMode,
            submitToMturk,
            supportEmail,
            steps,
            iframeUrl: this.iframeUrl
        }

        return config
    }

    static getStandardViewingSurveySteps(platform: PlatformInstructionsKey): ReadonlyArray<LandingPageStep> {
        return [
            { stepComponentKey: 'ViewingInstructions', titleL18nKey: "Instructions", props: {} },
            { stepComponentKey: 'Setup', titleL18nKey: "Setup", props: {} },
            { stepComponentKey: 'StartEarning', titleL18nKey: "StartEarning", props: { _type: 'platform', platform } }
        ]
    }

    static getDeskStandardViewingSurveySteps(platform: PlatformInstructionsDeskSolutionKey): ReadonlyArray<LandingPageStep> {
        return [
            { stepComponentKey: 'ViewingInstructionsDeskSolution', titleL18nKey: "ViewingInstructionsDeskSolution", props: { _type: 'platform', platform} },
            { stepComponentKey: 'SessionInformationDeskSolution', titleL18nKey: "SessionInformationDeskSolution", props: { _type: 'platform', platform} },
            { stepComponentKey: 'LoginAndCompletionDeskSolution', titleL18nKey: "LoginAndCompletionDeskSolution", props: { _type: 'platform', platform } },
            { stepComponentKey: 'AppDownloadDeskSolution', titleL18nKey: "AppDownloadDeskSolution", props: {} },
        ]
    }
}
