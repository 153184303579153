import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { TWIC1222CollGroupId } from "respondent_config_generator/src/projects/TWIC-1222";

export { TWIC1222CollGroupIds } from "respondent_config_generator/src/projects/TWIC-1222";
export type { TWIC1222CollGroupId } from "respondent_config_generator/src/projects/TWIC-1222";

const getPlatformForGroupId = (groupId: TWIC1222CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "TWIC-258-1222-US-MOBILE-ITW":
        case "TWIC-259-1222-UK-MOBILE-ITW":
        case "TWIC-260-1222-AU-MOBILE-ITW":
            return "twitch";

        default:
            assertNever(groupId)
    }
}

export const configureTWIC1222Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<TWIC1222CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}