import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { ConfigKey } from "../../configKey";

export const OMDG_GROUP_IDS = [
    'OMDG-1A',
    'OMDG-1B',
    'OMDG-1C',
    'OMDG-2A',
    'OMDG-2B',
    'OMDG-2C',
    'OMDG-3A',
    'OMDG-3B',
    'OMDG-3C',
    'OMDG-4',
    'OMDG-5'
] as const

export type OMDGGroupId = (typeof OMDG_GROUP_IDS)[number]

export function isOMDGGroupParams(params: CAIParams<ConfigKey>): params is CAIParams<OMDGGroupId> {
    return (OMDG_GROUP_IDS as ReadonlyArray<string>).includes(params.G)
}

const getPlatformForGroupId = (groupId: OMDGGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case 'OMDG-1A':
        case 'OMDG-1B':
        case 'OMDG-1C':
        case 'OMDG-4':
            return "facebook-newsfeed"

        case 'OMDG-2A':
        case 'OMDG-2B':
        case 'OMDG-2C':
        case 'OMDG-5':
            return "instagram-newsfeed"

        case 'OMDG-3A':
        case 'OMDG-3B':
        case 'OMDG-3C':
            return "youtube"
    }
}

export const configureOMDGPage = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<OMDGGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}