import { Grid, TextField, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { getCurrentLangStrings } from "../../../i18n";

import QRCodeComponent from "../QRCodeComponent";

export enum CompletionCodeState {
    waitingForInput = "waitingForInput",
    invalid = "invalid",
    valid = "valid",
}

export type PlatformInstructionsKey =
    | "bvod"
    | "facebook-newsfeed"
    | "facebook-stories"
    | "facebook-watch"
    | "facebook"
    | "instagram-newsfeed"
    | "instagram-stories"
    | "instagram"
    | "youtube"
    | "twitter"
    | "tiktok"
    | "webplatform-synergy"
    | "HomesToLove"
    | "MarieClaire"
    | "NowToLove"
    | "yahoo"
    | "snapchat"
    | "adColony"
    | "stuff"
    | "nzme"
    | "nzherald"
    | "omgmena"
    | "newscorp"
    | "tead"
    | "twitch"
    | "spotify"
    | "pepsi"
    | "spotify-india"
    | "snapar"
    | "pinterest"
    | "yahoo-mobile"
    | "spotify-au"

interface Props {
    loginId: string;
    platform?: PlatformInstructionsKey;
    completionCode?: string;
    completionCodeStateChanged?: (
        state: CompletionCodeState,
        input: string
    ) => void;
}

export const getInstructionsForPlatform = (
    platform: PlatformInstructionsKey
): string => {
    const langStrings = getCurrentLangStrings();
    switch (platform) {
        case "bvod":
            return "Log in to the app with your Login ID, then select an episode to watch from the list of programs available.";

        case "facebook-newsfeed":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions
                .facebookNewsfeed;
        case "facebook-stories":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions
                .facebookStories;
        case "facebook-watch":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions
                .facebookWatch;
        case "facebook":
            return `Once you’ve downloaded the app, it’s time to enter your Login ID.\nOnce you’re logged in, we will load your social media app and ask you to login to your personal account.\nTo earn, you’ll need to follow the instructions, scroll content, watch videos, like posts, have fun and wait for the timer to end 😎.`;
            
        case "instagram-newsfeed":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions
                .instagramNewsfeed;
        case "instagram-stories":
            return "Login to your Instagram account and see what your friends & favourite pages are posting on Stories. The app will notify you when your time is up!\nYou will need your Instagram login details ready before you begin";
        case "instagram":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions.instagram

        case "youtube":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions.youtube;

        case "tiktok":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions.tiktok;

        case "twitter":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions
                .twitter;

        case "webplatform-synergy":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions
                .webplatformSynergy;

        case "HomesToLove":
            return "Once you are in, scroll through the Homes To Love website as you normally would. The app will notify you when time is up.";
        case "MarieClaire":
            return "Once you are in, scroll through the Marie Claire website as you normally would. The app will notify you when time is up.";
        case "NowToLove":
            return "Once you are in, scroll through the Now To Love website as you normally would. The app will notify you when time is up.";

        case "yahoo":
            return "Once you are in, select a few articles to read until the timer is up.";

        case "snapchat":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions.snapchat;

        case "adColony":
            return "There are 3 levels to complete within 10 minutes. If you finish early, please wait until the timer runs out.";

        case "stuff":
            return "Upon login you will be directed to a website, please browse this site as you normally would; scroll, read articles, watch videos until the timer is up. There will be a short survey at the end.";
        case "nzme":
            return "Upon login you will be directed to a website, please browse this site as you normally would; scroll, read articles, watch videos for 5 minutes. At the 5 minute mark you will be automatically directed to a new website. Please browse this website for a further 2 minutes. There will be a short survey at the end.";
        case "nzherald":
            return "Upon login you will be directed to a website, please browse this site as you normally would; scroll, read articles, watch videos until the timer is up. There will be a short survey at the end.";

        case "omgmena":
            return "Upon logging in to attentionTRACE you will be directed to a website. Please browse this site as you normally would; scroll, read articles and watch videos until the timer is up.\nYou will be presented with a short survey about your experience at the end.";
        case "newscorp":
            return "Once you login you will be directed to a website, please browse this website as you normally would (read articles, watch videos) until the timer is up. At the halfway point you will be redirected to another website. You will need to browse both websites to receive your reward.";

        case "tead":
            return "Upon login you will be directed to a website, please browse this site as you normally would; scroll, read articles, watch videos for 7 minutes. At the 7 minute mark you will be automatically directed to a new website. Please browse this website for a further 7 minutes.";

        case "twitch":
            return "Scan the QR code above, or manually enter the unique ID, to login to the attentionTRACE app. Once logged in, you will be automatically directed to Twitch. Please select a live video that interests you and watch until the timer is up. You may watch as many videos as you like. Please ensure your audio is switched on and turned up for the duration of your session.";
        
        case "spotify":
            return `Please scan the QR code above, or manually enter the unique ID, to login to the attentionTRACE app.
            Once logged into the attentionTRACE app, you will be presented with a Spotify profile which will have playlists available for you to choose from. 
            Please select your preferred playlist from the options provided and listen until the timer is up. Once the audio file is complete, you will be presented with a survey on your experience.`;

        case "pepsi":
            return `Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will be presented with the social media platform of your choice and asked to log in to your personal account.\nPlease browse this platform as you normally would (watch videos, comment and interact with posts etc) until the timer is up.\nYou will be redirected to a survey, simply complete this and you are done!`

        case "spotify-india":
            return `Please scan the QR code above, or manually enter the unique ID, to login to the attentionTRACE app.\nOnce you have logged in, you will be presented with the social media platform of your choice and asked to log in to your personal account.\nPlease browse this platform as you normally would (watch videos, comment and interact with posts etc) for 10 minutes.\nOnce the timer is up you will be automatically redirected to Spotify.\nPlease select a playlist that interests you and listen to it in its entirety. Please note, the countdown timer will not commence until you have selected a playlist.\nOnce this is complete you will be presented with a survey on your experience and then you are done!`;

        case "spotify-au":
            return `Please scan the QR code above, or manually enter the unique ID, to login to the attentionTRACE app.\nOnce logged into the attentionTRACE app, you will be presented with a Spotify profile which will have playlists available for you to choose from.\nPlease select your preferred playlist from the options provided and listen until the timer is up. \nPlease note, the timer will not start until you have selected a playlist. Please ensure your phone remains unlocked during the experience. \nOnce the audio file is complete, you will be presented with a survey on your experience.`
        
        case "snapar":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions.snapar;
            
        case "pinterest":
            return langStrings.Steps.LoginAndCompletionCode.platformInstructions.pinterest;

        case "yahoo-mobile":
            return "Please scan the QR code above, or manually enter the unique ID to login to the attentionTRACE app.\nOnce you have logged in, you will be presented with a Yahoo! website.\nPlease browse this website as you normally would (watch videos, read articles comment and interact with content etc) until the timer is up."
        
        default:
            assertNever(platform);
    }
};

const LoginAndCompletionCode: React.FC<Props> = (props) => {
    const { completionCode, completionCodeStateChanged, loginId, platform } =
        props;

    const lang = getCurrentLangStrings();

    const [completionCodeInputState, setCompletionCodeInputState] = useState(
        CompletionCodeState.waitingForInput
    );

    useEffect(() => {
        if (!completionCode) {
            setCompletionCodeInputState(CompletionCodeState.valid);
            if (completionCodeStateChanged) {
                completionCodeStateChanged(CompletionCodeState.valid, "");
            }
        }
    }, [completionCode, completionCodeStateChanged]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            if (value.length < 5) {
                setCompletionCodeInputState(
                    CompletionCodeState.waitingForInput
                );
                if (completionCodeStateChanged) {
                    completionCodeStateChanged(
                        CompletionCodeState.waitingForInput,
                        value
                    );
                }
                return;
            }

            if (completionCode === value) {
                setCompletionCodeInputState(CompletionCodeState.valid);
                if (completionCodeStateChanged) {
                    completionCodeStateChanged(
                        CompletionCodeState.valid,
                        value
                    );
                }
            } else {
                setCompletionCodeInputState(CompletionCodeState.invalid);
                if (completionCodeStateChanged) {
                    completionCodeStateChanged(
                        CompletionCodeState.invalid,
                        value
                    );
                }
            }
        },
        [completionCode, completionCodeStateChanged]
    );

    return (
        <>
            <Typography align="center" gutterBottom>
                {lang.Steps.LoginAndCompletionCode.subtitle}
            </Typography>
            <Typography align="center" variant="h2" gutterBottom>
                {loginId}
            </Typography>
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                <QRCodeComponent loginId={loginId} size={128}></QRCodeComponent>
            </div>
            {platform && (
                <Typography align="center" whiteSpace="pre-line">
                    {getInstructionsForPlatform(platform)}
                </Typography>
            )}
            {completionCode && (
                <>
                    <Typography align="center">
                        Once you have completed enter your completion code
                        below:
                    </Typography>
                    <Grid container justifyContent="center">
                        <form noValidate autoComplete="off">
                            <TextField
                                disabled={
                                    completionCodeInputState ===
                                    CompletionCodeState.valid
                                }
                                error={
                                    completionCodeInputState ===
                                    CompletionCodeState.invalid
                                }
                                label="Completion Code"
                                margin="normal"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </form>
                    </Grid>
                </>
            )}
        </>
    );
};

export default LoginAndCompletionCode;
