import { YAHD0823DesktopGroupId, YAHD0823MobileGroupId } from "respondent_config_generator/src/projects/YAHD-0823";
import { PlatformInstructionsDeskSolutionKey } from "../../LandingPage/Components/Steps/LoginAndCompletionDeskSolution";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { AppLanguage } from "internationalisation";

const getDesktopPlatformForGroupId = (groupId: YAHD0823DesktopGroupId): PlatformInstructionsDeskSolutionKey => {
    switch (groupId) {
        case "YAHD-299-0823-USA-YAHOO-DESKTOP":
        case "YAHD-300-0823-USA-SPORTS-DESKTOP":
        case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP":
        case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP":
        case "YAHD-311-0823-AUS-YAHOO-DESKTOP":
        case "YAHD-312-0823-AUS-SPORTS-DESKTOP":
        case "YAHD-313-0823-AUS-FINANCE-DESKTOP":
        case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP":
            return "yahoo-desktop";

        default:
            assertNever(groupId)
    }
}

const getMobilePlatformForGroupId = (groupId: YAHD0823MobileGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "YAHD-303-0823-USA-YAHOO-MOBILE":
        case "YAHD-304-0823-USA-SPORTS-MOBILE":
        case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE":
        case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE":
        case "YAHD-307-0823-AUS-YAHOO-MOBILE":
        case "YAHD-308-0823-AUS-SPORTS-MOBILE":
        case "YAHD-309-0823-AUS-FINANCE-MOBILE":
        case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE":
            return "yahoo-mobile";

        default:
            assertNever(groupId)
    }
}

const setLangAndGetTitle = (): string => {
    setCurrentLang(AppLanguage.en);
    return getCurrentLangStrings().LandingPage.Title;
}

export const configureYAHD0823MobilePage = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<YAHD0823MobileGroupId>
): void => {
    landingPageBuilder.setPageTitle(setLangAndGetTitle());
    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getMobilePlatformForGroupId(params.G)))
}

export const configureYAHD0823DesktopPage = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<YAHD0823DesktopGroupId>
): void => {
    landingPageBuilder.setPageTitle(setLangAndGetTitle());
    landingPageBuilder.setSteps(LandingPageConfigBuilder.getDeskStandardViewingSurveySteps(getDesktopPlatformForGroupId(params.G)))
}