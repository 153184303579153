import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { ALLW0424CollGroupId } from "respondent_config_generator/src/projects/ALLW-0424";

export type { ALLW0424CollGroupId } from "respondent_config_generator/src/projects/ALLW-0424";
export { ALLW0424CollGroupIds } from "respondent_config_generator/src/projects/ALLW-0424";

const getPlatformForGroupId = (groupId: ALLW0424CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "ALLW-372-0424-UK-LOTTO-FACEBOOK":
        case "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK":
            return "facebook";

        case "ALLW-374-0424-UK-LOTTO-INSTAGRAM":
        case "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM":
            return "instagram";

        case "ALLW-376-0424-UK-LOTTO-TIKTOK":
            return "tiktok";

        case "ALLW-377-0424-UK-LOTTO-YOUTUBE":
        case "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE":
            return "youtube";

        default:
            assertNever(groupId)
    }
}

export const configureALLW0424Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<ALLW0424CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}