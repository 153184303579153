import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateExternalSurveyTask } from "../tasks";

const FINISH_MESSAGE = "Gratulacje!\nWszystkie wymagane zadania zostały wykonane, a dane zostały przesłane. Dziękuję za Twój czas.";

export const PEPS0523CollGroupIds = [
    "PEPS-274-0523-FACEBOOK-ESTABLISHED",
    "PEPS-275-0523-FACEBOOK-NON",
    "PEPS-276-0523-INSTAGRAM-ESTABLISHED",
    "PEPS-277-0523-INSTAGRAM-NON",
    "PEPS-278-0523-YOUTUBE-ESTABLISHED",
    "PEPS-279-0523-YOUTUBE-NON",
    "PEPS-280-0523-TIKTOK-ESTABLISHED",
    "PEPS-281-0523-TIKTOK-NON"
] as const;

export type PEPS0523CollGroupId = typeof PEPS0523CollGroupIds[number];

export const PEPS0523CollWebPlatformIds = [
    "PEPS-0523-FACEBOOK-ESTABLISHED",
    "PEPS-0523-FACEBOOK-NON",
    "PEPS-0523-INSTAGRAM-ESTABLISHED",
    "PEPS-0523-INSTAGRAM-NON",
    "PEPS-0523-YOUTUBE-ESTABLISHED",
    "PEPS-0523-YOUTUBE-NON",
    "PEPS-0523-TIKTOK-ESTABLISHED",
    "PEPS-0523-TIKTOK-NON"
] as const;

export type PEPS0523CollWebPlatformId = typeof PEPS0523CollWebPlatformIds[number]

type PEPS0523CollConfig = {
    groupId: PEPS0523CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: PEPS0523CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "PEPS-0523-FACEBOOK-ESTABLISHED":
        case "PEPS-0523-FACEBOOK-NON":
        case "PEPS-0523-INSTAGRAM-ESTABLISHED":
        case "PEPS-0523-INSTAGRAM-NON":
        case "PEPS-0523-TIKTOK-ESTABLISHED":
        case "PEPS-0523-TIKTOK-NON":
        case "PEPS-0523-YOUTUBE-ESTABLISHED":
        case "PEPS-0523-YOUTUBE-NON":
            return "10";

        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: PEPS0523CollGroupId): string => {
    switch (groupId) {
        case "PEPS-274-0523-FACEBOOK-ESTABLISHED":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PEPS-0523/PEPS-0523-FACEBOOK-ESTABLISHED.json"
        case "PEPS-275-0523-FACEBOOK-NON":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PEPS-0523/PEPS-0523-FACEBOOK-NON.json"
        case "PEPS-276-0523-INSTAGRAM-ESTABLISHED":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PEPS-0523/PEPS-0523-INSTAGRAM-ESTABLISHED.json"
        case "PEPS-277-0523-INSTAGRAM-NON":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PEPS-0523/PEPS-0523-INSTAGRAM-NON.json"
        case "PEPS-278-0523-YOUTUBE-ESTABLISHED":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PEPS-0523/PEPS-0523-YOUTUBE-ESTABLISHED.json"
        case "PEPS-279-0523-YOUTUBE-NON":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PEPS-0523/PEPS-0523-YOUTUBE-NON.json"
        case "PEPS-280-0523-TIKTOK-ESTABLISHED":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PEPS-0523/PEPS-0523-TIKTOK-ESTABLISHED.json"
        case "PEPS-281-0523-TIKTOK-NON":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PEPS-0523/PEPS-0523-TIKTOK-NON.json"
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: PEPS0523CollGroupId): PEPS0523CollWebPlatformId => {
    switch (groupId) {
        case "PEPS-274-0523-FACEBOOK-ESTABLISHED": return "PEPS-0523-FACEBOOK-ESTABLISHED";
        case "PEPS-275-0523-FACEBOOK-NON": return "PEPS-0523-FACEBOOK-NON";
        case "PEPS-276-0523-INSTAGRAM-ESTABLISHED": return "PEPS-0523-INSTAGRAM-ESTABLISHED";
        case "PEPS-277-0523-INSTAGRAM-NON": return "PEPS-0523-INSTAGRAM-NON";
        case "PEPS-278-0523-YOUTUBE-ESTABLISHED": return "PEPS-0523-YOUTUBE-ESTABLISHED";
        case "PEPS-279-0523-YOUTUBE-NON": return "PEPS-0523-YOUTUBE-NON";
        case "PEPS-280-0523-TIKTOK-ESTABLISHED": return "PEPS-0523-TIKTOK-ESTABLISHED";
        case "PEPS-281-0523-TIKTOK-NON": return "PEPS-0523-TIKTOK-NON";
        default:
            assertNever(groupId);
    }
}


const getSurveyUrl = (groupId: PEPS0523CollGroupId): string => {
    switch (groupId) {
        case "PEPS-274-0523-FACEBOOK-ESTABLISHED": return "https://survey.au1.qualtrics.com/jfe/form/SV_bO87IzGe45chqdw";
        case "PEPS-275-0523-FACEBOOK-NON": return "https://survey.au1.qualtrics.com/jfe/form/SV_0D7rAhBWRyIecrY";
        case "PEPS-276-0523-INSTAGRAM-ESTABLISHED": return "https://survey.au1.qualtrics.com/jfe/form/SV_0wYPvdE8lP3kBWS";
        case "PEPS-277-0523-INSTAGRAM-NON": return "https://survey.au1.qualtrics.com/jfe/form/SV_eqAVadkGYoeI3jw";
        case "PEPS-278-0523-YOUTUBE-ESTABLISHED": return "https://survey.au1.qualtrics.com/jfe/form/SV_ezc2PASR3U9HMgK";
        case "PEPS-279-0523-YOUTUBE-NON": return "https://survey.au1.qualtrics.com/jfe/form/SV_bq0numnRU0ABzFQ";
        case "PEPS-280-0523-TIKTOK-ESTABLISHED": return "https://survey.au1.qualtrics.com/jfe/form/SV_bQ5fBt9jOAMU3qK";
        case "PEPS-281-0523-TIKTOK-NON": return "https://survey.au1.qualtrics.com/jfe/form/SV_eDoQreUCWJKpa4K";

        default:
            assertNever(groupId);
    }
}

export const addPEPS0523CollConfig = (
    config: PEPS0523CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.po)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE);

    let webplatformId = getWebPlatformId(config.groupId);
    const webTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: "General",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });
    
    builder
        .addTask(webTask)
        .addTask(surveyTask)
        .addTask(uploadsTask);

    return builder;
}