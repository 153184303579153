import React from "react";
import { Typography, Link } from "@mui/material";
import { getCurrentLangStrings } from "../../i18n";
import { getQueryStringParams } from "../../utils";
import { parseQueryParams } from "../../queryParams";
import { isMturkGroupId } from "../../Respondent/ConfigDataStores/mturk";

const PrivacyPolicyLink: React.FC = () => {
    const lang = getCurrentLangStrings();
    const params = parseQueryParams(getQueryStringParams());
    const isMturk = isMturkGroupId(params.G);

    return (
        <Typography gutterBottom textAlign="center" marginTop="15px">
            <Link
                onClick={() => {
                    window.open(
                        isMturk ? "https://www.amplifiedintelligence.com.au/privacy-policy/"
                        : "https://blinqrewards.com/policies/privacy-policy"
                    );
                }}
            >
                {lang.StepsFooter.privacyPolicy}
            </Link>
        </Typography>
    )
};

export default PrivacyPolicyLink;
