import EnLangStrings from "internationalisation/en";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import {
    generateBvodTask,
    generateExternalSurveyTask,
    generateUploadsTask,
} from "../tasks";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const RCTV0523CollGroupIds = [
    'RCTV-297-0523-BVODMOBILE'
] as const

export type RCTV0523CollGroupId = typeof RCTV0523CollGroupIds[number]

type RCTV0523Config = {
    groupId: RCTV0523CollGroupId,
    metaFieldNames: ReadonlyArray<string>
}

// RCTV-0523 BVOD mobile groups config.
export const addRCTV0523CollConfig = (
    config: RCTV0523Config,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('minimumIOSVersion', '15.0')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .setFlag('preventTaskProgress', false)
        
    const meta = config as RCTV0523Config;

    const bvodTvTask = generateBvodTask({
        taskID: meta.groupId + '-BVOD',
        channelsJsonUrl: 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/RCTV-0523-MOBILE.json',
        isTv: false,
        langStrings: EnLangStrings,
        isRadio: false
    });

    builder.addTask(bvodTvTask)

    const externalSurveyTask = generateExternalSurveyTask({
        title: '',
        description: '',
        surveyUrl: 'https://survey.au1.qualtrics.com/jfe/form/SV_bHQG1BYII3GfuFE',
        taskID: meta.groupId + '-SURVEY',
        extraMetaFields: [...meta.metaFieldNames, "bvodTV_selectedChannel"]
    });

    builder.addTask(externalSurveyTask);

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: meta.groupId + '-UPLOADS',
    });

    builder.addTask(uploadsTask);

    return builder;
}
