import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";

export type { SKYN0823CollGroupId } from "respondent_config_generator/src/projects/SKYN-0823";
export { SKYN0823CollGroupIds } from "respondent_config_generator/src/projects/SKYN-0823";

export const configureSKYN0823Page = (
    landingPageBuilder: LandingPageConfigBuilder
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps('bvod'))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}