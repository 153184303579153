import { Grid, Typography, styled } from "@mui/material";
import React from "react";
import { getCurrentLangStrings } from "../../../i18n";
import installationDemo from "../static/installationDemo.gif";

const Gif = styled("img")`
    max-width: 400px;
`

export enum CompletionCodeState {
    waitingForInput = "waitingForInput",
    invalid = "invalid",
    valid = "valid",
}

export type PlatformInstructionsDeskSolutionKey =
    | "twitch-desktop"
    | "yahoo-desktop"

interface Props {
    loginId: string;
    platform?: PlatformInstructionsDeskSolutionKey;
    completionCode?: string;
    completionCodeStateChanged?: (
        state: CompletionCodeState,
        input: string
    ) => void;
}

const getInstructionsForPlatform = (
    platform: PlatformInstructionsDeskSolutionKey
): string => {
    switch (platform) {
        case "twitch-desktop":
        case "yahoo-desktop":
            return `Note: You are unable to recover your login code once you close this browser. If you are switching devices please ensure you write it down.`;
        
        default:
            assertNever(platform);
    }
};

const LoginAndCompletionDeskSolution: React.FC<Props> = (props: Props) => {
    const { loginId, platform } =
        props;

    const lang = getCurrentLangStrings();

    return (
        <div>
            <Typography align="center" gutterBottom>
                {lang.Steps.LoginAndCompletionDeskSolution.subtitle}
            </Typography>
            <Typography align="center" variant="h2" gutterBottom>
                {loginId}
            </Typography>
            <Grid container justifyContent="center" alignItems="center" sx={{paddingBottom: 4}}>
                <Gif src={installationDemo} alt="Chrome Extension Login Page for attentionTRACE Research" />
            </Grid>
            {platform && (
                <Typography align="center" whiteSpace="pre-line" style={{display: 'inline'}}>
                    {getInstructionsForPlatform(platform)}
                </Typography>
            )}
        </div>
    );
};

export default LoginAndCompletionDeskSolution;