import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { REDC0122CollGroupId } from "respondent_config_generator/src/projects/REDC-0122";

export { REDC0122CollGroupIds } from "respondent_config_generator/src/projects/REDC-0122";
export type { REDC0122CollGroupId } from "respondent_config_generator/src/projects/REDC-0122";

const getPlatformForGroupId = (groupId: REDC0122CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "REDC-64-0122-IRL-TWITTER-INFEEDVIDEO":
        case "REDC-65-0122-IRL-TWITTER-INFEEDSTATIC":
            return "twitter";
        
        case "REDC-66-0122-IRL-INSTAGRAM-INFEED":
            return "instagram-stories";

        case "REDC-67-0122-IRL-INSTAGRAM-STORIES":
            return "instagram-newsfeed";
        
        case "REDC-68-0122-IRL-YOUTUBE-PREROLL":
        case "REDC-69-0122-IRL-YOUTUBE-EXTRAS":
            return "youtube";
        
        case "REDC-70-0122-IRL-FACEBOOK-INFEED":
            return "facebook-newsfeed";

        case "REDC-71-0122-IRL-FACEBOOK-STORIES":
            return "facebook-stories";

        default:
            assertNever(groupId)
    }
}

export const configureREDC0122Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<REDC0122CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}