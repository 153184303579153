import React from 'react'
import { Grid, Typography } from '@mui/material'

import { getCurrentLangStrings } from '../../../i18n'
import { useStyles } from '../styles'
import { PlatformInstructionsKey, getInstructionsForPlatform } from './LoginAndCompletionCode'

interface Props {
    platform: PlatformInstructionsKey;
}

const StartEarning: React.FC<Props> = (props) => {
    const { platform } = props;
    const { classes } = useStyles();
    const lang = getCurrentLangStrings()

    return (
        <Grid container className={classes.grid}>
            <Typography>
                {lang.Steps.StartEarning}
            </Typography>
            <br />
            {platform && (
                <Typography whiteSpace="pre-line" marginBottom="20px">
                    {getInstructionsForPlatform(platform)}
                </Typography>
            )}
        </Grid>
    )
}

export default StartEarning