import React from "react";
import { Typography, Card, CardMedia, Grid } from "@mui/material";
import desktopPreview from "../static/desktopPreview.png";

interface Props {
    previewMode: boolean;
}

const MturkGazeDesktopInstructions: React.FC<Props> = ({ previewMode }) => {
    let footer: JSX.Element;
    if (previewMode) {
        footer = (
            <div>
                <Typography variant="body1" gutterBottom>
                    Once you accept this task, you will be presented with a
                    short survey about your age & MacBook model before being
                    redirected back to these instructions.
                </Typography>
                <Grid container justifyContent="center" alignItems="center">
                    <Card style={{ maxWidth: "100%" }}>
                        <CardMedia
                            component="img"
                            alt="Desktop Preview for attentionTRACE Research"
                            image={desktopPreview}
                        />
                    </Card>
                </Grid>
            </div>
        );
    } else {
        footer = (
            <Grid container justifyContent="center" alignItems="center">
                <Card style={{ maxWidth: "100%" }}>
                    <CardMedia
                        component="img"
                        alt="Desktop Preview for attentionTRACE Research"
                        image={desktopPreview}
                    />
                </Card>
            </Grid>
        );
    }

    return (
        <div>
            <Typography variant="body1" gutterBottom>
                The purpose of this HIT is to collect some training data for an
                algorithm involving attention measurement on Apple MacBooks.
            </Typography>

            <Typography variant="body1" gutterBottom>
                Every few seconds, a small object will appear at a random
                position on your screen. Your task is to simply focus your eyes
                on this object until it disappears.
            </Typography>

            <Typography variant="body1" gutterBottom>
                This task only takes 60 seconds to complete.
            </Typography>

            {footer}
        </div>
    );
};

export default MturkGazeDesktopInstructions;
