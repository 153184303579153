import { AppLanguage, getLanguageStrings } from "internationalisation";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateExternalSurveyTask } from "../tasks";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const PINT1123CollGroupIds = [
    "PINT-326-1123-US-GROUP1",
    "PINT-327-1123-US-GROUP2",
    "PINT-328-1123-US-GROUP3",
    "PINT-329-1123-US-GROUP4",
    "PINT-330-1123-UK-GROUP1",
    "PINT-331-1123-UK-GROUP2",
    "PINT-332-1123-UK-GROUP3",
    "PINT-333-1123-UK-GROUP4",
    "PINT-334-1123-AU-GROUP1",
    "PINT-335-1123-AU-GROUP2",
    "PINT-336-1123-AU-GROUP3",
    "PINT-337-1123-AU-GROUP4",
    "PINT-338-1123-FR-GROUP1",
    "PINT-339-1123-FR-GROUP2",
    "PINT-340-1123-FR-GROUP3",
    "PINT-341-1123-FR-GROUP4",
    "PINT-342-1123-DE-GROUP1",
    "PINT-343-1123-DE-GROUP2",
    "PINT-344-1123-DE-GROUP3",
    "PINT-345-1123-DE-GROUP4",
    "PINT-346-1123-CA-GROUP1",
    "PINT-347-1123-CA-GROUP2",
    "PINT-348-1123-CA-GROUP3",
    "PINT-349-1123-CA-GROUP4",
] as const;

export type PINT1123CollGroupId = typeof PINT1123CollGroupIds[number];

export const PINT1123CollWebPlatformIds = [
    "PINT-1123-US-GROUP1-homepage",
    "PINT-1123-US-GROUP1-vertical1",
    "PINT-1123-US-GROUP1-vertical2",
    "PINT-1123-US-GROUP2-homepage",
    "PINT-1123-US-GROUP2-vertical1",
    "PINT-1123-US-GROUP2-vertical2",
    "PINT-1123-US-GROUP3-homepage",
    "PINT-1123-US-GROUP3-vertical1",
    "PINT-1123-US-GROUP3-vertical2",
    "PINT-1123-US-GROUP4-homepage",
    "PINT-1123-US-GROUP4-vertical1",
    "PINT-1123-US-GROUP4-vertical2",
    "PINT-1123-UK-GROUP1-homepage",
    "PINT-1123-UK-GROUP1-vertical1",
    "PINT-1123-UK-GROUP1-vertical2",
    "PINT-1123-UK-GROUP2-homepage",
    "PINT-1123-UK-GROUP2-vertical1",
    "PINT-1123-UK-GROUP2-vertical2",
    "PINT-1123-UK-GROUP3-homepage",
    "PINT-1123-UK-GROUP3-vertical1",
    "PINT-1123-UK-GROUP3-vertical2",
    "PINT-1123-UK-GROUP4-homepage",
    "PINT-1123-UK-GROUP4-vertical1",
    "PINT-1123-UK-GROUP4-vertical2",
    "PINT-1123-AU-GROUP1-homepage",
    "PINT-1123-AU-GROUP1-vertical1",
    "PINT-1123-AU-GROUP1-vertical2",
    "PINT-1123-AU-GROUP2-homepage",
    "PINT-1123-AU-GROUP2-vertical1",
    "PINT-1123-AU-GROUP2-vertical2",
    "PINT-1123-AU-GROUP3-homepage",
    "PINT-1123-AU-GROUP3-vertical1",
    "PINT-1123-AU-GROUP3-vertical2",
    "PINT-1123-AU-GROUP4-homepage",
    "PINT-1123-AU-GROUP4-vertical1",
    "PINT-1123-AU-GROUP4-vertical2",
    "PINT-1123-FR-GROUP1-homepage",
    "PINT-1123-FR-GROUP1-vertical1",
    "PINT-1123-FR-GROUP1-vertical2",
    "PINT-1123-FR-GROUP2-homepage",
    "PINT-1123-FR-GROUP2-vertical1",
    "PINT-1123-FR-GROUP2-vertical2",
    "PINT-1123-FR-GROUP3-homepage",
    "PINT-1123-FR-GROUP3-vertical1",
    "PINT-1123-FR-GROUP3-vertical2",
    "PINT-1123-FR-GROUP4-homepage",
    "PINT-1123-FR-GROUP4-vertical1",
    "PINT-1123-FR-GROUP4-vertical2",
    "PINT-1123-DE-GROUP1-homepage",
    "PINT-1123-DE-GROUP1-vertical1",
    "PINT-1123-DE-GROUP1-vertical2",
    "PINT-1123-DE-GROUP2-homepage",
    "PINT-1123-DE-GROUP2-vertical1",
    "PINT-1123-DE-GROUP2-vertical2",
    "PINT-1123-DE-GROUP3-homepage",
    "PINT-1123-DE-GROUP3-vertical1",
    "PINT-1123-DE-GROUP3-vertical2",
    "PINT-1123-DE-GROUP4-homepage",
    "PINT-1123-DE-GROUP4-vertical1",
    "PINT-1123-DE-GROUP4-vertical2",
    "PINT-1123-CA-GROUP1-homepage",
    "PINT-1123-CA-GROUP1-vertical1",
    "PINT-1123-CA-GROUP1-vertical2",
    "PINT-1123-CA-GROUP2-homepage",
    "PINT-1123-CA-GROUP2-vertical1",
    "PINT-1123-CA-GROUP2-vertical2",
    "PINT-1123-CA-GROUP3-homepage",
    "PINT-1123-CA-GROUP3-vertical1",
    "PINT-1123-CA-GROUP3-vertical2",
    "PINT-1123-CA-GROUP4-homepage",
    "PINT-1123-CA-GROUP4-vertical1",
    "PINT-1123-CA-GROUP4-vertical2",
] as const;

export type PINT1123CollWebPlatformId = typeof PINT1123CollWebPlatformIds[number]

type PINT1123CollConfig = {
    groupId: PINT1123CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: PINT1123CollWebPlatformId): string => {
    if (webPlatformId.endsWith('homepage')) {
        return "3";
    }
    else {
        return "2";
    }
}

const getWebPlatformConfigUrl = (groupId: PINT1123CollGroupId, order: 0 | 1 | 2): string => {
    const webPLatfromID  = groupId.substring(0, 4) + groupId.substring(8);

    const configUrl = 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/PINT-1123/' + webPLatfromID;
    switch (order) {
        case 0:
            return configUrl + '-homepage.json';
        case 1:
            return configUrl + '-vertical1.json';
        case 2:
            return configUrl + '-vertical2.json';
    }
}

const getWebPlatformId = (groupId: PINT1123CollGroupId, order: 0 | 1 | 2): string => {
    const webPLatfromID  = groupId.substring(0, 4) + groupId.substring(8);

    switch (order) {
        case 0:
            return webPLatfromID + '-homepage';
        case 1:
            return webPLatfromID + '-vertical1';
        case 2:
            return webPLatfromID + '-vertical2';
    }
}

const getSurveyUrl = (groupId: PINT1123CollGroupId): string => {
    if (groupId.includes("US"))
        return "https://survey.au1.qualtrics.com/jfe/form/SV_0jsod4UPLARHuBM";
    else if (groupId.includes("UK"))
        return "https://survey.au1.qualtrics.com/jfe/form/SV_1BVlcypPO8xUZkW";
    else if (groupId.includes("AU"))
        return "https://survey.au1.qualtrics.com/jfe/form/SV_dnHEbwPHQbO45Se";
    else if (groupId.includes("FR"))
        return "https://survey.au1.qualtrics.com/jfe/form/SV_0678E7UfAk1NDOm";
    else if (groupId.includes("DE"))
        return "https://survey.au1.qualtrics.com/jfe/form/SV_51GH8M0BhGreTYO";
    else
        return "https://survey.au1.qualtrics.com/jfe/form/SV_9yFt0jpuJxRqBSe";
}

export const addPINT1123CollConfig = (
    config: PINT1123CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE);

    let webplatformId = getWebPlatformId(config.groupId, 0) as PINT1123CollWebPlatformId;
    const webTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: "Pinterest",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
        useWebPlatformIDForTaskID: true
    });

    let webplatformId1 = getWebPlatformId(config.groupId, 1) as PINT1123CollWebPlatformId;
    const webTask1 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId1,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 1),
        platform: "Pinterest",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId1),
        langStrings: langStrings,
        useWebPlatformIDForTaskID: true
    });

    let webplatformId2 = getWebPlatformId(config.groupId, 2) as PINT1123CollWebPlatformId;
    const webTask2 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId2,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 2),
        platform: "Pinterest",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId2),
        langStrings: langStrings,
        useWebPlatformIDForTaskID: true
    });

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });
    
    builder
        .addTask(webTask)
        .addTask(webTask1)
        .addTask(webTask2)
        .addTask(surveyTask)
        .addTask(uploadsTask);

    return builder;
}