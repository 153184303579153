import React, { createRef } from 'react';

enum QRCodeCorrectLevel {
    Low = 1,
    Medium = 0,
    Quartile = 3,
    High = 2
}

interface QRCodeOptions {
    text?: string,
    width?: number,
    height?: number,
    colorDark?: string,
    colorLight?: string,
    correctionLevel?: QRCodeCorrectLevel,
}

interface IQRCode {
    new(el: HTMLElement | String | null, options?: String | QRCodeOptions): IQRCode;
    makeCode(text: string): void;
    makeImage(): void;
    clear(): void;
}

declare const QRCode: IQRCode;

interface Props {
    loginId: string,
    size: number
}

interface State {
    qrCode: IQRCode
}

export default class QRCodeComponent extends React.Component<Props, State> {
    qrCodeRef: React.RefObject<HTMLDivElement> = createRef();

    componentDidMount() {
        this.setState({
            qrCode: new QRCode(this.qrCodeRef.current, {
                text: `https://apps.apple.com/app/id1227617967`,
                width: this.props.size,
                height: this.props.size,
                correctionLevel: QRCodeCorrectLevel.High
            })
        });
    }

    render() {
        return (
            <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '30px' }}>
                <div ref={this.qrCodeRef}></div>
            </div>
        );
    }
}
