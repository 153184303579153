import { AppLanguage, getLanguageStrings } from "internationalisation";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateWebPlatformTask } from "../tasks";
import { WebPlatform } from "../types";

export type AustCollGroupId =
    | 'AUST-18-NEWSFEED'
    | 'AUST-19-WATCH'
    | 'AUST-20-STORIES'
    | 'AUST-21-NEWSFEED'
    | 'AUST-22-STORIES'
    | 'AUST-23'
    | 'AUST-24'

type AustCollConfig = {
    groupId: AustCollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

/**
 * Get the length of the webPlatform Task for a given groupId in minutes
 */
function getTaskLength(groupId: AustCollGroupId): string {
    switch (groupId) {
        case 'AUST-18-NEWSFEED':
        case 'AUST-20-STORIES':
        case 'AUST-21-NEWSFEED':
        case 'AUST-22-STORIES':
        case 'AUST-24': {
            return "5"
        }

        case 'AUST-19-WATCH':
        case 'AUST-23': {
            return "15"
        }
    }
}

export const addAustCollConfig = (
    config: AustCollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder.setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3);

    let platform: WebPlatform
    let surveyUrl: string

    switch (config.groupId) {
        case 'AUST-18-NEWSFEED':
        case 'AUST-19-WATCH':
        case 'AUST-20-STORIES': {
            platform = 'Facebook'
            surveyUrl = "https://survey.au1.qualtrics.com/jfe/form/SV_a5XvxHcrV0yQ2UK"
            break
        }

        case 'AUST-21-NEWSFEED':
        case 'AUST-22-STORIES': {
            platform = 'Instagram'
            surveyUrl = "https://survey.au1.qualtrics.com/jfe/form/SV_a5XvxHcrV0yQ2UK"
            break
        }

        case 'AUST-23': {
            platform = "Youtube"
            surveyUrl = "https://survey.au1.qualtrics.com/jfe/form/SV_a5XvxHcrV0yQ2UK"
            break
        }

        case 'AUST-24': {
            platform = "Twitter"
            surveyUrl = "https://survey.au1.qualtrics.com/jfe/form/SV_00NXkuCAnlClHaC"
            break
        }

    }

    const webTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: config.groupId,
        webPlatformConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/${config.groupId}.json`,
        platform,
        taskLengthMinutes: getTaskLength(config.groupId),
        langStrings,
    })

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl,
    })

    builder
        .addTask(webTask)
        .addTask(surveyTask)

    return builder
}