import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { SPOT0123CollGroupId } from "respondent_config_generator/src/projects/SPOT-0123";

export type { SPOT0123CollGroupId } from "respondent_config_generator/src/projects/SPOT-0123";
export { SPOT0123CollGroupIds } from "respondent_config_generator/src/projects/SPOT-0123";

const getPlatformForGroupId = (groupId: SPOT0123CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "SPOT-264-0123-AU-MUSIC":
        case "SPOT-265-0123-AU-PODCAST":
        case "SPOT-266-0123-NZ-MUSIC":
        case "SPOT-267-0123-NZ-PODCAST":
            return "spotify";

        default:
            assertNever(groupId)
    }
}

export const configureSPOT0123Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<SPOT0123CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}