import React from 'react'
import { ClipLoader } from 'react-spinners'
import { Grid, Typography } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import { makeStyles } from 'tss-react/mui'

import { getCurrentLangStrings } from '../../i18n'

const useStyles = makeStyles()(theme => ({
    spinner: {
        margin: theme.spacing(3, 0, 5)
    }
}))

const PageLoading: React.FC = () => {
    const { classes } = useStyles()
    const theme = useTheme()
    const lang = getCurrentLangStrings()

    return (
        <Grid container flexDirection="column" justifyContent="center" alignItems="center" alignContent="center">
            <Grid item>
                <div className={classes.spinner}>
                    <ClipLoader size={60} color={theme.palette.primary.main}/>
                </div>
            </Grid>
            <Grid item>
                <Typography component="p">{ lang.Common.loading }...</Typography>
            </Grid>
        </Grid>
    )
}

export default PageLoading
