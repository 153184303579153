export class RequestError extends Error {
    constructor(message?: string) {
        super(message)
        this.name = "RequestError"
        Object.setPrototypeOf(this, Error.prototype)
    }
}

export class ConfigurationError extends Error {
    constructor(message?: string) {
        super(message)
        this.name = "ConfigurationError"
        Object.setPrototypeOf(this, Error.prototype)
    }
}
