import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { ConfigKey } from "../../configKey";

export const AUST_GROUP_IDS = [
    'AUST-18-NEWSFEED',
    'AUST-19-WATCH',
    'AUST-20-STORIES',
    'AUST-21-NEWSFEED',
    'AUST-22-STORIES',
    'AUST-23',
    'AUST-24',
] as const

export type AUSTGroupId = (typeof AUST_GROUP_IDS)[number]

export function isAUSTGroupParams(params: CAIParams<ConfigKey>): params is CAIParams<AUSTGroupId> {
    return (AUST_GROUP_IDS as ReadonlyArray<string>).includes(params.G)
}

const getPlatformInstructionsKeyForGroupId = (groupId: AUSTGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case 'AUST-18-NEWSFEED': return "facebook-newsfeed"
        case 'AUST-19-WATCH': return "facebook-watch"
        case 'AUST-20-STORIES': return "facebook-stories"

        case 'AUST-21-NEWSFEED': return "instagram-newsfeed"
        case 'AUST-22-STORIES': return "instagram-stories"

        case 'AUST-23': return "youtube"
        case 'AUST-24': return "twitter"
    }
}

export const configureAUSTPage = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<AUSTGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformInstructionsKeyForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}