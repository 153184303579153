import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateWebPlatformTask, generateUploadsTask } from "../tasks";
import { WebPlatform } from "../types";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your panel account has been incentivised. Thank you for your time."

export const SNAP0522CollGroupIds = [
    'SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP',
    'SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV',
    'SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP',
    'SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV',
    'SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP',
    'SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV',
    'SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP',
    'SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV',
    'SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP',
    'SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV',
    'SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP',
    'SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV',
    'SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP',
    'SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV',
    'SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP',
    'SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV',
    'SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP',
    'SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV',
    'SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP',
    'SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV',
    'SNAP-208-0522-UK-SNAPCHAT-RECOLLECT',
    'SNAP-209-0522-CA-SNAPCHAT-RECOLLECT',
    'SNAP-210-0522-US-SNAPCHAT-RECOLLECT',
    'SNAP-211-0522-AU-SNAPCHAT-RECOLLECT',
    'SNAP-212-0522-DE-SNAPCHAT-RECOLLECT'
] as const

export type SNAP0522CollGroupId = typeof SNAP0522CollGroupIds[number]

type Snap0522CollConfig = {
    groupId: SNAP0522CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const SNAP0522CollRelaunchGroupIds = [
    'SNAP-208-0522-UK-SNAPCHAT-RECOLLECT',
    'SNAP-209-0522-CA-SNAPCHAT-RECOLLECT',
    'SNAP-210-0522-US-SNAPCHAT-RECOLLECT',
    'SNAP-211-0522-AU-SNAPCHAT-RECOLLECT',
    'SNAP-212-0522-DE-SNAPCHAT-RECOLLECT',
]

const getWebPlatformConfigUrl = (groupId: SNAP0522CollGroupId): string => {
    switch (groupId) {
        case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-UK-brandpurpose-snap.json";
        case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-UK-brandpurpose-TV.json";
        case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-UK-nonbrand-snap.json";
        case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-UK-nonbrand-TV.json";
        case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-CA-brandpurpose-snap.json";
        case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-CA-brandpurpose-TV.json";
        case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-CA-nonbrand-snap.json";
        case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-CA-nonbrand-TV.json";
        case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-US-brandpurpose-snap.json";
        case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-US-brandpurpose-TV.json";
        case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-US-nonbrand-snap.json";
        case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-US-nonbrand-TV.json";
        case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-AU-brandpurpose-snap.json";
        case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-AU-brandpurpose-TV.json";
        case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-AU-nonbrand-snap.json";
        case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-AU-nonbrand-TV.json";
        case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-DE-brandpurpose-snap.json";
        case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-DE-brandpurpose-TV.json";
        case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-DE-nonbrand-snap.json";
        case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-DE-nonbrand-TV.json";
        case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-UK-recollect.json";
        case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-CA-recollect.json";
        case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-US-recollect.json";
        case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-AU-recollect.json";
        case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNAP-0522/SNAP-0522-DE-recollect.json";
        
        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: SNAP0522CollGroupId): WebPlatform => {
    switch (groupId) {
        case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV":
        case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV":
        case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV":
        case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV":
        case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV":
        case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT":
        case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT":
        case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT":
        case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT":
        case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT": {
            return "Snapchat";
        }

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: SNAP0522CollGroupId): string => {
    switch (groupId) {
        case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_9Y4ONQrrywABCTA";
        case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_eEXDI2azn2atitw";
        case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_eOKxkk5KTydItw2";
        case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_eJywu9qKGUR53a6";
        case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_6KnxAWrkPha07Ai";
        case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_8AM5xCDHhQkTUDc";
        case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_9t4FtSnWm3iliSO";
        case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_3a4DJiy0nXLkcu2";
        case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_9RGoKDhxRhEyc3I";
        case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_9M6rhZB65DwZApg";
        case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_cZOanK3JT3CsKxg";
        case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_2bJy42jcZMPBiPY";
        case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_0jPxfCMOcxOHUzA";
        case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_9yt2gDScL3kXncq";
        case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_2spaxp9x7UkDjYa";
        case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_5jvkku4bvJPBwgu";
        case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_9ZEGyQMw3OcRnSu";
        case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_6mrWyv4XIc7jbts";
        case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP": return "https://survey.au1.qualtrics.com/jfe/form/SV_1Lfp3FKobRdWwTQ";
        case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV": return "https://survey.au1.qualtrics.com/jfe/form/SV_8x0wi1cTZ732fsy";
        case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT": return "https://survey.au1.qualtrics.com/jfe/form/SV_02qNWPzb3GhflhI";
        case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT": return "https://survey.au1.qualtrics.com/jfe/form/SV_8AJwDsdUxQGId4a";
        case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT": return "https://survey.au1.qualtrics.com/jfe/form/SV_b1ns0veN18BzUXk";
        case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT": return "https://survey.au1.qualtrics.com/jfe/form/SV_1Y6Rob8UUQCVvUi";
        case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT": return "https://survey.au1.qualtrics.com/jfe/form/SV_9uwuOc0JeVq5FWK";
        
        default:
            assertNever(groupId);
    }
}

const getWebPlatformID = (groupId: SNAP0522CollGroupId): string => {
    switch (groupId) {
        case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP": return "SNAP-0522-UK-brandpurpose-snap";
        case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV": return "SNAP-0522-UK-brandpurpose-TV";
        case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP": return "SNAP-0522-UK-nonbrand-snap";
        case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV": return "SNAP-0522-UK-nonbrand-TV";
        case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP": return "SNAP-0522-CA-brandpurpose-snap";
        case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV": return "SNAP-0522-CA-brandpurpose-TV";
        case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP": return "SNAP-0522-CA-nonbrand-snap";
        case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV": return "SNAP-0522-CA-nonbrand-TV";
        case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP": return "SNAP-0522-US-brandpurpose-snap";
        case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV": return "SNAP-0522-US-brandpurpose-TV";
        case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP": return "SNAP-0522-US-nonbrand-snap";
        case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV": return "SNAP-0522-US-nonbrand-TV";
        case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP": return "SNAP-0522-AU-brandpurpose-snap";
        case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV": return "SNAP-0522-AU-brandpurpose-TV";
        case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP": return "SNAP-0522-AU-nonbrand-snap";
        case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV": return "SNAP-0522-AU-nonbrand-TV";
        case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP": return "SNAP-0522-DE-brandpurpose-snap";
        case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV": return "SNAP-0522-DE-brandpurpose-TV";
        case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP": return "SNAP-0522-DE-nonbrand-snap";
        case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV": return "SNAP-0522-DE-nonbrand-TV";
        case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT": return "SNAP-0522-UK-recollect";
        case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT": return "SNAP-0522-CA-recollect";
        case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT": return "SNAP-0522-US-recollect";
        case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT": return "SNAP-0522-AU-recollect";
        case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT": return "SNAP-0522-DE-recollect";
        
        default:
            assertNever(groupId);
    }
}

export const addSnap0522CollConfig = (
    config: Snap0522CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)

    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformID(config.groupId),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: getPlatformForGroup(config.groupId),
        taskLengthMinutes: "",
        langStrings: langStrings,
    });
    builder.addTask(webPlatformTask);

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });
    builder.addTask(surveyTask);

    if (SNAP0522CollRelaunchGroupIds.includes(config.groupId)) {
        const uploadsTask = generateUploadsTask({
            config: {
                "title": "Uploads"
            },
            taskId: config.groupId + '-UPLOADS',
        });
    
        builder.addTask(uploadsTask);
    }

    return builder;
}