import React from 'react'
import { Typography } from '@mui/material'

const PageRedirecting: React.FC = () => {
    return (
        <Typography align="center" gutterBottom>Hang tight, You will be redirected shortly</Typography>
    )
}

export default PageRedirecting
