import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { ACAY0822CollGroupId } from "respondent_config_generator/src/projects/ACAY-0822";

export { ACAY0822CollGroupIds } from "respondent_config_generator/src/projects/ACAY-0822";
export type { ACAY0822CollGroupId } from "respondent_config_generator/src/projects/ACAY-0822";

const getPlatformForGroupId = (groupId: ACAY0822CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "ACAY-151-0822-AUS-YOUTUBE":
            return "youtube";

        default:
            assertNever(groupId)
    }
}

export const configureACAY0822Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<ACAY0822CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}