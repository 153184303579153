import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateSnapARTask, generateUploadsTask } from "../tasks";

const SNAR0823SpecialGroupId = "SNAR-295-0823-VALIDATION";
export const SNAR0823GeneralGroupId = [
    "SNAR-316-0823-UK-SCALED-1",
    "SNAR-317-0823-UK-SCALED-2",
    "SNAR-318-0823-US-SCALED-1",
    "SNAR-319-0823-US-SCALED-2",
    "SNAR-355-0823-FR-SCALED-1",
    "SNAR-356-0823-FR-SCALED-2",
    "SNAR-357-0823-SA-SCALED-1",
    "SNAR-358-0823-SA-SCALED-2",
    "SNAR-359-0823-CA-SCALED-1",
    "SNAR-360-0823-CA-SCALED-2",
    "SNAR-361-0823-CA-SCALED-3",
    "SNAR-362-0823-AU-SCALED-1",
    "SNAR-363-0823-AU-SCALED-2",
] as const;

export const SNAR0823CollGroupIds = [
    SNAR0823SpecialGroupId,
    ...SNAR0823GeneralGroupId
] as const;

export type SNAR0823CollGroupId = typeof SNAR0823CollGroupIds[number];

export const SNAR0823CollWebPlatformIds = [
    "SNAR-0823-VALIDATION",
    "SNAR-0823-UK-SCALED-1",
    "SNAR-0823-UK-SCALED-2",
    "SNAR-0823-US-SCALED-1",
    "SNAR-0823-US-SCALED-2",
    "SNAR-0823-FR-SCALED-1",
    "SNAR-0823-FR-SCALED-2",
    "SNAR-0823-SA-SCALED-1",
    "SNAR-0823-SA-SCALED-2",
    "SNAR-0823-CA-SCALED-1",
    "SNAR-0823-CA-SCALED-2",
    "SNAR-0823-CA-SCALED-3",
    "SNAR-0823-AU-SCALED-1",
    "SNAR-0823-AU-SCALED-2",
] as const;

export type SNAR0823CollWebPlatformId = typeof SNAR0823CollWebPlatformIds[number]

type SNAR0823CollConfig = {
    groupId: SNAR0823CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: SNAR0823CollWebPlatformId): number => {
    switch (webPlatformId) {
        case "SNAR-0823-VALIDATION":
        case "SNAR-0823-UK-SCALED-1":
        case "SNAR-0823-UK-SCALED-2":
        case "SNAR-0823-US-SCALED-1":
        case "SNAR-0823-US-SCALED-2":
        case "SNAR-0823-FR-SCALED-1":
        case "SNAR-0823-FR-SCALED-2":
        case "SNAR-0823-AU-SCALED-1":
        case "SNAR-0823-AU-SCALED-2":
            return 300
        case "SNAR-0823-CA-SCALED-1":
        case "SNAR-0823-CA-SCALED-2":
        case "SNAR-0823-CA-SCALED-3":
            return 180
        case "SNAR-0823-SA-SCALED-1":
        case "SNAR-0823-SA-SCALED-2":
            return 420
        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformId = (groupId: SNAR0823CollGroupId): SNAR0823CollWebPlatformId => {
    switch (groupId) {
        case "SNAR-295-0823-VALIDATION":
            return "SNAR-0823-VALIDATION";
        case "SNAR-316-0823-UK-SCALED-1":
            return "SNAR-0823-UK-SCALED-1";
        case "SNAR-317-0823-UK-SCALED-2":
            return "SNAR-0823-UK-SCALED-2";
        case "SNAR-318-0823-US-SCALED-1":
            return "SNAR-0823-US-SCALED-1";
        case "SNAR-319-0823-US-SCALED-2":
            return "SNAR-0823-US-SCALED-2";
        case "SNAR-355-0823-FR-SCALED-1":
            return "SNAR-0823-FR-SCALED-1"
        case "SNAR-356-0823-FR-SCALED-2":
            return "SNAR-0823-FR-SCALED-2"
        case "SNAR-357-0823-SA-SCALED-1":
            return "SNAR-0823-SA-SCALED-1"
        case "SNAR-358-0823-SA-SCALED-2":
            return "SNAR-0823-SA-SCALED-2"
        case "SNAR-359-0823-CA-SCALED-1":
            return "SNAR-0823-CA-SCALED-1"
        case "SNAR-360-0823-CA-SCALED-2":
            return "SNAR-0823-CA-SCALED-2"
        case "SNAR-361-0823-CA-SCALED-3":
            return "SNAR-0823-CA-SCALED-3"
        case "SNAR-362-0823-AU-SCALED-1":
            return "SNAR-0823-AU-SCALED-1"
        case "SNAR-363-0823-AU-SCALED-2":
            return "SNAR-0823-AU-SCALED-2"
        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: SNAR0823CollGroupId): string => {
    switch (groupId) {
        case "SNAR-295-0823-VALIDATION":
            return 'https://survey.au1.qualtrics.com/jfe/form/SV_5u6DY6341lKCUpo';
        case "SNAR-316-0823-UK-SCALED-1":
        case "SNAR-317-0823-UK-SCALED-2":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_aVuNhOAE353HZTo";
        case "SNAR-318-0823-US-SCALED-1":
        case "SNAR-319-0823-US-SCALED-2":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_6VcEz8okyE30HFc";
        case "SNAR-355-0823-FR-SCALED-1":
        case "SNAR-356-0823-FR-SCALED-2":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_cC20gYsDIKZHX0O";
        case "SNAR-357-0823-SA-SCALED-1":
        case "SNAR-358-0823-SA-SCALED-2":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_6G4mj9nO46jUZMi";
        case "SNAR-359-0823-CA-SCALED-1":
        case "SNAR-360-0823-CA-SCALED-2":
        case "SNAR-361-0823-CA-SCALED-3":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_73A8ZgI7iNWnW6O";
        case "SNAR-362-0823-AU-SCALED-1":
        case "SNAR-363-0823-AU-SCALED-2":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_6l4i63pHbO0b1no";
        default:
            assertNever(groupId);
    }
}

const getLensGroupId = (groupId: SNAR0823CollGroupId): string => {
    switch (groupId) {
        case "SNAR-295-0823-VALIDATION":
            return "be8aab2c-d19f-4ae0-ac29-564005d8b11c";
        case "SNAR-316-0823-UK-SCALED-1":
            return "3cf6b203-5393-48cf-8ca4-e97bf72e2e4a";
        case "SNAR-317-0823-UK-SCALED-2":
            return "60ed9fbb-b610-47ad-9417-edb47c52f78b";
        case "SNAR-318-0823-US-SCALED-1":
            return "264aa290-be55-4bcd-a7f6-76ddf8e8617a";
        case "SNAR-319-0823-US-SCALED-2":
            return "2be181d3-1868-4259-913d-5d1596174da9";
        case "SNAR-355-0823-FR-SCALED-1":
            return "0f97adcb-2ed3-474f-b9e6-338c9f130de1";
        case "SNAR-356-0823-FR-SCALED-2":
            return "af822629-aede-43e5-ba06-ae9d686aba7a";
        case "SNAR-357-0823-SA-SCALED-1":
            return "a380029c-ac09-4291-84d5-5667c92f441f";
        case "SNAR-358-0823-SA-SCALED-2":
            return "8cdee95d-4dd7-4c72-a139-78b188bf09bd";
        case "SNAR-359-0823-CA-SCALED-1":
            return "a28a6864-5661-43ab-aa45-75432c2b99fc";
        case "SNAR-360-0823-CA-SCALED-2":
            return "c4cd3ee3-2f72-493a-bbd6-d7636754eb5a";
        case "SNAR-361-0823-CA-SCALED-3":
            return "8e011ec6-3e7b-46c7-8de4-06ebad6c61c3";
        case "SNAR-362-0823-AU-SCALED-1":
            return "ae6ec12f-dc26-47af-8cc7-356653cfd57f";
        case "SNAR-363-0823-AU-SCALED-2":
            return "4c989079-1101-420a-bc5b-aaa98ab0905e";
        default:
            assertNever(groupId);
    }
}

const getLanguage = (groupId: SNAR0823CollGroupId): AppLanguage => {
    switch (groupId) {
        case "SNAR-295-0823-VALIDATION":
        case "SNAR-316-0823-UK-SCALED-1":
        case "SNAR-317-0823-UK-SCALED-2":
        case "SNAR-318-0823-US-SCALED-1":
        case "SNAR-319-0823-US-SCALED-2":
        case "SNAR-359-0823-CA-SCALED-1":
        case "SNAR-360-0823-CA-SCALED-2":
        case "SNAR-361-0823-CA-SCALED-3":
        case "SNAR-362-0823-AU-SCALED-1":
        case "SNAR-363-0823-AU-SCALED-2":
            return AppLanguage.en;
        case "SNAR-355-0823-FR-SCALED-1":
        case "SNAR-356-0823-FR-SCALED-2":
            return AppLanguage.fr;
        case "SNAR-357-0823-SA-SCALED-1":
        case "SNAR-358-0823-SA-SCALED-2":
            return AppLanguage.ar;
        default:
            assertNever(groupId);
    }
}

const getFinishMessageLanguage = (groupId: SNAR0823CollGroupId): string => {
    switch (groupId) {
        case "SNAR-295-0823-VALIDATION":
        case "SNAR-316-0823-UK-SCALED-1":
        case "SNAR-317-0823-UK-SCALED-2":
        case "SNAR-318-0823-US-SCALED-1":
        case "SNAR-319-0823-US-SCALED-2":
        case "SNAR-359-0823-CA-SCALED-1":
        case "SNAR-360-0823-CA-SCALED-2":
        case "SNAR-361-0823-CA-SCALED-3":
        case "SNAR-362-0823-AU-SCALED-1":
        case "SNAR-363-0823-AU-SCALED-2":
            return "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";
        case "SNAR-355-0823-FR-SCALED-1":
        case "SNAR-356-0823-FR-SCALED-2":
            return "Toutes nos félicitations!\nVous avez maintenant terminé toutes les tâches requises et vos données ont été téléchargées. Votre incitation a été traitée. Merci pour votre temps.";
        case "SNAR-357-0823-SA-SCALED-1":
        case "SNAR-358-0823-SA-SCALED-2":
            return "تهانينا!\nلقد أكملت الآن جميع المهام المطلوبة وتم تحميل بياناتك. تمت معالجة الحافز الخاص بك. شكرًا لك على وقتك";
        default:
            assertNever(groupId);
    }
}


const getTitle = (language: AppLanguage): string => {
    switch (language) {
        case AppLanguage.de:
            return 'Unleash your imagination 📳';
        case AppLanguage.es:
            return 'Unleash your imagination 📳';
        case AppLanguage.po:
            return 'Unleash your imagination 📳';
        case AppLanguage.en:
            return 'Unleash your imagination 📳';
        case AppLanguage.fr:
            return 'Libérez votre imagination 📳';
        case AppLanguage.ar:
            return 'أطلق العنان لخيالك 📳';
        default:
            assertNever(language);
    }
}

export const addSNAR0823CollConfig = (
    config: SNAR0823CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const language = getLanguage(config.groupId);
    const langStrings = getLanguageStrings(language);
    const title = getTitle(language);
    const finishMessage = getFinishMessageLanguage(config.groupId);

    builder
    .setFlag('deviceLock', 'mobile')
    .setFlag('requireMinimumBattery', 0.3)
    .setFlag('preventTaskProgress', false)
    .setFlag('finishMessageOverride', finishMessage)

    const webplatformId = getWebPlatformId(config.groupId);

    const snapARTask = generateSnapARTask({
        langStrings: langStrings,
        platform: 'SnapAR',
        groupId: config.groupId,
        lensGroupID: getLensGroupId(config.groupId),
        sessionLength: getTaskLengthMinutes(webplatformId),
        title: title
    })

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder
    .addTask(snapARTask)
    .addTask(surveyTask)
    .addTask(uploadsTask);

    return builder;
}