import { PanelConfiguration, PanelMetaBase } from ".";
import { assertNever } from "../helper/assertNever";
import { AllButUpsideDown } from "../interfaceOrientation";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateCompletionCodeTask, generateGazeCalibrationTask, generateGazeCalibrationDesktopTask } from "../tasks";

const mturkGroupIds = [
    'MTURK-TEST-GROUP',
    'GAZE-119-0422-MTURK-GAZE',
    'DSTD-261-0223-MTURK-GAZE-DESKTOP'
] as const;

export type MturkGroupId = typeof mturkGroupIds[number]

const mturkExtraMetaKeys = [
    'assignmentId',
    'hitId',
    'panelIdentifier',
    'workerId',
    'completedDemographicSurvey',
    'isMacbook'
] as const

export type MturkExtraMetaKey = typeof mturkExtraMetaKeys[number] | string;

export type PanelMetaMturk = PanelMetaBase<MturkGroupId> & {
    assignmentId: string
    hitId: string
    workerId:  string
    completedDemographicSurvey: string
    isMacbook: string
};

export class PanelConfigurationMturk implements PanelConfiguration {
    constructor(
        private meta: PanelMetaMturk
    ) { }

    addExtraMetaAndFlags(configBuilder: RespondentConfigBuilder<MturkExtraMetaKey>) {
        configBuilder
            .setFlag('isMturk', true)
            .addExtraMeta('assignmentId', this.meta.assignmentId)
            .addExtraMeta('hitId', this.meta.hitId)
            .addExtraMeta('workerId', this.meta.workerId)

        if(this.meta.groupId === "DSTD-261-0223-MTURK-GAZE-DESKTOP") {
            configBuilder
                .addExtraMeta('completedDemographicSurvey', this.meta.completedDemographicSurvey)
                .addExtraMeta('isMacbook', this.meta.isMacbook)
            Object.entries(this.meta).forEach(([k, v]) => configBuilder.addExtraMeta(k, v));
        }

        
    }

    addCompletionTasks(configBuilder: RespondentConfigBuilder<MturkExtraMetaKey>) {
        configBuilder.addTask(generateCompletionCodeTask({ groupId: this.meta.groupId }))
    }

    addGroupConfig(configBuilder: RespondentConfigBuilder<MturkExtraMetaKey>) {
        switch (this.meta.groupId) {
            case 'MTURK-TEST-GROUP': {
                const gazeCalibrationTask = generateGazeCalibrationTask({
                    groupId: this.meta.groupId,
                    config: {
                        orientations: AllButUpsideDown,
                        pointsPerOrientation: 7,
                        staticPointsPerBlock: 5,
                        movingPointsPerBlock: 3,
                        presentEachPointWithRotations: [-25, 0, 25]
                    }
                })

                configBuilder
                    .setFlag('deviceLock', 'mobile')
                    .setFlag('shouldPresentUploadsVC', true)
                    .addTask(gazeCalibrationTask)

                break;
            }

            case 'DSTD-261-0223-MTURK-GAZE-DESKTOP': {
                const gazeCalibrationDesktopTask = generateGazeCalibrationDesktopTask({
                    groupId: this.meta.groupId,
                    config: {}
                })

                configBuilder.addTask(gazeCalibrationDesktopTask)

                break;
            }

            case 'GAZE-119-0422-MTURK-GAZE': {
                const gazeCalibrationTask = generateGazeCalibrationTask({
                    groupId: this.meta.groupId,
                    config: {
                        orientations: AllButUpsideDown,
                        pointsPerOrientation: 7,
                        staticPointsPerBlock: 5,
                        movingPointsPerBlock: 0,
                        presentEachPointWithRotations: [-25, 0, 25]
                    }
                })

                configBuilder
                    .setFlag('deviceLock', 'mobile')
                    .setFlag('shouldPresentUploadsVC', true)
                    .addTask(gazeCalibrationTask)

                break;
            }

            default: assertNever(this.meta.groupId);
        }
    }
}