import * as React from 'react'
import { Grid, Typography } from '@mui/material'
import { getCurrentLangStrings } from '../../../i18n'
import { useStyles } from '../styles';

const ViewingInstructions: React.FC = () => {
    const { classes } = useStyles();
    const langStrings = getCurrentLangStrings();

    return <>
        <Grid 
            container 
            direction="column" 
            justifyContent="center" 
            alignItems="center" 
            alignContent="center"
            className={classes.grid}
        >
            <Grid item>
                <Typography variant="h6" className={classes.fontOfeliaBold} gutterBottom>{langStrings.Steps.ViewingInstructions.title}</Typography>
                <Typography 
                    gutterBottom 
                    dangerouslySetInnerHTML={{__html: langStrings.Steps.ViewingInstructions.subtitle}}>
                </Typography>
            </Grid>
        </Grid>
    </>
}

export default ViewingInstructions
