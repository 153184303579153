import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { SNAP0522CollGroupId } from "respondent_config_generator/src/projects/SNAP-0522";

export { SNAP0522CollGroupIds } from "respondent_config_generator/src/projects/SNAP-0522";
export type { SNAP0522CollGroupId } from "respondent_config_generator/src/projects/SNAP-0522";

const getPlatformForGroupId = (groupId: SNAP0522CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "SNAP-120-0522-UK-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-121-0522-UK-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-122-0522-UK-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-123-0522-UK-SNAPCHAT-NONBRAND-TV":
        case "SNAP-124-0522-CA-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-125-0522-CA-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-126-0522-CA-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-127-0522-CA-SNAPCHAT-NONBRAND-TV":
        case "SNAP-128-0522-US-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-129-0522-US-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-130-0522-US-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-131-0522-US-SNAPCHAT-NONBRAND-TV":
        case "SNAP-132-0522-AU-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-133-0522-AU-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-134-0522-AU-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-135-0522-AU-SNAPCHAT-NONBRAND-TV":
        case "SNAP-136-0522-DE-SNAPCHAT-BRANDPURPOSE-SNAP":
        case "SNAP-137-0522-DE-SNAPCHAT-BRANDPURPOSE-TV":
        case "SNAP-138-0522-DE-SNAPCHAT-NONBRAND-SNAP":
        case "SNAP-139-0522-DE-SNAPCHAT-NONBRAND-TV":
        case "SNAP-208-0522-UK-SNAPCHAT-RECOLLECT":
        case "SNAP-209-0522-CA-SNAPCHAT-RECOLLECT":
        case "SNAP-210-0522-US-SNAPCHAT-RECOLLECT":
        case "SNAP-211-0522-AU-SNAPCHAT-RECOLLECT":
        case "SNAP-212-0522-DE-SNAPCHAT-RECOLLECT":
            return "snapchat";

        default:
            assertNever(groupId)
    }
}

export const configureSNAP0522Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<SNAP0522CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}