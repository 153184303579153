import React from 'react'

import { Box, Typography, Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
    divider: {
        margin: theme.spacing(1, 0, 1, 0)
    },
    topParagraph: {
        marginBottom: theme.spacing(1)
    }
}))

type GazeInstructionProps = {
    showSurveyAlert: boolean
};

const MturkGazeInstructions: React.FC<GazeInstructionProps> = (props: GazeInstructionProps) => {
    const { classes } = useStyles()

    const overviewText = 'The purpose of this HIT is to collect some training data for an algorithm involving attention measurement. Your task will be to focus on points on your phone then verify you were paying attention to each point by counting appearances of a symbol. The task will take about 10-15 minutes in total.'

    return <>
        <Typography component="div" variant="body1" align="center" className={classes.topParagraph}>
            <Box fontWeight="fontWeightMedium">{overviewText}</Box>
        </Typography>

        {props.showSurveyAlert && <>
            <Divider className={classes.divider} variant="middle" />
            <Typography component="div" align="center" gutterBottom>
                <Box fontWeight="fontWeightMedium">Once you accept this task, you will be presented with a short demographic survey before being redirected back to these instructions.</Box>
            </Typography>
        </>}

        <Divider className={classes.divider} variant="middle" />
        <Typography component="div" align="center" gutterBottom>
            <Box fontWeight="fontWeightMedium">We currently have two similar iPhone HITs running simultaniously, you are allowed (and encouraged) to complete both if there are Assignments available.</Box>
        </Typography>
    </>
}

export default MturkGazeInstructions