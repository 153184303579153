import React from "react";
import { getCurrentLang } from "../../i18n";
import { Typography, Link, Tooltip } from "@mui/material";
import { LandingPageSupportEmail } from "../reducer";
import { AppLanguage } from "internationalisation";

const SupportEmailLinkDeskSolution: React.FC = () => {
    const lang = getCurrentLang();

    switch (lang) {
        case AppLanguage.en:
            return (
                <div>
                    <Typography align="center" gutterBottom>
                        If you encounter issues during this study you can {" "} 
                        <Tooltip title="help@amplifiedintelligence.com.au">
                            <Link href={`mailto:${LandingPageSupportEmail.help}`}>
                                contact us
                            </Link>    
                        </Tooltip>
                        {" "}via email. Please ensure you include your Login ID to assist us with resolving your issue.
                        <br />
                        <Link
                            onClick={() => {
                                window.open(
                                    "https://www.amplifiedintelligence.com.au/privacy-policy/"
                                );
                            }}
                        >
                            Privacy Policy
                        </Link>
                    </Typography>
                </div>
            );
        case AppLanguage.de:
            return (
                <Typography align="center" gutterBottom>
                    If you encounter issues during this study you can {" "} 
                    <Link href={`mailto:${LandingPageSupportEmail.help}`}>
                        contact us
                    </Link>
                    via email. Please ensure you include your Login ID to assist us with resolving your issue.
                    <br />
                    <Link
                        onClick={() => {
                            window.open(
                                "https://www.amplifiedintelligence.com.au/privacy-policy/"
                            );
                        }}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
            );
        case AppLanguage.es:
            return (
                <Typography align="center" gutterBottom>
                    If you encounter issues during this study you can {" "} 
                    <Link href={`mailto:${LandingPageSupportEmail.help}`}>
                        contact us
                    </Link>
                    via email. Please ensure you include your Login ID to assist us with resolving your issue.
                    <br />
                    <Link
                        onClick={() => {
                            window.open(
                                "https://www.amplifiedintelligence.com.au/privacy-policy/"
                            );
                        }}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
            );
        case AppLanguage.fr:
            return (
                <Typography align="center" gutterBottom>
                    If you encounter issues during this study you can {" "} 
                    <Link href={`mailto:${LandingPageSupportEmail.help}`}>
                        contact us
                    </Link>
                    via email. Please ensure you include your Login ID to assist us with resolving your issue.
                    <br />
                    <Link
                        onClick={() => {
                            window.open(
                                "https://www.amplifiedintelligence.com.au/privacy-policy/"
                            );
                        }}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
            );
        case AppLanguage.ar:
            return (
                <Typography align="center" gutterBottom>
                    If you encounter issues during this study you can {" "} 
                    <Link href={`mailto:${LandingPageSupportEmail.help}`}>
                        contact us
                    </Link>
                    via email. Please ensure you include your Login ID to assist us with resolving your issue.
                    <br />
                    <Link
                        onClick={() => {
                            window.open(
                                "https://www.amplifiedintelligence.com.au/privacy-policy/"
                            );
                        }}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
            );
        case AppLanguage.po:
            return (
                <Typography align="center" gutterBottom>
                    If you encounter issues during this study you can {" "} 
                    <Link href={`mailto:${LandingPageSupportEmail.help}`}>
                        contact us
                    </Link>
                    via email. Please ensure you include your Login ID to assist us with resolving your issue.
                    <br />
                    <Link
                        onClick={() => {
                            window.open(
                                "https://www.amplifiedintelligence.com.au/privacy-policy/"
                            );
                        }}
                    >
                        Privacy Policy
                    </Link>
                </Typography>
            );
        default:
            assertNever(lang);
    }
};

export default SupportEmailLinkDeskSolution;
