import React, { useMemo } from 'react'
import { Typography, Grid, Link, IconButton, Divider } from '@mui/material'

import { getCurrentLangStrings } from '../../../i18n'
import { useStyles } from '../styles'
import FileCopyIcon from '@mui/icons-material/FileCopy';
import QRCodeComponent from '../QRCodeComponent'
import { BlinqAppStoreCropped, DownloadOnAppStore } from '@assets'

interface Props {
    loginId: string;
}

const Setup: React.FC<Props> = (props) => {
    const { loginId } = props;
    const { classes } = useStyles()
    const lang = getCurrentLangStrings()
    const steps = lang.Steps.Setup;

    const handleCopy = async (text: string) => {
        try {
            await navigator.clipboard.writeText(text);
        } catch {
            console.error('Failed to copy text to clipboard');
        }
    };

    const renderSteps = useMemo(() => {
        return steps.map((step, i) => {
            if (step.key === 'loginCode') {
                return (
                    <div key={i}>
                        <Grid className={classes.grid}>
                            <Typography className={classes.fontOfeliaBold}>{step.title}</Typography>
                            <Typography>{step.description}</Typography>
                            <Typography display="flex" justifyContent="center" alignItems="flex-start" variant="h2" gutterBottom >
                                {loginId}
                                <IconButton aria-label="copy" size="small" onClick={() => handleCopy(loginId)}>
                                    <FileCopyIcon color='primary' />
                                </IconButton>
                            </Typography>
                        </Grid>
                        <Divider className={classes.divider} variant="middle" />
                    </div>
                )
            }
            if (step.key === 'appDownload') {
                return (
                    <Grid container key={i} className={classes.grid}>
                        <Grid item xs={12} marginBottom="20px">
                            <Typography className={classes.fontOfeliaBold}>{step.title}</Typography>
                        </Grid>
                        <Grid item xs={5}>
                            <QRCodeComponent loginId={loginId} size={200}></QRCodeComponent>
                        </Grid>
                        <Grid item xs={7} marginBottom="20px">
                            <Grid container flexDirection="column" alignItems="flex-start" marginLeft="20px">
                                <img src={BlinqAppStoreCropped} style={{ height: '100px', marginBottom: '10px' }} alt="Blinq App Logo" />
                                <Link href="https://apps.apple.com/app/id1227617967" target="_blank" rel="noopener">
                                    <img src={DownloadOnAppStore} style={{ height: '50px', marginBottom: '16px' }} alt="Download on App Store" />
                                </Link>
                                <Link href="https://apps.apple.com/app/id1227617967" target="_blank" rel="noopener" align="center" variant="body1">{lang.Steps.AppDownload.downloadLink}</Link>
                            </Grid>
                        </Grid>
                        <Grid item xs={12} marginBottom="20px">
                            <Typography>{step.description}</Typography>
                        </Grid>
                    </Grid>
                )
            }
            return <></>;
        })
    }, [classes.divider, classes.fontOfeliaBold, classes.grid, lang.Steps.AppDownload.downloadLink, loginId, steps]);

    return (
        <>
            {renderSteps}
        </>
    )
}

export default Setup