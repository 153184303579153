import React, { PropsWithChildren } from 'react'
import { Paper } from '@mui/material'
import { useStyles } from './styles'

const PageWrapper: React.FC<PropsWithChildren<{}>> = ({ children }) => {
    const { classes } = useStyles()

    return (
        <main className={classes.layout}>
            <Paper className={classes.paper}>
                {children}
            </Paper>
        </main>
    )
}

export default PageWrapper
