import { LangStrings } from './LangStrings';

const DeLangStrings: LangStrings = {
    "Common": {
        "survey": "Umfrage",
        "back": "Zurück",
        "next": "Weiter",
        "loading": "Wird geladen",
        "go": ""
    },
    "Survey": {
        "description": "Eine 10-minütige Umfrage zu Ihren Einkaufsgewohnheiten"
    },
    "WebPlatform": {
        "facebook": (minutes: string) => `Scrolle ${minutes} Minuten lang durch Facebook`,
        "youtube": (minutes: string) => `${minutes} Minuten lang Youtube schauen`,
        "instagram": (minutes: string) => `Scrolle ${minutes} Minuten lang durch Instagram`,
        "tiktok": (minutes: string) => `Scrolle ${minutes} Minuten lang durch TikTok`,
        "snapar": () => `Explore the provided lenses simply by tapping on each one. You can play games, try on accessories and take pictures with characters. We’ll let you know when the session is up.`
    },
    "LandingPage": {
        "Title": "Setup-Anweisungen",
    },
    "Decay": {
        "Surveys": {
            "Initial": "Shopping-Umfrage",
            "TwoWeek": "2 Wochen Shopping-Umfrage",
            "FourWeek": "4 Wochen Shopping-Umfrage"
        }
    },
    "BvodInApp": {
        "title": "Fernsehshow-Betrachtung",
        "description": "Sehen Sie sich eine Fernsehsendung auf Ihrem Handy an",
    },
    "Steps": {
        "StepLabel": {
            "Instructions": "Anleitung",
            "ViewingInstructionsDeskSolution": "Anleitung",
            "Requirements": "Requirements",
            "AppDownload": "App Herunterladen",
            "AppDownloadDeskSolution": "",
            "LoginAndCompletionCode": "Ein-loggen",
            "MturkGazeDesktopCompletion": "Ein-loggen",
            "LoginAndCompletionDeskSolution": "",
            "SessionInformationDeskSolution": "",
            "Setup": "",
            StartEarning: ""
        },
        ViewingInstructionsDeskSolution: {
            title: "",
            subtitle: "",
        },
        "AppDownload": {
            "subtitle": "Geben Sie zunächst \"attentionTRACE Research\" in die Suche im Apple App Store ein. In den Ergebnissen  sollten die folgende App finden.",
            "or": "ODER",
            "downloadLink": "Klicken Sie hier, um unsere App herunterzuladen",
        },
        "AppDownloadDeskSolution": {
            "subtitle": "",
            "downloadLink": "",
        },
        "LoginAndCompletionCode": {
            subtitle: "Verwenden Sie diesen Code, um sich bei AttentionTRACE anzumelden:",
            platformInstructions: {
                facebookNewsfeed: "Melden Sie sich bei Ihrem Facebook-Konto an und scrollen Sie durch den Newsfeed. Tun Sie alles, was Sie normalerweise tun würden; scrollen, liken und kommentieren Sie einige Posts und sehen Sie sich einige lustige Memes an, bis der Timer abgelaufen ist!\nBevor Sie beginnen, müssen Sie Ihre Facebook-Anmeldedaten bereithalten",
                facebookWatch: "Melden Sie sich bei Ihrem Facebook-Konto an. Sie werden zum Tab „Ansehen“ weitergeleitet, wo Sie sich verschiedene Videos ansehen können. Sieh dir so viele an, wie du möchtest, bevor der Timer abgelaufen ist!\nBevor du beginnst, brauchst du deine Facebook-Anmeldedaten",
                facebookStories: "Melde dich bei deinem Facebook-Konto an und sieh dir an, welche Geschichten deine Freunde posten, bis der Timer abgelaufen ist!\nBevor du beginnst, musst du deine Facebook-Details bereithalten",
                instagram: '',
                instagramNewsfeed: "Melden Sie sich bei Ihrem Instagram-Konto an und scrollen Sie durch Ihren Feed. Machen Sie alles wie gewohnt, liken, kommentieren und teilen Sie einige Beiträge, bis der Timer abgelaufen ist!\nBevor Sie beginnen, müssen Sie Ihre Instagram-Anmeldedaten bereithalten",
                twitter: "Sie benötigen Ihre Anmeldedaten für Ihr Twitter-Konto, um teilnehmen zu können. Sobald Sie drin sind, verwenden Sie Twitter wie gewohnt. Scrollen Sie durch den Feed und sehen Sie, was angesagt ist, bis die App Sie benachrichtigt, dass die Zeit abgelaufen ist",
                webplatformSynergy: "Sie benötigen Ihre Anmeldedaten für Ihr Twitter-Konto, um teilnehmen zu können. Sobald Sie drin sind, verwenden Sie Twitter wie gewohnt. Scrollen Sie durch den Feed und sehen Sie, was angesagt ist, bis die App Sie benachrichtigt, dass die Zeit abgelaufen ist.\nSobald die Zeit abgelaufen ist, lädt die App automatisch die nächste Plattform, entweder Instagram, Facebook oder TikTok. Bitte halten Sie daher auch diese Zugangsdaten bereit.",
                youtube: "Sobald Sie drin sind, verwenden Sie YouTube wie gewohnt und sehen sich ein paar verschiedene Videos an. Wir empfehlen Ihnen, sich bei Ihrem Google-Konto anzumelden, also halten Sie diese Details bereit, wenn Sie mit der Anzeige beginnen. Die App benachrichtigt Sie, wenn die Zeit abgelaufen ist.",
                tiktok: "Sie benötigen Ihre TikTok-Konto-Anmeldedaten, um sich anmelden zu können. Sobald Sie drin sind, verwenden Sie TikTok wie gewohnt. Scrollen Sie durch den Feed und sehen Sie, was angesagt ist, bis die App Sie benachrichtigt, dass die Zeit abgelaufen ist",
                pinterest: "Bitte melden Sie sich mit dem oben genannten eindeutigen Anmeldecode bei der AttentionTRACE-App an.\nSobald Sie sich angemeldet haben, wird Ihnen die Pinterest-Plattform angezeigt und Sie werden aufgefordert, sich mit Ihrem persönlichen Konto anzumelden.\nDurchsuchen und erkunden Sie Pinterest frei, indem Sie den Anweisungen folgen (sehen Sie sich die Erkundungsseite an, navigieren Sie durch vorgeschlagene Kategorien und interagieren Sie mit Inhalten), bis der Timer abgelaufen ist.\nSie werden zu einer Umfrage weitergeleitet. Füllen Sie diese einfach aus und schon sind Sie fertig!",
                snapar: "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will presented with a collection of Snapchat AR lenses. \nPlease tap on each lens to utilise it and interact with the lens as you normally would until the timer is up.",
                snapchat: ''
            }
        },
        "MturkGazeDesktopCompletion": {
            "subtitle": "Als nächstes öffnen Sie AttentionTRACE und melden sich mit der Login-ID an:"
        },
        "LoginAndCompletionDeskSolution": {
            "subtitle": ""
        },
        "SessionInformationDeskSolution": {
            "description": ""
        },
        "Error": {
            "title": "Entschuldigung",
            "subTitle": "Ein unerwarteter Fehler ist aufgetreten. Bitte versuchen Sie es später erneut",
        },
        ViewingInstructions: {
            title: "Vielen Dank für Ihre Teilnahme an unserer Studie. Befolgen Sie die Schritte, um unsere App herunterzuladen und loszulegen.",
            subtitle: "Zunächst werden wir Sie dabei unterstützen, unsere iPhone-App herunterzuladen und eine Login-ID zu erhalten. Klicke weiter um fortzufahren.",
        },
        Setup: [
            {
                key: "loginCode",
                title: "Step 1: Record your Login ID",
                description: "This code will be your key to login to the Blinq Research App and start earning. Keep it in a safe place, just in case."
            },
            {
                key: "appDownload",
                title: "Step 2: Download the Blinq Research App",
                description: "Either scan the QR code using the camera on your phone, or click on the App Store logo to download directly."
            }
        ],
        StartEarning: 'Once you’ve downloaded the app, it’s time to enter your Login ID.'
    },
    StepsFooter: {
        description: "Wenn Sie auf Probleme stoßen, können Sie {contactUs}. Bitte geben Sie in Ihrer E-Mail-Anfrage Ihre {loginIdString} an.",
        contactUs: "uns kontaktieren",
        privacyPolicy: "Datenschutzrichtlinie"
    }
}

export default DeLangStrings