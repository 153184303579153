import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateWebPlatformTask } from "../tasks";
import { WebPlatform } from "../types";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your panel account has been incentivised. Thank you for your time."

export const REDC0122CollGroupIds = [
    'REDC-64-0122-IRL-TWITTER-INFEEDVIDEO',
    'REDC-65-0122-IRL-TWITTER-INFEEDSTATIC',
    'REDC-66-0122-IRL-INSTAGRAM-INFEED',
    'REDC-67-0122-IRL-INSTAGRAM-STORIES',
    'REDC-68-0122-IRL-YOUTUBE-PREROLL',
    'REDC-69-0122-IRL-YOUTUBE-EXTRAS',
    'REDC-70-0122-IRL-FACEBOOK-INFEED',
    'REDC-71-0122-IRL-FACEBOOK-STORIES',
] as const

export type REDC0122CollGroupId = typeof REDC0122CollGroupIds[number]

type Redc0122CollConfig = {
    groupId: REDC0122CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getWebPlatformConfigUrl = (groupId: REDC0122CollGroupId): string => {
    switch (groupId) {
        case "REDC-64-0122-IRL-TWITTER-INFEEDVIDEO": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/REDC-0122/REDC-64-0122-IRL-TWITTER-INFEEDVIDEO.json";
        case "REDC-65-0122-IRL-TWITTER-INFEEDSTATIC": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/REDC-0122/REDC-65-0122-IRL-TWITTER-INFEEDSTATIC.json";

        case "REDC-66-0122-IRL-INSTAGRAM-INFEED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/REDC-0122/REDC-66-0122-IRL-INSTAGRAM-INFEED.json";
        case "REDC-67-0122-IRL-INSTAGRAM-STORIES": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/REDC-0122/REDC-67-0122-IRL-INSTAGRAM-STORIES.json";

        case "REDC-68-0122-IRL-YOUTUBE-PREROLL": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/REDC-0122/REDC-68-0122-IRL-YOUTUBE-PREROLL.json";
        case "REDC-69-0122-IRL-YOUTUBE-EXTRAS": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/REDC-0122/REDC-69-0122-IRL-YOUTUBE-EXTRAS.json";

        case "REDC-70-0122-IRL-FACEBOOK-INFEED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/REDC-0122/REDC-70-0122-IRL-FACEBOOK-INFEED.json";
        case "REDC-71-0122-IRL-FACEBOOK-STORIES": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/REDC-0122/REDC-71-0122-IRL-FACEBOOK-STORIES.json";

        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: REDC0122CollGroupId): WebPlatform => {
    switch (groupId) {
        case "REDC-64-0122-IRL-TWITTER-INFEEDVIDEO":
        case "REDC-65-0122-IRL-TWITTER-INFEEDSTATIC": {
            return "Twitter";
        }

        case "REDC-66-0122-IRL-INSTAGRAM-INFEED":
        case "REDC-67-0122-IRL-INSTAGRAM-STORIES": {
            return "Instagram";
        }

        case "REDC-68-0122-IRL-YOUTUBE-PREROLL":
        case "REDC-69-0122-IRL-YOUTUBE-EXTRAS": {
            return "Youtube";
        }

        case "REDC-70-0122-IRL-FACEBOOK-INFEED":
        case "REDC-71-0122-IRL-FACEBOOK-STORIES": {
            return "Facebook";
        }

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: REDC0122CollGroupId): string => {
    switch (groupId) {
        case "REDC-64-0122-IRL-TWITTER-INFEEDVIDEO":
        case "REDC-65-0122-IRL-TWITTER-INFEEDSTATIC": {
            return "https://survey.au1.qualtrics.com/jfe/form/SV_b8czLhygg56qXCm";
        }

        case "REDC-66-0122-IRL-INSTAGRAM-INFEED":
        case "REDC-67-0122-IRL-INSTAGRAM-STORIES": {
            return "https://survey.au1.qualtrics.com/jfe/form/SV_bCuI9yamnRClj02";
        }

        case "REDC-68-0122-IRL-YOUTUBE-PREROLL":
        case "REDC-69-0122-IRL-YOUTUBE-EXTRAS": {
            return "https://survey.au1.qualtrics.com/jfe/form/SV_ab21G4XyRBe5hau";
        }

        case "REDC-70-0122-IRL-FACEBOOK-INFEED":
        case "REDC-71-0122-IRL-FACEBOOK-STORIES": {
            return "https://survey.au1.qualtrics.com/jfe/form/SV_3rbKNpg0WoGzzHE";
        }

        default:
            assertNever(groupId);
    }
}

export const addRedc0122CollConfig = (
    config: Redc0122CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)

    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: config.groupId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: getPlatformForGroup(config.groupId),
        taskLengthMinutes: "",
        langStrings: langStrings,
    });
    builder.addTask(webPlatformTask);

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });
    builder.addTask(surveyTask);

    return builder;
}