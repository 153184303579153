import { LangStrings } from "./LangStrings";

const PoLangStrings: LangStrings = {
    Common: {
        survey: "Ankieta",
        back: "Back",
        next: "Następny",
        loading: "Ładowanie",
        go: ""
    },
    Survey: {
        description: "Krótka ankieta na temat Twojego doświadczenia",
    },
    WebPlatform: {
        facebook: (minutes: string) => `Scroll Facebook for ${minutes} minutes`,
        youtube: (minutes: string) => `Scroll YouTube for ${minutes} minutes`,
        instagram: (minutes: string) => `Scroll Instagram for ${minutes} minutes`,
        tiktok: (minutes: string) => `Scroll TikTok for ${minutes} minutes`,
        snapar: () => 'Explorez les objectifs fournis en appuyant simplement sur chacun d\'eux. Vous pouvez jouer à des jeux, essayer des accessoires et prendre des photos avec des personnages. Nous vous informerons lorsque la séance sera terminée.',
    },
    LandingPage: {
        Title: "Instrukcje konfiguracji",
    },
    Decay: {
        Surveys: {
            Initial: "Shopping Survey",
            TwoWeek: "2 Week Shopping Survey",
            FourWeek: "4 Week Shopping Survey",
        },
    },
    BvodInApp: {
        title: "TV Show Viewing",
        description: "Watch a TV show on your phone",
    },
    Steps: {
        StepLabel: {
            Instructions: "Instrukcje",
            ViewingInstructionsDeskSolution: "Instrukcje",
            Requirements: "Wymagania",
            AppDownload: "Pobieranie aplikacji",
            AppDownloadDeskSolution: "Chrome Extension Set Up",
            LoginAndCompletionCode: "Zaloguj sie",
            MturkGazeDesktopCompletion: "Rozpocznij zadanie",
            LoginAndCompletionDeskSolution: "Login",
            SessionInformationDeskSolution: "",
            Setup: "",
            StartEarning: ""
        },
        ViewingInstructions: {
            title: "Dziękujemy za udział w naszym badaniu. Postępuj zgodnie z instrukcjami, aby pobrać naszą aplikację i zacząć.",
            subtitle:
                'Na początek poprowadzimy Cię przez proces pobierania naszej aplikacji na iPhone\'a i uzyskania identyfikatora logowania. Naciśnij dalej by kontynuować.',
        },
        ViewingInstructionsDeskSolution: {
            title: "Thank you for participating in our study. Please follow the steps to set up the Chrome extension and get started. ",
            subtitle:
                `To begin, we will show you how to set up the attentionTRACE Chrome extension to your browser and obtain a Login ID. Click “next” to continue.`,
        },
        AppDownload: {
            subtitle:
                'W Apple App Store wyszukaj „attentionTRACE Research',
            or: "LUB",
            downloadLink: "Kliknij tutaj, aby pobrać naszą aplikację",
        },
        AppDownloadDeskSolution: {
            subtitle:
                ` 
                1. Click the "Add to Chrome" button.

                2. Click "Add extension" in the pop-up message.

                3. Wait a few seconds for the extension to install.

                4. Look for the notification in the top right corner of the browser that says "Extension added to Chrome" to confirm installation.

                5. Once you have installed the attentionTRACE Chrome extension, you can select the jigsaw puzzle icon located in your Chrome toolbox and pin the extension to the toolbar.`,
            downloadLink: "Click here to set up the attentionTRACE Chrome extension",
        },
        LoginAndCompletionCode: {
            subtitle: "Użyj tego kodu, aby zalogować się do AttentionTRACE:",
            platformInstructions: {
                facebookNewsfeed:
                    "Zaloguj się do aplikacji AttentionTRACE, używając powyższego unikalnego kodu logowania.\nPo zalogowaniu zostanie wyświetlona wybrana platforma mediów społecznościowych i poproszona o zalogowanie się na konto osobiste.\nPrzeglądaj tę platformę tak, jak zwykle (oglądać filmy, komentować i wchodzić w interakcje z postami itp.) do końca odliczania.\nZostaniesz przekierowany do ankiety, po prostu ją wypełnij i gotowe!",
                facebookWatch:
                    "Login to your Facebook account. You’ll be directed to the ‘Watch’ tab, where you can check out some different videos. Watch as many as you are interested in before the timer is up!\nYou will need your Facebook login details ready before you begin",
                facebookStories:
                    "Login to your Facebook account and have a look at what Stories your friends are posting until the timer is up!\nYou will need your Facebook details ready before you begin",
                instagram:
                    '',
                instagramNewsfeed:
                    "Zaloguj się do aplikacji AttentionTRACE, używając powyższego unikalnego kodu logowania.\nPo zalogowaniu zostanie wyświetlona wybrana platforma mediów społecznościowych i poproszona o zalogowanie się na konto osobiste.\nPrzeglądaj tę platformę tak, jak zwykle (oglądać filmy, komentować i wchodzić w interakcje z postami itp.) do końca odliczania.\nZostaniesz przekierowany do ankiety, po prostu ją wypełnij i gotowe!",
                youtube:
                    "Zaloguj się do aplikacji AttentionTRACE, używając powyższego unikalnego kodu logowania.\nPo zalogowaniu zostanie wyświetlona wybrana platforma mediów społecznościowych i poproszona o zalogowanie się na konto osobiste.\nPrzeglądaj tę platformę tak, jak zwykle (oglądać filmy, komentować i wchodzić w interakcje z postami itp.) do końca odliczania.\nZostaniesz przekierowany do ankiety, po prostu ją wypełnij i gotowe!",
                twitter:
                    "You will need your Twitter account login details ready to enter. Once you are in, use Twitter as you normally would. Scroll the feed & see what's trending until the app notifies you that time is up",
                tiktok: 
                    "Zaloguj się do aplikacji AttentionTRACE, używając powyższego unikalnego kodu logowania.\nPo zalogowaniu zostanie wyświetlona wybrana platforma mediów społecznościowych i poproszona o zalogowanie się na konto osobiste.\nPrzeglądaj tę platformę tak, jak zwykle (oglądać filmy, komentować i wchodzić w interakcje z postami itp.) do końca odliczania.\nZostaniesz przekierowany do ankiety, po prostu ją wypełnij i gotowe!",
                webplatformSynergy:
                    "You will need your Twitter account login details ready to enter. Once you are in, use Twitter as you normally would. Scroll the feed & see what's trending until the app notifies you that time is up.\nOnce time is up the app will automatically load the next Platform, either Instagram, Facebook or TikTok. So please have those login details ready as well.",
                pinterest: 
                    "Zaloguj się do aplikacji uwagaTRACE, korzystając z powyższego unikalnego kodu logowania.\nPo zalogowaniu zostanie wyświetlona platforma Pinterest i prośba o zalogowanie się na Twoje konto osobiste.\nSwobodnie przeglądaj i eksploruj Pinterest, postępując zgodnie z instrukcjami (sprawdź przejdź na stronę eksploracji, poruszaj się po sugerowanych kategoriach i wchodź w interakcję z treścią), aż upłynie limit czasu.\nZostaniesz przekierowany do ankiety, po prostu ją wypełnij i gotowe!",
                snapar:
                    "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will presented with a collection of Snapchat AR lenses. \nPlease tap on each lens to utilise it and interact with the lens as you normally would until the timer is up.",
                snapchat: ''
            },
        },
        MturkGazeDesktopCompletion: {
            subtitle: "Use this code to login to attentionTRACE:",
        },
        LoginAndCompletionDeskSolution: {
            subtitle: "Use this code to login to attentionTRACE Chrome extension:",
        },
        Error: {
            title: "Uh-Oh!",
            subTitle: "Wystąpił nieoczekiwany błąd, spróbuj ponownie później",
        },
        SessionInformationDeskSolution: { description: ""},
        Setup: [
            {
                key: "loginCode",
                title: "Step 1: Record your Login ID",
                description: "This code will be your key to login to the Blinq Research App and start earning. Keep it in a safe place, just in case."
            },
            {
                key: "appDownload",
                title: "Step 2: Download the Blinq Research App",
                description: "Either scan the QR code using the camera on your phone, or click on the App Store logo to download directly."
            }
        ],
        StartEarning: 'Once you’ve downloaded the app, it’s time to enter your Login ID.'
    },
    StepsFooter: {
        description: "Jeśli napotkasz problemy, możesz {contactUs}. W zapytaniu e-mailowym proszę podać swój {loginIdString}.",
        contactUs: "Skontaktuj się z nami",
        privacyPolicy: "Polityka prywatności"
    }
};
export default PoLangStrings;
