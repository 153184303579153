import { AppLanguage } from "internationalisation";
import { ODEU0422CollGroupId } from "respondent_config_generator/src/projects/ODEU-0422";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";

export { ODEU0422CollGroupIds } from "respondent_config_generator/src/projects/ODEU-0422";
export type { ODEU0422CollGroupId } from "respondent_config_generator/src/projects/ODEU-0422";

const getPlatformForGroupId = (groupId: ODEU0422CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "ODEU-113-0422-DEU-FACEBOOK-INFEED":
            return "facebook-newsfeed";

        case "ODEU-114-0422-DEU-FACEBOOK-INSTREAM":
            return "facebook-watch"

        case "ODEU-115-0422-DEU-FACEBOOK-STORIES":
            return "facebook-stories";

        case "ODEU-116-0422-DEU-YOUTUBE-PREROLL":
        case "ODEU-117-0422-DEU-YOUTUBE-BONUS":
            return "youtube";

        case "ODEU-118-0422-DEU-TWITTER-INFEED":
            return "twitter";

        default:
            assertNever(groupId)
    }
}

export const configureODEU0422Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<ODEU0422CollGroupId>
): void => {
    setCurrentLang(AppLanguage.de)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}