import { AppLanguage, getLanguageStrings } from "internationalisation";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateUploadsTask, generateWebPlatformTask } from "../tasks";
import { assertNever } from "../helper";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your panel account has been incentivised. Thank you for your time.";

export const YAHD0823DesktopGroupIds = [
    "YAHD-299-0823-USA-YAHOO-DESKTOP",
    "YAHD-300-0823-USA-SPORTS-DESKTOP",
    "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP",
    "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP",
    "YAHD-311-0823-AUS-YAHOO-DESKTOP",
    "YAHD-312-0823-AUS-SPORTS-DESKTOP",
    "YAHD-313-0823-AUS-FINANCE-DESKTOP",
    "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP"
] as const;

export const YAHD0823MobileGroupIds = [
    "YAHD-303-0823-USA-YAHOO-MOBILE",
    "YAHD-304-0823-USA-SPORTS-MOBILE",
    "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE",
    "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE",
    "YAHD-307-0823-AUS-YAHOO-MOBILE",
    "YAHD-308-0823-AUS-SPORTS-MOBILE",
    "YAHD-309-0823-AUS-FINANCE-MOBILE",
    "YAHD-310-0823-AUS-LIFESTYLE-MOBILE"
] as const;

export type YAHD0823DesktopGroupId = typeof YAHD0823DesktopGroupIds[number];
export type YAHD0823MobileGroupId = typeof YAHD0823MobileGroupIds[number];

export type YAHD0823GroupId =
    | YAHD0823DesktopGroupId
    | YAHD0823MobileGroupId

type YAHD0823Config = {
    groupId: YAHD0823GroupId,
    metaFieldNames: ReadonlyArray<string>
}

const getSurveyUrl = (groupId: YAHD0823GroupId): string => {
    // TODO: add survey URLs for each group
    switch (groupId) {
        case "YAHD-299-0823-USA-YAHOO-DESKTOP":
        case "YAHD-300-0823-USA-SPORTS-DESKTOP":
        case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP":
        case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP":
        case "YAHD-303-0823-USA-YAHOO-MOBILE":
        case "YAHD-304-0823-USA-SPORTS-MOBILE":
        case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE":
        case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_3QWxCo2lrVc1WYe";
        case "YAHD-311-0823-AUS-YAHOO-DESKTOP":
        case "YAHD-312-0823-AUS-SPORTS-DESKTOP":
        case "YAHD-313-0823-AUS-FINANCE-DESKTOP":
        case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP":
        case "YAHD-307-0823-AUS-YAHOO-MOBILE":
        case "YAHD-308-0823-AUS-SPORTS-MOBILE":
        case "YAHD-309-0823-AUS-FINANCE-MOBILE":
        case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_eLJNlG4MGWzLBfE";
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (group: YAHD0823GroupId): string => {
    switch (group) {
        case "YAHD-299-0823-USA-YAHOO-DESKTOP":
            return "YAHD-0823-USA-YAHOO-DESKTOP";
        case "YAHD-300-0823-USA-SPORTS-DESKTOP":
            return "YAHD-0823-USA-SPORTS-DESKTOP";
        case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP":
            return "YAHD-0823-USA-FANTASYSPORTS-DESKTOP";
        case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP":
            return "YAHD-0823-USA-ENTERTAINMENT-DESKTOP";

        case "YAHD-311-0823-AUS-YAHOO-DESKTOP":
            return "YAHD-0823-AUS-YAHOO-DESKTOP";
        case "YAHD-312-0823-AUS-SPORTS-DESKTOP":
            return "YAHD-0823-AUS-SPORTS-DESKTOP";
        case "YAHD-313-0823-AUS-FINANCE-DESKTOP":
            return "YAHD-0823-AUS-FINANCE-DESKTOP";
        case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP":
            return "YAHD-0823-AUS-LIFESTYLE-DESKTOP";

        case "YAHD-303-0823-USA-YAHOO-MOBILE":
            return "YAHD-0823-USA-YAHOO-MOBILE";
        case "YAHD-304-0823-USA-SPORTS-MOBILE":
            return "YAHD-0823-USA-SPORTS-MOBILE";
        case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE":
            return "YAHD-0823-USA-FANTASYSPORTS-MOBILE";
        case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE":
            return "YAHD-0823-USA-ENTERTAINMENT-MOBILE";

        case "YAHD-307-0823-AUS-YAHOO-MOBILE":
            return "YAHD-0823-AUS-YAHOO-MOBILE";
        case "YAHD-308-0823-AUS-SPORTS-MOBILE":
            return "YAHD-0823-AUS-SPORTS-MOBILE";
        case "YAHD-309-0823-AUS-FINANCE-MOBILE":
            return "YAHD-0823-AUS-FINANCE-MOBILE";
        case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE":
            return "YAHD-0823-AUS-LIFESTYLE-MOBILE";

        default:
            assertNever(group);
    }
}

const getWebPlatformConfigUrl = (groupId: YAHD0823GroupId): string => {
    var cdnUrl = "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/";
    var project = "YAHD-0823/";
    var webPlatfromID = getWebPlatformId(groupId)
    var configUrl = cdnUrl + project + webPlatfromID + ".json";
    return configUrl;
}


/**
 * Yahoo 0923 groups config
 */
export const addYahd0823Config = (
    config: YAHD0823Config,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en);

    switch (config.groupId) {
        case "YAHD-299-0823-USA-YAHOO-DESKTOP":
        case "YAHD-300-0823-USA-SPORTS-DESKTOP":
        case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP":
        case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP":
        case "YAHD-311-0823-AUS-YAHOO-DESKTOP":
        case "YAHD-312-0823-AUS-SPORTS-DESKTOP":
        case "YAHD-313-0823-AUS-FINANCE-DESKTOP":
        case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP": {
            builder.setFlag('deviceLock', 'desktop');
            break;
        }

        case "YAHD-303-0823-USA-YAHOO-MOBILE":
        case "YAHD-304-0823-USA-SPORTS-MOBILE":
        case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE":
        case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE":
        case "YAHD-307-0823-AUS-YAHOO-MOBILE":
        case "YAHD-308-0823-AUS-SPORTS-MOBILE":
        case "YAHD-309-0823-AUS-FINANCE-MOBILE":
        case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE": {
            builder.setFlag('deviceLock', 'mobile');
            break;
        }
    }

    builder
        .setFlag('shouldPresentUploadsVC', true)
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .setFlag('preventTaskProgress', false)

    const meta = config as YAHD0823Config;

    const webTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: 'Yahoo',
        taskLengthMinutes: "10",
        langStrings: langStrings,
    });

    builder.addTask(webTask);

    const externalSurveyTask = generateExternalSurveyTask({
        title: "Survey",
        description: "Complete a brief survey about the brands you saw",
        surveyUrl: getSurveyUrl(config.groupId),
        taskID: meta.groupId + '-SURVEY',
        extraMetaFields: [...meta.metaFieldNames]
    });

    builder.addTask(externalSurveyTask);

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder.addTask(uploadsTask);

    return builder;
}
