import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { TEAD1222CollGroupId } from "respondent_config_generator/src/projects/TEAD-1222";

export type { TEAD1222CollGroupId } from "respondent_config_generator/src/projects/TEAD-1222";
export { TEAD1222CollGroupIds } from "respondent_config_generator/src/projects/TEAD-1222";

const getPlatformForGroupId = (groupId: TEAD1222CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "TEAD-248-1222-US-PIONEER-COSMOPOLITAN-EXPOSED":
        case "TEAD-249-1222-US-TASTY-COUNTRYLIVING-EXPOSED":
        case "TEAD-250-1222-US-HOUSEBEAUTIFUL-HEALTHLINE-EXPOSED":
        case "TEAD-251-1222-US-GOODHOUSEKEEPING-THEKITCHN-EXPOSED":
        case "TEAD-252-1222-US-ESPN-DELISH-EXPOSED":
            return "tead";

        default:
            assertNever(groupId)
    }
}

export const configureTEAD1222Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<TEAD1222CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}