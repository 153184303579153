import React from 'react'
import { Typography, Card, CardMedia, CardContent, Grid, Link } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

import { getCurrentLangStrings } from '../../../i18n'
import appStoreListing from '../static/appStoreListing.jpg'

const useStyles = makeStyles()(theme => ({
    orText: {
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2)
    }
}))

const AppDownload: React.FC = () => {
    const { classes } = useStyles()
    const lang = getCurrentLangStrings()

    return (
        <>
            <Typography gutterBottom variant="body1" align="center">{ lang.Steps.AppDownload.subtitle }</Typography>
            <Grid container justifyContent="center" alignItems="center">
                <Card style={ { maxWidth: "50%" } }>
                    <CardMedia
                        component="img"
                        alt="App Store Listing for attentionTRACE Research"
                        image={appStoreListing}
                        title="attentionTRACE"
                    />
                    <CardContent>
                        <Typography align="center" variant="subtitle1" component="h2">AttentionTRACE Research</Typography>
                    </CardContent>
                </Card>
            </Grid>
            <Typography className={classes.orText} variant="h5" align="center">{lang.Steps.AppDownload.or}</Typography>
            <Grid container justifyContent="center" alignItems="center">
                <Link href="https://apps.apple.com/app/attentiontrace/id1227617967" target="_blank" rel="noopener" align="center" variant="body1">{lang.Steps.AppDownload.downloadLink}</Link>
            </Grid>
        </>
    )
}

export default AppDownload