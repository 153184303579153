import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import {
    generateBvodTask,
    generateExternalSurveyTask
} from "../tasks";

export const CRAA0822CollGroupIds = [
    'CRAA-152-0822-SCA-ADL-BREAKFAST',
    'CRAA-153-0822-SCA-SYD-BREAKFAST',
    'CRAA-154-0822-SCA-MEL-BREAKFAST',
    'CRAA-155-0822-SCA-BNE-BREAKFAST',
    'CRAA-156-0822-SCA-PER-BREAKFAST',
    'CRAA-157-0822-SCA-ALL-MORNAFT',
    'CRAA-158-0822-SCA-ALL-DRIVE',
    'CRAA-159-0822-SCA-ALL-EVENINGS',
    'CRAA-160-0822-SCA-PODCAST-THEBRIEFING',
    'CRAA-161-0822-SCA-PODCAST-HAMISHANDY',
    'CRAA-162-0822-ARN-ADL-BREAKFAST',
    'CRAA-163-0822-ARN-SYD-BREAKFAST',
    'CRAA-164-0822-ARN-MEL-BREAKFAST',
    'CRAA-165-0822-ARN-BNE-BREAKFAST',
    'CRAA-166-0822-ARN-PER-BREAKFAST',
    'CRAA-167-0822-ARN-ALL-MORNAFT',
    'CRAA-168-0822-ARN-ALL-DRIVE',
    'CRAA-169-0822-ARN-ADL-EVENING',
    'CRAA-170-0822-ARN-SYD-EVENING',
    'CRAA-171-0822-ARN-MEL-EVENING',
    'CRAA-172-0822-ARN-BNE-EVENING',
    'CRAA-173-0822-ARN-PER-EVENING',
    'CRAA-174-0822-ARN-PODCAST-IMPERFECTS',
    'CRAA-175-0822-ARN-PODCAST-KYLEJACKIE',
    'CRAA-176-0822-ARN-PODCAST-LIFEUNCUT',
    'CRAA-177-0822-NINE-SYD-BREAKFAST',
    'CRAA-178-0822-NINE-MEL-BREAKFAST',
    'CRAA-179-0822-NINE-BNE-BREAKFAST',
    'CRAA-180-0822-NINE-PER-BREAKFAST',
    'CRAA-181-0822-NINE-SYD-MORNAFT',
    'CRAA-182-0822-NINE-MEL-MORNAFT',
    'CRAA-183-0822-NINE-BNE-MORNAFT',
    'CRAA-184-0822-NINE-PER-MORNAFT',
    'CRAA-185-0822-NINE-SYD-DRIVE',
    'CRAA-186-0822-NINE-MEL-DRIVE',
    'CRAA-187-0822-NINE-BNE-DRIVE',
    'CRAA-188-0822-NINE-PER-DRIVE',
    'CRAA-189-0822-NINE-SYD-EVENING',
    'CRAA-190-0822-NINE-MEL-EVENING',
    'CRAA-191-0822-NINE-BNE-EVENING',
    'CRAA-192-0822-NINE-PER-EVENING',
    'CRAA-193-0822-NINE-PODCAST-MONEYNEWS',
    'CRAA-194-0822-NOVA-ADL-BREAKFAST',
    'CRAA-195-0822-NOVA-SYD-BREAKFAST',
    'CRAA-196-0822-NOVA-MEL-BREAKFAST',
    'CRAA-197-0822-NOVA-BNE-BREAKFAST',
    'CRAA-198-0822-NOVA-PER-BREAKFAST',
    'CRAA-199-0822-NOVA-AMBP-MORNAFT',
    'CRAA-200-0822-NOVA-SYD-MORNAFT',
    'CRAA-201-0822-NOVA-ALL-DRIVE',
    'CRAA-202-0822-NOVA-ALL-EVENING',
    'CRAA-203-0822-NOVA-PODCAST-HENNESSYS',
    'CRAA-204-0822-NOVA-PODCAST-KTJ',
    'CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE'
] as const

export type CRAA0822CollGroupId = typeof CRAA0822CollGroupIds[number]

type Craa0822CollConfig = {
    groupId: CRAA0822CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};


function getBvodChannelUrl(
    groupId: CRAA0822CollGroupId
): string {
    switch (groupId) {
        case 'CRAA-152-0822-SCA-ADL-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW1.json'
        case 'CRAA-153-0822-SCA-SYD-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW2.json'
        case 'CRAA-154-0822-SCA-MEL-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW3.json'
        case 'CRAA-155-0822-SCA-BNE-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW4.json'
        case 'CRAA-156-0822-SCA-PER-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW5.json'
        case 'CRAA-157-0822-SCA-ALL-MORNAFT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW6.json'
        case 'CRAA-158-0822-SCA-ALL-DRIVE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW7.json'
        case 'CRAA-159-0822-SCA-ALL-EVENINGS': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW8.json'
        case 'CRAA-160-0822-SCA-PODCAST-THEBRIEFING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW9.json'
        case 'CRAA-161-0822-SCA-PODCAST-HAMISHANDY': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW10.json'
        case 'CRAA-162-0822-ARN-ADL-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW11.json'
        case 'CRAA-163-0822-ARN-SYD-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW12.json'
        case 'CRAA-164-0822-ARN-MEL-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW13.json'
        case 'CRAA-165-0822-ARN-BNE-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW14.json'
        case 'CRAA-166-0822-ARN-PER-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW15.json'
        case 'CRAA-167-0822-ARN-ALL-MORNAFT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW16.json'
        case 'CRAA-168-0822-ARN-ALL-DRIVE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW17.json'
        case 'CRAA-169-0822-ARN-ADL-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW18.json'
        case 'CRAA-170-0822-ARN-SYD-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW19.json'
        case 'CRAA-171-0822-ARN-MEL-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW20.json'
        case 'CRAA-172-0822-ARN-BNE-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW21.json'
        case 'CRAA-173-0822-ARN-PER-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW22.json'
        case 'CRAA-174-0822-ARN-PODCAST-IMPERFECTS': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW23.json'
        case 'CRAA-175-0822-ARN-PODCAST-KYLEJACKIE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW24.json'
        case 'CRAA-176-0822-ARN-PODCAST-LIFEUNCUT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW25.json'
        case 'CRAA-177-0822-NINE-SYD-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW26.json'
        case 'CRAA-178-0822-NINE-MEL-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW27.json'
        case 'CRAA-179-0822-NINE-BNE-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW28.json'
        case 'CRAA-180-0822-NINE-PER-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW29.json'
        case 'CRAA-181-0822-NINE-SYD-MORNAFT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW30.json'
        case 'CRAA-182-0822-NINE-MEL-MORNAFT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW31.json'
        case 'CRAA-183-0822-NINE-BNE-MORNAFT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW32.json'
        case 'CRAA-184-0822-NINE-PER-MORNAFT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW33.json'
        case 'CRAA-185-0822-NINE-SYD-DRIVE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW34.json'
        case 'CRAA-186-0822-NINE-MEL-DRIVE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW35.json'
        case 'CRAA-187-0822-NINE-BNE-DRIVE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW36.json'
        case 'CRAA-188-0822-NINE-PER-DRIVE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW37.json'
        case 'CRAA-189-0822-NINE-SYD-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW38.json'
        case 'CRAA-190-0822-NINE-MEL-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW39.json'
        case 'CRAA-191-0822-NINE-BNE-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW40.json'
        case 'CRAA-192-0822-NINE-PER-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW41.json'
        case 'CRAA-193-0822-NINE-PODCAST-MONEYNEWS': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW42.json'
        case 'CRAA-194-0822-NOVA-ADL-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW43.json'
        case 'CRAA-195-0822-NOVA-SYD-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW44.json'
        case 'CRAA-196-0822-NOVA-MEL-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW45.json'
        case 'CRAA-197-0822-NOVA-BNE-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW46.json'
        case 'CRAA-198-0822-NOVA-PER-BREAKFAST': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW47.json'
        case 'CRAA-199-0822-NOVA-AMBP-MORNAFT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW48.json'
        case 'CRAA-200-0822-NOVA-SYD-MORNAFT': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW49.json'
        case 'CRAA-201-0822-NOVA-ALL-DRIVE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW50.json'
        case 'CRAA-202-0822-NOVA-ALL-EVENING': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW51.json'
        case 'CRAA-203-0822-NOVA-PODCAST-HENNESSYS': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW52.json'
        case 'CRAA-204-0822-NOVA-PODCAST-KTJ': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW53.json'
        case 'CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE': return 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/CRAA-0822/CRAA-0822-VIEW54.json'

        default:
            assertNever(groupId);
    }
}

function getSurveyUrl(
    groupId: CRAA0822CollGroupId
): string {
    switch (groupId) {
        case 'CRAA-152-0822-SCA-ADL-BREAKFAST':
        case 'CRAA-153-0822-SCA-SYD-BREAKFAST':
        case 'CRAA-154-0822-SCA-MEL-BREAKFAST':
        case 'CRAA-155-0822-SCA-BNE-BREAKFAST':
        case 'CRAA-156-0822-SCA-PER-BREAKFAST':
        case 'CRAA-157-0822-SCA-ALL-MORNAFT':
        case 'CRAA-158-0822-SCA-ALL-DRIVE':
        case 'CRAA-159-0822-SCA-ALL-EVENINGS':
        case 'CRAA-160-0822-SCA-PODCAST-THEBRIEFING':
        case 'CRAA-161-0822-SCA-PODCAST-HAMISHANDY':
            return "https://survey.au1.qualtrics.com/jfe/form/SV_2rjR0OU5I5yvzLM"
        case 'CRAA-162-0822-ARN-ADL-BREAKFAST':
        case 'CRAA-163-0822-ARN-SYD-BREAKFAST':
        case 'CRAA-164-0822-ARN-MEL-BREAKFAST':
        case 'CRAA-165-0822-ARN-BNE-BREAKFAST':
        case 'CRAA-166-0822-ARN-PER-BREAKFAST':
        case 'CRAA-167-0822-ARN-ALL-MORNAFT':
        case 'CRAA-168-0822-ARN-ALL-DRIVE':
        case 'CRAA-169-0822-ARN-ADL-EVENING':
        case 'CRAA-170-0822-ARN-SYD-EVENING':
        case 'CRAA-171-0822-ARN-MEL-EVENING':
        case 'CRAA-172-0822-ARN-BNE-EVENING':
        case 'CRAA-173-0822-ARN-PER-EVENING':
            return "https://survey.au1.qualtrics.com/jfe/form/SV_0vVCVvAsdrA6XSS"
        case 'CRAA-174-0822-ARN-PODCAST-IMPERFECTS':
        case 'CRAA-175-0822-ARN-PODCAST-KYLEJACKIE':
        case 'CRAA-176-0822-ARN-PODCAST-LIFEUNCUT':
            return "https://survey.au1.qualtrics.com/jfe/form/SV_6MuIyY8l8FXfxBk"
        case 'CRAA-177-0822-NINE-SYD-BREAKFAST':
        case 'CRAA-178-0822-NINE-MEL-BREAKFAST':
        case 'CRAA-179-0822-NINE-BNE-BREAKFAST':
        case 'CRAA-180-0822-NINE-PER-BREAKFAST':
        case 'CRAA-181-0822-NINE-SYD-MORNAFT':
        case 'CRAA-182-0822-NINE-MEL-MORNAFT':
        case 'CRAA-183-0822-NINE-BNE-MORNAFT':
        case 'CRAA-184-0822-NINE-PER-MORNAFT':
        case 'CRAA-185-0822-NINE-SYD-DRIVE':
        case 'CRAA-186-0822-NINE-MEL-DRIVE':
        case 'CRAA-187-0822-NINE-BNE-DRIVE':
        case 'CRAA-188-0822-NINE-PER-DRIVE':
        case 'CRAA-189-0822-NINE-SYD-EVENING':
        case 'CRAA-190-0822-NINE-MEL-EVENING':
        case 'CRAA-191-0822-NINE-BNE-EVENING':
        case 'CRAA-192-0822-NINE-PER-EVENING':
        case 'CRAA-193-0822-NINE-PODCAST-MONEYNEWS':
            return "https://survey.au1.qualtrics.com/jfe/form/SV_3qooMOzoUCwhzAG"
        case 'CRAA-194-0822-NOVA-ADL-BREAKFAST':
        case 'CRAA-195-0822-NOVA-SYD-BREAKFAST':
        case 'CRAA-196-0822-NOVA-MEL-BREAKFAST':
        case 'CRAA-197-0822-NOVA-BNE-BREAKFAST':
        case 'CRAA-198-0822-NOVA-PER-BREAKFAST':
        case 'CRAA-199-0822-NOVA-AMBP-MORNAFT':
        case 'CRAA-200-0822-NOVA-SYD-MORNAFT':
        case 'CRAA-201-0822-NOVA-ALL-DRIVE':
        case 'CRAA-202-0822-NOVA-ALL-EVENING':
        case 'CRAA-203-0822-NOVA-PODCAST-HENNESSYS':
        case 'CRAA-204-0822-NOVA-PODCAST-KTJ':
        case 'CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE':
            return "https://survey.au1.qualtrics.com/jfe/form/SV_8IAQqj5yrTo7BL8"

        default:
            assertNever(groupId);
    }
}

export const addCraa0822CollConfig = (
    config: Craa0822CollConfig,
    builder: RespondentConfigBuilder
) => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    const FINISH_MESSAGE = 'Thank you for your participation.';
    
    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('minimumIOSVersion', '15.0')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .setFlag('preventTaskProgress', false)

    const bvodMobileTask = generateBvodTask({
        taskID: config.groupId + '-BVOD',
        channelsJsonUrl: getBvodChannelUrl(config.groupId),
        isTv: false,
        langStrings: langStrings,
        isRadio: true
    });

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });
    
    builder
        .addTask(bvodMobileTask)
        .addTask(surveyTask)

    return builder;
}