import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateExternalSurveyTask } from "../tasks";
import { WebPlatform } from '../types';

export const SNEM0524CollGroupIds = [
    "SNEM-379-0524-AU-SNAP-HIGH",
    "SNEM-380-0524-AU-SNAP-MED",
    "SNEM-381-0524-AU-SNAP-LOW",
    "SNEM-382-0524-AU-YOUTUBE",
    "SNEM-383-0524-AU-TIKTOK-HIGH",
    "SNEM-384-0524-AU-TIKTOK-MED",
    "SNEM-385-0524-AU-TIKTOK-LOW",
    "SNEM-386-0524-AU-INSTA-HIGH",
    "SNEM-387-0524-AU-INSTA-MED",
    "SNEM-388-0524-AU-INSTA-LOW",
    "SNEM-389-0524-FR-SNAP-HIGH",
    "SNEM-390-0524-FR-SNAP-MED",
    "SNEM-391-0524-FR-SNAP-LOW",
    "SNEM-392-0524-FR-YOUTUBE",
    "SNEM-393-0524-FR-TIKTOK-HIGH",
    "SNEM-394-0524-FR-TIKTOK-MED",
    "SNEM-395-0524-FR-TIKTOK-LOW",
    "SNEM-396-0524-FR-INSTA-HIGH",
    "SNEM-397-0524-FR-INSTA-MED",
    "SNEM-398-0524-FR-INSTA-LOW",
    "SNEM-399-0524-SA-SNAP-HIGH",
    "SNEM-400-0524-SA-SNAP-MED",
    "SNEM-401-0524-SA-SNAP-LOW",
    "SNEM-402-0524-SA-YOUTUBE",
    "SNEM-403-0524-SA-TIKTOK-HIGH",
    "SNEM-404-0524-SA-TIKTOK-MED",
    "SNEM-405-0524-SA-TIKTOK-LOW",
    "SNEM-406-0524-SA-INSTA-HIGH",
    "SNEM-407-0524-SA-INSTA-MED",
    "SNEM-408-0524-SA-INSTA-LOW",
    "SNEM-409-0524-UK-SNAP-HIGH",
    "SNEM-410-0524-UK-SNAP-MED",
    "SNEM-411-0524-UK-SNAP-LOW",
    "SNEM-412-0524-UK-YOUTUBE",
    "SNEM-413-0524-UK-TIKTOK-HIGH",
    "SNEM-414-0524-UK-TIKTOK-MED",
    "SNEM-415-0524-UK-TIKTOK-LOW",
    "SNEM-416-0524-UK-INSTA-HIGH",
    "SNEM-417-0524-UK-INSTA-MED",
    "SNEM-418-0524-UK-INSTA-LOW",
    "SNEM-419-0524-US-SNAP-HIGH",
    "SNEM-420-0524-US-SNAP-MED",
    "SNEM-421-0524-US-SNAP-LOW",
    "SNEM-422-0524-US-YOUTUBE",
    "SNEM-423-0524-US-TIKTOK-HIGH",
    "SNEM-424-0524-US-TIKTOK-MED",
    "SNEM-425-0524-US-TIKTOK-LOW",
    "SNEM-426-0524-US-INSTA-HIGH",
    "SNEM-427-0524-US-INSTA-MED",
    "SNEM-428-0524-US-INSTA-LOW"
] as const;

export type SNEM0524CollGroupId = typeof SNEM0524CollGroupIds[number];

export const SNEM0524CollWebPlatformIds = [
    "SNEM-0524-AU-SNAP-HIGH",
    "SNEM-0524-AU-SNAP-MED",
    "SNEM-0524-AU-SNAP-LOW",
    "SNEM-0524-AU-YOUTUBE",
    "SNEM-0524-AU-TIKTOK-HIGH",
    "SNEM-0524-AU-TIKTOK-MED",
    "SNEM-0524-AU-TIKTOK-LOW",
    "SNEM-0524-AU-INSTA-HIGH",
    "SNEM-0524-AU-INSTA-MED",
    "SNEM-0524-AU-INSTA-LOW",
    "SNEM-0524-FR-SNAP-HIGH",
    "SNEM-0524-FR-SNAP-MED",
    "SNEM-0524-FR-SNAP-LOW",
    "SNEM-0524-FR-YOUTUBE",
    "SNEM-0524-FR-TIKTOK-HIGH",
    "SNEM-0524-FR-TIKTOK-MED",
    "SNEM-0524-FR-TIKTOK-LOW",
    "SNEM-0524-FR-INSTA-HIGH",
    "SNEM-0524-FR-INSTA-MED",
    "SNEM-0524-FR-INSTA-LOW",
    "SNEM-0524-SA-SNAP-HIGH",
    "SNEM-0524-SA-SNAP-MED",
    "SNEM-0524-SA-SNAP-LOW",
    "SNEM-0524-SA-YOUTUBE",
    "SNEM-0524-SA-TIKTOK-HIGH",
    "SNEM-0524-SA-TIKTOK-MED",
    "SNEM-0524-SA-TIKTOK-LOW",
    "SNEM-0524-SA-INSTA-HIGH",
    "SNEM-0524-SA-INSTA-MED",
    "SNEM-0524-SA-INSTA-LOW",
    "SNEM-0524-UK-SNAP-HIGH",
    "SNEM-0524-UK-SNAP-MED",
    "SNEM-0524-UK-SNAP-LOW",
    "SNEM-0524-UK-YOUTUBE",
    "SNEM-0524-UK-TIKTOK-HIGH",
    "SNEM-0524-UK-TIKTOK-MED",
    "SNEM-0524-UK-TIKTOK-LOW",
    "SNEM-0524-UK-INSTA-HIGH",
    "SNEM-0524-UK-INSTA-MED",
    "SNEM-0524-UK-INSTA-LOW",
    "SNEM-0524-US-SNAP-HIGH",
    "SNEM-0524-US-SNAP-MED",
    "SNEM-0524-US-SNAP-LOW",
    "SNEM-0524-US-YOUTUBE",
    "SNEM-0524-US-TIKTOK-HIGH",
    "SNEM-0524-US-TIKTOK-MED",
    "SNEM-0524-US-TIKTOK-LOW",
    "SNEM-0524-US-INSTA-HIGH",
    "SNEM-0524-US-INSTA-MED",
    "SNEM-0524-US-INSTA-LOW"
] as const;

export type SNEM0524CollWebPlatformId = typeof SNEM0524CollWebPlatformIds[number]

type SNEM0524CollConfig = {
    groupId: SNEM0524CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: SNEM0524CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "SNEM-0524-AU-INSTA-HIGH":
        case "SNEM-0524-AU-INSTA-MED":
        case "SNEM-0524-AU-INSTA-LOW":
        case "SNEM-0524-FR-INSTA-HIGH":
        case "SNEM-0524-FR-INSTA-MED":
        case "SNEM-0524-FR-INSTA-LOW":
        case "SNEM-0524-SA-INSTA-HIGH":
        case "SNEM-0524-SA-INSTA-MED":
        case "SNEM-0524-SA-INSTA-LOW":
        case "SNEM-0524-UK-INSTA-HIGH":
        case "SNEM-0524-UK-INSTA-MED":
        case "SNEM-0524-UK-INSTA-LOW":
        case "SNEM-0524-US-INSTA-HIGH":
        case "SNEM-0524-US-INSTA-MED":
        case "SNEM-0524-US-INSTA-LOW":
            return "7";

        case "SNEM-0524-AU-SNAP-HIGH":
        case "SNEM-0524-AU-SNAP-MED":
        case "SNEM-0524-AU-SNAP-LOW":
        case "SNEM-0524-AU-YOUTUBE":
        case "SNEM-0524-AU-TIKTOK-HIGH":
        case "SNEM-0524-AU-TIKTOK-MED":
        case "SNEM-0524-AU-TIKTOK-LOW":
        case "SNEM-0524-FR-SNAP-HIGH":
        case "SNEM-0524-FR-SNAP-MED":
        case "SNEM-0524-FR-SNAP-LOW":
        case "SNEM-0524-FR-YOUTUBE":
        case "SNEM-0524-FR-TIKTOK-HIGH":
        case "SNEM-0524-FR-TIKTOK-MED":
        case "SNEM-0524-FR-TIKTOK-LOW":
        case "SNEM-0524-SA-SNAP-HIGH":
        case "SNEM-0524-SA-SNAP-MED":
        case "SNEM-0524-SA-SNAP-LOW":
        case "SNEM-0524-SA-YOUTUBE":
        case "SNEM-0524-SA-TIKTOK-HIGH":
        case "SNEM-0524-SA-TIKTOK-MED":
        case "SNEM-0524-SA-TIKTOK-LOW":
        case "SNEM-0524-UK-SNAP-HIGH":
        case "SNEM-0524-UK-SNAP-MED":
        case "SNEM-0524-UK-SNAP-LOW":
        case "SNEM-0524-UK-YOUTUBE":
        case "SNEM-0524-UK-TIKTOK-HIGH":
        case "SNEM-0524-UK-TIKTOK-MED":
        case "SNEM-0524-UK-TIKTOK-LOW":
        case "SNEM-0524-US-SNAP-HIGH":
        case "SNEM-0524-US-SNAP-MED":
        case "SNEM-0524-US-SNAP-LOW":
        case "SNEM-0524-US-YOUTUBE":
        case "SNEM-0524-US-TIKTOK-HIGH":
        case "SNEM-0524-US-TIKTOK-MED":
        case "SNEM-0524-US-TIKTOK-LOW":
            return "10";

        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: SNEM0524CollGroupId): string => {
    switch (groupId) {
        case "SNEM-379-0524-AU-SNAP-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-SNAP-HIGH.json"
        case "SNEM-380-0524-AU-SNAP-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-SNAP-MED.json"
        case "SNEM-381-0524-AU-SNAP-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-SNAP-LOW.json"
        case "SNEM-382-0524-AU-YOUTUBE": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-YOUTUBE.json"
        case "SNEM-383-0524-AU-TIKTOK-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-TIKTOK-HIGH.json"
        case "SNEM-384-0524-AU-TIKTOK-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-TIKTOK-MED.json"
        case "SNEM-385-0524-AU-TIKTOK-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-TIKTOK-LOW.json"
        case "SNEM-386-0524-AU-INSTA-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-INSTA-HIGH.json"
        case "SNEM-387-0524-AU-INSTA-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-INSTA-MED.json"
        case "SNEM-388-0524-AU-INSTA-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-AU-INSTA-LOW.json"
        case "SNEM-389-0524-FR-SNAP-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-SNAP-HIGH.json"
        case "SNEM-390-0524-FR-SNAP-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-SNAP-MED.json"
        case "SNEM-391-0524-FR-SNAP-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-SNAP-LOW.json"
        case "SNEM-392-0524-FR-YOUTUBE": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-YOUTUBE.json"
        case "SNEM-393-0524-FR-TIKTOK-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-TIKTOK-HIGH.json"
        case "SNEM-394-0524-FR-TIKTOK-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-TIKTOK-MED.json"
        case "SNEM-395-0524-FR-TIKTOK-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-TIKTOK-LOW.json"
        case "SNEM-396-0524-FR-INSTA-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-INSTA-HIGH.json"
        case "SNEM-397-0524-FR-INSTA-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-INSTA-MED.json"
        case "SNEM-398-0524-FR-INSTA-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-FR-INSTA-LOW.json"
        case "SNEM-399-0524-SA-SNAP-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-SNAP-HIGH.json"
        case "SNEM-400-0524-SA-SNAP-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-SNAP-MED.json"
        case "SNEM-401-0524-SA-SNAP-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-SNAP-LOW.json"
        case "SNEM-402-0524-SA-YOUTUBE": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-YOUTUBE.json"
        case "SNEM-403-0524-SA-TIKTOK-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-TIKTOK-HIGH.json"
        case "SNEM-404-0524-SA-TIKTOK-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-TIKTOK-MED.json"
        case "SNEM-405-0524-SA-TIKTOK-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-TIKTOK-LOW.json"
        case "SNEM-406-0524-SA-INSTA-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-INSTA-HIGH.json"
        case "SNEM-407-0524-SA-INSTA-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-INSTA-MED.json"
        case "SNEM-408-0524-SA-INSTA-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-SA-INSTA-LOW.json"
        case "SNEM-409-0524-UK-SNAP-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-SNAP-HIGH.json"
        case "SNEM-410-0524-UK-SNAP-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-SNAP-MED.json"
        case "SNEM-411-0524-UK-SNAP-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-SNAP-LOW.json"
        case "SNEM-412-0524-UK-YOUTUBE": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-YOUTUBE.json"
        case "SNEM-413-0524-UK-TIKTOK-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-TIKTOK-HIGH.json"
        case "SNEM-414-0524-UK-TIKTOK-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-TIKTOK-MED.json"
        case "SNEM-415-0524-UK-TIKTOK-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-TIKTOK-LOW.json"
        case "SNEM-416-0524-UK-INSTA-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-INSTA-HIGH.json"
        case "SNEM-417-0524-UK-INSTA-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-INSTA-MED.json"
        case "SNEM-418-0524-UK-INSTA-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-UK-INSTA-LOW.json"
        case "SNEM-419-0524-US-SNAP-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-SNAP-HIGH.json"
        case "SNEM-420-0524-US-SNAP-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-SNAP-MED.json"
        case "SNEM-421-0524-US-SNAP-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-SNAP-LOW.json"
        case "SNEM-422-0524-US-YOUTUBE": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-YOUTUBE.json"
        case "SNEM-423-0524-US-TIKTOK-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-TIKTOK-HIGH.json"
        case "SNEM-424-0524-US-TIKTOK-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-TIKTOK-MED.json"
        case "SNEM-425-0524-US-TIKTOK-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-TIKTOK-LOW.json"
        case "SNEM-426-0524-US-INSTA-HIGH": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-INSTA-HIGH.json"
        case "SNEM-427-0524-US-INSTA-MED": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-INSTA-MED.json"
        case "SNEM-428-0524-US-INSTA-LOW": 
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SNEM-0524/SNEM-0524-US-INSTA-LOW.json"
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: SNEM0524CollGroupId): SNEM0524CollWebPlatformId => {
    switch (groupId) {
        case "SNEM-379-0524-AU-SNAP-HIGH": return "SNEM-0524-AU-SNAP-HIGH"
        case "SNEM-380-0524-AU-SNAP-MED": return "SNEM-0524-AU-SNAP-MED"
        case "SNEM-381-0524-AU-SNAP-LOW": return "SNEM-0524-AU-SNAP-LOW"
        case "SNEM-382-0524-AU-YOUTUBE": return "SNEM-0524-AU-YOUTUBE"
        case "SNEM-383-0524-AU-TIKTOK-HIGH": return "SNEM-0524-AU-TIKTOK-HIGH"
        case "SNEM-384-0524-AU-TIKTOK-MED": return "SNEM-0524-AU-TIKTOK-MED"
        case "SNEM-385-0524-AU-TIKTOK-LOW": return "SNEM-0524-AU-TIKTOK-LOW"
        case "SNEM-386-0524-AU-INSTA-HIGH": return "SNEM-0524-AU-INSTA-HIGH"
        case "SNEM-387-0524-AU-INSTA-MED": return "SNEM-0524-AU-INSTA-MED"
        case "SNEM-388-0524-AU-INSTA-LOW": return "SNEM-0524-AU-INSTA-LOW"
        case "SNEM-389-0524-FR-SNAP-HIGH": return "SNEM-0524-FR-SNAP-HIGH"
        case "SNEM-390-0524-FR-SNAP-MED": return "SNEM-0524-FR-SNAP-MED"
        case "SNEM-391-0524-FR-SNAP-LOW": return "SNEM-0524-FR-SNAP-LOW"
        case "SNEM-392-0524-FR-YOUTUBE": return "SNEM-0524-FR-YOUTUBE"
        case "SNEM-393-0524-FR-TIKTOK-HIGH": return "SNEM-0524-FR-TIKTOK-HIGH"
        case "SNEM-394-0524-FR-TIKTOK-MED": return "SNEM-0524-FR-TIKTOK-MED"
        case "SNEM-395-0524-FR-TIKTOK-LOW": return "SNEM-0524-FR-TIKTOK-LOW"
        case "SNEM-396-0524-FR-INSTA-HIGH": return "SNEM-0524-FR-INSTA-HIGH"
        case "SNEM-397-0524-FR-INSTA-MED": return "SNEM-0524-FR-INSTA-MED"
        case "SNEM-398-0524-FR-INSTA-LOW": return "SNEM-0524-FR-INSTA-LOW"
        case "SNEM-399-0524-SA-SNAP-HIGH": return "SNEM-0524-SA-SNAP-HIGH"
        case "SNEM-400-0524-SA-SNAP-MED": return "SNEM-0524-SA-SNAP-MED"
        case "SNEM-401-0524-SA-SNAP-LOW": return "SNEM-0524-SA-SNAP-LOW"
        case "SNEM-402-0524-SA-YOUTUBE": return "SNEM-0524-SA-YOUTUBE"
        case "SNEM-403-0524-SA-TIKTOK-HIGH": return "SNEM-0524-SA-TIKTOK-HIGH"
        case "SNEM-404-0524-SA-TIKTOK-MED": return "SNEM-0524-SA-TIKTOK-MED"
        case "SNEM-405-0524-SA-TIKTOK-LOW": return "SNEM-0524-SA-TIKTOK-LOW"
        case "SNEM-406-0524-SA-INSTA-HIGH": return "SNEM-0524-SA-INSTA-HIGH"
        case "SNEM-407-0524-SA-INSTA-MED": return "SNEM-0524-SA-INSTA-MED"
        case "SNEM-408-0524-SA-INSTA-LOW": return "SNEM-0524-SA-INSTA-LOW"
        case "SNEM-409-0524-UK-SNAP-HIGH": return "SNEM-0524-UK-SNAP-HIGH"
        case "SNEM-410-0524-UK-SNAP-MED": return "SNEM-0524-UK-SNAP-MED"
        case "SNEM-411-0524-UK-SNAP-LOW": return "SNEM-0524-UK-SNAP-LOW"
        case "SNEM-412-0524-UK-YOUTUBE": return "SNEM-0524-UK-YOUTUBE"
        case "SNEM-413-0524-UK-TIKTOK-HIGH": return "SNEM-0524-UK-TIKTOK-HIGH"
        case "SNEM-414-0524-UK-TIKTOK-MED": return "SNEM-0524-UK-TIKTOK-MED"
        case "SNEM-415-0524-UK-TIKTOK-LOW": return "SNEM-0524-UK-TIKTOK-LOW"
        case "SNEM-416-0524-UK-INSTA-HIGH": return "SNEM-0524-UK-INSTA-HIGH"
        case "SNEM-417-0524-UK-INSTA-MED": return "SNEM-0524-UK-INSTA-MED"
        case "SNEM-418-0524-UK-INSTA-LOW": return "SNEM-0524-UK-INSTA-LOW"
        case "SNEM-419-0524-US-SNAP-HIGH": return "SNEM-0524-US-SNAP-HIGH"
        case "SNEM-420-0524-US-SNAP-MED": return "SNEM-0524-US-SNAP-MED"
        case "SNEM-421-0524-US-SNAP-LOW": return "SNEM-0524-US-SNAP-LOW"
        case "SNEM-422-0524-US-YOUTUBE": return "SNEM-0524-US-YOUTUBE"
        case "SNEM-423-0524-US-TIKTOK-HIGH": return "SNEM-0524-US-TIKTOK-HIGH"
        case "SNEM-424-0524-US-TIKTOK-MED": return "SNEM-0524-US-TIKTOK-MED"
        case "SNEM-425-0524-US-TIKTOK-LOW": return "SNEM-0524-US-TIKTOK-LOW"
        case "SNEM-426-0524-US-INSTA-HIGH": return "SNEM-0524-US-INSTA-HIGH"
        case "SNEM-427-0524-US-INSTA-MED": return "SNEM-0524-US-INSTA-MED"
        case "SNEM-428-0524-US-INSTA-LOW": return "SNEM-0524-US-INSTA-LOW"
        default: assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: SNEM0524CollGroupId): WebPlatform => {
    switch (groupId) {
        case "SNEM-379-0524-AU-SNAP-HIGH":
        case "SNEM-380-0524-AU-SNAP-MED":
        case "SNEM-381-0524-AU-SNAP-LOW":
        case "SNEM-389-0524-FR-SNAP-HIGH":
        case "SNEM-390-0524-FR-SNAP-MED":
        case "SNEM-391-0524-FR-SNAP-LOW":
        case "SNEM-399-0524-SA-SNAP-HIGH":
        case "SNEM-400-0524-SA-SNAP-MED":
        case "SNEM-401-0524-SA-SNAP-LOW":
        case "SNEM-409-0524-UK-SNAP-HIGH":
        case "SNEM-410-0524-UK-SNAP-MED":
        case "SNEM-411-0524-UK-SNAP-LOW":
        case "SNEM-419-0524-US-SNAP-HIGH":
        case "SNEM-420-0524-US-SNAP-MED":
        case "SNEM-421-0524-US-SNAP-LOW":
            return "Snapchat";

        case "SNEM-383-0524-AU-TIKTOK-HIGH":
        case "SNEM-384-0524-AU-TIKTOK-MED":
        case "SNEM-385-0524-AU-TIKTOK-LOW":
        case "SNEM-393-0524-FR-TIKTOK-HIGH":
        case "SNEM-394-0524-FR-TIKTOK-MED":
        case "SNEM-395-0524-FR-TIKTOK-LOW":
        case "SNEM-403-0524-SA-TIKTOK-HIGH":
        case "SNEM-404-0524-SA-TIKTOK-MED":
        case "SNEM-405-0524-SA-TIKTOK-LOW":
        case "SNEM-413-0524-UK-TIKTOK-HIGH":
        case "SNEM-414-0524-UK-TIKTOK-MED":
        case "SNEM-415-0524-UK-TIKTOK-LOW":
        case "SNEM-423-0524-US-TIKTOK-HIGH":
        case "SNEM-424-0524-US-TIKTOK-MED":
        case "SNEM-425-0524-US-TIKTOK-LOW":
            return "TikTok";

        case "SNEM-382-0524-AU-YOUTUBE":
        case "SNEM-392-0524-FR-YOUTUBE":
        case "SNEM-402-0524-SA-YOUTUBE":
        case "SNEM-412-0524-UK-YOUTUBE":
        case "SNEM-422-0524-US-YOUTUBE":
            return "Youtube";

        case "SNEM-386-0524-AU-INSTA-HIGH":
        case "SNEM-387-0524-AU-INSTA-MED":
        case "SNEM-388-0524-AU-INSTA-LOW":
        case "SNEM-396-0524-FR-INSTA-HIGH":
        case "SNEM-397-0524-FR-INSTA-MED":
        case "SNEM-398-0524-FR-INSTA-LOW":
        case "SNEM-406-0524-SA-INSTA-HIGH":
        case "SNEM-407-0524-SA-INSTA-MED":
        case "SNEM-408-0524-SA-INSTA-LOW":
        case "SNEM-416-0524-UK-INSTA-HIGH":
        case "SNEM-417-0524-UK-INSTA-MED":
        case "SNEM-418-0524-UK-INSTA-LOW":
        case "SNEM-426-0524-US-INSTA-HIGH":
        case "SNEM-427-0524-US-INSTA-MED":
        case "SNEM-428-0524-US-INSTA-LOW":
            return "Instagram";

        default:
            assertNever(groupId);
    }
}

export const addSNEM0524CollConfig = (
    config: SNEM0524CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)

    let webplatformId = getWebPlatformId(config.groupId);
    const webplatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: getPlatformForGroup(config.groupId),
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    const getSurveyUrl = (groupId: SNEM0524CollGroupId): string => {
        switch (groupId) {
            case "SNEM-379-0524-AU-SNAP-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_8qXkPiauPUEYes6"
            case "SNEM-380-0524-AU-SNAP-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_8qXkPiauPUEYes6"
            case "SNEM-381-0524-AU-SNAP-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_8qXkPiauPUEYes6"
            case "SNEM-382-0524-AU-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_eUTb4mRjPI4HXbE"
            case "SNEM-383-0524-AU-TIKTOK-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_9BvRQDRbLNvb5Yi"
            case "SNEM-384-0524-AU-TIKTOK-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_9BvRQDRbLNvb5Yi"
            case "SNEM-385-0524-AU-TIKTOK-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_9BvRQDRbLNvb5Yi"
            case "SNEM-386-0524-AU-INSTA-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_9M5oxSpgg6gZdEG"
            case "SNEM-387-0524-AU-INSTA-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_9M5oxSpgg6gZdEG"
            case "SNEM-388-0524-AU-INSTA-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_9M5oxSpgg6gZdEG"
            case "SNEM-389-0524-FR-SNAP-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_b9IazWnrCLGTO2q"
            case "SNEM-390-0524-FR-SNAP-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_b9IazWnrCLGTO2q"
            case "SNEM-391-0524-FR-SNAP-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_b9IazWnrCLGTO2q"
            case "SNEM-392-0524-FR-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_bdbInIVMji0wCX4"
            case "SNEM-393-0524-FR-TIKTOK-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_eUTntu2TYQ9JXhQ"
            case "SNEM-394-0524-FR-TIKTOK-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_eUTntu2TYQ9JXhQ"
            case "SNEM-395-0524-FR-TIKTOK-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_eUTntu2TYQ9JXhQ"
            case "SNEM-396-0524-FR-INSTA-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_eVPPPeWpOGTBYii"
            case "SNEM-397-0524-FR-INSTA-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_eVPPPeWpOGTBYii"
            case "SNEM-398-0524-FR-INSTA-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_eVPPPeWpOGTBYii"
            case "SNEM-399-0524-SA-SNAP-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_8IdmAcR5JcTGATA"
            case "SNEM-400-0524-SA-SNAP-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_8IdmAcR5JcTGATA"
            case "SNEM-401-0524-SA-SNAP-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_8IdmAcR5JcTGATA"
            case "SNEM-402-0524-SA-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_a2A6J5ZrAMou8xU"
            case "SNEM-403-0524-SA-TIKTOK-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_aW8jkCy7Ajsvrtc"
            case "SNEM-404-0524-SA-TIKTOK-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_aW8jkCy7Ajsvrtc"
            case "SNEM-405-0524-SA-TIKTOK-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_aW8jkCy7Ajsvrtc"
            case "SNEM-406-0524-SA-INSTA-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_3IgmHo9imwd8Zme"
            case "SNEM-407-0524-SA-INSTA-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_3IgmHo9imwd8Zme"
            case "SNEM-408-0524-SA-INSTA-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_3IgmHo9imwd8Zme"
            case "SNEM-409-0524-UK-SNAP-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_1LVz77ipkqHQHqu"
            case "SNEM-410-0524-UK-SNAP-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_1LVz77ipkqHQHqu"
            case "SNEM-411-0524-UK-SNAP-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_1LVz77ipkqHQHqu"
            case "SNEM-412-0524-UK-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_cUP1kdGcPN1hXGC"
            case "SNEM-413-0524-UK-TIKTOK-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_1TGSTWcjK1RYh3o"
            case "SNEM-414-0524-UK-TIKTOK-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_1TGSTWcjK1RYh3o"
            case "SNEM-415-0524-UK-TIKTOK-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_1TGSTWcjK1RYh3o"
            case "SNEM-416-0524-UK-INSTA-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_8eQDxKXAtyAVMtE"
            case "SNEM-417-0524-UK-INSTA-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_8eQDxKXAtyAVMtE"
            case "SNEM-418-0524-UK-INSTA-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_8eQDxKXAtyAVMtE"
            case "SNEM-419-0524-US-SNAP-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_1NUPFE8BdsA5ELc"
            case "SNEM-420-0524-US-SNAP-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_1NUPFE8BdsA5ELc"
            case "SNEM-421-0524-US-SNAP-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_1NUPFE8BdsA5ELc"
            case "SNEM-422-0524-US-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_6R3cFAIpNgQLqPs"
            case "SNEM-423-0524-US-TIKTOK-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_cviU4rFv04gOsHs"
            case "SNEM-424-0524-US-TIKTOK-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_cviU4rFv04gOsHs"
            case "SNEM-425-0524-US-TIKTOK-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_cviU4rFv04gOsHs"
            case "SNEM-426-0524-US-INSTA-HIGH": return "https://survey.au1.qualtrics.com/jfe/form/SV_4SDx9vQ1yVJDRpc"
            case "SNEM-427-0524-US-INSTA-MED": return "https://survey.au1.qualtrics.com/jfe/form/SV_4SDx9vQ1yVJDRpc"
            case "SNEM-428-0524-US-INSTA-LOW": return "https://survey.au1.qualtrics.com/jfe/form/SV_4SDx9vQ1yVJDRpc"

            default:
                assertNever(groupId);
        }
    }

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder
        .addTask(webplatformTask)
        .addTask(surveyTask)
        .addTask(uploadsTask);

    return builder;
}