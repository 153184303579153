import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateWebRequestTask } from "../tasks";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const GYGG0623CollGroupIds = [
    "GYGG-282-0623-NY-FACEBOOK",
    "GYGG-283-0623-LA-FACEBOOK",
    "GYGG-284-0623-NY-INSTAGRAM",
    "GYGG-285-0623-LA-INSTAGRAM",
    "GYGG-286-0623-NY-YOUTUBE",
    "GYGG-287-0623-LA-YOUTUBE"
] as const;

export type GYGG0623CollGroupId = typeof GYGG0623CollGroupIds[number];

export const GYGG0623CollWebPlatformIds = [
    "GYGG-0623-NY-FACEBOOK",
    "GYGG-0623-LA-FACEBOOK",
    "GYGG-0623-NY-INSTAGRAM",
    "GYGG-0623-LA-INSTAGRAM",
    "GYGG-0623-NY-YOUTUBE",
    "GYGG-0623-LA-YOUTUBE"
] as const;

export type GYGG0623CollWebPlatformId = typeof GYGG0623CollWebPlatformIds[number]

type GYGG0623CollConfig = {
    groupId: GYGG0623CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: GYGG0623CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "GYGG-0623-NY-FACEBOOK":
        case "GYGG-0623-LA-FACEBOOK":
        case "GYGG-0623-NY-INSTAGRAM":
        case "GYGG-0623-LA-INSTAGRAM":
        case "GYGG-0623-NY-YOUTUBE":
        case "GYGG-0623-LA-YOUTUBE":
            return "10";

        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: GYGG0623CollGroupId): string => {
    switch (groupId) {
        case "GYGG-282-0623-NY-FACEBOOK":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0623/GYGG-0623-NY-FACEBOOK.json"
        case "GYGG-283-0623-LA-FACEBOOK":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0623/GYGG-0623-LA-FACEBOOK.json"
        case "GYGG-284-0623-NY-INSTAGRAM":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0623/GYGG-0623-NY-INSTAGRAM.json"
        case "GYGG-285-0623-LA-INSTAGRAM":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0623/GYGG-0623-LA-INSTAGRAM.json"
        case "GYGG-286-0623-NY-YOUTUBE":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0623/GYGG-0623-NY-YOUTUBE.json"
        case "GYGG-287-0623-LA-YOUTUBE":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0623/GYGG-0623-LA-YOUTUBE.json"
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: GYGG0623CollGroupId): GYGG0623CollWebPlatformId => {
    switch (groupId) {
        case "GYGG-282-0623-NY-FACEBOOK": return "GYGG-0623-NY-FACEBOOK";
        case "GYGG-283-0623-LA-FACEBOOK": return "GYGG-0623-LA-FACEBOOK";
        case "GYGG-284-0623-NY-INSTAGRAM": return "GYGG-0623-NY-INSTAGRAM";
        case "GYGG-285-0623-LA-INSTAGRAM": return "GYGG-0623-LA-INSTAGRAM";
        case "GYGG-286-0623-NY-YOUTUBE": return "GYGG-0623-NY-YOUTUBE";
        case "GYGG-287-0623-LA-YOUTUBE": return "GYGG-0623-LA-YOUTUBE";
        default:
            assertNever(groupId);
    }
}

export const addGYGG0623CollConfig = (
    config: GYGG0623CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE);

    let webplatformId = getWebPlatformId(config.groupId);
    const webplatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: "General",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/GYGG-0623/${config.groupId}_INCENTIVE.json`,
        params: {},
        taskID: config.groupId + '-WEBREQUEST',
    })

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder
        .addTask(webplatformTask)
        .addTask(webRequestTask)
        .addTask(uploadsTask);

    return builder;
}