import React, { useEffect } from "react";
import { Typography } from "@mui/material";

import { QueryParams } from "../../../queryParams";
import { isPreview, isMturkParams } from "../../../MTurk/mturkParams";
import { ConfigurationError } from "../../../Errors";

interface MturkSurveyRedirectProps {
    params: QueryParams;
}

const MturkSurveyRedirect: React.FC<MturkSurveyRedirectProps> = (
    props: MturkSurveyRedirectProps
) => {
    const params = props.params;
    if (!isMturkParams(params)) {
        throw new ConfigurationError(
            "MturkSurveyRedirect step rendered without mTurk props"
        );
    }

    if (isPreview(params)) {
        throw new ConfigurationError(
            "MturkSurveyRedirect step rendered in preview mode"
        );
    }

    const redirectUrl =
        "https://survey.au1.qualtrics.com/jfe/form/SV_6AaVv56LbvpA5OS" +
        `?G=${props.params.G}` +
        `&assignmentId=${params.assignmentId}` +
        `&hitId=${params.hitId}` +
        `&turkSubmitTo=${params.turkSubmitTo}` +
        `&workerId=${params.workerId}`;

    useEffect(() => {
        window.location.href = redirectUrl;
    });

    return (
        <>
            <Typography variant="h6" align="center">
                Hold tight, you are being redirected.
            </Typography>
            <Typography variant="body1" align="center">
                <a href={redirectUrl}>Click here</a> if you are not
                automatically redirected.
            </Typography>
        </>
    );
};

export default MturkSurveyRedirect;
