import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { ConfigKey } from "../../configKey";

export const AREMEDIA_GROUP_IDS = [
    'AREM-15-MC',
    'AREM-16-HTL',
    'AREM-17-NTL',
] as const

export type AREMediaGroupId = (typeof AREMEDIA_GROUP_IDS)[number]

export function isAREMediaGroupParams(params: CAIParams<ConfigKey>): params is CAIParams<AREMediaGroupId> {
    return (AREMEDIA_GROUP_IDS as ReadonlyArray<string>).includes(params.G)
}

const getPlatformForGroupId = (groupId: AREMediaGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case 'AREM-15-MC': return 'MarieClaire'
        case 'AREM-16-HTL': return 'HomesToLove'
        case 'AREM-17-NTL': return 'NowToLove'
    }
}

export const configureAreMediaPage = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<AREMediaGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}