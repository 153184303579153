import React from 'react'

import { Box, Grid, List, ListItem, Typography } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
    listGrid: {
        marginBottom: theme.spacing(0.5),
    },
    list: {
        paddingTop: 0
    },
    listItem: {
        paddingTop: '2px',
        paddingBottom: '2px'
    }
}))

const getListItem = (
    text: String,
    classes: ReturnType<typeof useStyles>['classes']
) => <ListItem className={classes.listItem}><Typography variant="body2">&#8226; {text}</Typography></ListItem>

const MturkGazeRequirements: React.FC = () => {
    const { classes } = useStyles()

    return <>
        <Box className={classes.listGrid}>
            <Typography variant="h6">This HIT:</Typography>
            <Grid container justifyContent="center">
                <List dense={true} className={classes.list}>
                    {getListItem("Requires an iPhone running iOS 12 or newer", classes)}
                    {getListItem("Requires you to download our attentionTRACE app onto your device", classes)}
                    {getListItem("Requires access to your devices Front Facing camera to record eye movement", classes)}
                    {getListItem("May only be completed once", classes)}
                </List>
            </Grid>
        </Box>
        <Box className={classes.listGrid}>
            <Typography variant="h6">The attentionTRACE App:</Typography>
            <Grid container justifyContent="center" >
                <List dense={true} className={classes.list}>
                    {getListItem("Collects no information which could be used identify you besides the video from the front facing camera", classes)}
                    {getListItem("Can be uninstalled after completion of the HIT", classes)}
                    {getListItem("Has no lasting effect on your device", classes)}
                    {getListItem("May only be completed once", classes)}
                </List>
            </Grid>
        </Box>
        <Box className={classes.listGrid}>
            <Typography variant="h6">Before you accept this HIT:</Typography>
            <Grid container justifyContent="center" >
                <List dense={true} className={classes.list}>
                    {getListItem("Ensure you can download the attentionTRACE app using the instructions on the following page", classes)}
                    {getListItem("Ensure that you have the most recent app update the app through the App Store to the latest version if you have completed a previous HIT for us using the App", classes)}
                </List>
            </Grid>
        </Box>
    </>
}

export default MturkGazeRequirements