import { createStandardAction, ActionType } from 'typesafe-actions'
import { LandingPageConfig } from './reducer'

// Actions
const SET_LOCALE = 'landingPage/SET_LOCALE'
const SETUP_SUCCESS = 'landingPage/SETUP_SUCCESS'
const SETUP_FAILED = 'landingPage/SETUP_FAILED'

export const setLocale = createStandardAction(SET_LOCALE)<boolean>()
export const setupSuccess = createStandardAction(SETUP_SUCCESS)<LandingPageConfig>()
export const setupFailed = createStandardAction(SETUP_FAILED)<void>()

const actions = {
    setLocale,
    setupSuccess,
    setupFailed
}
export type LandingPageAction = ActionType<typeof actions>
