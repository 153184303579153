import React from "react";
import { Typography, Link } from "@mui/material";
import { LandingPageSupportEmail } from "../reducer";
import { getCurrentLangStrings } from "../../i18n";
import { useStyles } from "./styles";

type Props = {
    supportEmail: LandingPageSupportEmail;
};

const SupportEmailLink: React.FC<Props> = ({ supportEmail }) => {
    const { classes } = useStyles();
    const lang = getCurrentLangStrings();

    const isGeneralEnquiry = supportEmail === LandingPageSupportEmail.help;

    let loginIdString = "Login ID";
    if (supportEmail === LandingPageSupportEmail.mturkDesktop) {
        loginIdString = "Worker ID";
    }
    if (supportEmail === LandingPageSupportEmail.mturk) {
        loginIdString = "Login ID & Worker ID";
    }

    const splitDescription = lang.StepsFooter.description.split("{contactUs}");

    const beforeContactUs = splitDescription[0];
    const afterContactUs = splitDescription[1].replace('{loginIdString}', loginIdString);
    return (
        <div className={classes.grid}>
            <Typography gutterBottom>
                {beforeContactUs}
                <Link href={isGeneralEnquiry ? supportEmail : `mailto:${supportEmail}`} target="_blank">
                    {lang.StepsFooter.contactUs}
                </Link>
                {afterContactUs}
                <br />
            </Typography>
        </div>
    )
};

export default SupportEmailLink;
