export enum InterfaceOrientation {
    TOP = "top",
    RIGHT = "right",
    LEFT = "left",
    BOTTOM = "bottom"
}

export const All: readonly InterfaceOrientation[] = [
    InterfaceOrientation.TOP,
    InterfaceOrientation.BOTTOM,

    InterfaceOrientation.LEFT,
    InterfaceOrientation.RIGHT
] as const

export const AllButUpsideDown: readonly InterfaceOrientation[] = [
    InterfaceOrientation.TOP,
    InterfaceOrientation.LEFT,
    InterfaceOrientation.RIGHT
] as const

export const Landscape: readonly InterfaceOrientation[] = [
    InterfaceOrientation.LEFT,
    InterfaceOrientation.RIGHT
] as const