import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateExternalSurveyTask, generateUploadsTask } from "../tasks";
import { WebPlatform } from "../types";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const SPAU0324CollGroupIds = [
    "SPAU-369-0324-SPOTIFY",
] as const

export type SPAU0324CollGroupId = typeof SPAU0324CollGroupIds[number]

type SPAU0324CollConfig = {
    groupId: SPAU0324CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (groupId: SPAU0324CollGroupId): string => {
    switch (groupId) {
        case "SPAU-369-0324-SPOTIFY":
            return "10";
        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: SPAU0324CollGroupId, taskIndex: 0 | 1): WebPlatform => {
    switch (groupId) {
        case "SPAU-369-0324-SPOTIFY": 
            return "Spotify"
        
        default:
            assertNever(groupId);
    }
}

const getWebPlatformConfigUrl = (groupId: SPAU0324CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "SPAU-369-0324-SPOTIFY": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/SPAU-0324/SPAU-0324-SPOTIFY.json"
        
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: SPAU0324CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "SPAU-369-0324-SPOTIFY": return "SPAU-0324-SPOTIFY"

        default: assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: SPAU0324CollGroupId): string => {
    switch (groupId) {
        case "SPAU-369-0324-SPOTIFY": return "https://survey.au1.qualtrics.com/jfe/form/SV_cHke6oxXDmNnmSy";
        
        default:
            assertNever(groupId);
    }
}

export const addSPAU0324CollConfig = (
    config: SPAU0324CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder.setFlag('deviceLock', 'mobile')
    .setFlag('requireMinimumBattery', 0.3)
    .setFlag('preventTaskProgress', false)
    .setFlag('finishMessageOverride', FINISH_MESSAGE);

    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId, 0),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: getPlatformForGroup(config.groupId, 0),
        taskLengthMinutes: getTaskLengthMinutes(config.groupId),
        langStrings,
    })
    builder.addTask(webPlatformTask)

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: [...config.metaFieldNames, "webPlatform_playListID"],
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    builder.addTask(surveyTask)

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder.addTask(uploadsTask)

    return builder
}