import { AMT_SANDBOX_SUBMIT_URL, AMT_SUBMIT_URL } from './constants'
import { isPreview, isSandBox, isMturkParams } from './mturkParams'
import { QueryParams } from '../queryParams'
import { ConfigurationError } from '../Errors'

export const submitHIT = (
    params: QueryParams,
    loginId: string,
    completionCode?: string
) => {
    if (!isMturkParams(params)) {
        throw new ConfigurationError('Cannot submit to mTurk without mTurk params')
    }

    // Never submit when in preview mode, ideally the submit button should be disabled. But always nice to be safe
    if (isPreview(params)) {
        console.log('Running in Preview Mode canceled HIT Submission')
        return
    }

    const { assignmentId } = params
    const sandbox = isSandBox(params)

    // Create form & submit
    const form = document.createElement('form')
    form.method = 'POST'
    if (sandbox) {
        form.action = AMT_SANDBOX_SUBMIT_URL
    } else {
        form.action = AMT_SUBMIT_URL
    }

    const assignmentIdInput = document.createElement('input')
    assignmentIdInput.name = 'assignmentId'
    assignmentIdInput.type = 'hidden'
    assignmentIdInput.value = assignmentId
    form.appendChild(assignmentIdInput)

    // NB: At least one "data" field is required otherwise mTurk fails to process the submission!
    const loginIdInput = document.createElement('input')
    loginIdInput.name = 'loginId'
    loginIdInput.type = 'hidden'
    loginIdInput.value = loginId
    form.appendChild(loginIdInput)

    if (completionCode) {
        const completionCodeInput = document.createElement('input')
        completionCodeInput.name = 'completionCode'
        completionCodeInput.type = 'hidden'
        completionCodeInput.value = completionCode
        form.appendChild(completionCodeInput)
    }

    document.body.appendChild(form)
    form.submit()
}
