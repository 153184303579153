import { LangStrings } from "./LangStrings";

const EnLangStrings: LangStrings = {
    Common: {
        survey: "Survey",
        back: "Back",
        next: "Next",
        loading: "Loading",
        go: "Let's go"
    },
    Survey: {
        description: "A 5 minute survey on your shopping preferences",
    },
    WebPlatform: {
        facebook: (minutes: string) => `Scroll Facebook for ${minutes} minutes`,
        youtube: (minutes: string) => `Scroll YouTube for ${minutes} minutes`,
        instagram: (minutes: string) =>
            `Scroll Instagram for ${minutes} minutes`,
        tiktok: (minutes: string) => `Scroll TikTok for ${minutes} minutes`,
        snapar: () => 'Explore the provided lenses simply by tapping on each one. You can play games, try on accessories and take pictures with characters. We’ll let you know when the session is up.',
    },
    LandingPage: {
        Title: "Let's get you set up to earn!",
    },
    Decay: {
        Surveys: {
            Initial: "Shopping Survey",
            TwoWeek: "2 Week Shopping Survey",
            FourWeek: "4 Week Shopping Survey",
        },
    },
    BvodInApp: {
        title: "TV Show Viewing",
        description: "Watch a TV show on your phone",
    },
    Steps: {
        StepLabel: {
            Instructions: "Welcome",
            ViewingInstructionsDeskSolution: "Instructions",
            Requirements: "Requirements",
            AppDownload: "App Download",
            AppDownloadDeskSolution: "Chrome Extension Set Up",
            LoginAndCompletionCode: "Login",
            MturkGazeDesktopCompletion: "Start Task",
            LoginAndCompletionDeskSolution: "Login",
            SessionInformationDeskSolution: "Session Information",
            Setup: "Setup",
            StartEarning: "Start Earning"
        },
        ViewingInstructions: {
            title: "How exciting, you're ready to start your first Blinq adventure.",
            subtitle:
                `Follow these simple steps and you will be ready to earn rewards in a matter of minutes.<br/><br/>
                First things first, it's time to download our app and be provided with a unique login ID.<br/><br/>
                <b>Reminder:</b> This is a mobile-only study, so make sure you have your phone handy to complete this setup and participate.<br/><br/>
                Click Let's Go to continue.`,
        },
        ViewingInstructionsDeskSolution: {
            title: "Thank you for participating in our study. Below are the steps you will need to complete to receive your reward. ",
            subtitle:
                `
                1. Download Chrome extension 
                2. Copy login ID 
                3. Read Twitch Collection Information
                4. Login to the extension and complete your 10 minute viewing session`,
        },
        AppDownload: {
            subtitle:
                'In the Apple App Store, search for "attentionTRACE Research"',
            or: "OR",
            downloadLink: "Click here to download",
        },
        AppDownloadDeskSolution: {
            subtitle:
                ` 
                1. Click the "Add to Chrome" button.

                2. Click "Add extension" in the pop-up message.

                3. Wait a few seconds for the extension to install.

                4. Look for the notification in the top right corner of the browser that says "Extension added to Chrome" to confirm installation.

                5. Once you have installed the attentionTRACE Chrome extension, you can select the jigsaw puzzle icon located in your Chrome toolbox and pin the extension to the toolbar.`,
            downloadLink: "Download",
        },
        LoginAndCompletionCode: {
            subtitle: "Use this code to login to attentionTRACE:",
            platformInstructions: {
                facebookNewsfeed:
                    "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will be presented with the social media platform of your choice and asked to log in to your personal account.\nPlease browse this platform as you normally would (watch videos, comment and interact with posts etc) until the timer is up.\n",
                facebookWatch:
                    "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will be presented with the social media platform of your choice and asked to log in to your personal account.\nPlease browse this platform as you normally would (watch videos, comment and interact with posts etc) until the timer is up.\n",
                facebookStories:
                    "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will be presented with the social media platform of your choice and asked to log in to your personal account.\nPlease browse this platform as you normally would (watch videos, comment and interact with posts etc) until the timer is up.\n",
                instagram:
                    `Once you’ve downloaded the app, it’s time to enter your Login ID.\nOnce you’re logged in, we will load your social media app and ask you to login to your personal account.\nTo earn, you’ll need to follow the instructions, scroll content, watch videos, like posts, have fun and wait for the timer to end 😎.`,
                instagramNewsfeed:
                    "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will be presented with the social media platform of your choice and asked to log in to your personal account.\nPlease browse this platform as you normally would (watch videos, comment and interact with posts etc) until the timer is up.\n",
                youtube:
                    `Once you’ve downloaded the app, it’s time to enter your Login ID.\nOnce you’re logged in, we will load your social media app and ask you to login to your personal account.\nTo earn, you’ll need to follow the instructions, scroll content, watch videos, like posts, have fun and wait for the timer to end 😎.`,
                twitter:
                    "You will need your Twitter account login details ready to enter. Once you are in, use Twitter as you normally would. Scroll the feed & see what's trending until the app notifies you that time is up",
                tiktok: 
                    `Once you’re logged in, we will load your social media app and ask you to login to your personal account.\nTo earn, you’ll need to follow the instructions, scroll content, watch videos, like posts, have fun and wait for the timer to end 😎.`,
                webplatformSynergy:
                    `To snag your reward, you’ll need to browse two social media platforms: Facebook, Instagram, or YouTube. You’ll need to log in to Facebook and Instagram, but not YouTube.\n\nOur app will load the first platform for you (remember which ones need a login). So have fun with it by scrolling, watching videos, liking posts, until the timer runs out.\n\nOnce that session ends, the next social app will load. Start exploring again until the timer finishes.\n\nWhich means in less than 20 minutes, you'll unlock your rewards which will be processed within the next 10 days. How cool is that?!\n\nSo, what are you waiting for? It’s time to dive in and earn those rewards!`,
                pinterest:
                    "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will be presented with the Pinterest platform and asked to log in to your personal account.\nFreely browse and explore Pinterest by following the prompts (check out the explore page, navigate suggested categories and interact with content) until the timer is up.\nYou will be redirected to a survey, simply complete this and you are done!",
                snapar:
                    "Please login to the attentionTRACE app using the unique login code above.\nOnce you have logged in, you will presented with a collection of Snapchat AR lenses. \nPlease tap on each lens to utilise it and interact with the lens as you normally would until the timer is up.",
                snapchat:
                    `After you’ve logged in, we will load Snapchat and all you'll need to do is jump onto the Discover page and check out all of the funny, crazy, sad and awe inspiring content you can handle until the timer runs out.\nIt couldn't be easier to earn rewards with Blinq. So let's get started! 😎`
            },
        },
        MturkGazeDesktopCompletion: {
            subtitle: "Use this code to login to attentionTRACE:",
        },
        LoginAndCompletionDeskSolution: {
            subtitle: "Please copy your unique login code below in the extension:",
        },
        SessionInformationDeskSolution: {
            "description": `1. Download Chrome extension. Instructions are found on the next page.
            2. Enter your unique login ID into the space provided 
            3. Read the guidelines for a successful session and press next 
            4. Press ‘start session’ to commence your viewing session 
            5. The extension will automatically redirect you to Yahoo and start the countdown timer (10 minutes) 
            6. Spend your time reading articles, watching videos and engaging with content from across the Yahoo website
            7. As you reach the end of your session, a pop up will appear at the top of your screen to let you know how much time is remaining 
            8. At the end of your session, you may reopen the extension to remove it from your browser 
            9. Your reward will be credited to your account by your panel provider. This may take up to 10 business days            
            `
        },
        Error: {
            title: "Uh-Oh!",
            subTitle: "An unexpected error occurred, please try again later",
        },
        Setup: [
            {
                key: "loginCode",
                title: "Step 1: Record your Login ID",
                description: "This code will be your key to login to the Blinq Research App and start earning. Keep it in a safe place, just in case."
            },
            {
                key: "appDownload",
                title: "Step 2: Download the Blinq Research App",
                description: "Either scan the QR code using the camera on your phone, or click on the App Store logo to download directly."
            }
        ],
        StartEarning: 'First things first, pop your login ID into the Blinq app to link your activity with your reward 🤑'
    },
    StepsFooter: {
        description: "If you run into trouble you can {contactUs}. Please include your {loginIdString} in your email query.",
        contactUs: "contact us",
        privacyPolicy: "Privacy Policy"
    }
};
export default EnLangStrings;
