import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateWebRequestTask, generateUploadsTask, generateExternalSurveyTask } from "../tasks";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const TWIH0123CollGroupIds = [
    "TWIH-268-0123-US-DESKTOP-ITW",
    "TWIH-269-0123-UK-DESKTOP-ITW",
    "TWIH-270-0123-AU-DESKTOP-ITW",
    "TWIH-271-0123-US-DESKTOP-INT",
    "TWIH-272-0123-UK-DESKTOP-INT",
    "TWIH-273-0123-AU-DESKTOP-INT"
] as const;

export type TWIH0123CollGroupId = typeof TWIH0123CollGroupIds[number]

export const TWIH0123CollWebPlatformIds = [
    "TWIH-0123-US-Desktop-ITW",
    "TWIH-0123-UK-Desktop-ITW",
    "TWIH-0123-AU-Desktop-ITW",
    "TWIH-0123-US-Desktop-INT",
    "TWIH-0123-UK-Desktop-INT",
    "TWIH-0123-AU-Desktop-INT"
] as const;

export type TWIH0123CollWebPlatformId = typeof TWIH0123CollWebPlatformIds[number]

type TWIH0123CollConfig = {
    groupId: TWIH0123CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: TWIH0123CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "TWIH-0123-US-Desktop-ITW":
        case "TWIH-0123-UK-Desktop-ITW":
        case "TWIH-0123-AU-Desktop-ITW":
        case "TWIH-0123-US-Desktop-INT":
        case "TWIH-0123-UK-Desktop-INT":
        case "TWIH-0123-AU-Desktop-INT":
            return "10";

        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: TWIH0123CollGroupId): string => {
    switch (groupId) {
        case "TWIH-268-0123-US-DESKTOP-ITW":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIH-0123/TWIH-0123-US-Desktop-ITW.json";

        case "TWIH-269-0123-UK-DESKTOP-ITW":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIH-0123/TWIH-0123-UK-Desktop-ITW.json";

        case "TWIH-270-0123-AU-DESKTOP-ITW":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIH-0123/TWIH-0123-AU-Desktop-ITW.json";

        case "TWIH-271-0123-US-DESKTOP-INT":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIH-0123/TWIH-0123-US-Desktop-INT.json";

        case "TWIH-272-0123-UK-DESKTOP-INT":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIH-0123/TWIH-0123-UK-Desktop-INT.json";

        case "TWIH-273-0123-AU-DESKTOP-INT":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIH-0123/TWIH-0123-AU-Desktop-INT.json";

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: TWIH0123CollGroupId): TWIH0123CollWebPlatformId => {
    switch (groupId) {
        case "TWIH-268-0123-US-DESKTOP-ITW": return "TWIH-0123-US-Desktop-ITW";

        case "TWIH-269-0123-UK-DESKTOP-ITW": return "TWIH-0123-UK-Desktop-ITW";

        case "TWIH-270-0123-AU-DESKTOP-ITW": return "TWIH-0123-AU-Desktop-ITW";

        case "TWIH-271-0123-US-DESKTOP-INT": return "TWIH-0123-US-Desktop-INT";

        case "TWIH-272-0123-UK-DESKTOP-INT": return "TWIH-0123-UK-Desktop-INT";

        case "TWIH-273-0123-AU-DESKTOP-INT": return "TWIH-0123-AU-Desktop-INT";

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: TWIH0123CollGroupId): string => {
    switch (groupId) {
        case "TWIH-268-0123-US-DESKTOP-ITW":
        case "TWIH-269-0123-UK-DESKTOP-ITW":
        case "TWIH-270-0123-AU-DESKTOP-ITW":
            throw new Error('Invalid Group Id, survey not found')

        case "TWIH-271-0123-US-DESKTOP-INT":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_5gP09ESHOixB3zo";

        case "TWIH-272-0123-UK-DESKTOP-INT":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_0pPkMGOgpfdvsj4";

        case "TWIH-273-0123-AU-DESKTOP-INT":
            return "https://survey.au1.qualtrics.com/jfe/form/SV_6yAjen6oZoyPAge";

        default:
            assertNever(groupId);
    }
}

export const addTWIH0123CollConfig = (
    config: TWIH0123CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'desktop')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', true)
        .setFlag('finishMessageOverride', FINISH_MESSAGE);

    let webplatformId = getWebPlatformId(config.groupId);
    const webTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: "General",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    builder.addTask(webTask);

    if (config.groupId.includes("ITW")) {
        const uploadsTask = generateUploadsTask({
            config: {
                "title": "Uploads"
            },
            taskId: config.groupId + '-UPLOADS',
        });

        const webRequestTask = generateWebRequestTask({
            webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/TWIH-0123/${config.groupId}_INCENTIVE.json`,
            params: {},
            taskID: config.groupId + '-WEBREQUEST',
        })

        builder
            .addTask(webRequestTask)
            .addTask(uploadsTask);
    }
    else if (config.groupId.includes("INT")) {
        const surveyTask = generateExternalSurveyTask({
            title: langStrings.Common.survey,
            description: langStrings.Survey.description,
            extraMetaFields: config.metaFieldNames,
            taskID: `${config.groupId}-SURVEY`,
            surveyUrl: getSurveyUrl(config.groupId),
        });
        builder.addTask(surveyTask);
    }


    return builder;
}