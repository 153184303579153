import * as AWS from 'aws-sdk';
import { RespondentConfig } from 'respondent_config_generator'
import { LoginIdApiStage } from '.';
import { loginIdSplitter, stageToBucket } from './helpers';

const respondentConfigBucket = new AWS.S3({
    apiVersion: '2006-03-01',
    useAccelerateEndpoint: true
})

export type RespondentConfigDownloader = (
    loginId: string,
    stage: LoginIdApiStage
) => Promise<RespondentConfig | undefined>;

export const downloadRespondentConfig: RespondentConfigDownloader = async (
    loginId: string,
    stage: LoginIdApiStage
): Promise<RespondentConfig | undefined> => {
    const [loginIdChar, loginIdInt] = loginIdSplitter(loginId)
    const s3Key = `respondentConfigs/${loginIdChar}/${loginIdInt}.json`

    const req = await respondentConfigBucket.getObject({
        Bucket: stageToBucket(stage),
        Key: s3Key
    }).promise()

    if (req.Body) {
        return JSON.parse(req.Body.toString()) as unknown as RespondentConfig
    }
}
