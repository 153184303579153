import React from "react";

import { Box, Container, Grid, List, Typography } from "@mui/material";
import { makeStyles } from "tss-react/mui";

const useStyles = makeStyles()((theme) => ({
    listGrid: {
        marginBottom: theme.spacing(0.5),
    },
    list: {
        paddingTop: 0,
    },
    listItem: {
        paddingTop: "2px",
        paddingBottom: "2px",
    },
    contentContainer: {
        paddingTop: "2px",
        paddingBottom: "2px",
        "&::before": {
            content: '"•"',
            marginRight: "0.25em",
        },
        marginLeft: "-0.5em",
        display: "flex",
    },
}));

const getListItem = (
    text: String,
    classes: ReturnType<typeof useStyles>["classes"]
) => (
    <Container className={classes.contentContainer}>
        <Typography variant="body2">{text}</Typography>
    </Container>
);

const MturkGazeDesktopRequirements: React.FC = () => {
    const { classes } = useStyles();

    return (
        <div>
            <Box className={classes.listGrid}>
                <Typography variant="h6">This HIT:</Typography>
                <List dense={true} className={classes.list}>
                    {getListItem(
                        "Aims to capture facial images using the MacBook's built-in webcam. Using a different device will result in rejection of the assignment.",
                        classes
                    )}
                    {getListItem(
                        "Will open in a new window in your browser.",
                        classes
                    )}
                    {getListItem(
                        "Requires you to switch your browser window into fullscreen. This enables us to accurately track the location of the objects as they appear on your screen.",
                        classes
                    )}
                    {getListItem("May only be completed once.", classes)}
                </List>
            </Box>
            <Box className={classes.listGrid}>
                <Typography variant="h6">
                    Before you accept this HIT:
                </Typography>
                <Grid container justifyContent="center">
                    <List dense={true} className={classes.list}>
                        {getListItem(
                            "Disconnect MacBook from external displays, if applicable. Use MacBook’s built-in display.",
                            classes
                        )}
                        {getListItem(
                            "Ensure your web browser is up to date. We recommend using Google Chrome to complete this task.",
                            classes
                        )}
                    </List>
                </Grid>
            </Box>
        </div>
    );
};

export default MturkGazeDesktopRequirements;
