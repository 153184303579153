import { AppLanguage } from "internationalisation"
import { ConfigKey } from "../../configKey"
import { getCurrentLangStrings, setCurrentLang } from "../../i18n"
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder"
import { CAIParams } from "../../queryParams"

export const C9AU1021_GROUP_IDS = [
    'C9AU-27-1021-BVOD'
] as const

export type C9AU1021GroupId = (typeof C9AU1021_GROUP_IDS)[number]

export function isC9AU1021GroupParams(params: CAIParams<ConfigKey>): params is CAIParams<C9AU1021GroupId> {
    return (C9AU1021_GROUP_IDS as ReadonlyArray<string>).includes(params.G)
}

export const configureC9AU1021Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<C9AU1021GroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps('bvod'))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}