import { PanelConfiguration, PanelMetaBase } from ".";
import { assertNever } from "../helper/assertNever";
import { addOmdGladeConfig } from "../projects/OMDG-OmdGlade";
import { addCraa0822CollConfig } from "../projects/CRAA-0822";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { addYahd0823Config } from "../projects/YAHD-0823";

const cintGroupIds = [
    'OMDG-1A',
    'OMDG-1B',
    'OMDG-1C',
    'OMDG-2A',
    'OMDG-2B',
    'OMDG-2C',
    'OMDG-3A',
    'OMDG-3B',
    'OMDG-3C',
    'YAHD-299-0823-USA-YAHOO-DESKTOP',
    'YAHD-300-0823-USA-SPORTS-DESKTOP',
    'YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP',
    'YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP',
    'YAHD-303-0823-USA-YAHOO-MOBILE',
    'YAHD-304-0823-USA-SPORTS-MOBILE',
    'YAHD-305-0823-USA-FANTASYSPORTS-MOBILE',
    'YAHD-306-0823-USA-ENTERTAINMENT-MOBILE',
    'YAHD-307-0823-AUS-YAHOO-MOBILE',
    'YAHD-308-0823-AUS-SPORTS-MOBILE',
    'YAHD-309-0823-AUS-FINANCE-MOBILE',
    'YAHD-310-0823-AUS-LIFESTYLE-MOBILE',
    'YAHD-311-0823-AUS-YAHOO-DESKTOP',
    'YAHD-312-0823-AUS-SPORTS-DESKTOP',
    'YAHD-313-0823-AUS-FINANCE-DESKTOP',
    'YAHD-314-0823-AUS-LIFESTYLE-DESKTOP'
] as const;

const amplifiedGroupIds = [
    'CRAA-152-0822-SCA-ADL-BREAKFAST',
    'CRAA-153-0822-SCA-SYD-BREAKFAST',
    'CRAA-154-0822-SCA-MEL-BREAKFAST',
    'CRAA-155-0822-SCA-BNE-BREAKFAST',
    'CRAA-156-0822-SCA-PER-BREAKFAST',
    'CRAA-157-0822-SCA-ALL-MORNAFT',
    'CRAA-158-0822-SCA-ALL-DRIVE',
    'CRAA-159-0822-SCA-ALL-EVENINGS',
    'CRAA-160-0822-SCA-PODCAST-THEBRIEFING',
    'CRAA-161-0822-SCA-PODCAST-HAMISHANDY',
    'CRAA-162-0822-ARN-ADL-BREAKFAST',
    'CRAA-163-0822-ARN-SYD-BREAKFAST',
    'CRAA-164-0822-ARN-MEL-BREAKFAST',
    'CRAA-165-0822-ARN-BNE-BREAKFAST',
    'CRAA-166-0822-ARN-PER-BREAKFAST',
    'CRAA-167-0822-ARN-ALL-MORNAFT',
    'CRAA-168-0822-ARN-ALL-DRIVE',
    'CRAA-169-0822-ARN-ADL-EVENING',
    'CRAA-170-0822-ARN-SYD-EVENING',
    'CRAA-171-0822-ARN-MEL-EVENING',
    'CRAA-172-0822-ARN-BNE-EVENING',
    'CRAA-173-0822-ARN-PER-EVENING',
    'CRAA-174-0822-ARN-PODCAST-IMPERFECTS',
    'CRAA-175-0822-ARN-PODCAST-KYLEJACKIE',
    'CRAA-176-0822-ARN-PODCAST-LIFEUNCUT',
    'CRAA-177-0822-NINE-SYD-BREAKFAST',
    'CRAA-178-0822-NINE-MEL-BREAKFAST',
    'CRAA-179-0822-NINE-BNE-BREAKFAST',
    'CRAA-180-0822-NINE-PER-BREAKFAST',
    'CRAA-181-0822-NINE-SYD-MORNAFT',
    'CRAA-182-0822-NINE-MEL-MORNAFT',
    'CRAA-183-0822-NINE-BNE-MORNAFT',
    'CRAA-184-0822-NINE-PER-MORNAFT',
    'CRAA-185-0822-NINE-SYD-DRIVE',
    'CRAA-186-0822-NINE-MEL-DRIVE',
    'CRAA-187-0822-NINE-BNE-DRIVE',
    'CRAA-188-0822-NINE-PER-DRIVE',
    'CRAA-189-0822-NINE-SYD-EVENING',
    'CRAA-190-0822-NINE-MEL-EVENING',
    'CRAA-191-0822-NINE-BNE-EVENING',
    'CRAA-192-0822-NINE-PER-EVENING',
    'CRAA-193-0822-NINE-PODCAST-MONEYNEWS',
    'CRAA-194-0822-NOVA-ADL-BREAKFAST',
    'CRAA-195-0822-NOVA-SYD-BREAKFAST',
    'CRAA-196-0822-NOVA-MEL-BREAKFAST',
    'CRAA-197-0822-NOVA-BNE-BREAKFAST',
    'CRAA-198-0822-NOVA-PER-BREAKFAST',
    'CRAA-199-0822-NOVA-AMBP-MORNAFT',
    'CRAA-200-0822-NOVA-SYD-MORNAFT',
    'CRAA-201-0822-NOVA-ALL-DRIVE',
    'CRAA-202-0822-NOVA-ALL-EVENING',
    'CRAA-203-0822-NOVA-PODCAST-HENNESSYS',
    'CRAA-204-0822-NOVA-PODCAST-KTJ',
    'CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE'
] as const;

export type CintGroupId = typeof cintGroupIds[number]

export type AmplifiedGroupId = typeof amplifiedGroupIds[number]

export const isCintGroupId = (groupId: string): groupId is CintGroupId => {
    return (cintGroupIds as ReadonlyArray<string>).includes(groupId)
}

export const isAmplifiedGroupId = (groupId: string): groupId is AmplifiedGroupId => {
    return (amplifiedGroupIds as ReadonlyArray<string>).includes(groupId)
}

const cintExtraMetaKeys = [
    'panelRespondentID',
    'panelIdentifier'
] as const

export type CintExtraMetaKey = typeof cintExtraMetaKeys[number]

export type PanelMetaCint = PanelMetaBase<CintGroupId | AmplifiedGroupId> & {
    panelRespondentId: string
};

export class PanelConfigurationCint implements PanelConfiguration {
    constructor(
        private meta: PanelMetaCint
    ) {}

    addExtraMetaAndFlags(configBuilder: RespondentConfigBuilder<CintExtraMetaKey>) {
        switch (this.meta.groupId) {
            case 'OMDG-1A':
            case 'OMDG-1B':
            case 'OMDG-1C':
            case 'OMDG-2A':
            case 'OMDG-2B':
            case 'OMDG-2C':
            case 'OMDG-3A':
            case 'OMDG-3B':

            case 'OMDG-3C': {
                configBuilder
                    .addExtraMeta('panelRespondentID', this.meta.panelRespondentId)
                    .addExtraMeta('panelIdentifier', 'cint')
                break;
            }

            case 'CRAA-152-0822-SCA-ADL-BREAKFAST':
            case 'CRAA-153-0822-SCA-SYD-BREAKFAST':
            case 'CRAA-154-0822-SCA-MEL-BREAKFAST':
            case 'CRAA-155-0822-SCA-BNE-BREAKFAST':
            case 'CRAA-156-0822-SCA-PER-BREAKFAST':
            case 'CRAA-157-0822-SCA-ALL-MORNAFT':
            case 'CRAA-158-0822-SCA-ALL-DRIVE':
            case 'CRAA-159-0822-SCA-ALL-EVENINGS':
            case 'CRAA-160-0822-SCA-PODCAST-THEBRIEFING':
            case 'CRAA-161-0822-SCA-PODCAST-HAMISHANDY':
            case 'CRAA-162-0822-ARN-ADL-BREAKFAST':
            case 'CRAA-163-0822-ARN-SYD-BREAKFAST':
            case 'CRAA-164-0822-ARN-MEL-BREAKFAST':
            case 'CRAA-165-0822-ARN-BNE-BREAKFAST':
            case 'CRAA-166-0822-ARN-PER-BREAKFAST':
            case 'CRAA-167-0822-ARN-ALL-MORNAFT':
            case 'CRAA-168-0822-ARN-ALL-DRIVE':
            case 'CRAA-169-0822-ARN-ADL-EVENING':
            case 'CRAA-170-0822-ARN-SYD-EVENING':
            case 'CRAA-171-0822-ARN-MEL-EVENING':
            case 'CRAA-172-0822-ARN-BNE-EVENING':
            case 'CRAA-173-0822-ARN-PER-EVENING':
            case 'CRAA-174-0822-ARN-PODCAST-IMPERFECTS':
            case 'CRAA-175-0822-ARN-PODCAST-KYLEJACKIE':
            case 'CRAA-176-0822-ARN-PODCAST-LIFEUNCUT':
            case 'CRAA-177-0822-NINE-SYD-BREAKFAST':
            case 'CRAA-178-0822-NINE-MEL-BREAKFAST':
            case 'CRAA-179-0822-NINE-BNE-BREAKFAST':
            case 'CRAA-180-0822-NINE-PER-BREAKFAST':
            case 'CRAA-181-0822-NINE-SYD-MORNAFT':
            case 'CRAA-182-0822-NINE-MEL-MORNAFT':
            case 'CRAA-183-0822-NINE-BNE-MORNAFT':
            case 'CRAA-184-0822-NINE-PER-MORNAFT':
            case 'CRAA-185-0822-NINE-SYD-DRIVE':
            case 'CRAA-186-0822-NINE-MEL-DRIVE':
            case 'CRAA-187-0822-NINE-BNE-DRIVE':
            case 'CRAA-188-0822-NINE-PER-DRIVE':
            case 'CRAA-189-0822-NINE-SYD-EVENING':
            case 'CRAA-190-0822-NINE-MEL-EVENING':
            case 'CRAA-191-0822-NINE-BNE-EVENING':
            case 'CRAA-192-0822-NINE-PER-EVENING':
            case 'CRAA-193-0822-NINE-PODCAST-MONEYNEWS':
            case 'CRAA-194-0822-NOVA-ADL-BREAKFAST':
            case 'CRAA-195-0822-NOVA-SYD-BREAKFAST':
            case 'CRAA-196-0822-NOVA-MEL-BREAKFAST':
            case 'CRAA-197-0822-NOVA-BNE-BREAKFAST':
            case 'CRAA-198-0822-NOVA-PER-BREAKFAST':
            case 'CRAA-199-0822-NOVA-AMBP-MORNAFT':
            case 'CRAA-200-0822-NOVA-SYD-MORNAFT':
            case 'CRAA-201-0822-NOVA-ALL-DRIVE':
            case 'CRAA-202-0822-NOVA-ALL-EVENING':
            case 'CRAA-203-0822-NOVA-PODCAST-HENNESSYS':
            case 'CRAA-204-0822-NOVA-PODCAST-KTJ':
            case 'CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE':
            case "YAHD-303-0823-USA-YAHOO-MOBILE":
            case "YAHD-304-0823-USA-SPORTS-MOBILE":
            case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE":
            case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE":
            case "YAHD-307-0823-AUS-YAHOO-MOBILE":
            case "YAHD-308-0823-AUS-SPORTS-MOBILE":
            case "YAHD-309-0823-AUS-FINANCE-MOBILE":
            case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE":
            case "YAHD-299-0823-USA-YAHOO-DESKTOP":
            case "YAHD-300-0823-USA-SPORTS-DESKTOP":
            case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP":
            case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP":
            case "YAHD-311-0823-AUS-YAHOO-DESKTOP":
            case "YAHD-312-0823-AUS-SPORTS-DESKTOP":
            case "YAHD-313-0823-AUS-FINANCE-DESKTOP":
            case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP":
                // Round 1 PanelIdentifier is 'amplified-qualtrics', while round 2 PanelIdentifier is 'cint'.
                configBuilder
                    .addExtraMeta('panelRespondentID', this.meta.panelRespondentId)
                    .addExtraMeta(
                        'panelIdentifier',
                        this.meta.panelRespondentId.startsWith('R_') ? 'amplified-qualtrics' : 'cint'
                    )
                break;
        }
    }

    addCompletionTasks(configBuilder: RespondentConfigBuilder<CintExtraMetaKey>) {
        // Cint panel has no completion tasks to add
    }

    addGroupConfig(configBuilder: RespondentConfigBuilder<CintExtraMetaKey>): void {
        switch (this.meta.groupId) {
            case 'OMDG-1A':
            case 'OMDG-1B':
            case 'OMDG-1C':
            case 'OMDG-2A':
            case 'OMDG-2B':
            case 'OMDG-2C':
            case 'OMDG-3A':
            case 'OMDG-3B':
            case 'OMDG-3C': {
                addOmdGladeConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: cintExtraMetaKeys
                }, configBuilder)
                break;
            }

            case "YAHD-299-0823-USA-YAHOO-DESKTOP":
            case "YAHD-300-0823-USA-SPORTS-DESKTOP":
            case "YAHD-301-0823-USA-FANTASYSPORTS-DESKTOP":
            case "YAHD-302-0823-USA-ENTERTAINMENT-DESKTOP":
            case "YAHD-311-0823-AUS-YAHOO-DESKTOP":
            case "YAHD-312-0823-AUS-SPORTS-DESKTOP":
            case "YAHD-313-0823-AUS-FINANCE-DESKTOP":
            case "YAHD-314-0823-AUS-LIFESTYLE-DESKTOP":
            case "YAHD-304-0823-USA-SPORTS-MOBILE":
            case "YAHD-305-0823-USA-FANTASYSPORTS-MOBILE":
            case "YAHD-306-0823-USA-ENTERTAINMENT-MOBILE":
            case "YAHD-307-0823-AUS-YAHOO-MOBILE":
            case "YAHD-308-0823-AUS-SPORTS-MOBILE":
            case "YAHD-309-0823-AUS-FINANCE-MOBILE":
            case "YAHD-310-0823-AUS-LIFESTYLE-MOBILE":
            case "YAHD-303-0823-USA-YAHOO-MOBILE": {
                addYahd0823Config({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId']
                }, configBuilder)
                break;
            }
            case 'CRAA-152-0822-SCA-ADL-BREAKFAST':
            case 'CRAA-153-0822-SCA-SYD-BREAKFAST':
            case 'CRAA-154-0822-SCA-MEL-BREAKFAST':
            case 'CRAA-155-0822-SCA-BNE-BREAKFAST':
            case 'CRAA-156-0822-SCA-PER-BREAKFAST':
            case 'CRAA-157-0822-SCA-ALL-MORNAFT':
            case 'CRAA-158-0822-SCA-ALL-DRIVE':
            case 'CRAA-159-0822-SCA-ALL-EVENINGS':
            case 'CRAA-160-0822-SCA-PODCAST-THEBRIEFING':
            case 'CRAA-161-0822-SCA-PODCAST-HAMISHANDY':
            case 'CRAA-162-0822-ARN-ADL-BREAKFAST':
            case 'CRAA-163-0822-ARN-SYD-BREAKFAST':
            case 'CRAA-164-0822-ARN-MEL-BREAKFAST':
            case 'CRAA-165-0822-ARN-BNE-BREAKFAST':
            case 'CRAA-166-0822-ARN-PER-BREAKFAST':
            case 'CRAA-167-0822-ARN-ALL-MORNAFT':
            case 'CRAA-168-0822-ARN-ALL-DRIVE':
            case 'CRAA-169-0822-ARN-ADL-EVENING':
            case 'CRAA-170-0822-ARN-SYD-EVENING':
            case 'CRAA-171-0822-ARN-MEL-EVENING':
            case 'CRAA-172-0822-ARN-BNE-EVENING':
            case 'CRAA-173-0822-ARN-PER-EVENING':
            case 'CRAA-174-0822-ARN-PODCAST-IMPERFECTS':
            case 'CRAA-175-0822-ARN-PODCAST-KYLEJACKIE':
            case 'CRAA-176-0822-ARN-PODCAST-LIFEUNCUT':
            case 'CRAA-177-0822-NINE-SYD-BREAKFAST':
            case 'CRAA-178-0822-NINE-MEL-BREAKFAST':
            case 'CRAA-179-0822-NINE-BNE-BREAKFAST':
            case 'CRAA-180-0822-NINE-PER-BREAKFAST':
            case 'CRAA-181-0822-NINE-SYD-MORNAFT':
            case 'CRAA-182-0822-NINE-MEL-MORNAFT':
            case 'CRAA-183-0822-NINE-BNE-MORNAFT':
            case 'CRAA-184-0822-NINE-PER-MORNAFT':
            case 'CRAA-185-0822-NINE-SYD-DRIVE':
            case 'CRAA-186-0822-NINE-MEL-DRIVE':
            case 'CRAA-187-0822-NINE-BNE-DRIVE':
            case 'CRAA-188-0822-NINE-PER-DRIVE':
            case 'CRAA-189-0822-NINE-SYD-EVENING':
            case 'CRAA-190-0822-NINE-MEL-EVENING':
            case 'CRAA-191-0822-NINE-BNE-EVENING':
            case 'CRAA-192-0822-NINE-PER-EVENING':
            case 'CRAA-193-0822-NINE-PODCAST-MONEYNEWS':
            case 'CRAA-194-0822-NOVA-ADL-BREAKFAST':
            case 'CRAA-195-0822-NOVA-SYD-BREAKFAST':
            case 'CRAA-196-0822-NOVA-MEL-BREAKFAST':
            case 'CRAA-197-0822-NOVA-BNE-BREAKFAST':
            case 'CRAA-198-0822-NOVA-PER-BREAKFAST':
            case 'CRAA-199-0822-NOVA-AMBP-MORNAFT':
            case 'CRAA-200-0822-NOVA-SYD-MORNAFT':
            case 'CRAA-201-0822-NOVA-ALL-DRIVE':
            case 'CRAA-202-0822-NOVA-ALL-EVENING':
            case 'CRAA-203-0822-NOVA-PODCAST-HENNESSYS':
            case 'CRAA-204-0822-NOVA-PODCAST-KTJ':
            case 'CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE':
                addCraa0822CollConfig({
                    groupId: this.meta.groupId,
                    metaFieldNames: [...cintExtraMetaKeys, 'loginId', 'groupID']
                }, configBuilder)
                break;

            default: assertNever(this.meta.groupId);
        }
    }
}