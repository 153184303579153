import { Grid, TextField, Typography, Button } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { createTheme, ThemeProvider } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: {
            main: "#007041",
            contrastText: "#fff",
        },
    },
});

export enum CompletionDesktopState {
    waitingForInput = "waitingForInput",
    invalid = "invalid",
    valid = "valid",
}

interface Props {
    loginId: string;
    completionCode?: string;
    completionCodeStateChanged?: (
        state: CompletionDesktopState,
        input: string
    ) => void;
}

function buttonDisable() {
    const url = new URL(document.URL).searchParams;
    const params = new URLSearchParams(url);
    if (params.get("assignmentId") === "ASSIGNMENT_ID_NOT_AVAILABLE")
        return true;

    if (!params.get("isMacBook") || params.get("isMacBook") === "false")
        return true;

    if (
        !params.get("completedDemographicSurvey") ||
        params.get("completedDemographicSurvey") === "false"
    )
        return true;

    return false;
}

const MturkGazeDesktopCompletion: React.FC<Props> = (props) => {
    const { completionCode, completionCodeStateChanged, loginId } = props;

    const [completionCodeInputState, setCompletionCodeInputState] = useState(
        CompletionDesktopState.waitingForInput
    );

    useEffect(() => {
        if (!completionCode) {
            setCompletionCodeInputState(CompletionDesktopState.valid);
            if (completionCodeStateChanged) {
                completionCodeStateChanged(CompletionDesktopState.valid, "");
            }
        }
    }, [completionCode, completionCodeStateChanged]);

    const handleChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            const { value } = event.target;

            if (value.length < 5) {
                setCompletionCodeInputState(
                    CompletionDesktopState.waitingForInput
                );
                if (completionCodeStateChanged) {
                    completionCodeStateChanged(
                        CompletionDesktopState.waitingForInput,
                        value
                    );
                }
                return;
            }

            if (completionCode === value) {
                setCompletionCodeInputState(CompletionDesktopState.valid);
                if (completionCodeStateChanged) {
                    completionCodeStateChanged(
                        CompletionDesktopState.valid,
                        value
                    );
                }
            } else {
                setCompletionCodeInputState(CompletionDesktopState.invalid);
                if (completionCodeStateChanged) {
                    completionCodeStateChanged(
                        CompletionDesktopState.invalid,
                        value
                    );
                }
            }
        },
        [completionCode, completionCodeStateChanged]
    );

    return (
        <div>
            <Typography align="center" gutterBottom>
                The task will start in a new window in your browser.
            </Typography>
            <Grid container justifyContent="center">
                <ThemeProvider theme={theme}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {
                            window.open(
                                `https://crowdsource.dev.amplifiedintelligence.io/?loginId=${loginId}`,
                                "Training Site",
                                "popup"
                            );
                        }}
                        sx={{ m: 4 }}
                        disabled={buttonDisable()}
                    >
                        START TASK
                    </Button>
                </ThemeProvider>
            </Grid>
            {completionCode && (
                <div>
                    <Typography align="center">
                        Once you have completed enter your completion code
                        below:
                    </Typography>
                    <Grid container justifyContent="center">
                        <form noValidate autoComplete="off">
                            <TextField
                                disabled={
                                    completionCodeInputState ===
                                    CompletionDesktopState.valid
                                }
                                error={
                                    completionCodeInputState ===
                                    CompletionDesktopState.invalid
                                }
                                label="Completion Code"
                                margin="normal"
                                variant="outlined"
                                onChange={handleChange}
                            />
                        </form>
                    </Grid>
                </div>
            )}
        </div>
    );
};

export default MturkGazeDesktopCompletion;
