import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { SNAR0823CollGroupId } from "respondent_config_generator/src/projects/SNAR-0823";

export type { SNAR0823CollGroupId } from "respondent_config_generator/src/projects/SNAR-0823";
export { SNAR0823CollGroupIds } from "respondent_config_generator/src/projects/SNAR-0823";

const getPlatformForGroupId = (groupId: SNAR0823CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "SNAR-295-0823-VALIDATION":
        case "SNAR-316-0823-UK-SCALED-1":
        case "SNAR-317-0823-UK-SCALED-2":
        case "SNAR-318-0823-US-SCALED-1":
        case "SNAR-319-0823-US-SCALED-2":
        case "SNAR-355-0823-FR-SCALED-1":
        case "SNAR-356-0823-FR-SCALED-2":
        case "SNAR-357-0823-SA-SCALED-1":
        case "SNAR-358-0823-SA-SCALED-2":
        case "SNAR-359-0823-CA-SCALED-1":
        case "SNAR-360-0823-CA-SCALED-2":
        case "SNAR-361-0823-CA-SCALED-3":
        case "SNAR-362-0823-AU-SCALED-1":
        case "SNAR-363-0823-AU-SCALED-2":
            return "snapar";

        default:
            assertNever(groupId)
    }
}

const getLanguageForGroupId = (groupId: SNAR0823CollGroupId): AppLanguage => {
    switch (groupId) {
        case "SNAR-295-0823-VALIDATION":
        case "SNAR-316-0823-UK-SCALED-1":
        case "SNAR-317-0823-UK-SCALED-2":
        case "SNAR-318-0823-US-SCALED-1":
        case "SNAR-319-0823-US-SCALED-2":
        case "SNAR-359-0823-CA-SCALED-1":
        case "SNAR-360-0823-CA-SCALED-2":
        case "SNAR-361-0823-CA-SCALED-3":
        case "SNAR-362-0823-AU-SCALED-1":
        case "SNAR-363-0823-AU-SCALED-2":
            return AppLanguage.en;
        case "SNAR-355-0823-FR-SCALED-1":
        case "SNAR-356-0823-FR-SCALED-2":
            return AppLanguage.fr;
        case "SNAR-357-0823-SA-SCALED-1":
        case "SNAR-358-0823-SA-SCALED-2":
            return AppLanguage.ar;

        default:
            assertNever(groupId)
    }
}
export const configureSNAR0823Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<SNAR0823CollGroupId>
): void => {
    setCurrentLang(getLanguageForGroupId(params.G));
    const lang = getCurrentLangStrings();

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}