import { AppLanguage, getLanguageStrings } from "internationalisation";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { ConfigKey } from "../../configKey";

import { SPAU0324CollGroupIds, SPAU0324CollGroupId } from "respondent_config_generator/src/projects/SPAU-0324";
import { setCurrentLang } from "../../i18n";
export { SPAU0324CollGroupIds } from 'respondent_config_generator/src/projects/SPAU-0324'

export type { SPAU0324CollGroupId } from 'respondent_config_generator/src/projects/SPAU-0324'

export function isSPAU0324GroupParams(params: CAIParams<ConfigKey>): params is CAIParams<SPAU0324CollGroupId> {
    return (SPAU0324CollGroupIds as ReadonlyArray<string>).includes(params.G)
}

const getPlatformForGroupId = (groupId: SPAU0324CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {

        case "SPAU-369-0324-SPOTIFY":
            return "spotify-au";

        default:
            assertNever(groupId)
    }
}

export const configureSPAU0324Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<SPAU0324CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getLanguageStrings(AppLanguage.en)

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}