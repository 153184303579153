import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { GYGG0124CollGroupId } from "respondent_config_generator/src/projects/GYGG-0124";

export type { GYGG0124CollGroupId } from "respondent_config_generator/src/projects/GYGG-0124";
export { GYGG0124CollGroupIds } from "respondent_config_generator/src/projects/GYGG-0124";

const getPlatformForGroupId = (groupId: GYGG0124CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "GYGG-350-0124-US-YOUTUBE-1":
        case "GYGG-351-0124-US-YOUTUBE-2":
        case "GYGG-367-0124-US-YOUTUBE-3":
        case "GYGG-368-0124-UK-YOUTUBE-4":
        case "GYGG-370-0124-US-YOUTUBE-5":
        case "GYGG-371-0124-UK-YOUTUBE-6":
            return "youtube";

        case "GYGG-364-0124-US-FACEBOOK":
            return "facebook";

        case "GYGG-365-0124-US-INSTAGRAM":
            return "instagram";

        case "GYGG-366-0124-US-TIKTOK":
            return "tiktok";

        default:
            assertNever(groupId)
    }
}

export const configureGYGG0124Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<GYGG0124CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}