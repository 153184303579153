import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateExternalSurveyTask, generateWebPlatformTask } from "../tasks";
import { WebPlatform } from "../types";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your panel account has been incentivised. Thank you for your time."

export const ADCO0622CollGroupIds = [
    'ADCO-146-0622-UK-ADCOLONY',
] as const

export type ADCO0622CollGroupId = typeof ADCO0622CollGroupIds[number]

type Adco0622CollConfig = {
    groupId: ADCO0622CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getWebPlatformConfigUrl = (groupId: ADCO0622CollGroupId): string => {
    switch (groupId) {
        case "ADCO-146-0622-UK-ADCOLONY": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ADCO-0622/ADCO-0622-UK-ADCOLONY.json";

        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: ADCO0622CollGroupId): WebPlatform => {
    switch (groupId) {
        case "ADCO-146-0622-UK-ADCOLONY": {
            return "AdColony";
        }

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: ADCO0622CollGroupId): string => {
    switch (groupId) {
        case "ADCO-146-0622-UK-ADCOLONY": return "https://survey.au1.qualtrics.com/jfe/form/SV_erC8XvmOZw3Duei";

        default:
            assertNever(groupId);
    }
}

const getWebPlatformID = (groupId: ADCO0622CollGroupId): string => {
    switch (groupId) {
        case "ADCO-146-0622-UK-ADCOLONY": return "ADCO-0622-UK-ADCOLONY";
        
        default:
            assertNever(groupId);
    }
}

export const addAdco0622CollConfig = (
    config: Adco0622CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)

    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformID(config.groupId),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: getPlatformForGroup(config.groupId),
        taskLengthMinutes: "",
        langStrings: langStrings,
    });
    builder.addTask(webPlatformTask);

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });
    builder.addTask(surveyTask);

    return builder;
}