import { makeStyles } from 'tss-react/mui'

export const useStyles = makeStyles()(theme => ({
    layout: {
        width: 'auto',
        marginLeft: theme.spacing(2),
        marginRight: theme.spacing(2),
        [theme.breakpoints.up(700 + 32)]: {
            width: 700,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },
    paper: {
        marginTop: theme.spacing(3),
        marginBottom: theme.spacing(3),
        padding: theme.spacing(2),
        [theme.breakpoints.up(700 + 48)]: {
            marginTop: theme.spacing(6),
            marginBottom: theme.spacing(6),
            padding: theme.spacing(3),
        },
    },
    stepper: {
        padding: theme.spacing(3, 0, 3)
    },
    buttons: {
        marginBottom: theme.spacing(2),
        display: 'flex',
        justifyContent: 'center',
        gap: theme.spacing(2)
    },
    buttonPrimary: {
        height: 28,
        minWidth: 100,
        borderRadius: 22,
        boxShadow: 'none'
    },
    buttonSecondary: {
        height: 28,
        minWidth: 100,
        borderRadius: 22,
        backgroundColor: 'white',
        border: '1px solid #29404F',
        color: '#29404F',
        boxShadow: 'none'
    },
    buttonDisabled: {
        border: 'unset',
    },
    divider: {
        margin: theme.spacing(1, 0, 1, 0),
        [theme.breakpoints.up(700 + 32)]: {
            marginLeft: theme.spacing(8),
            marginRight: theme.spacing(8)
        }
    },
    grid: {
        [theme.breakpoints.up(700 + 32)]: {
            paddingLeft: theme.spacing(8),
            paddingRight: theme.spacing(8)
        }
    },
    fontOfeliaBold: {
        fontFamily: 'OfeliaBold'
    }
}))