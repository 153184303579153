import { LangStrings } from './LangStrings';

const ArLangStrings: LangStrings = {
    "Common": {
        "survey": "الدراسة الاستكشافية",
        "back": "خلف",
        "next": "التالي",
        "loading": "جار التحميل",
        "go": "دعنا نذهب"
    },
    "Survey": {
        "description": "استبيان مدته 5 دقائق حول تفضيلات التسوق الخاصة بك"
    },
    "WebPlatform": {
        "facebook": (minutes: string) => `مرر على Facebook لمدة ${minutes} دقيقة`,
        "youtube": (minutes: string) => `شاهد YouTube مقابل ${minutes} دقيقة`,
        "instagram": (minutes: string) => `مرر على Instagram لمدة ${minutes} دقيقة`,
        "tiktok": (minutes: string) => `مرر TikTok لمدة ${minutes} دقيقة`,
        "snapar": () => 'استكشف العدسات المتوفرة ببساطة عن طريق النقر على كل واحدة منها. يمكنك ممارسة الألعاب وتجربة الملحقات والتقاط الصور مع الشخصيات. سنخبرك عند انتهاء الجلسة.',
    },
    "LandingPage": {
        "Title": "تعليمات الإعداد",
    },
    "Steps": {
        "StepLabel": {
            "Instructions": "مرحباً",
            "ViewingInstructionsDeskSolution": "",
            "Requirements": "متطلبات",
            "AppDownload": "تنزيل التطبيق",
            "AppDownloadDeskSolution": "",
            "LoginAndCompletionCode": "تسجيل الدخول",
            "MturkGazeDesktopCompletion": "تسجيل الدخول",
            "LoginAndCompletionDeskSolution": "",
            "SessionInformationDeskSolution": "",
            "Setup": "يثبت",
            StartEarning: "البدء في كسب"
        },
        ViewingInstructions: {
            title: '.الأولى Blinq كم هو مثير أنك جاهز لبدء مغامرة',
            subtitle:
                `.اتبع هذه الخطوات البسيطة وستكون جاهزًا لكسب المكافآت في غضون دقائق<br/><br/>
                .أول شيء أولاً، حان الوقت لتنزيل تطبيقنا والحصول على معرف تسجيل دخول فريد<br/><br/>
                .تذكير: هذه دراسة مخصصة للهاتف المحمول فقط، لذا تأكد من أن هاتفك في متناول يديك لإكمال هذا الإعداد والمشاركة.<br/><br/>
                .هذه دراسة مخصصة للهاتف المحمول فقط، لذا تأكد من أن هاتفك في متناول يديك لإكمال هذا الإعداد والمشاركة<b>:تذكير<b/><br/><br/>
                .انقر على "دعونا نذهب" للمتابعة`
        },
        ViewingInstructionsDeskSolution: {
            title: "",
            subtitle: "",
        },
        "AppDownload": {
            "subtitle": 'في متجر تطبيقات Apple ، ابحث عن \ "بحث عن الاهتمام \"',
            "or": "أو",
            "downloadLink": "إضغط هنا للتحميل"
        },
        "AppDownloadDeskSolution": {
            "subtitle": "",
            "downloadLink": "",
        },
        "LoginAndCompletionCode": {
            "subtitle": "استخدم هذا الرمز لتسجيل الدخول إلى موقع الانتباه:",
            platformInstructions: {
                facebookNewsfeed: "قم بتسجيل الدخول إلى حساب Facebook الخاص بك وقم بالتمرير إلى القصص الخاصة بك ، وتغذية وتوجه إلى علامة التبويب 'مشاهدة' (ابحث عن أيقونة الفيديو في الجزء العلوي من الشاشة). افعل كل ما تفعله عادة ؛ قم بالتمرير والمشاهدة والإعجاب والتعليق حتى ينتهي الوقت!ستحتاج إلى أن تكون تفاصيل تسجيل الدخول إلى Facebook جاهزة قبل أن تبدأ.سيتم تقديم استبيان قصير حول تجربتك بمجرد اكتمال المهمة.",
                instagram: 
                    `.بمجرد تسجيل الدخول، سنقوم بتحميل تطبيق الوسائط الاجتماعية الخاص بك ونطلب منك تسجيل الدخول إلى حسابك الشخصي\n
                    😎 لتحقيق الربح، ستحتاج إلى اتباع التعليمات، وتمرير المحتوى، ومشاهدة مقاطع الفيديو، والإعجاب بالمشاركات، والاستمتاع والانتظار حتى انتهاء المؤقت`,
                instagramNewsfeed: "قم بتسجيل الدخول إلى حساب Instagram الخاص بك وانتقل إلى قصصك وخلاصاتك. افعل كل ما تفعله عادة ؛ قم بالتمرير والمشاهدة والإعجاب والتعليق حتى ينتهي الوقت!ستحتاج إلى أن تكون تفاصيل تسجيل الدخول إلى Instagram جاهزة قبل أن تبدأ.سيتم تقديم استبيان قصير حول تجربتك بمجرد اكتمال المهمة.",
                tiktok: 
                    `.بمجرد تسجيل الدخول، سنقوم بتحميل تطبيق الوسائط الاجتماعية الخاص بك ونطلب منك تسجيل الدخول إلى حسابك الشخصي\n
                    😎 لتحقيق الربح، ستحتاج إلى اتباع التعليمات، وتمرير المحتوى، ومشاهدة مقاطع الفيديو، والإعجاب بالمشاركات، والاستمتاع والانتظار حتى انتهاء المؤقت`,
                youtube: 
                    `.بمجرد تسجيل الدخول، سنقوم بتحميل تطبيق الوسائط الاجتماعية الخاص بك ونطلب منك تسجيل الدخول إلى حسابك الشخصي\n
                    😎 لتحقيق الربح، ستحتاج إلى اتباع التعليمات، وتمرير المحتوى، ومشاهدة مقاطع الفيديو، والإعجاب بالمشاركات، والاستمتاع والانتظار حتى انتهاء المؤقت`,
                twitter: "قم بتسجيل الدخول إلى حساب Twitter الخاص بك وانتقل إلى موجز ويب الخاص بك. افعل كل ما تفعله عادة ؛ قم بالتمرير والمشاهدة والإعجاب والتعليق حتى ينتهي الوقت!ستحتاج إلى أن تكون تفاصيل تسجيل الدخول إلى Twitter جاهزة قبل أن تبدأ.سيتم تقديم استبيان قصير حول تجربتك بمجرد اكتمال المهمة.",
                webplatformSynergy: "عند تسجيل الدخول إلى موقع للتنبيه TRACE ، سيتم توجيهك إلى موقع ويب. يرجى تصفح هذا الموقع كما تفعل بشكل طبيعي ؛ قم بالتمرير وقراءة المقالات ومشاهدة مقاطع الفيديو حتى ينتهي المؤقت.سيتم تقديم استبيان قصير حول تجربتك في النهاية.",
                facebookWatch: "",
                facebookStories: "",
                pinterest: "يرجى تسجيل الدخول إلى تطبيق attentionTRACE باستخدام رمز تسجيل الدخول الفريد أعلاه.\nبمجرد تسجيل الدخول، سيتم تقديمك إلى منصة Pinterest وسيطلب منك تسجيل الدخول إلى حسابك الشخصي.\nتصفح واستكشف Pinterest بحرية عن طريق اتباع المطالبات (حدد خارج صفحة الاستكشاف، والتنقل بين الفئات المقترحة والتفاعل مع المحتوى) حتى انتهاء المؤقت.\nستتم إعادة توجيهك إلى الاستبيان، ما عليك سوى إكمال هذا الاستبيان وستكون قد انتهيت!",
                snapar: 'يرجى تسجيل الدخول إلى تطبيق attentionTRACE باستخدام رمز تسجيل الدخول الفريد أعلاه.\nبمجرد تسجيل الدخول، ستظهر لك مجموعة من عدسات Snapchat AR. \nيُرجى النقر على كل عدسة للاستفادة منها والتفاعل مع العدسة كما تفعل عادةً حتى انتهاء المؤقت.',
                snapchat: 
                    `.وكل ما عليك فعله هو الانتقال إلى صفحة "اكتشف" والتحقق من كل المحتوى المضحك والمجنون والحزين والمذهل الذي يمكنك التعامل معه حتى نفاد المؤقت Snapchat بعد تسجيل الدخول، سنقوم بتحميل\n
                     اذا هيا بنا نبدأ.Blinq لا يمكن أن يكون الحصول على المكافآت أسهل مع`
            }
        },
        "MturkGazeDesktopCompletion": {
            "subtitle": "استخدم هذا الرمز لتسجيل الدخول إلى موقع الانتباه:",
        },
        "LoginAndCompletionDeskSolution": {
            "subtitle": "",
        },
        "SessionInformationDeskSolution": {
            "description": ""
        },
        "Error": {
            title: "عذرًا!",
            subTitle: "حدث خطأ غير متوقع ، يرجى المحاولة مرة أخرى في وقت لاحق",
        },
        Setup: [
            {
                key: "loginCode",
                title: "الخطوة 1: قم بتسجيل معرف تسجيل الدخول الخاص بك",
                description: "سيكون هذا الرمز هو مفتاحك لتسجيل الدخول إلى تطبيق Blinq Research والبدء في الربح. احتفظ بها في مكان آمن، فقط في حالة."
            },
            {
                key: "appDownload",
                title: "Blinq Research الخطوة 2: قم بتنزيل تطبيق",
                description: ".قم إما بمسح رمز الاستجابة السريعة ضوئيًا باستخدام الكاميرا الموجودة على هاتفك، أو انقر فوق شعار متجر التطبيقات للتنزيل مباشرة"
            }
        ],
        StartEarning: '.بمجرد تنزيل التطبيق، حان الوقت لإدخال معرف تسجيل الدخول الخاص بك'
    },
    "Decay": {
        "Surveys": {
            "Initial": "",
            "TwoWeek": "",
            "FourWeek": ""
        }
    },
    BvodInApp: {
        "title": "",
        "description": "",
    },
    StepsFooter: {
        description: "إذا واجهت مشكلة، يمكنك {contactUs}. الرجاء تضمين {loginIdString} في استعلام البريد الإلكتروني الخاص بك.",
        contactUs: "اتصل بنا",
        privacyPolicy: 'سياسة الخصوصية'
    }
}
export default ArLangStrings