import EnLangStrings from "internationalisation/en";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import {
    generateBvodTask,
    generateExternalSurveyTask,
    generateUploadsTask,
} from "../tasks";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const SKYN0823CollGroupIds = [
    'SKYN-296-0723-BVOD-MOBILE'
] as const

export type SKYN0823CollGroupId = typeof SKYN0823CollGroupIds[number]

type SKYN0823Config = {
    groupId: SKYN0823CollGroupId,
    metaFieldNames: ReadonlyArray<string>
}

// SKYN-0823 BVOD mobile groups config.
export const addSKYN0823CollConfig = (
    config: SKYN0823Config,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('minimumIOSVersion', '15.0')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .setFlag('preventTaskProgress', false)
        
    const meta = config as SKYN0823Config;

    const bvodTvTask = generateBvodTask({
        taskID: meta.groupId + '-BVOD',
        channelsJsonUrl: 'https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/SKYN-0723-BVOD-MOBILE.json',
        isTv: false,
        langStrings: EnLangStrings,
        isRadio: false
    });

    builder.addTask(bvodTvTask)

    const externalSurveyTask = generateExternalSurveyTask({
        title: '',
        description: '',
        surveyUrl: 'https://survey.au1.qualtrics.com/jfe/form/SV_ezhWHGomtuI8Nvg',
        taskID: meta.groupId + '-SURVEY',
        extraMetaFields: [...meta.metaFieldNames, "bvodTV_selectedChannel"]
    });

    builder.addTask(externalSurveyTask);

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: meta.groupId + '-UPLOADS',
    });

    builder.addTask(uploadsTask);

    return builder;
}
