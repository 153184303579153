import EnLangStrings from 'internationalisation/en';
import { RespondentConfigBuilder } from '../respondentConfigBuilder';
import {
    generateWebPlatformTask,
    generateLinearTvTask,
    generateBvodTask,
    generateExternalSurveyTask,
    generateWebRequestTask,
    generateUploadsTask
} from '../tasks';
import { assertNever } from '../helper/assertNever';
import { RespondentWebRequestTask, WebPlatform } from '../types';
import { AppLanguage, getLanguageStrings } from 'internationalisation';

const FINISH_MESSAGE = 'Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your panel account has been incentivised. Thank you for your time.'

const OMNZ1022LinearTVGroupIds = ['OMNZ-215-TVNZ-LIVE'] as const;

const OMNZ1022BVODTVGroupIds = ['OMNZ-216-BVOD-TV'] as const;

const OMNZ1022WebGroupIds = [
    'OMNZ-217-NZ-FACEBOOK',
    'OMNZ-218-NZ-INSTAGRAM',
    'OMNZ-219-NZ-YOUTUBE-PREROLL',
    'OMNZ-220-NZ-TWITTER-FEED',
    'OMNZ-221-NZ-TIKTOK-FEED',
    'OMNZ-223-NZ-NZME-EXPOSED',
    'OMNZ-222-NZ-STUFF-EXPOSED',
    'OMNZ-247-NZ-NZHERALD-RECOLLECT'
] as const;

const OMNZ1022WebSynergyGroupIds = [
    'OMNZ-223-NZ-NZME-EXPOSED'
] as const;

export const OMNZ1022CollGroupIds = [
    ...OMNZ1022LinearTVGroupIds,
    ...OMNZ1022BVODTVGroupIds,
    ...OMNZ1022WebGroupIds
] as const;

type OMNZ1022LinearTVGroupId = typeof OMNZ1022LinearTVGroupIds[number];
type OMNZ1022BVODTVGroupId = typeof OMNZ1022BVODTVGroupIds[number];
type OMNZ1022WebSynergyGroupId = typeof OMNZ1022WebSynergyGroupIds[number];
export type OMNZ1022WebGroupId = typeof OMNZ1022WebGroupIds[number];
    
export type OMNZ1022CollGroupId =
    | OMNZ1022LinearTVGroupId
    | OMNZ1022BVODTVGroupId
    | OMNZ1022WebGroupId

type OMNZ1022WebCollConfig = {
    groupId: OMNZ1022WebGroupId,
    metaFieldNames: ReadonlyArray<string>
};

type OMNZ1022TvCollConfig = {
    groupId: OMNZ1022BVODTVGroupId | OMNZ1022LinearTVGroupId,
    viewingCount: number,
    metaFieldNames: ReadonlyArray<string>,
    user: string,
    email: string
}

const getWebPlatformConfigUrl = (groupId: OMNZ1022WebGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case 'OMNZ-217-NZ-FACEBOOK': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-FACEBOOK.json';
        case 'OMNZ-218-NZ-INSTAGRAM': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-INSTAGRAM.json';
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-YOUTUBE-PREROLL.json';
        case 'OMNZ-220-NZ-TWITTER-FEED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-TWITTER-FEED.json';
        case 'OMNZ-221-NZ-TIKTOK-FEED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-TIKTOK-FEED.json';
        case 'OMNZ-222-NZ-STUFF-EXPOSED': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-STUFF-EXPOSED.json';
        case 'OMNZ-223-NZ-NZME-EXPOSED':
            return taskIndex === 0
                ? 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-NZHERALD-EXPOSED.json'
                : 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-ONEROOF-EXPOSED.json';
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT': return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/OMNZ-1022/OMNZ-1022-NZ-NZHERALD-RECOLLECT.json';

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: OMNZ1022BVODTVGroupId | OMNZ1022WebGroupId): string => {
    switch (groupId) {
        case 'OMNZ-217-NZ-FACEBOOK': return 'https://survey.au1.qualtrics.com/jfe/form/SV_0rG8IqWfplOm0vA';
        case 'OMNZ-218-NZ-INSTAGRAM': return 'https://survey.au1.qualtrics.com/jfe/form/SV_bjvvIfHLEGRZX6K';
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL': return 'https://survey.au1.qualtrics.com/jfe/form/SV_becCXvRLSlvp9PM';
        case 'OMNZ-220-NZ-TWITTER-FEED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_dhwpEH9FvLtJvRs';
        case 'OMNZ-221-NZ-TIKTOK-FEED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_9FayxWDwBBLX5To';

        case 'OMNZ-222-NZ-STUFF-EXPOSED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_etBFuuja9LagUZg';
        case 'OMNZ-223-NZ-NZME-EXPOSED': return 'https://survey.au1.qualtrics.com/jfe/form/SV_etBFuuja9LagUZg';
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT': return 'https://survey.au1.qualtrics.com/jfe/form/SV_4UVWuHdJ0HXcLoW';

        case 'OMNZ-216-BVOD-TV': return "https://survey.au1.qualtrics.com/jfe/form/SV_9Qv6c70dEPVUmJ8";

        default:
            assertNever(groupId);
    }
}

const getTasklength = (groupId: OMNZ1022WebGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case 'OMNZ-217-NZ-FACEBOOK': return '10';
        case 'OMNZ-218-NZ-INSTAGRAM': return '10';
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL': return '20';
        case 'OMNZ-220-NZ-TWITTER-FEED': return '10';
        case 'OMNZ-221-NZ-TIKTOK-FEED': return '10';

        case 'OMNZ-222-NZ-STUFF-EXPOSED': return '7';
        case 'OMNZ-223-NZ-NZME-EXPOSED':
            return taskIndex === 0 ? '5' : '2';
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT': return '8';

        default:
            assertNever(groupId);
    }
}

const isSynergyGroupId = (groupId: OMNZ1022CollGroupId): groupId is OMNZ1022WebSynergyGroupId =>{
    return (OMNZ1022WebSynergyGroupIds as ReadonlyArray<string>).includes(groupId);
}

const getWebPlatformId = (groupId: OMNZ1022WebGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case 'OMNZ-217-NZ-FACEBOOK': return 'OMNZ-1022-NZ-FACEBOOK';
        case 'OMNZ-218-NZ-INSTAGRAM': return 'OMNZ-1022-NZ-INSTAGRAM';
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL': return 'OMNZ-1022-NZ-YOUTUBE-PREROLL';
        case 'OMNZ-220-NZ-TWITTER-FEED': return 'OMNZ-1022-NZ-TWITTER-FEED';
        case 'OMNZ-221-NZ-TIKTOK-FEED': return 'OMNZ-1022-NZ-TIKTOK-FEED';
        case 'OMNZ-222-NZ-STUFF-EXPOSED': return 'OMNZ-1022-NZ-STUFF-EXPOSED';
        case 'OMNZ-223-NZ-NZME-EXPOSED':
            return taskIndex === 0
                ? 'OMNZ-1022-NZ-NZHERALD-EXPOSED'
                : 'OMNZ-1022-NZ-ONEROOF-EXPOSED'
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT': return 'OMNZ-1022-NZ-NZHERALD-RECOLLECT';

        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: OMNZ1022WebGroupId, taskIndex: 0 | 1): WebPlatform => {
    switch (groupId) {
        case 'OMNZ-217-NZ-FACEBOOK': return 'Facebook';
        case 'OMNZ-218-NZ-INSTAGRAM': return 'Instagram';
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL': return 'Youtube';
        case 'OMNZ-220-NZ-TWITTER-FEED': return 'Twitter';
        case 'OMNZ-221-NZ-TIKTOK-FEED': return 'TikTok';
        
        case 'OMNZ-222-NZ-STUFF-EXPOSED': return 'Stuff';
        case 'OMNZ-223-NZ-NZME-EXPOSED':
            return taskIndex === 0 ? 'NZ Herald' : 'OneRoof';
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT': return 'NZ Herald';
        
        default:
            assertNever(groupId);
    }
}

function addLinearTvConfig(
    builder: RespondentConfigBuilder,
    groupId: OMNZ1022LinearTVGroupId,
    email: string,
    user: string
) {
    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/OMNZ-1022/${groupId}_INCENTIVE.json`,
        params: {
            r_user: user,
            r_email: email
        },
        taskID: groupId + '-WEBREQUEST'
    });

    const linearTvTask = generateLinearTvTask({
        title: '',
        description: '',
        taskID: groupId + '-LINEARTV',
        viewingLengthOptions: [30, 60]
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: groupId + '-UPLOADS',
    });

    builder
        .addTask(linearTvTask)
        .addTask(webRequestTask)
        .addTask(uploadsTask)
}

function getBvodChannelUrl(
    viewingCount: number
): string {
    switch(viewingCount) {
        case 1:
            return "https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/OMNZ-1022-VIEW1.json";
        case 2:
            return "https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/OMNZ-1022-VIEWOPEN.json"
        
        default: throw new RangeError('BVOD TV viewing count must be between 1 and 2 (inclusive)!')
    }
}

function addBvodTVConfig(
    builder: RespondentConfigBuilder,
    groupId: OMNZ1022BVODTVGroupId,
    viewingCount: number,
    user: string,
    email: string,
    metaFieldNames: ReadonlyArray<string>
): void {
    const bvodTvTask = generateBvodTask({
        taskID: groupId + '-BVOD',
        channelsJsonUrl: getBvodChannelUrl(viewingCount),
        isTv: true,
        langStrings: EnLangStrings,
        isRadio: false
    });

    builder.addTask(bvodTvTask)

    // Login 1 get external surveys.
    if (viewingCount === 1) {
        builder.setFlag('preventTaskProgress', false);

        const externalSurveyTask = generateExternalSurveyTask({
            title: '',
            description: '',
            surveyUrl: 'https://survey.au1.qualtrics.com/jfe/form/SV_72v2f7zipJH4FTg',
            taskID: groupId + '-SURVEY',
            extraMetaFields: metaFieldNames
        });

        builder.addTask(externalSurveyTask);
    }
    // Login 2 gets a web request.
    else if (viewingCount === 2) {
        builder.setFlag('preventTaskProgress', true);
        builder.setFlag('finishMessageOverride', BVOD_VIEWOPEN_FINISH_MESSAGE);

        const webRequestTask = generateWebRequestTask({
            webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/OMNZ-1022/${groupId}-RECOLLECT_INCENTIVE.json`,
            params: {
                r_user: user,
                r_email: email
            },
            taskID: groupId + '-WEBREQUEST'
        });
        
        builder.addTask(webRequestTask);
    }
    else {
        throw new RangeError("BVOD-TV groups are limited to 2 login IDs")
    }

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: groupId + '-UPLOADS',
    });

    builder.addTask(uploadsTask)
}

// OMNZ 1022 web groups config.
export const addOmnz1022CollWebplatformConfig = (
    config: OMNZ1022WebCollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en);

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)
        .setFlag('preventTaskProgress', false);

    const webPlatformTask1 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId, 0),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: getPlatformForGroup(config.groupId, 0),
        taskLengthMinutes: getTasklength(config.groupId, 0),
        langStrings,
    })
    builder.addTask(webPlatformTask1)

    if (isSynergyGroupId(config.groupId)) {
        const webPlatformTask2 = generateWebPlatformTask({
            groupId: config.groupId,
            webPlatformID: getWebPlatformId(config.groupId, 1),
            webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 1),
            platform: getPlatformForGroup(config.groupId, 1),
            taskLengthMinutes: getTasklength(config.groupId, 1),
            langStrings,
        })
        builder.addTask(webPlatformTask2)
    }

    const surveyTask = generateExternalSurveyTask({
        title: langStrings.Common.survey,
        description: langStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder
        .addTask(surveyTask)
        .addTask(uploadsTask);

    return builder;
}

const TV_FINISH_MESSAGE = 'Thank you for your participation today. Your reward will be emailed to you shortly. Please leave the device charging & locked with this app screen open to ensure your response is recorded. Keep your eyes peeled on your e-mail for further instructions.';
const BVOD_VIEWOPEN_FINISH_MESSAGE = 'Thank you for your participation today. Your reward has been added to your account and will be paid at the end of the study. Please leave the device charging & locked with this app screen open to ensure your response is recorded.';

export const addOmnz1022TVConfig = (
    config: OMNZ1022TvCollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('minimumIOSVersion', '15.0')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', TV_FINISH_MESSAGE)

    switch (config.groupId) {
        case 'OMNZ-215-TVNZ-LIVE': {
            const tvConfig = config as OMNZ1022TvCollConfig;
            builder.setFlag('preventTaskProgress', true);

            addLinearTvConfig(
                builder,
                config.groupId,
                tvConfig.email,
                tvConfig.user
            );
            break;
        }

        /** BVOD mobile is limited to 1 view */
        case 'OMNZ-216-BVOD-TV': {
            const meta = config as OMNZ1022TvCollConfig;

            addBvodTVConfig(
                builder,
                config.groupId,
                meta.viewingCount,
                meta.user,
                meta.email,
                meta.metaFieldNames
            );
            break;
        }
    }

    return builder;
}
