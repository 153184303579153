import React from "react";
import { Grid, Link, Typography } from "@mui/material";
import { LandingPageSupportEmail } from "../reducer";
import SupportEmailLink from "./SupportEmailLink";
import PrivacyPolicyLink from "./PrivacyPolicyLink";

interface Props {
    isMturk: boolean;
    isMturkDesktop?: boolean;
}

const PageError: React.FC<Props> = ({ isMturkDesktop, isMturk }) => {
    let supportEmail: LandingPageSupportEmail = isMturk
        ? LandingPageSupportEmail.mturk
        : LandingPageSupportEmail.help;
    if (isMturkDesktop) {
        supportEmail = LandingPageSupportEmail.mturkDesktop;
    }

    return (
        <Grid
            container
            direction="column"
            justifyContent="center"
            alignItems="center"
            alignContent="center"
        >
            <Grid item>
                <Typography align="center" gutterBottom variant="h3">
                    Uh-Oh!
                </Typography>
                {isMturk ? (
                    <>
                        <Typography align="center" gutterBottom component="p">
                            An unexpected error occurred, please return the HIT
                            and try again later
                        </Typography>
                        <Typography align="center">
                            If required, you can {" "}
                            <Link href={`mailto:${supportEmail}`}>
                                contact us
                            </Link>{" "}
                            for support
                        </Typography>
                    </>
                ) : (
                    <>
                        <Typography align="center">
                            An unexpected error occurred.
                        </Typography>
                        <SupportEmailLink supportEmail={supportEmail} />
                        <PrivacyPolicyLink />
                    </>
                )}
            </Grid>
        </Grid>
    );
};

export default PageError;
