import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { SNEM0524CollGroupId } from "respondent_config_generator/src/projects/SNEM-0524";

export { SNEM0524CollGroupIds } from "respondent_config_generator/src/projects/SNEM-0524";
export type { SNEM0524CollGroupId } from "respondent_config_generator/src/projects/SNEM-0524";

const getPlatformForGroupId = (groupId: SNEM0524CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "SNEM-379-0524-AU-SNAP-HIGH":
        case "SNEM-380-0524-AU-SNAP-MED":
        case "SNEM-381-0524-AU-SNAP-LOW":
        case "SNEM-389-0524-FR-SNAP-HIGH":
        case "SNEM-390-0524-FR-SNAP-MED":
        case "SNEM-391-0524-FR-SNAP-LOW":
        case "SNEM-399-0524-SA-SNAP-HIGH":
        case "SNEM-400-0524-SA-SNAP-MED":
        case "SNEM-401-0524-SA-SNAP-LOW":
        case "SNEM-409-0524-UK-SNAP-HIGH":
        case "SNEM-410-0524-UK-SNAP-MED":
        case "SNEM-411-0524-UK-SNAP-LOW":
        case "SNEM-419-0524-US-SNAP-HIGH":
        case "SNEM-420-0524-US-SNAP-MED":
        case "SNEM-421-0524-US-SNAP-LOW":
            return "snapchat";

        case "SNEM-383-0524-AU-TIKTOK-HIGH":
        case "SNEM-384-0524-AU-TIKTOK-MED":
        case "SNEM-385-0524-AU-TIKTOK-LOW":
        case "SNEM-393-0524-FR-TIKTOK-HIGH":
        case "SNEM-394-0524-FR-TIKTOK-MED":
        case "SNEM-395-0524-FR-TIKTOK-LOW":
        case "SNEM-403-0524-SA-TIKTOK-HIGH":
        case "SNEM-404-0524-SA-TIKTOK-MED":
        case "SNEM-405-0524-SA-TIKTOK-LOW":
        case "SNEM-413-0524-UK-TIKTOK-HIGH":
        case "SNEM-414-0524-UK-TIKTOK-MED":
        case "SNEM-415-0524-UK-TIKTOK-LOW":
        case "SNEM-423-0524-US-TIKTOK-HIGH":
        case "SNEM-424-0524-US-TIKTOK-MED":
        case "SNEM-425-0524-US-TIKTOK-LOW":
            return "tiktok";

        case "SNEM-382-0524-AU-YOUTUBE":
        case "SNEM-392-0524-FR-YOUTUBE":
        case "SNEM-402-0524-SA-YOUTUBE":
        case "SNEM-412-0524-UK-YOUTUBE":
        case "SNEM-422-0524-US-YOUTUBE":
            return "youtube";

        case "SNEM-386-0524-AU-INSTA-HIGH":
        case "SNEM-387-0524-AU-INSTA-MED":
        case "SNEM-388-0524-AU-INSTA-LOW":
        case "SNEM-396-0524-FR-INSTA-HIGH":
        case "SNEM-397-0524-FR-INSTA-MED":
        case "SNEM-398-0524-FR-INSTA-LOW":
        case "SNEM-406-0524-SA-INSTA-HIGH":
        case "SNEM-407-0524-SA-INSTA-MED":
        case "SNEM-408-0524-SA-INSTA-LOW":
        case "SNEM-416-0524-UK-INSTA-HIGH":
        case "SNEM-417-0524-UK-INSTA-MED":
        case "SNEM-418-0524-UK-INSTA-LOW":
        case "SNEM-426-0524-US-INSTA-HIGH":
        case "SNEM-427-0524-US-INSTA-MED":
        case "SNEM-428-0524-US-INSTA-LOW":
            return "instagram";

        default:
            assertNever(groupId)
    }
}

export const configureSNEM0524Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<SNEM0524CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}