import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

import App from './App';
import * as serviceWorker from './serviceWorker';
import Store from './Store';
import './fonts.css';

const sentryConfig: Sentry.BrowserOptions = {
  dsn: 'https://854f60ea567c4a4581d3e83f8690f28c@sentry.io/1810008'
}

if (process.env.NODE_ENV === 'production') {
  sentryConfig.release = process.env.REACT_APP_SENTRY_RELEASE
  sentryConfig.environment = 'prod'
} else {
  sentryConfig.environment = 'dev'
  sentryConfig.enabled = false
}

Sentry.init(sentryConfig)

const root = createRoot(document.getElementById('root')!)
root.render(<Provider store={Store}><App /></Provider>)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
