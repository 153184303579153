import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { ConfigKey } from "../../configKey";

export const OMNZ1022CollGroupIds = [
    'OMNZ-217-NZ-FACEBOOK',
    'OMNZ-218-NZ-INSTAGRAM',
    'OMNZ-219-NZ-YOUTUBE-PREROLL',
    'OMNZ-220-NZ-TWITTER-FEED',
    'OMNZ-221-NZ-TIKTOK-FEED',
    'OMNZ-222-NZ-STUFF-EXPOSED',
    'OMNZ-223-NZ-NZME-EXPOSED',
    'OMNZ-247-NZ-NZHERALD-RECOLLECT'
] as const

export type OMNZ1022CollGroupId = (typeof OMNZ1022CollGroupIds)[number]

export function isOMDGGroupParams(params: CAIParams<ConfigKey>): params is CAIParams<OMNZ1022CollGroupId> {
    return (OMNZ1022CollGroupIds as ReadonlyArray<string>).includes(params.G)
}

const getPlatformForGroupId = (groupId: OMNZ1022CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case 'OMNZ-217-NZ-FACEBOOK':
            return "facebook";
        case 'OMNZ-218-NZ-INSTAGRAM':
            return "instagram";
        case 'OMNZ-219-NZ-YOUTUBE-PREROLL':
            return "youtube";
        case 'OMNZ-220-NZ-TWITTER-FEED':
            return "twitter"
        case 'OMNZ-221-NZ-TIKTOK-FEED':
            return "tiktok"
        case 'OMNZ-222-NZ-STUFF-EXPOSED':
            return "stuff";
        case 'OMNZ-223-NZ-NZME-EXPOSED':
            return 'nzme';
        case 'OMNZ-247-NZ-NZHERALD-RECOLLECT':
            return 'nzherald';
        
        default:
            assertNever(groupId)
    }
}

export const configureOMNZPage = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<OMNZ1022CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}