import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateWebRequestTask } from "../tasks";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const NEAU1022CollGroupIds = [
    "NEAU-225-1022-NEWS-HERALDSUN-BASELINE",
    "NEAU-226-1022-NEWS-HERALDSUN-FREQUENCY",
    "NEAU-227-1022-VOGUE-AUSTRALIAN-BASELINE",
    "NEAU-228-1022-VOGUE-AUSTRALIAN-FREQUENCY",
    "NEAU-229-1022-KIDSPOT-ESCAPE-BASELINE",
    "NEAU-230-1022-KIDSPOT-ESCAPE-FREQUENCY",
    "NEAU-231-1022-TASTE-DAILYTELEGRAPH-BASELINE",
    "NEAU-232-1022-TASTE-DAILYTELEGRAPH-FREQUENCY",
    "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD"
] as const;

export type NEAU1022CollGroupId = typeof NEAU1022CollGroupIds[number]

export const NEAU1022CollWebPlatformIds = [
    "NEAU-1022-AU-NEWS-BASELINE",
    "NEAU-1022-AU-HERALDSUN-BASELINE",
    "NEAU-1022-AU-NEWS-FREQUENCY",
    "NEAU-1022-AU-HERALDSUN-FREQUENCY",
    "NEAU-1022-AU-VOGUE-BASELINE",
    "NEAU-1022-AU-AUSTRALIAN-BASELINE",
    "NEAU-1022-AU-VOGUE-FREQUENCY",
    "NEAU-1022-AU-AUSTRALIAN-FREQUENCY",
    "NEAU-1022-AU-KIDSPOT-BASELINE",
    "NEAU-1022-AU-ESCAPE-BASELINE",    
    "NEAU-1022-AU-KIDSPOT-FREQUENCY",
    "NEAU-1022-AU-ESCAPE-FREQUENCY",
    "NEAU-1022-AU-TASTE-BASELINE",
    "NEAU-1022-AU-DAILYTELEGRAPH-BASELINE",
    "NEAU-1022-AU-TASTE-FREQUENCY",
    "NEAU-1022-AU-DAILYTELEGRAPH-FREQUENCY",
    "NEAU-1022-AU-ADELAIDENOW-STANDARD",
    "NEAU-1022-AU-COURIERMAIL-STANDARD",
    "NEAU-1022-AU-DELICIOUS-STANDARD"
] as const;

export type NEAU1022CollWebPlatformId = typeof NEAU1022CollWebPlatformIds[number]

type NEAU1022CollConfig = {
    groupId: NEAU1022CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: NEAU1022CollWebPlatformId): string => {
    switch(webPlatformId) {
        case "NEAU-1022-AU-NEWS-BASELINE":
        case "NEAU-1022-AU-HERALDSUN-BASELINE":
        case "NEAU-1022-AU-NEWS-FREQUENCY":
        case "NEAU-1022-AU-HERALDSUN-FREQUENCY":
        case "NEAU-1022-AU-VOGUE-BASELINE":
        case "NEAU-1022-AU-AUSTRALIAN-BASELINE":
        case "NEAU-1022-AU-VOGUE-FREQUENCY":
        case "NEAU-1022-AU-AUSTRALIAN-FREQUENCY":
        case "NEAU-1022-AU-KIDSPOT-BASELINE":
        case "NEAU-1022-AU-ESCAPE-BASELINE":    
        case "NEAU-1022-AU-KIDSPOT-FREQUENCY":
        case "NEAU-1022-AU-ESCAPE-FREQUENCY":
        case "NEAU-1022-AU-TASTE-BASELINE":
        case "NEAU-1022-AU-DAILYTELEGRAPH-BASELINE":
        case "NEAU-1022-AU-TASTE-FREQUENCY":
        case "NEAU-1022-AU-DAILYTELEGRAPH-FREQUENCY":
            return "5";

        case "NEAU-1022-AU-ADELAIDENOW-STANDARD":
        case "NEAU-1022-AU-COURIERMAIL-STANDARD":
        case "NEAU-1022-AU-DELICIOUS-STANDARD":
            return "3";

        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: NEAU1022CollGroupId, taskIndex: 0 | 1 | 2): string => {
    switch (groupId) {
        case "NEAU-225-1022-NEWS-HERALDSUN-BASELINE":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-NEWS-BASELINE.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-HERALDSUN-BASELINE.json";
        
        case "NEAU-226-1022-NEWS-HERALDSUN-FREQUENCY":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-NEWS-FREQUENCY.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-HERALDSUN-FREQUENCY.json";
        
        case "NEAU-227-1022-VOGUE-AUSTRALIAN-BASELINE":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-VOGUE-BASELINE.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-AUSTRALIAN-BASELINE.json";
        
        case "NEAU-228-1022-VOGUE-AUSTRALIAN-FREQUENCY":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-VOGUE-FREQUENCY.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-AUSTRALIAN-FREQUENCY.json";
        
        case "NEAU-229-1022-KIDSPOT-ESCAPE-BASELINE":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-KIDSPOT-BASELINE.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-ESCAPE-BASELINE.json";
            
        case "NEAU-230-1022-KIDSPOT-ESCAPE-FREQUENCY":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-KIDSPOT-FREQUENCY.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-ESCAPE-FREQUENCY.json";
        
        case "NEAU-231-1022-TASTE-DAILYTELEGRAPH-BASELINE":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-TASTE-BASELINE.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-DAILYTELEGRAPH-BASELINE.json";
        
        case "NEAU-232-1022-TASTE-DAILYTELEGRAPH-FREQUENCY":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-TASTE-FREQUENCY.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-DAILYTELEGRAPH-FREQUENCY.json";
        
        case "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-ADELAIDENOW-STANDARD.json"
                : taskIndex === 1
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-COURIERMAIL-STANDARD.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/NEAU-1022/NEAU-1022-AU-DELICIOUS-STANDARD.json";

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: NEAU1022CollGroupId, taskIndex: 0 | 1 | 2): NEAU1022CollWebPlatformId => {
    switch (groupId) {
        case "NEAU-225-1022-NEWS-HERALDSUN-BASELINE":
            return taskIndex === 0
                ? "NEAU-1022-AU-NEWS-BASELINE"
                : "NEAU-1022-AU-HERALDSUN-BASELINE";
        
        case "NEAU-226-1022-NEWS-HERALDSUN-FREQUENCY":
            return taskIndex === 0
                ? "NEAU-1022-AU-NEWS-FREQUENCY"
                : "NEAU-1022-AU-HERALDSUN-FREQUENCY";
        
        case "NEAU-227-1022-VOGUE-AUSTRALIAN-BASELINE":
            return taskIndex === 0
                ? "NEAU-1022-AU-VOGUE-BASELINE"
                : "NEAU-1022-AU-AUSTRALIAN-BASELINE";
        
        case "NEAU-228-1022-VOGUE-AUSTRALIAN-FREQUENCY":
            return taskIndex === 0
                ? "NEAU-1022-AU-VOGUE-FREQUENCY"
                : "NEAU-1022-AU-AUSTRALIAN-FREQUENCY";
        
        case "NEAU-229-1022-KIDSPOT-ESCAPE-BASELINE":
            return taskIndex === 0
                ? "NEAU-1022-AU-KIDSPOT-BASELINE"
                : "NEAU-1022-AU-ESCAPE-BASELINE";
            
        case "NEAU-230-1022-KIDSPOT-ESCAPE-FREQUENCY":
            return taskIndex === 0
                ? "NEAU-1022-AU-KIDSPOT-FREQUENCY"
                : "NEAU-1022-AU-ESCAPE-FREQUENCY";
        
        case "NEAU-231-1022-TASTE-DAILYTELEGRAPH-BASELINE":
            return taskIndex === 0
                ? "NEAU-1022-AU-TASTE-BASELINE"
                : "NEAU-1022-AU-DAILYTELEGRAPH-BASELINE";
        
        case "NEAU-232-1022-TASTE-DAILYTELEGRAPH-FREQUENCY":
            return taskIndex === 0
                ? "NEAU-1022-AU-TASTE-FREQUENCY"
                : "NEAU-1022-AU-DAILYTELEGRAPH-FREQUENCY";
        
        case "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD":
            return taskIndex === 0
                ? "NEAU-1022-AU-ADELAIDENOW-STANDARD"
                : taskIndex === 1
                ? "NEAU-1022-AU-COURIERMAIL-STANDARD"
                : "NEAU-1022-AU-DELICIOUS-STANDARD";
        
        default:
            assertNever(groupId);
    }
}

export const addNeau1022CollConfig = (
    config: NEAU1022CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE);

    let webplatformId = getWebPlatformId(config.groupId, 0);
    const webTask1 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: "General",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });
    builder.addTask(webTask1);

    webplatformId = getWebPlatformId(config.groupId, 1);
    const webTask2 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 1),
        platform: "General",
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    // Modify task Id to make it unique.
    webTask2.meta.taskID = `${webTask2.meta.taskID}-2`;
    builder.addTask(webTask2);

    if (config.groupId === "NEAU-233-1022-ADELAIDENOW-COURIERMAIL-DELICIOUS-STANDARD") {
        webplatformId = getWebPlatformId(config.groupId, 2);
        const webTask3 = generateWebPlatformTask({
            groupId: config.groupId,
            webPlatformID: webplatformId,
            webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 2),
            platform: "General",
            taskLengthMinutes: getTaskLengthMinutes(webplatformId),
            langStrings: langStrings,
        });
    
        webTask3.meta.taskID = `${webTask3.meta.taskID}-3`;
        builder.addTask(webTask3);
    }

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/NEAU-1022/${config.groupId}_INCENTIVE.json`,
        params: {},
        taskID: config.groupId + '-WEBREQUEST',
    })
    builder.addTask(webRequestTask);

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });
    builder.addTask(uploadsTask);

    return builder;
}