import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import {
    generateWebPlatformTask,
    generateUploadsTask,
    generateExternalSurveyTask, generateWebRequestTask
} from "../tasks";
import { WebPlatform } from "../types";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const GYGG0124CollGroupIds = [
    "GYGG-350-0124-US-YOUTUBE-1",
    "GYGG-351-0124-US-YOUTUBE-2",
    "GYGG-364-0124-US-FACEBOOK",
    "GYGG-365-0124-US-INSTAGRAM",
    "GYGG-366-0124-US-TIKTOK",
    "GYGG-367-0124-US-YOUTUBE-3",
    "GYGG-368-0124-UK-YOUTUBE-4",
    "GYGG-370-0124-US-YOUTUBE-5",
    "GYGG-371-0124-UK-YOUTUBE-6",
] as const;

export type GYGG0124CollGroupId = typeof GYGG0124CollGroupIds[number];

const GYGG0124CollWebPlatformIds = [
    'GYGG-0124-US-YOUTUBE-1',
    'GYGG-0124-US-YOUTUBE-2',
    "GYGG-0124-US-FACEBOOK",
    "GYGG-0124-US-INSTAGRAM",
    "GYGG-0124-US-TIKTOK",
    'GYGG-0124-US-YOUTUBE-3',
    'GYGG-0124-UK-YOUTUBE-4',
    'GYGG-0124-US-YOUTUBE-5',
    'GYGG-0124-UK-YOUTUBE-6',
] as const;

export type GYGG0124CollWebPlatformId = typeof GYGG0124CollWebPlatformIds[number]

type GYGG0124CollConfig = {
    groupId: GYGG0124CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: GYGG0124CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "GYGG-0124-US-YOUTUBE-1":
        case "GYGG-0124-US-YOUTUBE-2":
        case "GYGG-0124-US-FACEBOOK":
        case "GYGG-0124-US-INSTAGRAM":
        case "GYGG-0124-US-TIKTOK":
        case 'GYGG-0124-US-YOUTUBE-3':
        case 'GYGG-0124-UK-YOUTUBE-4':
        case 'GYGG-0124-US-YOUTUBE-5':
        case 'GYGG-0124-UK-YOUTUBE-6':
            return "10";

        default:
            assertNever(webPlatformId);
    }
}

const getSurveyUrl = (groupId: GYGG0124CollGroupId): string => {
    switch (groupId) {
        case 'GYGG-350-0124-US-YOUTUBE-1':
        case 'GYGG-351-0124-US-YOUTUBE-2':
            return 'https://survey.au1.qualtrics.com/jfe/form/SV_87Vo4KS48POpFvU';
        case "GYGG-364-0124-US-FACEBOOK":
            return 'https://survey.au1.qualtrics.com/jfe/form/SV_3lqz7DZxuqDvWhE';
        case "GYGG-365-0124-US-INSTAGRAM":
            return 'https://survey.au1.qualtrics.com/jfe/form/SV_0dlpPa4aHDEuDGK';
        case "GYGG-366-0124-US-TIKTOK":
            return 'https://survey.au1.qualtrics.com/jfe/form/SV_9Y037pwZcTJYenc';
        case "GYGG-367-0124-US-YOUTUBE-3":
        case "GYGG-370-0124-US-YOUTUBE-5":
            return 'https://survey.au1.qualtrics.com/jfe/form/SV_77COowSaLISqzJA';
        case "GYGG-368-0124-UK-YOUTUBE-4":
        case "GYGG-371-0124-UK-YOUTUBE-6":
            return 'https://survey.au1.qualtrics.com/jfe/form/SV_51IkASWOgJQDVFI';
        default:
            assertNever(groupId);
    }
}

const getWebPlatformConfigUrl = (groupId: GYGG0124CollGroupId): string => {
    switch (groupId) {
        case "GYGG-350-0124-US-YOUTUBE-1":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-US-YOUTUBE-1.json";
        case "GYGG-351-0124-US-YOUTUBE-2":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-US-YOUTUBE-2.json";
        case "GYGG-364-0124-US-FACEBOOK":
            return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-US-FACEBOOK.json';
        case "GYGG-365-0124-US-INSTAGRAM":
            return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-US-INSTAGRAM.json';
        case "GYGG-366-0124-US-TIKTOK":
            return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-US-TIKTOK.json';
        case "GYGG-367-0124-US-YOUTUBE-3":
            return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-US-YOUTUBE-3.json';
        case "GYGG-368-0124-UK-YOUTUBE-4":
            return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-UK-YOUTUBE-4.json';
        case "GYGG-370-0124-US-YOUTUBE-5":
            return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-US-YOUTUBE-5.json';
        case "GYGG-371-0124-UK-YOUTUBE-6":
            return 'https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/GYGG-0124/GYGG-0124-UK-YOUTUBE-6.json';
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: GYGG0124CollGroupId): GYGG0124CollWebPlatformId => {
    switch (groupId) {
        case "GYGG-350-0124-US-YOUTUBE-1":
            return "GYGG-0124-US-YOUTUBE-1";
        case "GYGG-351-0124-US-YOUTUBE-2":
            return "GYGG-0124-US-YOUTUBE-2";
        case "GYGG-364-0124-US-FACEBOOK":
            return 'GYGG-0124-US-FACEBOOK';
        case "GYGG-365-0124-US-INSTAGRAM":
            return 'GYGG-0124-US-INSTAGRAM';
        case "GYGG-366-0124-US-TIKTOK":
            return 'GYGG-0124-US-TIKTOK';
        case "GYGG-367-0124-US-YOUTUBE-3":
            return 'GYGG-0124-US-YOUTUBE-3';
        case "GYGG-368-0124-UK-YOUTUBE-4":
            return 'GYGG-0124-UK-YOUTUBE-4'
        case "GYGG-370-0124-US-YOUTUBE-5":
            return 'GYGG-0124-US-YOUTUBE-5'
        case "GYGG-371-0124-UK-YOUTUBE-6":
            return 'GYGG-0124-UK-YOUTUBE-6'
        default:
            assertNever(groupId);
    }
}

const isWebRequest = (groupId: GYGG0124CollGroupId): boolean => {
    return [
        "GYGG-364-0124-US-FACEBOOK",
        "GYGG-365-0124-US-INSTAGRAM",
        "GYGG-366-0124-US-TIKTOK"
    ].includes(groupId);
}

const getPlatform = (groupId: GYGG0124CollGroupId): WebPlatform => {
    switch (groupId) {
        case "GYGG-350-0124-US-YOUTUBE-1":
        case "GYGG-351-0124-US-YOUTUBE-2":
        case "GYGG-367-0124-US-YOUTUBE-3":
        case "GYGG-368-0124-UK-YOUTUBE-4":
        case "GYGG-370-0124-US-YOUTUBE-5":
        case "GYGG-371-0124-UK-YOUTUBE-6":
            return "Youtube";
        case "GYGG-364-0124-US-FACEBOOK":
            return 'Facebook';
        case "GYGG-365-0124-US-INSTAGRAM":
            return 'Instagram';
        case "GYGG-366-0124-US-TIKTOK":
            return 'TikTok';
        default:
            assertNever(groupId);
    }
}

export const addGYGG0124CollConfig = (
    config: GYGG0124CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
    .setFlag('deviceLock', 'mobile')
    .setFlag('requireMinimumBattery', 0.3)
    .setFlag('preventTaskProgress', false)
    .setFlag('finishMessageOverride', FINISH_MESSAGE);

    let webplatformId = getWebPlatformId(config.groupId);
    const webplatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: getPlatform(config.groupId),
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder.addTask(webplatformTask)

    if (isWebRequest(config.groupId)) {
        const webRequestTask = generateWebRequestTask({
            webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/GYGG-0124/${config.groupId}_INCENTIVE.json`,
            params: {},
            taskID: config.groupId + '-WEBREQUEST',
        });
        builder.addTask(webRequestTask);
    }
    else {
        const surveyTask = generateExternalSurveyTask({
            title: langStrings.Common.survey,
            description: langStrings.Survey.description,
            extraMetaFields: config.metaFieldNames,
            taskID: `${config.groupId}-SURVEY`,
            surveyUrl: getSurveyUrl(config.groupId),
        });
        builder.addTask(surveyTask)
    }

    builder.addTask(uploadsTask);

    return builder;
}