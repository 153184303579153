import { applyMiddleware, compose, createStore } from 'redux';
import logger from 'redux-logger'
import reduxThunk from 'redux-thunk';
import { StateType } from 'typesafe-actions';

import rootReducer from './root-reducer';

export type RootState = StateType<typeof rootReducer>

// Load Redux Thunk
let middleware = applyMiddleware(reduxThunk);

// Redux Dev Tools & Logger
if (process.env.NODE_ENV !== 'production') {
    middleware = applyMiddleware(reduxThunk, logger)
    const windowFix = window as any;
    const devToolsExtension = windowFix.__REDUX_DEVTOOLS_EXTENSION__;
    if (typeof devToolsExtension === 'function') {
        middleware = compose(middleware, devToolsExtension());
    }
}

// Create Store
const store = createStore(rootReducer, middleware);

// Enable Reducer hot reloading
if (module.hot) {
    module.hot.accept('./root-reducer', () => {
        store.replaceReducer(rootReducer)
    });
}

export default store;