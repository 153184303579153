import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebRequestTask, generateWebPlatformTask } from "../tasks";
import { WebPlatform } from "../types";

const TWIT1221CollBaselineGroupIds = [
    // UK
    'TWIT-30-1221-UK-TWITTER',
    'TWIT-31-1221-UK-INSTAGRAM',
    'TWIT-32-1221-UK-FACEBOOK',
    'TWIT-33-1221-UK-TIKTOK',
    'TWIT-34-1221-UK-YOUTUBE',

    // CAN
    'TWIT-38-1221-CAN-TWITTER',
    'TWIT-39-1221-CAN-INSTAGRAM',
    'TWIT-40-1221-CAN-FACEBOOK',
    'TWIT-41-1221-CAN-TIKTOK',
    'TWIT-42-1221-CAN-YOUTUBE',

    // FRA
    'TWIT-46-1221-FRA-TWITTER',
    'TWIT-47-1221-FRA-INSTAGRAM',
    'TWIT-48-1221-FRA-FACEBOOK',
    'TWIT-49-1221-FRA-TIKTOK',
    'TWIT-50-1221-FRA-YOUTUBE',

    // MEX
    'TWIT-54-1221-MEX-TWITTER',
    'TWIT-55-1221-MEX-INSTAGRAM',
    'TWIT-56-1221-MEX-FACEBOOK',
    'TWIT-57-1221-MEX-TIKTOK',
    'TWIT-58-1221-MEX-YOUTUBE',
] as const

const TWIT1221CollSynergyGroupIds = [
   'TWIT-35-1221-UK-TWITTER-TIKTOK',
   'TWIT-36-1221-UK-TWITTER-INSTAGRAM',
   'TWIT-37-1221-UK-TWITTER-FACEBOOK',

   'TWIT-43-1221-CAN-TWITTER-TIKTOK',
   'TWIT-44-1221-CAN-TWITTER-INSTAGRAM',
   'TWIT-45-1221-CAN-TWITTER-FACEBOOK',

   'TWIT-51-1221-FRA-TWITTER-TIKTOK',
   'TWIT-52-1221-FRA-TWITTER-INSTAGRAM',
   'TWIT-53-1221-FRA-TWITTER-FACEBOOK',

   'TWIT-59-1221-MEX-TWITTER-TIKTOK',
   'TWIT-60-1221-MEX-TWITTER-INSTAGRAM',
   'TWIT-61-1221-MEX-TWITTER-FACEBOOK',
] as const

export const TWIT1221CollAllGroupIds = [
    ...TWIT1221CollBaselineGroupIds,
    ...TWIT1221CollSynergyGroupIds,
] as const;

type TWIT1221CollBaselineGroupId = typeof TWIT1221CollBaselineGroupIds[number]
type TWIT1221CollSynergyGroupId = typeof TWIT1221CollSynergyGroupIds[number]

export type TWIT1221CollGroupId =
    | TWIT1221CollBaselineGroupId
    | TWIT1221CollSynergyGroupId

const isSynergyGroupId = (groupId: TWIT1221CollGroupId): groupId is TWIT1221CollSynergyGroupId => {
    return (TWIT1221CollSynergyGroupIds as ReadonlyArray<string>).includes(groupId)
}

type Twit1221CollConfig = {
    groupId: TWIT1221CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getPlatformForGroup = (groupId: TWIT1221CollGroupId, taskIndex: 0 | 1): WebPlatform => {
    switch (groupId) {
        case "TWIT-30-1221-UK-TWITTER":
        case "TWIT-38-1221-CAN-TWITTER":
        case "TWIT-46-1221-FRA-TWITTER":
        case "TWIT-54-1221-MEX-TWITTER": {
            return "Twitter"
        }

        case "TWIT-31-1221-UK-INSTAGRAM":
        case "TWIT-39-1221-CAN-INSTAGRAM":
        case "TWIT-47-1221-FRA-INSTAGRAM":
        case "TWIT-55-1221-MEX-INSTAGRAM": {
            return "Instagram"
        }

        case "TWIT-32-1221-UK-FACEBOOK":
        case "TWIT-40-1221-CAN-FACEBOOK":
        case "TWIT-48-1221-FRA-FACEBOOK":
        case "TWIT-56-1221-MEX-FACEBOOK": {
            return "Facebook"
        }

        case "TWIT-34-1221-UK-YOUTUBE":
        case "TWIT-42-1221-CAN-YOUTUBE":
        case "TWIT-50-1221-FRA-YOUTUBE":
        case "TWIT-58-1221-MEX-YOUTUBE": {
            return "Youtube"
        }

        case "TWIT-36-1221-UK-TWITTER-INSTAGRAM":
        case "TWIT-44-1221-CAN-TWITTER-INSTAGRAM":
        case "TWIT-52-1221-FRA-TWITTER-INSTAGRAM":
        case "TWIT-60-1221-MEX-TWITTER-INSTAGRAM": {
            return taskIndex === 0 ? "Twitter" : "Instagram"
        }

        case "TWIT-37-1221-UK-TWITTER-FACEBOOK":
        case "TWIT-45-1221-CAN-TWITTER-FACEBOOK":
        case "TWIT-53-1221-FRA-TWITTER-FACEBOOK":
        case "TWIT-61-1221-MEX-TWITTER-FACEBOOK": {
            return taskIndex === 0 ? "Twitter" : "Facebook"
        }

        case "TWIT-33-1221-UK-TIKTOK":
        case "TWIT-41-1221-CAN-TIKTOK":
        case "TWIT-49-1221-FRA-TIKTOK":
        case "TWIT-57-1221-MEX-TIKTOK":
            return "TikTok"

        case "TWIT-35-1221-UK-TWITTER-TIKTOK":
        case "TWIT-43-1221-CAN-TWITTER-TIKTOK":
        case "TWIT-51-1221-FRA-TWITTER-TIKTOK":
        case "TWIT-59-1221-MEX-TWITTER-TIKTOK":
            return taskIndex === 0 ? "Twitter" : "TikTok"

        default:
            assertNever(groupId);
    }
}

const getWebPlatformConfigUrl = (groupId: TWIT1221CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "TWIT-30-1221-UK-TWITTER": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-TWITTER.json"
        case "TWIT-38-1221-CAN-TWITTER": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-TWITTER.json"
        case "TWIT-46-1221-FRA-TWITTER": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-TWITTER.json"
        case "TWIT-54-1221-MEX-TWITTER": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-TWITTER.json"

        case "TWIT-31-1221-UK-INSTAGRAM": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-INSTAGRAM.json"
        case "TWIT-39-1221-CAN-INSTAGRAM": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-INSTAGRAM.json"
        case "TWIT-47-1221-FRA-INSTAGRAM": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-INSTAGRAM.json"
        case "TWIT-55-1221-MEX-INSTAGRAM": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-INSTAGRAM.json"

        case "TWIT-32-1221-UK-FACEBOOK": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-FACEBOOK.json"
        case "TWIT-40-1221-CAN-FACEBOOK": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-FACEBOOK.json"
        case "TWIT-48-1221-FRA-FACEBOOK": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-FACEBOOK.json"
        case "TWIT-56-1221-MEX-FACEBOOK": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-FACEBOOK.json"

        case "TWIT-34-1221-UK-YOUTUBE": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-YOUTUBE.json"
        case "TWIT-42-1221-CAN-YOUTUBE": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-YOUTUBE.json"
        case "TWIT-50-1221-FRA-YOUTUBE": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-YOUTUBE.json"
        case "TWIT-58-1221-MEX-YOUTUBE": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-YOUTUBE.json"

        case "TWIT-33-1221-UK-TIKTOK":  return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-TIKTOK.json"
        case "TWIT-41-1221-CAN-TIKTOK": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-TIKTOK.json"
        case "TWIT-49-1221-FRA-TIKTOK": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-TIKTOK.json"
        case "TWIT-57-1221-MEX-TIKTOK": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-TIKTOK.json"

        case "TWIT-35-1221-UK-TWITTER-TIKTOK":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-TIKTOK.json";

        case "TWIT-43-1221-CAN-TWITTER-TIKTOK":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-TIKTOK.json";

        case "TWIT-51-1221-FRA-TWITTER-TIKTOK":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-TIKTOK.json";

        case "TWIT-59-1221-MEX-TWITTER-TIKTOK":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-TIKTOK.json";

        case "TWIT-36-1221-UK-TWITTER-INSTAGRAM":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-INSTAGRAM.json"

        case "TWIT-44-1221-CAN-TWITTER-INSTAGRAM":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-INSTAGRAM.json"

        case "TWIT-52-1221-FRA-TWITTER-INSTAGRAM":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-INSTAGRAM.json"

        case "TWIT-60-1221-MEX-TWITTER-INSTAGRAM":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-INSTAGRAM.json"

        case "TWIT-37-1221-UK-TWITTER-FACEBOOK":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-UK-FACEBOOK.json"

        case "TWIT-45-1221-CAN-TWITTER-FACEBOOK":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-CAN-FACEBOOK.json"

        case "TWIT-53-1221-FRA-TWITTER-FACEBOOK":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-FRA-FACEBOOK.json"

        case "TWIT-61-1221-MEX-TWITTER-FACEBOOK":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-TWITTER.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/TWIT-1221/TWIT-1221-MEX-FACEBOOK.json"

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: TWIT1221CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "TWIT-30-1221-UK-TWITTER": return "TWIT-1221-UK-TWITTER"
        case "TWIT-31-1221-UK-INSTAGRAM": return "TWIT-1221-UK-INSTAGRAM"
        case "TWIT-32-1221-UK-FACEBOOK": return "TWIT-1221-UK-FACEBOOK"
        case "TWIT-34-1221-UK-YOUTUBE": return "TWIT-1221-UK-YOUTUBE"
        case "TWIT-37-1221-UK-TWITTER-FACEBOOK":
            return taskIndex === 0
                ? "TWIT-1221-UK-TWITTER"
                : "TWIT-1221-UK-FACEBOOK"

        case "TWIT-36-1221-UK-TWITTER-INSTAGRAM":
            return taskIndex === 0
                ? "TWIT-1221-UK-TWITTER"
                : "TWIT-1221-UK-INSTAGRAM"

        case "TWIT-38-1221-CAN-TWITTER": return "TWIT-1221-CAN-TWITTER"
        case "TWIT-39-1221-CAN-INSTAGRAM": return "TWIT-1221-CAN-INSTAGRAM"
        case "TWIT-40-1221-CAN-FACEBOOK": return "TWIT-1221-CAN-FACEBOOK"
        case "TWIT-42-1221-CAN-YOUTUBE": return "TWIT-1221-CAN-YOUTUBE"
        case "TWIT-45-1221-CAN-TWITTER-FACEBOOK":
            return taskIndex === 0
            ? "TWIT-1221-CAN-TWITTER"
            : "TWIT-1221-CAN-FACEBOOK"

        case "TWIT-44-1221-CAN-TWITTER-INSTAGRAM":
            return taskIndex === 0
                ? "TWIT-1221-CAN-TWITTER"
                : "TWIT-1221-CAN-INSTAGRAM"

        case "TWIT-46-1221-FRA-TWITTER": return "TWIT-1221-FRA-TWITTER"
        case "TWIT-47-1221-FRA-INSTAGRAM": return "TWIT-1221-FRA-INSTAGRAM"
        case "TWIT-48-1221-FRA-FACEBOOK": return "TWIT-1221-FRA-FACEBOOK"
        case "TWIT-50-1221-FRA-YOUTUBE": return "TWIT-1221-FRA-YOUTUBE"
        case "TWIT-52-1221-FRA-TWITTER-INSTAGRAM":
            return taskIndex === 0
                ? "TWIT-1221-FRA-TWITTER"
                : "TWIT-1221-FRA-INSTAGRAM"

        case "TWIT-53-1221-FRA-TWITTER-FACEBOOK":
            return taskIndex === 0
                ? "TWIT-1221-FRA-TWITTER"
                : "TWIT-1221-FRA-FACEBOOK"

        case "TWIT-54-1221-MEX-TWITTER": return "TWIT-1221-MEX-TWITTER"
        case "TWIT-55-1221-MEX-INSTAGRAM": return "TWIT-1221-MEX-INSTAGRAM"
        case "TWIT-56-1221-MEX-FACEBOOK": return "TWIT-1221-MEX-FACEBOOK"
        case "TWIT-58-1221-MEX-YOUTUBE": return "TWIT-1221-MEX-YOUTUBE"
        case "TWIT-60-1221-MEX-TWITTER-INSTAGRAM":
            return taskIndex === 0
                ? "TWIT-1221-MEX-TWITTER"
                : "TWIT-1221-MEX-INSTAGRAM"

        case "TWIT-61-1221-MEX-TWITTER-FACEBOOK":
            return taskIndex === 0
                ? "TWIT-1221-MEX-TWITTER"
                : "TWIT-1221-MEX-FACEBOOK"

        case "TWIT-33-1221-UK-TIKTOK":  return "TWIT-1221-UK-TIKTOK"
        case "TWIT-41-1221-CAN-TIKTOK": return "TWIT-1221-CAN-TIKTOK"
        case "TWIT-49-1221-FRA-TIKTOK": return "TWIT-1221-FRA-TIKTOK"
        case "TWIT-57-1221-MEX-TIKTOK": return "TWIT-1221-MEX-TIKTOK"

        case "TWIT-35-1221-UK-TWITTER-TIKTOK":
            return taskIndex === 0
                ? "TWIT-1221-UK-TWITTER"
                : "TWIT-1221-UK-TIKTOK";

        case "TWIT-43-1221-CAN-TWITTER-TIKTOK":
            return taskIndex === 0
                ? "TWIT-1221-CAN-TWITTER"
                : "TWIT-1221-CAN-TIKTOK";

        case "TWIT-51-1221-FRA-TWITTER-TIKTOK":
            return taskIndex === 0
                ? "TWIT-1221-FRA-TWITTER"
                : "TWIT-1221-FRA-TIKTOK";

        case "TWIT-59-1221-MEX-TWITTER-TIKTOK":
            return taskIndex === 0
                ? "TWIT-1221-MEX-TWITTER"
                : "TWIT-1221-MEX-TIKTOK";

        default: assertNever(groupId);
    }
}

export const addTwit1221CollConfig = (
    config: Twit1221CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder.setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3);

    const webTask1 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId, 0),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: getPlatformForGroup(config.groupId, 0),
        taskLengthMinutes: "",
        langStrings,
    })
    builder.addTask(webTask1)

    if (isSynergyGroupId(config.groupId)) {
        const webTask2 = generateWebPlatformTask({
            groupId: config.groupId,
            webPlatformID: getWebPlatformId(config.groupId, 1),
            webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 1),
            platform: getPlatformForGroup(config.groupId, 1),
            taskLengthMinutes: "",
            langStrings,
        })
        builder.addTask(webTask2)
    }

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/TWIT-1221/${config.groupId}_INCENTIVE.json`,
        params: {},
        taskID: config.groupId + '-WEBREQUEST',
    })
    builder.addTask(webRequestTask)

    return builder
}