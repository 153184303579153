import * as React from 'react'
import { Grid, Typography, Divider } from '@mui/material'
import { makeStyles } from 'tss-react/mui'

const useStyles = makeStyles()(theme => ({
    btnWrapper: {
        display: 'flex',
        justifyContent: 'center'
    },
    btn: {
        marginTop: theme.spacing(2),
    },
    divider: {
        margin: theme.spacing(1, 0, 1, 0)
    }
}))

interface Props {
    previewMode: boolean
}

const MakeupHitInstructions: React.FC<Props> = ({ previewMode }) => {
    const { classes } = useStyles()

    let footer: JSX.Element
    if (previewMode) {
        footer = <>
            <Typography align="center" variant="h6">We are unable to verify your WorkerID until you accept the HIT.</Typography>
            <Typography align="center" >Please accept and submit the HIT so we can compensate you with a bonus.</Typography>
        </>
    } else {
        footer = <Typography align="center" component="p">We have verified your WorkerID is eligible for compensation, submit this HIT and your Bonus will be processed within the next few days.</Typography>
    }

    return <>
        <Grid container direction="column" justifyContent="center" alignItems="center" alignContent="center">
            <Grid item>
                <Typography variant="h6" align="center" gutterBottom>You can not complete this HIT unless you have been sent here after contacting Amplified Intelligence.</Typography>

                <Divider className={classes.divider} variant="middle" />

                <Typography align="center" component="p">The purpose of this HIT is to allow us to compensate workers affected by a bug in one of our previous HITs.</Typography>
                <Typography align="center" gutterBottom component="p">If you were not affected by an error in our previous HITs and directed to this HIT for compensation please ignore this HIT.</Typography>

                <Divider className={classes.divider} variant="middle" />
                {footer}
            </Grid>
        </Grid>
    </>
}

export default MakeupHitInstructions
