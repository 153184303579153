import { MTurkParams, QueryParams, MturkParamsPreview, MturkParamsPreviewOrAccepted, MturkParamsAccepted } from "../queryParams";
import { MturkGroupId } from '../Respondent/ConfigDataStores/mturk';

export const isMturkParams = (params: QueryParams): params is MTurkParams => {
    if (params.__type === 'MTURK_PREVIEW_PARAMS') {
        return true;
    }

    if (params.__type === 'MTURK_PARAMS') {
        return true;
    }

    return false;
};

export function isPreview<G extends MturkGroupId>(params: MturkParamsPreviewOrAccepted<G>): params is MturkParamsPreview<G> {
    if (params.__type !== 'MTURK_PREVIEW_PARAMS') {
        return false;
    }

    if (params.assignmentId === 'ASSIGNMENT_ID_NOT_AVAILABLE') {
        return true;
    }

    return false;
};

export const isSandBox = (params: QueryParams): boolean => {
    return isMturkParams(params) && params.turkSubmitTo.toLowerCase().includes('workersandbox')
};

export const isMakeupHit = (params: MTurkParams): boolean => {
    return params.G === 'MTURK-MAKEUP';
};

export const hasCompletedSurvey = (params: MturkParamsAccepted<MturkGroupId>): boolean => {
    return params.completedDemographicSurvey === true
}