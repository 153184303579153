import React, { useState, useCallback } from "react";

import { LandingPageConfig, LandingPageStep } from "../reducer";

import MturkGazeDesktopInstructions from "./Steps/MturkGazeDesktopInstructions";
import MturkGazeDesktopRequirements from "./Steps/MturkGazeDesktopRequirements";
import MturkGazeDesktopCompletion, {
    CompletionDesktopState,
} from "./Steps/MturkGazeDesktopCompletion";
import MakeupHitInstructions from "./Steps/MakeupHitInstructions";
import MturkGazeInstructions from "./Steps/MturkGazeInstructions";
import MturkGazeRequirements from "./Steps/MturkGazeRequirements";
import MturkSurveyRedirect from "./Steps/MturkSurveyRedirect";
import ViewingInstructions from "./Steps/ViewingInstructions";
import AppDownload from "./Steps/AppDownload";
import LoginAndCompletionCode, {
    CompletionCodeState,
} from "./Steps/LoginAndCompletionCode";
import AppDownloadDeskSolution from "./Steps/AppDownloadDeskSolution";
import LoginAndCompletionDeskSolution from "./Steps/LoginAndCompletionDeskSolution";
import ViewingInstructionsDeskSolution from "./Steps/ViewingInstructionsDeskSolution";
import NavButtons from "./NavButtons";
import { useStyles } from "./styles";
import { submitHIT } from "../../MTurk/actions";
import { Stepper, Step, StepLabel, Divider, Alert } from "@mui/material";
import { getCurrentLangStrings } from "../../i18n";
import SupportEmailLink from "./SupportEmailLink";
import SupportEmailLinkDeskSolution from "./SupportEmailLinkDeskSolution";
import { checkIsMacBookChromeSafariFirefox } from '../../utils';
import SessionInformationDeskSolution from "./Steps/SessionInformationDeskSolution";
import PrivacyPolicyLink from "./PrivacyPolicyLink";
import Setup from "./Steps/Setup";
import StartEarning from "./Steps/StartEarning";

const renderStep = (
    step: LandingPageStep,
    config: LandingPageConfig,
    setCanSubmit: (canSubmit: boolean) => void,
    setCodeInput: (value: string) => void
) => {
    switch (step.stepComponentKey) {
        case "MturkSurveyRedirect":
            return <MturkSurveyRedirect params={config.params} />;
        case "MturkGazeInstructions":
            return (
                <MturkGazeInstructions showSurveyAlert={config.previewMode} />
            );
        case "MturkGazeRequirements":
            return <MturkGazeRequirements />;
        case "MakeupHitInstructions":
            return <MakeupHitInstructions previewMode={config.previewMode} />;
        case "ViewingInstructions":
            return <ViewingInstructions />;
        case "ViewingInstructionsDeskSolution": {
            switch (step.props._type) {
                case "platform":
                    return (
                        <ViewingInstructionsDeskSolution
                            loginId={config.loginId}
                            platform={step.props.platform}
                        />
                    );
            }
            break;
        }
        case "AppDownload":
            return <AppDownload />;
        case "AppDownloadDeskSolution":
        return <AppDownloadDeskSolution />;
        case "LoginAndCompletionCode": {
            switch (step.props._type) {
                case "completionCode":
                    return (
                        <LoginAndCompletionCode
                            loginId={config.loginId}
                            completionCodeStateChanged={(s, i) => {
                                s === CompletionCodeState.valid
                                    ? setCanSubmit(true)
                                    : setCanSubmit(false);
                                setCodeInput(i);
                            }}
                            completionCode={step.props.completionCode}
                        />
                    );
                case "platform":
                    return (
                        <LoginAndCompletionCode
                            loginId={config.loginId}
                            platform={step.props.platform}
                        />
                    );
            }
            break;
        }
        case "LoginAndCompletionDeskSolution": {
            switch (step.props._type) {
                case "completionCodeDeskSolution":
                    return (
                        <LoginAndCompletionDeskSolution
                            loginId={config.loginId}
                            completionCodeStateChanged={(s, i) => {
                                s === CompletionCodeState.valid
                                    ? setCanSubmit(true)
                                    : setCanSubmit(false);
                                setCodeInput(i);
                            }}
                            completionCode={step.props.completionCode}
                        />
                    );
                case "platform":
                    return (
                        <LoginAndCompletionDeskSolution
                            loginId={config.loginId}
                            platform={step.props.platform}
                        />
                    );
            }
            break;
        }
        case "SessionInformationDeskSolution": {
            switch (step.props._type) {
                case "platform":
                    return (
                        <SessionInformationDeskSolution
                            loginId={config.loginId}
                            platform={step.props.platform}
                        />
                    );
            }
            break;
        }
        case "MturkGazeDesktopInstructions": {
            return (
                <MturkGazeDesktopInstructions
                    previewMode={config.previewMode}
                />
            );
        }
        case "MturkGazeDesktopRequirements": {
            return <MturkGazeDesktopRequirements />;
        }
        case "MturkGazeDesktopCompletion": {
            switch (step.props._type) {
                case "completionCode":
                    return (
                        <MturkGazeDesktopCompletion
                            loginId={config.loginId}
                            completionCodeStateChanged={(s, i) => {
                                s === CompletionDesktopState.valid
                                    ? setCanSubmit(true)
                                    : setCanSubmit(false);
                                setCodeInput(i);
                            }}
                            completionCode={step.props.completionCode}
                        />
                    );
            }
            break;
        }
        case "Setup":
            return <Setup loginId={config.loginId} />;
            
        case "StartEarning":
            if (step.props._type === "platform") { 
                return <StartEarning platform={step.props.platform}/>
            }
            return <></>;
        default:
            assertNever(step);
    }
};

interface Props {
    config: LandingPageConfig;
    onSubmit: () => void;
}

const StepsRenderer: React.FC<Props> = (props) => {
    const { config, onSubmit: onSubmitCallback } = props;
    const { classes } = useStyles();
    const lang = getCurrentLangStrings();
    const [activeStep, setActiveStep] = useState(0);
    const [canSubmit, setCanSubmit] = useState(false);
    const [codeInput, setCodeInput] = useState("");
    const onPrevStep = () => setActiveStep(activeStep - 1);
    const onNextStep = () => setActiveStep(activeStep + 1);

    const onSubmit = useCallback(() => {
        setCanSubmit(false);
        if (config.submitToMturk) {
            submitHIT(config.params, config.loginId, codeInput);
            onSubmitCallback();
        }
    }, [config, setCanSubmit, codeInput, onSubmitCallback]);

    const step = config.steps[activeStep];
    
    // Don't render NavButtons or Stepper if redirectng
    if (step.stepComponentKey === "MturkSurveyRedirect") {
        return renderStep(step, config, setCanSubmit, setCodeInput);
    }

    if (step.stepComponentKey === "MakeupHitInstructions") {
        return (
            <>
                {renderStep(step, config, setCanSubmit, setCodeInput)}

                <div className={classes.buttons}>
                    <NavButtons
                        activeStep={activeStep}
                        disableSubmit={config.previewMode}
                        hideSubmit={false}
                        numOfSteps={config.steps.length}
                        onNext={onNextStep}
                        onPrev={onPrevStep}
                        onSubmit={onSubmit}
                    />
                </div>
                <Divider className={classes.divider} variant="middle" />
                <SupportEmailLink supportEmail={config.supportEmail} />
                <PrivacyPolicyLink />
            </>
        );
    }

    if (step.stepComponentKey === "MturkGazeDesktopInstructions" && !checkIsMacBookChromeSafariFirefox()) {
        let Notification;
        if (config.previewMode) {
            Notification =  <Alert severity="error"> Sorry, this HIT is only available for <strong>MacBook users using the Safari, Firefox or Google Chrome browser.</strong> Please skip this HIT. </Alert>;
        } else {
            Notification =  <Alert severity="error"> Sorry, this HIT is only available for <strong>MacBook users using the Safari, Firefox or Google Chrome browser.</strong> Please return this HIT. </Alert>;
        }
        return (
            <>  
                {Notification}
                <Stepper
                    activeStep={activeStep}
                    className={classes.stepper}
                    alternativeLabel
                >
                    {config.steps.map((step, i) => (
                        <Step key={i}>
                            <StepLabel>
                                <div className={classes.fontOfeliaBold}>
                                    {lang.Steps.StepLabel[step.titleL18nKey]}
                                </div>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                {renderStep(step, config, setCanSubmit, setCodeInput)}

                <div className={classes.buttons}>
                    <NavButtons
                        activeStep={activeStep}
                        disableSubmit={config.previewMode}
                        hideSubmit={false}
                        numOfSteps={config.steps.length}
                        onNext={onNextStep}
                        onPrev={onPrevStep}
                        onSubmit={onSubmit}
                    />
                </div>
                <Divider className={classes.divider} variant="middle" />
                <SupportEmailLink supportEmail={config.supportEmail} />
                <PrivacyPolicyLink />
            </>
        );
    }

    if (step.stepComponentKey === "ViewingInstructionsDeskSolution" || step.stepComponentKey === "AppDownloadDeskSolution" || step.stepComponentKey === "LoginAndCompletionDeskSolution" || step.stepComponentKey === "SessionInformationDeskSolution") {
        return (
            <>
                <Stepper
                    activeStep={activeStep}
                    className={classes.stepper}
                    alternativeLabel
                >
                    {config.steps.map((step, i) => (
                        <Step key={i}>
                            <StepLabel>
                                <div className={classes.fontOfeliaBold}>
                                    {lang.Steps.StepLabel[step.titleL18nKey]}
                                </div>
                            </StepLabel>
                        </Step>
                    ))}
                </Stepper>
                
                {renderStep(step, config, setCanSubmit, setCodeInput)}

                <div className={classes.buttons}>
                    <NavButtons
                        activeStep={activeStep}
                        hideSubmit={true}
                        numOfSteps={config.steps.length}
                        onNext={onNextStep}
                        onPrev={onPrevStep}
                        onSubmit={onSubmit} 
                        disableSubmit={true}
                    />
                </div>
                <Divider className={classes.divider} variant="middle" />
                <SupportEmailLinkDeskSolution/>
                <PrivacyPolicyLink />
            </>
        );
    }

    return (
        <>
            <Stepper
                activeStep={activeStep}
                className={classes.stepper}
                alternativeLabel
            >
                {config.steps.map((step, i) => (
                    <Step key={i}>
                        <StepLabel>
                            <div className={classes.fontOfeliaBold}>
                                {lang.Steps.StepLabel[step.titleL18nKey]}
                            </div>
                        </StepLabel>
                    </Step>
                ))}
            </Stepper>

            {renderStep(step, config, setCanSubmit, setCodeInput)}

            <div className={classes.buttons}>
                <NavButtons
                    activeStep={activeStep}
                    disableSubmit={
                        config.previewMode ||
                        !config.submitToMturk ||
                        !canSubmit
                    }
                    hideSubmit={!config.submitToMturk}
                    numOfSteps={config.steps.length}
                    onNext={onNextStep}
                    onPrev={onPrevStep}
                    onSubmit={onSubmit}
                />
            </div>
            <Divider className={classes.divider} variant="middle" />
            <SupportEmailLink supportEmail={config.supportEmail} />
            <PrivacyPolicyLink />
        </>
    );
};

export default StepsRenderer;
