import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateUploadsTask, generateWebRequestTask } from "../tasks";
import { WebPlatform } from '../types';

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your incentive has been processed. Thank you for your time.";

export const ALLW0424CollGroupIds = [
    "ALLW-372-0424-UK-LOTTO-FACEBOOK",
    "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK",
    "ALLW-374-0424-UK-LOTTO-INSTAGRAM",
    "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM",
    "ALLW-376-0424-UK-LOTTO-TIKTOK",
    "ALLW-377-0424-UK-LOTTO-YOUTUBE",
    "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE"
] as const;

export type ALLW0424CollGroupId = typeof ALLW0424CollGroupIds[number];

export const ALLW0424CollWebPlatformIds = [
    "ALLW-0424-UK-LOTTO-FACEBOOK",
    "ALLW-0424-UK-SETFORLIFE-FACEBOOK",
    "ALLW-0424-UK-LOTTO-INSTAGRAM",
    "ALLW-0424-UK-SETFORLIFE-INSTAGRAM",
    "ALLW-0424-UK-LOTTO-TIKTOK",
    "ALLW-0424-UK-LOTTO-YOUTUBE",
    "ALLW-0424-UK-SETFORLIFE-YOUTUBE"
] as const;

export type ALLW0424CollWebPlatformId = typeof ALLW0424CollWebPlatformIds[number]

type ALLW0424CollConfig = {
    groupId: ALLW0424CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getTaskLengthMinutes = (webPlatformId: ALLW0424CollWebPlatformId): string => {
    switch (webPlatformId) {
        case "ALLW-0424-UK-LOTTO-FACEBOOK":
        case "ALLW-0424-UK-SETFORLIFE-FACEBOOK":
        case "ALLW-0424-UK-LOTTO-INSTAGRAM":
        case "ALLW-0424-UK-SETFORLIFE-INSTAGRAM":
            return "7";

        case "ALLW-0424-UK-LOTTO-TIKTOK":
        case "ALLW-0424-UK-LOTTO-YOUTUBE":
        case "ALLW-0424-UK-SETFORLIFE-YOUTUBE":
            return "10";

        default:
            assertNever(webPlatformId);
    }
}

const getWebPlatformConfigUrl = (groupId: ALLW0424CollGroupId): string => {
    switch (groupId) {
        case "ALLW-372-0424-UK-LOTTO-FACEBOOK":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ALLW-0424/ALLW-0424-UK-LOTTO-FACEBOOK.json"
        case "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ALLW-0424/ALLW-0424-UK-SETFORLIFE-FACEBOOK.json"
        case "ALLW-374-0424-UK-LOTTO-INSTAGRAM":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ALLW-0424/ALLW-0424-UK-LOTTO-INSTAGRAM.json"
        case "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ALLW-0424/ALLW-0424-UK-SETFORLIFE-INSTAGRAM.json"
        case "ALLW-376-0424-UK-LOTTO-TIKTOK":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ALLW-0424/ALLW-0424-UK-LOTTO-TIKTOK.json"
        case "ALLW-377-0424-UK-LOTTO-YOUTUBE":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ALLW-0424/ALLW-0424-UK-LOTTO-YOUTUBE.json"
        case "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE":
            return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ALLW-0424/ALLW-0424-UK-SETFORLIFE-YOUTUBE.json"
        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: ALLW0424CollGroupId): ALLW0424CollWebPlatformId => {
    switch (groupId) {
        case "ALLW-372-0424-UK-LOTTO-FACEBOOK": return "ALLW-0424-UK-LOTTO-FACEBOOK"
        case "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK": return "ALLW-0424-UK-SETFORLIFE-FACEBOOK"
        case "ALLW-374-0424-UK-LOTTO-INSTAGRAM": return "ALLW-0424-UK-LOTTO-INSTAGRAM"
        case "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM": return "ALLW-0424-UK-SETFORLIFE-INSTAGRAM"
        case "ALLW-376-0424-UK-LOTTO-TIKTOK": return "ALLW-0424-UK-LOTTO-TIKTOK"
        case "ALLW-377-0424-UK-LOTTO-YOUTUBE": return "ALLW-0424-UK-LOTTO-YOUTUBE"
        case "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE": return "ALLW-0424-UK-SETFORLIFE-YOUTUBE"
        default: assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: ALLW0424CollGroupId): WebPlatform => {
    switch (groupId) {
        case "ALLW-372-0424-UK-LOTTO-FACEBOOK":
        case "ALLW-373-0424-UK-SETFORLIFE-FACEBOOK":
            return "Facebook";

        case "ALLW-374-0424-UK-LOTTO-INSTAGRAM":
        case "ALLW-375-0424-UK-SETFORLIFE-INSTAGRAM":
            return "Instagram";

        case "ALLW-376-0424-UK-LOTTO-TIKTOK":
            return "TikTok";

        case "ALLW-377-0424-UK-LOTTO-YOUTUBE":
        case "ALLW-378-0424-UK-SETFORLIFE-YOUTUBE":
            return "Youtube";

        default:
            assertNever(groupId);
    }
}

export const addALLW0424CollConfig = (
    config: ALLW0424CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('preventTaskProgress', false)
        .setFlag('finishMessageOverride', FINISH_MESSAGE);

    let webplatformId = getWebPlatformId(config.groupId);
    const webplatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: webplatformId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: getPlatformForGroup(config.groupId),
        taskLengthMinutes: getTaskLengthMinutes(webplatformId),
        langStrings: langStrings,
    });

    const webRequestTask = generateWebRequestTask({
        webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/ALLW-0424/${config.groupId}_INCENTIVE.json`,
        params: {},
        taskID: config.groupId + '-WEBREQUEST',
    })

    const uploadsTask = generateUploadsTask({
        config: {
            "title": "Uploads"
        },
        taskId: config.groupId + '-UPLOADS',
    });

    builder
        .addTask(webplatformTask)
        .addTask(webRequestTask)
        .addTask(uploadsTask);

    return builder;
}