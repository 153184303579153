import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { YAHO0422CollGroupId } from "respondent_config_generator/src/projects/YAHO-0422";

export { YAHO0422CollAllGroupIds } from "respondent_config_generator/src/projects/YAHO-0422";
export type { YAHO0422CollGroupId } from "respondent_config_generator/src/projects/YAHO-0422";

const getPlatformForGroupId = (groupId: YAHO0422CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "YAHO-72-0422-USA-YAHOO-ITW":
        case "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW":
        case "YAHO-74-0422-USA-FINANCE-ITW":
        case "YAHO-75-0422-USA-ENTERTAINMENT-ITW":
        case "YAHO-76-0422-USA-LIFESTYLE-ITW":
        case "YAHO-83-0422-CAN-YAHOO-ITW":
        case "YAHO-84-0422-CAN-SPORTS-ITW":
        case "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW":
        case "YAHO-86-0422-CAN-FINANCE-ITW":
        case "YAHO-87-0422-CAN-AOL-ITW":
        case "YAHO-93-0422-AUS-LIFESTYLE-ITW":
        case "YAHO-94-0422-AUS-YAHOO-ITW":
        case "YAHO-95-0422-AUS-FINANCE-ITW":
        case "YAHO-96-0422-AUS-SPORTS-ITW":
        case "YAHO-102-0422-UK-YAHOO-ITW":
        case "YAHO-103-0422-UK-AOL-ITW":
        case "YAHO-104-0422-UK-FINANCE-ITW":
        case "YAHO-105-0422-UK-STYLE-ITW":
        case "YAHO-106-0422-UK-SPORTS-ITW":
        case "YAHO-77-0422-USA-YAHOO-EXPOSED":
        case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED":
        case "YAHO-79-0422-USA-FINANCE-EXPOSED":
        case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED":
        case "YAHO-81-0422-USA-MSN-EXPOSED":
        case "YAHO-88-0422-CAN-YAHOO-EXPOSED":
        case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED":
        case "YAHO-90-0422-CAN-SPORTS-EXPOSED":
        case "YAHO-91-0422-CAN-FINANCE-EXPOSED":
        case "YAHO-92-0422-CAN-MSN-EXPOSED":
        case "YAHO-97-0422-AUS-YAHOO-EXPOSED":
        case "YAHO-98-0422-AUS-FINANCE-EXPOSED":
        case "YAHO-99-0422-AUS-MSN-EXPOSED":
        case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED":
        case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED":
        case "YAHO-107-0422-UK-YAHOO-EXPOSED":
        case "YAHO-108-0422-UK-AOL-EXPOSED":
        case "YAHO-109-0422-UK-MSN-EXPOSED":
        case "YAHO-110-0422-UK-FINANCE-EXPOSED":
        case "YAHO-111-0422-UK-SPORTS-EXPOSED":
        case "YAHO-112-0422-AUS-SPORTS-EXPOSED":
        case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED":
            return "yahoo";

        default:
            assertNever(groupId)
    }
}

export const configureYAHO0422Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<YAHO0422CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}