import { AppLanguage } from "internationalisation";
import { getCurrentLangStrings, setCurrentLang } from "../../i18n";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PEPS0523CollGroupId } from "respondent_config_generator/src/projects/PEPS-0523";

export type { PEPS0523CollGroupId } from "respondent_config_generator/src/projects/PEPS-0523";
export { PEPS0523CollGroupIds } from "respondent_config_generator/src/projects/PEPS-0523";

const getPlatformForGroupId = (groupId: PEPS0523CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {
        case "PEPS-274-0523-FACEBOOK-ESTABLISHED":
        case "PEPS-275-0523-FACEBOOK-NON":
            return "facebook-newsfeed";
        case "PEPS-276-0523-INSTAGRAM-ESTABLISHED":
        case "PEPS-277-0523-INSTAGRAM-NON":
            return "instagram-newsfeed";
        case "PEPS-278-0523-YOUTUBE-ESTABLISHED":
        case "PEPS-279-0523-YOUTUBE-NON":
            return "youtube";
        case "PEPS-280-0523-TIKTOK-ESTABLISHED":
        case "PEPS-281-0523-TIKTOK-NON":
            return "tiktok";

        default:
            assertNever(groupId)
    }
}

export const configurePEPS0523Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<PEPS0523CollGroupId>
): void => {
    setCurrentLang(AppLanguage.po)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}