import React, { useEffect } from 'react';
import { CssBaseline, Toolbar } from '@mui/material'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import * as Sentry from '@sentry/browser'
import createCache from '@emotion/cache'
import { CacheProvider } from '@emotion/react'

import { useReduxDispatch, getQueryStringParams } from './utils';
import { buildAndPushConfiguration } from './Respondent/actions';

import { BlinqLogoTagline } from '@assets'
import { getLoginId, validateCintRespondent } from './APIs/loginId';
import { uploadJSON, downloadConfig } from './APIs/s3';
import { setupFailed } from './LandingPage/actions';
import LandingPageContainer from './LandingPage/Containers/LandingPageContainer';

const theme = createTheme({
    palette: {
      primary: { main: '#29404F' },
      secondary: { main: '#FFFFFF' },
    },
    typography: {
      fontFamily: [
        'Ofelia',
        'Roboto',
        'Arial',
        'sans-serif'
      ].join(','),
    }
})

export const muiCache = createCache({
  key: 'mui',
  prepend: true,
});


const App: React.FC = () => {
  const dispatch = useReduxDispatch()

  const DESKTOP_GAZE_MTURK_TEST_PAGE = "DESKTOP_GAZE_MTURK_TEST_PAGE";

  useEffect(() => {
    const queryStringParams = getQueryStringParams();

    if (queryStringParams["G"] === DESKTOP_GAZE_MTURK_TEST_PAGE) {
      return;
    }

    dispatch(buildAndPushConfiguration(
      queryStringParams,
      validateCintRespondent,
      getLoginId,
      downloadConfig,
      uploadJSON
    )).catch(e => {
      Sentry.captureException(e)
      dispatch(setupFailed())
      console.error(e)
    })
  }, [dispatch])

  if (getQueryStringParams()["G"] === DESKTOP_GAZE_MTURK_TEST_PAGE) {
    const onClick = (bWindow: boolean) => {
      window.open("https://crowdsource.dev.amplifiedintelligence.io/", "Gaze Calibration Web", bWindow ? "popup" : undefined);
    }
    return (
      <div>
        <p>This is a test page for mturk gaze calibration on the web</p>
        <button onClick={() => onClick(true)}>Start in new window</button>
        <br/>
        <br/>
        <button onClick={() => onClick(false)}>Start in new tab</button>
      </div>
    )
  }

  return (
    <CacheProvider value={muiCache}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Toolbar sx={{ display: 'flex', justiftContent: 'center', marginTop: '10px' }}>
          <img src={BlinqLogoTagline} style={{ height: '100px' }} alt="Blinq Logo"/>
        </Toolbar>
        <LandingPageContainer />
      </ThemeProvider>
    </CacheProvider>
  );
}

export default App;
