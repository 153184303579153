import { AppLanguage } from "internationalisation"
import { ConfigKey } from "../../configKey"
import { getCurrentLangStrings, setCurrentLang } from "../../i18n"
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder"
import { CAIParams } from "../../queryParams"

export const CRAA0822CollGroupIds = [
    'CRAA-152-0822-SCA-ADL-BREAKFAST',
    'CRAA-153-0822-SCA-SYD-BREAKFAST',
    'CRAA-154-0822-SCA-MEL-BREAKFAST',
    'CRAA-155-0822-SCA-BNE-BREAKFAST',
    'CRAA-156-0822-SCA-PER-BREAKFAST',
    'CRAA-157-0822-SCA-ALL-MORNAFT',
    'CRAA-158-0822-SCA-ALL-DRIVE',
    'CRAA-159-0822-SCA-ALL-EVENINGS',
    'CRAA-160-0822-SCA-PODCAST-THEBRIEFING',
    'CRAA-161-0822-SCA-PODCAST-HAMISHANDY',
    'CRAA-162-0822-ARN-ADL-BREAKFAST',
    'CRAA-163-0822-ARN-SYD-BREAKFAST',
    'CRAA-164-0822-ARN-MEL-BREAKFAST',
    'CRAA-165-0822-ARN-BNE-BREAKFAST',
    'CRAA-166-0822-ARN-PER-BREAKFAST',
    'CRAA-167-0822-ARN-ALL-MORNAFT',
    'CRAA-168-0822-ARN-ALL-DRIVE',
    'CRAA-169-0822-ARN-ADL-EVENING',
    'CRAA-170-0822-ARN-SYD-EVENING',
    'CRAA-171-0822-ARN-MEL-EVENING',
    'CRAA-172-0822-ARN-BNE-EVENING',
    'CRAA-173-0822-ARN-PER-EVENING',
    'CRAA-174-0822-ARN-PODCAST-IMPERFECTS',
    'CRAA-175-0822-ARN-PODCAST-KYLEJACKIE',
    'CRAA-176-0822-ARN-PODCAST-LIFEUNCUT',
    'CRAA-177-0822-NINE-SYD-BREAKFAST',
    'CRAA-178-0822-NINE-MEL-BREAKFAST',
    'CRAA-179-0822-NINE-BNE-BREAKFAST',
    'CRAA-180-0822-NINE-PER-BREAKFAST',
    'CRAA-181-0822-NINE-SYD-MORNAFT',
    'CRAA-182-0822-NINE-MEL-MORNAFT',
    'CRAA-183-0822-NINE-BNE-MORNAFT',
    'CRAA-184-0822-NINE-PER-MORNAFT',
    'CRAA-185-0822-NINE-SYD-DRIVE',
    'CRAA-186-0822-NINE-MEL-DRIVE',
    'CRAA-187-0822-NINE-BNE-DRIVE',
    'CRAA-188-0822-NINE-PER-DRIVE',
    'CRAA-189-0822-NINE-SYD-EVENING',
    'CRAA-190-0822-NINE-MEL-EVENING',
    'CRAA-191-0822-NINE-BNE-EVENING',
    'CRAA-192-0822-NINE-PER-EVENING',
    'CRAA-193-0822-NINE-PODCAST-MONEYNEWS',
    'CRAA-194-0822-NOVA-ADL-BREAKFAST',
    'CRAA-195-0822-NOVA-SYD-BREAKFAST',
    'CRAA-196-0822-NOVA-MEL-BREAKFAST',
    'CRAA-197-0822-NOVA-BNE-BREAKFAST',
    'CRAA-198-0822-NOVA-PER-BREAKFAST',
    'CRAA-199-0822-NOVA-AMBP-MORNAFT',
    'CRAA-200-0822-NOVA-SYD-MORNAFT',
    'CRAA-201-0822-NOVA-ALL-DRIVE',
    'CRAA-202-0822-NOVA-ALL-EVENING',
    'CRAA-203-0822-NOVA-PODCAST-HENNESSYS',
    'CRAA-204-0822-NOVA-PODCAST-KTJ',
    'CRAA-205-0822-NOVA-PODCAST-SMALLCHANGE'
] as const

export type CRAA0822GroupId = (typeof CRAA0822CollGroupIds)[number]

export function isCRAA0822GroupParams(params: CAIParams<ConfigKey>): params is CAIParams<CRAA0822GroupId> {
    return (CRAA0822CollGroupIds as ReadonlyArray<string>).includes(params.G)
}

export const configureCRAA0822Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<CRAA0822GroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getCurrentLangStrings()

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps('bvod'))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}