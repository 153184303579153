import { AppLanguage, getLanguageStrings } from "internationalisation";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";
import { PlatformInstructionsKey } from "../../LandingPage/Components/Steps/LoginAndCompletionCode";
import { ConfigKey } from "../../configKey";

import { SPIN0823CollAllGroupIds, SPIN0823CollGroupId } from "respondent_config_generator/src/projects/SPIN-0823";
import { setCurrentLang } from "../../i18n";
export { SPIN0823CollAllGroupIds } from 'respondent_config_generator/src/projects/SPIN-0823'

export type { SPIN0823CollGroupId } from 'respondent_config_generator/src/projects/SPIN-0823'

export function isSPIN0823GroupParams(params: CAIParams<ConfigKey>): params is CAIParams<SPIN0823CollGroupId> {
    return (SPIN0823CollAllGroupIds as ReadonlyArray<string>).includes(params.G)
}

const getPlatformForGroupId = (groupId: SPIN0823CollGroupId): PlatformInstructionsKey => {
    switch (groupId) {

        case "SPIN-288-0823-SPOTIFY":
            return "spotify-india";

        case "SPIN-289-0823-FACEBOOK":
            return "facebook";

        case "SPIN-290-0823-INSTAGRAM":
            return "instagram";

        case "SPIN-291-0823-YOUTUBE":
            return "youtube";

        case "SPIN-292-0823-FB-SPOT":
        case "SPIN-293-0823-IG-SPOT":
        case "SPIN-294-0823-YT-SPOT":
            return "webplatform-synergy"

        default:
            assertNever(groupId)
    }
}

export const configureSPIN0823Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<SPIN0823CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getLanguageStrings(AppLanguage.en)

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps(getPlatformForGroupId(params.G)))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}