import { AppLanguage, getLanguageStrings } from "internationalisation";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateWebPlatformTask, generateExternalSurveyTask, generateWebRequestTask } from "../tasks";
import { WebPlatform } from "../types";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your panel account has been incentivised. Thank you for your time."

const YAHO0422CollBaselineITWGroupIds = [
    "YAHO-72-0422-USA-YAHOO-ITW",
    "YAHO-74-0422-USA-FINANCE-ITW",
    "YAHO-75-0422-USA-ENTERTAINMENT-ITW",
    "YAHO-76-0422-USA-LIFESTYLE-ITW",
    "YAHO-83-0422-CAN-YAHOO-ITW",
    "YAHO-84-0422-CAN-SPORTS-ITW",
    "YAHO-86-0422-CAN-FINANCE-ITW",
    "YAHO-87-0422-CAN-AOL-ITW",
    "YAHO-93-0422-AUS-LIFESTYLE-ITW",
    "YAHO-94-0422-AUS-YAHOO-ITW",
    "YAHO-95-0422-AUS-FINANCE-ITW",
    "YAHO-96-0422-AUS-SPORTS-ITW",
    "YAHO-102-0422-UK-YAHOO-ITW",
    "YAHO-103-0422-UK-AOL-ITW",
    "YAHO-104-0422-UK-FINANCE-ITW",
    "YAHO-105-0422-UK-STYLE-ITW",
    "YAHO-106-0422-UK-SPORTS-ITW",
] as const

const YAHO0422CollBaselineExposedGroupIds = [
    "YAHO-77-0422-USA-YAHOO-EXPOSED",
    "YAHO-79-0422-USA-FINANCE-EXPOSED",
    "YAHO-81-0422-USA-MSN-EXPOSED",
    "YAHO-88-0422-CAN-YAHOO-EXPOSED",
    "YAHO-90-0422-CAN-SPORTS-EXPOSED",
    "YAHO-91-0422-CAN-FINANCE-EXPOSED",
    "YAHO-92-0422-CAN-MSN-EXPOSED",
    "YAHO-97-0422-AUS-YAHOO-EXPOSED",
    "YAHO-98-0422-AUS-FINANCE-EXPOSED",
    "YAHO-99-0422-AUS-MSN-EXPOSED",
    "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED",
    "YAHO-101-0422-AUS-LADBIBLE-EXPOSED",
    "YAHO-107-0422-UK-YAHOO-EXPOSED",
    "YAHO-108-0422-UK-AOL-EXPOSED",
    "YAHO-109-0422-UK-MSN-EXPOSED",
    "YAHO-110-0422-UK-FINANCE-EXPOSED",
    "YAHO-111-0422-UK-SPORTS-EXPOSED",
    "YAHO-112-0422-AUS-SPORTS-EXPOSED",
    "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED",
] as const

const YAHO0422CollSynergyGroupIds = [
    "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW",
    "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW",
    "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED",
    "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED",
    "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED",
] as const

const YAHO0422CollSynergyExposedGroupIds = [
    "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED",
    "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED",
    "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED",
] as const

export const YAHO0422CollAllGroupIds = [
    ...YAHO0422CollBaselineITWGroupIds,
    ...YAHO0422CollBaselineExposedGroupIds,
    ...YAHO0422CollSynergyGroupIds,
] as const;

type YAHO0422CollBaselineITWGroupId = typeof YAHO0422CollBaselineITWGroupIds[number]
type YAHO0422CollBaselineExposedGroupId = typeof YAHO0422CollBaselineExposedGroupIds[number]
type YAHO0422CollSynergyGroupId = typeof YAHO0422CollSynergyGroupIds[number]
type YAHO0422CollSynergyExposedGroupId = typeof YAHO0422CollSynergyExposedGroupIds[number]

export type YAHO0422CollGroupId =
    | YAHO0422CollBaselineITWGroupId
    | YAHO0422CollBaselineExposedGroupId
    | YAHO0422CollSynergyGroupId

const isSynergyGroupId = (groupId: YAHO0422CollGroupId): groupId is YAHO0422CollSynergyGroupId => {
    return (YAHO0422CollSynergyGroupIds as ReadonlyArray<string>).includes(groupId)
}

const isExposedGroupId = (groupId: YAHO0422CollGroupId): groupId is YAHO0422CollBaselineExposedGroupId | YAHO0422CollSynergyExposedGroupId => {
    return ((YAHO0422CollBaselineExposedGroupIds as ReadonlyArray<string>).includes(groupId) || (YAHO0422CollSynergyExposedGroupIds as ReadonlyArray<string>).includes(groupId))
}

type YAHO0422CollConfig = {
    groupId: YAHO0422CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getWebPlatformConfigUrl = (groupId: YAHO0422CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "YAHO-72-0422-USA-YAHOO-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-72-0422-USA-YAHOO-ITW.json";
        case "YAHO-74-0422-USA-FINANCE-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-74-0422-USA-FINANCE-ITW.json";
        case "YAHO-75-0422-USA-ENTERTAINMENT-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-75-0422-USA-ENTERTAINMENT-ITW.json";
        case "YAHO-76-0422-USA-LIFESTYLE-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-76-0422-USA-LIFESTYLE-ITW.json";
        case "YAHO-83-0422-CAN-YAHOO-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-83-0422-CAN-YAHOO-ITW.json";
        case "YAHO-84-0422-CAN-SPORTS-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-84-0422-CAN-SPORTS-ITW.json";
        case "YAHO-86-0422-CAN-FINANCE-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-86-0422-CAN-FINANCE-ITW.json";
        case "YAHO-87-0422-CAN-AOL-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-87-0422-CAN-AOL-ITW.json";
        case "YAHO-93-0422-AUS-LIFESTYLE-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-93-0422-AUS-LIFESTYLE-ITW.json";
        case "YAHO-94-0422-AUS-YAHOO-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-94-0422-AUS-YAHOO-ITW.json";
        case "YAHO-95-0422-AUS-FINANCE-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-95-0422-AUS-FINANCE-ITW.json";
        case "YAHO-96-0422-AUS-SPORTS-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-96-0422-AUS-SPORTS-ITW.json";
        case "YAHO-102-0422-UK-YAHOO-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-102-0422-UK-YAHOO-ITW.json";
        case "YAHO-103-0422-UK-AOL-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-103-0422-UK-AOL-ITW.json";
        case "YAHO-104-0422-UK-FINANCE-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-104-0422-UK-FINANCE-ITW.json";
        case "YAHO-105-0422-UK-STYLE-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-105-0422-UK-STYLE-ITW.json";
        case "YAHO-106-0422-UK-SPORTS-ITW": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-106-0422-UK-SPORTS-ITW.json";
        
        case "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW": 
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-73-0422-USA-SPORTS-ITW.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-73-0422-USA-FANTASYSPORTS-ITW.json";
                
        case "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW": 
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-85-0422-CAN-STYLE-ITW.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-85-0422-CAN-ENTERTAINMENT-ITW.json";

        case "YAHO-77-0422-USA-YAHOO-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-77-0422-USA-YAHOO-EXPOSED.json";
        case "YAHO-79-0422-USA-FINANCE-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-79-0422-USA-FINANCE-EXPOSED.json";
        case "YAHO-81-0422-USA-MSN-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-81-0422-USA-MSN-EXPOSED.json";
        case "YAHO-88-0422-CAN-YAHOO-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-88-0422-CAN-YAHOO-EXPOSED.json";
        case "YAHO-90-0422-CAN-SPORTS-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-90-0422-CAN-SPORTS-EXPOSED.json";
        case "YAHO-91-0422-CAN-FINANCE-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-91-0422-CAN-FINANCE-EXPOSED.json";
        case "YAHO-92-0422-CAN-MSN-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-92-0422-CAN-MSN-EXPOSED.json";
        case "YAHO-97-0422-AUS-YAHOO-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-97-0422-AUS-YAHOO-EXPOSED.json";
        case "YAHO-98-0422-AUS-FINANCE-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-98-0422-AUS-FINANCE-EXPOSED.json";
        case "YAHO-99-0422-AUS-MSN-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-99-0422-AUS-MSN-EXPOSED.json";
        case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-100-0422-AUS-LIFESTYLE-EXPOSED.json";
        case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-101-0422-AUS-LADBIBLE-EXPOSED.json";
        case "YAHO-107-0422-UK-YAHOO-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-107-0422-UK-YAHOO-EXPOSED.json";
        case "YAHO-108-0422-UK-AOL-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-108-0422-UK-AOL-EXPOSED.json";
        case "YAHO-109-0422-UK-MSN-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-109-0422-UK-MSN-EXPOSED.json";
        case "YAHO-110-0422-UK-FINANCE-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-110-0422-UK-FINANCE-EXPOSED.json";
        case "YAHO-111-0422-UK-SPORTS-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-111-0422-UK-SPORTS-EXPOSED.json";
        case "YAHO-112-0422-AUS-SPORTS-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-112-0422-AUS-SPORTS-EXPOSED.json";
        case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED.json";

        case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-78-0422-USA-SPORTS-EXPOSED.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-78-0422-USA-FANTASYSPORTS-EXPOSED.json";

        case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-80-0422-USA-ENTERTAINMENT-EXPOSED.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-80-0422-USA-LIFESTYLE-EXPOSED.json";
        
        case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED":
            return taskIndex === 0
                ? "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-89-0422-CAN-STYLE-EXPOSED.json"
                : "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/YAHO-0422/YAHO-89-0422-CAN-CELEBRITY-EXPOSED.json";

        default:
            assertNever(groupId);
    }
}

const getPlatformForGroup = (groupId: YAHO0422CollGroupId): WebPlatform => {
    switch (groupId) {
        case "YAHO-72-0422-USA-YAHOO-ITW":
        case "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW":
        case "YAHO-74-0422-USA-FINANCE-ITW":
        case "YAHO-75-0422-USA-ENTERTAINMENT-ITW":
        case "YAHO-76-0422-USA-LIFESTYLE-ITW":
        case "YAHO-83-0422-CAN-YAHOO-ITW":
        case "YAHO-84-0422-CAN-SPORTS-ITW":
        case "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW":
        case "YAHO-86-0422-CAN-FINANCE-ITW":
        case "YAHO-87-0422-CAN-AOL-ITW":
        case "YAHO-93-0422-AUS-LIFESTYLE-ITW":
        case "YAHO-94-0422-AUS-YAHOO-ITW":
        case "YAHO-95-0422-AUS-FINANCE-ITW":
        case "YAHO-96-0422-AUS-SPORTS-ITW":
        case "YAHO-102-0422-UK-YAHOO-ITW":
        case "YAHO-103-0422-UK-AOL-ITW":
        case "YAHO-104-0422-UK-FINANCE-ITW":
        case "YAHO-105-0422-UK-STYLE-ITW":
        case "YAHO-106-0422-UK-SPORTS-ITW":
        case "YAHO-77-0422-USA-YAHOO-EXPOSED":
        case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED":
        case "YAHO-79-0422-USA-FINANCE-EXPOSED":
        case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED":
        case "YAHO-81-0422-USA-MSN-EXPOSED":
        case "YAHO-88-0422-CAN-YAHOO-EXPOSED":
        case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED":
        case "YAHO-90-0422-CAN-SPORTS-EXPOSED":
        case "YAHO-91-0422-CAN-FINANCE-EXPOSED":
        case "YAHO-92-0422-CAN-MSN-EXPOSED":
        case "YAHO-97-0422-AUS-YAHOO-EXPOSED":
        case "YAHO-98-0422-AUS-FINANCE-EXPOSED":
        case "YAHO-99-0422-AUS-MSN-EXPOSED":
        case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED":
        case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED":
        case "YAHO-107-0422-UK-YAHOO-EXPOSED":
        case "YAHO-108-0422-UK-AOL-EXPOSED":
        case "YAHO-109-0422-UK-MSN-EXPOSED":
        case "YAHO-110-0422-UK-FINANCE-EXPOSED":
        case "YAHO-111-0422-UK-SPORTS-EXPOSED":
        case "YAHO-112-0422-AUS-SPORTS-EXPOSED":
        case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED":
            return "Yahoo";

        default:
            assertNever(groupId);
    }
}

const getWebPlatformId = (groupId: YAHO0422CollGroupId, taskIndex: 0 | 1): string => {
    switch (groupId) {
        case "YAHO-72-0422-USA-YAHOO-ITW":
        case "YAHO-77-0422-USA-YAHOO-EXPOSED":
            return "YAHO-0422-USA-YAHOO";
        
        case "YAHO-74-0422-USA-FINANCE-ITW":
        case "YAHO-79-0422-USA-FINANCE-EXPOSED":
            return "YAHO-0422-USA-FINANCE";
        
        case "YAHO-75-0422-USA-ENTERTAINMENT-ITW":
            return "YAHO-0422-USA-ENTERTAINMENT";
        
        case "YAHO-76-0422-USA-LIFESTYLE-ITW":
            return "YAHO-0422-USA-LIFESTYLE";
        
        case "YAHO-83-0422-CAN-YAHOO-ITW":
        case "YAHO-88-0422-CAN-YAHOO-EXPOSED":
        case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED":
            return "YAHO-0422-CAN-YAHOO";
        
        case "YAHO-84-0422-CAN-SPORTS-ITW":
        case "YAHO-90-0422-CAN-SPORTS-EXPOSED":
            return "YAHO-0422-CAN-SPORTS";
        
        case "YAHO-86-0422-CAN-FINANCE-ITW":
        case "YAHO-91-0422-CAN-FINANCE-EXPOSED":
            return "YAHO-0422-CAN-FINANCE";
        
        case "YAHO-87-0422-CAN-AOL-ITW":
            return "YAHO-0422-CAN-AOL";

        case "YAHO-93-0422-AUS-LIFESTYLE-ITW":
        case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED":
            return "YAHO-0422-AUS-LIFESTYLE";

        case "YAHO-94-0422-AUS-YAHOO-ITW":
        case "YAHO-97-0422-AUS-YAHOO-EXPOSED":
            return "YAHO-0422-AUS-YAHOO";
        
        case "YAHO-95-0422-AUS-FINANCE-ITW":
        case "YAHO-98-0422-AUS-FINANCE-EXPOSED":
            return "YAHO-0422-AUS-FINANCE";
        
        case "YAHO-96-0422-AUS-SPORTS-ITW":
        case "YAHO-112-0422-AUS-SPORTS-EXPOSED":
            return "YAHO-0422-AUS-SPORTS";
        
        case "YAHO-102-0422-UK-YAHOO-ITW":
        case "YAHO-107-0422-UK-YAHOO-EXPOSED":
            return "YAHO-0422-UK-YAHOO";
        
        case "YAHO-103-0422-UK-AOL-ITW":
        case "YAHO-108-0422-UK-AOL-EXPOSED":
            return "YAHO-0422-UK-AOL";
        
        case "YAHO-104-0422-UK-FINANCE-ITW":
        case "YAHO-110-0422-UK-FINANCE-EXPOSED":
            return "YAHO-0422-UK-FINANCE";
        
        case "YAHO-105-0422-UK-STYLE-ITW":
            return "YAHO-0422-UK-STYLE";
        
        case "YAHO-106-0422-UK-SPORTS-ITW":
        case "YAHO-111-0422-UK-SPORTS-EXPOSED":
            return "YAHO-0422-UK-SPORTS";

        case "YAHO-73-0422-USA-SPORTS-FANTASYSPORTS-ITW":
            return taskIndex === 0
                ? "YAHO-0422-USA-SPORTS"
                : "YAHO-0422-USA-FANTASYSPORTS";

        case "YAHO-85-0422-CAN-STYLE-ENTERTAINMENT-ITW":
            return taskIndex === 0
                ? "YAHO-0422-CAN-STYLE"
                : "YAHO-0422-CAN-ENTERTAINMENT";

        case "YAHO-81-0422-USA-MSN-EXPOSED":
            return "YAHO-0422-USA-MSN";
        
        case "YAHO-92-0422-CAN-MSN-EXPOSED":
            return "YAHO-0422-CAN-MSN";
    
        case "YAHO-99-0422-AUS-MSN-EXPOSED":
            return "YAHO-0422-AUS-MSN";
    
        case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED":
            return "YAHO-0422-AUS-LADBIBLE";
    
        case "YAHO-109-0422-UK-MSN-EXPOSED":
            return "YAHO-0422-UK-MSN";

        case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED":
            return taskIndex === 0
                ? "YAHO-0422-USA-SPORTS"
                : "YAHO-0422-USA-FANTASYSPORTS";

        case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED":
            return taskIndex === 0
                ? "YAHO-0422-USA-ENTERTAINMENT"
                : "YAHO-0422-USA-LIFESTYLE";

        case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED":
            return taskIndex === 0
                ? "YAHO-0422-CAN-STYLE"
                : "YAHO-0422-CAN-ENTERTAINMENT";

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: YAHO0422CollBaselineExposedGroupId | YAHO0422CollSynergyExposedGroupId): string => {
    switch (groupId) {
        case "YAHO-77-0422-USA-YAHOO-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_cHot2aYl5mu2RLw"
        case "YAHO-78-0422-USA-SPORTS-FANTASYSPORTS-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_1YqfpcehrPeDmWW"
        case "YAHO-79-0422-USA-FINANCE-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_5zCYUVp1X2Ll2Zg"
        case "YAHO-80-0422-USA-ENTERTAINMENT-LIFESTYLE-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_cViH1KVuQjtRzV4"
        case "YAHO-81-0422-USA-MSN-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_3Vl7ggCQ3JA0eLI"
        case "YAHO-88-0422-CAN-YAHOO-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_0ukVYsTjwDVt2se"
        case "YAHO-89-0422-CAN-STYLE-CELEBRITY-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_6zII5lsP40qNbTg"
        case "YAHO-90-0422-CAN-SPORTS-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_1TUkqZZg6rCRRHg"
        case "YAHO-91-0422-CAN-FINANCE-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_5v600XA4lM6yAoS"
        case "YAHO-92-0422-CAN-MSN-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_8B72lHtYOfMjAvc"
        case "YAHO-97-0422-AUS-YAHOO-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_bxEbhLSzTkC0DCS"
        case "YAHO-98-0422-AUS-FINANCE-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_5AOjKtEsmnyAEZw"
        case "YAHO-99-0422-AUS-MSN-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_3WNWE3cR7qEDLBI"
        case "YAHO-100-0422-AUS-LIFESTYLE-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_2tKb3yQKk2n66gK"
        case "YAHO-101-0422-AUS-LADBIBLE-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_24ZVOE9EAmeVpXw"
        case "YAHO-107-0422-UK-YAHOO-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_bBB1ssQOzBfeWrk"
        case "YAHO-108-0422-UK-AOL-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_9ACYFEyzEa8YiRE"
        case "YAHO-109-0422-UK-MSN-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_06SqlHHANVzKg06"
        case "YAHO-110-0422-UK-FINANCE-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_88JlFyYcMxctAoe"
        case "YAHO-111-0422-UK-SPORTS-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_8tRGuVAjKK3hhJk"
        case "YAHO-112-0422-AUS-SPORTS-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_bO921lFUQjzDscS"
        case "YAHO-148-0422-CAN-YAHOO-VIDEO-EXPOSED": return "https://survey.au1.qualtrics.com/jfe/form/SV_7NE3KIfHnZgFT82"

        default:
            assertNever(groupId);
    }
}

export const addYaho0422CollConfig = (
    config: YAHO0422CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    const langStrings = getLanguageStrings(AppLanguage.en)

    builder
        .setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)

    const webTask1 = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: getWebPlatformId(config.groupId, 0),
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 0),
        platform: getPlatformForGroup(config.groupId),
        taskLengthMinutes: "",
        langStrings: langStrings,
    })
    builder.addTask(webTask1)

    if (isSynergyGroupId(config.groupId)) {
        const webTask2 = generateWebPlatformTask({
            groupId: config.groupId,
            webPlatformID: getWebPlatformId(config.groupId, 1),
            webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId, 1),
            platform: getPlatformForGroup(config.groupId),
            taskLengthMinutes: "",
            langStrings: langStrings,
        })

        // Modify task Id to make it unique
        webTask2.meta.taskID = `${webTask2.meta.taskID}-2`;

        builder.addTask(webTask2)
    }

    if (isExposedGroupId(config.groupId)) {
        const surveyTask = generateExternalSurveyTask({
            title: langStrings.Common.survey,
            description: langStrings.Survey.description,
            extraMetaFields: config.metaFieldNames,
            taskID: `${config.groupId}-SURVEY`,
            surveyUrl: getSurveyUrl(config.groupId),
        });
        builder.addTask(surveyTask);
    } else {
        const webRequestTask = generateWebRequestTask({
            webRequestConfigUrl: `https://atrace-cdn.amplifiedintelligence.io/WebRequestConfigs/YAHO-0422/${config.groupId}_INCENTIVE.json`,
            params: {},
            taskID: config.groupId + '-WEBREQUEST',
        })
        builder.addTask(webRequestTask)
    }

    return builder;
}