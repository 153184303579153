import { AppLanguage, getLanguageStrings } from "internationalisation";
import { LangStrings } from "internationalisation/LangStrings";
import { assertNever } from "../helper/assertNever";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import { generateBvodTask, generateExternalSurveyTask, generateLinearTvTask } from "../tasks";

export type C9AU1021CollTVGroupId =
    | 'C9AU-25-1021-LINEARTV'
    | 'C9AU-26-1021-BVODTV'

export type C9AU1021CollMobileGroupId = 'C9AU-27-1021-BVOD'

function getTVSurveyURL(
    viewingCount: number,
    groupId: C9AU1021CollTVGroupId
): string {
    switch (viewingCount) {
        case 1: {
            return groupId === 'C9AU-25-1021-LINEARTV' ?
                'https://survey.au1.qualtrics.com/jfe/form/SV_6eQJmyYkUpiEO2i' :
                'https://survey.au1.qualtrics.com/jfe/form/SV_bauNYRGFSxEx6rs';
        }
        case 2: return 'https://survey.au1.qualtrics.com/jfe/form/SV_d0ew0IzzLfTtFTU';
        case 3: return 'https://survey.au1.qualtrics.com/jfe/form/SV_3sbsUN2Zyf6q6Gy';
        case 4: return 'https://survey.au1.qualtrics.com/jfe/form/SV_6LkU0DuV8wNiymW';
        case 5: return 'https://survey.au1.qualtrics.com/jfe/form/SV_5zkmCXj1HIo3YRE';
        case 6: return 'https://survey.au1.qualtrics.com/jfe/form/SV_ekTh34Kbuz8ZVoG';
        case 7:
        case 8:
        case 9:
        case 10: {
            return 'https://survey.au1.qualtrics.com/jfe/form/SV_1N6oqXwWt8zvvlc';
        }

        default: throw new RangeError('CH9 TV viewing count must be between 1 and 10 (inclusive)')
    }
}

function getBvodChannelUrl(
    isFirstViewing: boolean
) {
    return isFirstViewing ?
        "https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/C9AU-1021-STAS.json" :
        "https://atrace-cdn.amplifiedintelligence.io/BvodChannels/ChannelLists/C9AU-1021-ALL.json";
}

function addLinearTVConfig(
    viewingCount: number,
    metaFieldNames: ReadonlyArray<string>,
    builder: RespondentConfigBuilder,
) {
    const extraMetaFields: ReadonlyArray<string> = [
        ...metaFieldNames,
        'linearTV_terminatedEarly',
    ];

    const linearTv = generateLinearTvTask({
        title: '',
        description: '',
        taskID: 'C9AU-25-1021-LINEARTV-LINEARTV',
    })

    const surveyTask = generateExternalSurveyTask({
        taskID: `C9AU-25-1021-LINEARTV-SURVEY`,
        extraMetaFields,
        surveyUrl: getTVSurveyURL(viewingCount, 'C9AU-25-1021-LINEARTV'),
        title: '',
        description: '',
    })

    builder
        .addTask(linearTv)
        .addTask(surveyTask)
}

function addBvodTVConfig(
    viewingCount: number,
    metaFieldNames: ReadonlyArray<string>,
    langStrings: LangStrings,
    builder: RespondentConfigBuilder,
) {
    const isFirstViewing = viewingCount === 1;

    const bvodTvTask = generateBvodTask({
        taskID: 'C9AU-26-1021-BVODTV-BVOD',
        channelsJsonUrl: getBvodChannelUrl(isFirstViewing),
        langStrings,
        isTv: true,
        isRadio: false
    })

    const surveyTask = generateExternalSurveyTask({
        taskID: `C9AU-26-1021-BVODTV-SURVEY`,
        extraMetaFields: metaFieldNames,
        surveyUrl: getTVSurveyURL(viewingCount, 'C9AU-26-1021-BVODTV'),
        title: '',
        description: '',
    })

    builder
        .addTask(bvodTvTask)
        .addTask(surveyTask)
}

function addBvodMobileConfig(
    metaFieldNames: ReadonlyArray<string>,
    langStrings: LangStrings,
    builder: RespondentConfigBuilder,
) {
    // Mobile respondents only ever do one viewing
    const isFirstViewing = true;

    const bvodTask = generateBvodTask({
        taskID: 'C9AU-27-1021-BVOD-BVOD',
        channelsJsonUrl: getBvodChannelUrl(isFirstViewing),
        langStrings,
        isTv: false,
        isRadio: false
    })

    const surveyTask = generateExternalSurveyTask({
        taskID: `C9AU-27-1021-BVOD-SURVEY`,
        extraMetaFields: metaFieldNames,
        surveyUrl: "https://survey.au1.qualtrics.com/jfe/form/SV_25BdN94vi0ksbDo",
        title: '',
        description: '',
    })

    builder
        .addTask(bvodTask)
        .addTask(surveyTask)
}

const TV_FINISH_MESSAGE = 'Thankyou for your participation today. Please leave the device charging & locked with this app screen open to ensure your response is recorded. Keep your eyes peeled on your e-mail for further instructions.';

export const addCh9TVCollConfig = (
    groupId: C9AU1021CollTVGroupId,
    viewingCount: number,
    metaFieldNames: ReadonlyArray<string>,
    builder: RespondentConfigBuilder,
) => {
    const langStrings = getLanguageStrings(AppLanguage.en);

    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('minimumIOSVersion', '15.0')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', TV_FINISH_MESSAGE)


    switch (groupId) {
        case 'C9AU-25-1021-LINEARTV': {
            addLinearTVConfig(viewingCount, metaFieldNames, builder);
            break;
        }
        case 'C9AU-26-1021-BVODTV': {
            addBvodTVConfig(viewingCount, metaFieldNames, langStrings, builder);
            break;
        }

        default: assertNever(groupId)
    }
}

export const addCh9MobileCollConfig = (
    groupId: C9AU1021CollMobileGroupId,
    metaFieldNames: ReadonlyArray<string>,
    builder: RespondentConfigBuilder,
) => {
    const langStrings = getLanguageStrings(AppLanguage.en);

    builder.setFlag('shouldPresentUploadsVC', true)
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)

    switch (groupId) {
        case 'C9AU-27-1021-BVOD': {
            addBvodMobileConfig(metaFieldNames, langStrings, builder);
            break;
        }

        default: assertNever(groupId)
    }
}