import * as AWS from 'aws-sdk';
import { RespondentConfig } from 'respondent_config_generator';
import { loginIdSplitter, stageToBucket } from './helpers';
import { LoginIdApiStage } from './index'

const respondentConfigBucket = new AWS.S3({
    apiVersion: '2006-03-01',
    useAccelerateEndpoint: true
})

export type RespondentConfigUploader = (
    loginId: string,
    body: RespondentConfig,
    stage: LoginIdApiStage
) => Promise<void>;

export const uploadRespondentConfig: RespondentConfigUploader = async (
    loginId: string,
    body: RespondentConfig,
    stage: LoginIdApiStage
): Promise<void> => {
    const [loginIdChar, loginIdInt] = loginIdSplitter(loginId)
    const s3Key = `respondentConfigs/${loginIdChar}/${loginIdInt}.json`

    await respondentConfigBucket.putObject({
        ContentType: 'application/json',
        CacheControl: "no-store",
        Key: s3Key,
        Bucket: stageToBucket(stage),
        Body: JSON.stringify(body)
    }).promise()
}