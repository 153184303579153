import * as AWS from 'aws-sdk'
import { RespondentConfig } from 'respondent_config_generator/src/types';

AWS.config.update({
    region: process.env.REACT_APP_RESPONDENT_CONFIG_BUCKET_REGION,
    credentials: new AWS.CognitoIdentityCredentials({
        IdentityPoolId: process.env.REACT_APP_COGNITO_IDENTITY_POOL_ID
    })
});

const respondentConfigBucket = new AWS.S3({
    apiVersion: '2006-03-01',
    useAccelerateEndpoint: true
})

// Interfaces yew.
export type ConfigDownloader = (key: string) => Promise<RespondentConfig | undefined>;
export type ConfigUploader = (key: string, body: RespondentConfig) => Promise<void>;

export const downloadConfig: ConfigDownloader = async (key: string): Promise<RespondentConfig | undefined> => {
    const req = await respondentConfigBucket.getObject({
        Bucket: process.env.REACT_APP_RESPONDENT_CONFIG_BUCKET_NAME,
        Key: key
    }).promise()

    if (req.Body) {
        return JSON.parse(req.Body.toString()) as unknown as RespondentConfig
    }
}

export const uploadJSON: ConfigUploader = async (key: string, body: RespondentConfig): Promise<void> => {
    await respondentConfigBucket.putObject({
        ContentType: 'application/json',
        CacheControl: "no-store",
        Key: key,
        Bucket: process.env.REACT_APP_RESPONDENT_CONFIG_BUCKET_NAME,
        Body: JSON.stringify(body)
    }).promise()
}