import EnLangStrings from "internationalisation/en";
import { RespondentConfigBuilder } from "../respondentConfigBuilder";
import {
    generateWebPlatformTask,
    generateExternalSurveyTask,
} from "../tasks";
import { assertNever } from "../helper/assertNever";

const FINISH_MESSAGE = "Congratulations!\nYou have now completed all required tasks and your data has been uploaded. Your panel account has been incentivised. Thank you for your time."

// Only one group.
export const ACAY0822CollGroupIds = [
    'ACAY-151-0822-AUS-YOUTUBE'
] as const

export type ACAY0822CollGroupId = typeof ACAY0822CollGroupIds[number]

type Acay0822CollConfig = {
    groupId: ACAY0822CollGroupId,
    metaFieldNames: ReadonlyArray<string>
};

const getWebPlatformConfigUrl = (groupId: ACAY0822CollGroupId): string => {
    switch (groupId) {
        case "ACAY-151-0822-AUS-YOUTUBE": return "https://atrace-cdn.amplifiedintelligence.io/WebPlatform/Configs/ACAY-0822/ACAY-0822-AUS-YOUTUBE.json";

        default:
            assertNever(groupId);
    }
}

const getSurveyUrl = (groupId: ACAY0822CollGroupId): string => {
    switch (groupId) {
        case "ACAY-151-0822-AUS-YOUTUBE": return "https://survey.au1.qualtrics.com/jfe/form/SV_5BACN8Vx31vmw18";

        default:
            assertNever(groupId);
    }
}

// ACAY-0822 Youtube groups config.
export const addAcay0822CollWebplatformConfig = (
    config: Acay0822CollConfig,
    builder: RespondentConfigBuilder
): RespondentConfigBuilder => {
    builder
        .setFlag('deviceLock', 'mobile')
        .setFlag('requireMinimumBattery', 0.3)
        .setFlag('finishMessageOverride', FINISH_MESSAGE)

    const webPlatformTask = generateWebPlatformTask({
        groupId: config.groupId,
        webPlatformID: config.groupId,
        webPlatformConfigUrl: getWebPlatformConfigUrl(config.groupId),
        platform: "Youtube",
        taskLengthMinutes: "",
        langStrings: EnLangStrings,
    });

    const surveyTask = generateExternalSurveyTask({
        title: EnLangStrings.Common.survey,
        description: EnLangStrings.Survey.description,
        extraMetaFields: config.metaFieldNames,
        taskID: `${config.groupId}-SURVEY`,
        surveyUrl: getSurveyUrl(config.groupId),
    });

    builder
        .addTask(webPlatformTask)
        .addTask(surveyTask)

    return builder;
}