import { QueryParams, QueryParamsRequestable } from "../queryParams";
import { isPreview, isMturkParams, hasCompletedSurvey } from "../MTurk/mturkParams";
import { GetLoginIdRequest } from "../APIs/loginId";
import { isMturkMakeupHitGroupId } from "./ConfigDataStores/mturk";

const getEnv = (params: QueryParamsRequestable): string => {
    const envOverride = params.env;
    if (envOverride) {
        return envOverride;
    }

    return params.G
};

export const getLoginIdRequestParameters = (params: QueryParamsRequestable): GetLoginIdRequest => {
    const env = getEnv(params);
    const groupId = params.G;
    const panelId = getPanelId(params);

    return {
        env,
        groupId,
        panelId
    };
}
/**
 * Does the current set of query params dictate that a
 * LoginId & Respondent Config should be generated and pushed to S3?
 * 
 */
export const requiresConfiguration = (params: QueryParams): params is QueryParamsRequestable => {
    if (isMturkParams(params)) {
        if (isPreview(params)) {
            return false;
        }

        if (isMturkMakeupHitGroupId(params.G)) {
            return false
        }

        // Mturk Demographic Survey
        if (!hasCompletedSurvey(params)) {
            return false
        }
    }

    return true;
};

const getPanelId = (params: QueryParamsRequestable): string => {
    switch (params.__type) {
        case 'CAI_PARAMS':
            return `${params.rmeta.panelIdentifier}_${params.rmeta.panelRespondentID}`;
        case 'MTURK_PARAMS':
            return `MTURK_${params.workerId}_${params.assignmentId}`;
        default:
            assertNever(params)
    }
}
