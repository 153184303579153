import axios from 'axios'
import { GroupId } from 'respondent_config_generator';
import { getLoginIdGroupChar, assertNever } from './helpers';

export { downloadRespondentConfig } from './respondentConfigDownloader'
export { uploadRespondentConfig } from './respondentConfigUploader'
export type { RespondentConfigDownloader } from './respondentConfigDownloader'
export type { RespondentConfigUploader } from './respondentConfigUploader'

export type GetLoginIDResponse = {
    loginId: string
    respondentId: string
}

export type GetLoginIdRequest = {
    groupId: GroupId,
    panelId: string,
    env: string
};

export type LoginIdApiStage =
    | 'DEV'
    | 'PROD'

export type GetLoginIdRequester = (
    req: GetLoginIdRequest,
    stage?: LoginIdApiStage
) => Promise<GetLoginIDResponse>

export type ValidateCintRespondentRequester = (
    panelId: string,
    stage?: LoginIdApiStage
) => Promise<void>

function getLoginIdApiUrl(stage: LoginIdApiStage) {
    switch (stage) {
        case 'DEV': return "https://jgzznmkm63.execute-api.ap-southeast-2.amazonaws.com/Prod/"
        case 'PROD': return "https://oiaeu25n8e.execute-api.ap-southeast-2.amazonaws.com/Prod/"
        default: assertNever(stage)
    }
}

export const requestLoginId: GetLoginIdRequester = async (
    opts: GetLoginIdRequest,
    stage: LoginIdApiStage = 'DEV'
): Promise<GetLoginIDResponse> => {
    const loginIdApiUrl = getLoginIdApiUrl(stage)
    const groupKey = getLoginIdGroupChar(opts.groupId)
    const res = await axios.post<GetLoginIDResponse>(`${loginIdApiUrl}loginId?groupKey=${groupKey}&groupId=${opts.groupId}&panelId=${opts.panelId}&env=${opts.env}`)

    return res.data
}

export const requestValidateCintRespondent: ValidateCintRespondentRequester = async (
    panelId: string,
    stage: LoginIdApiStage = 'DEV'
): Promise<void> => {
    const loginIdApiUrl = getLoginIdApiUrl(stage)
    await axios.post<void>(`${loginIdApiUrl}validateCintRespondent?panelId=${panelId}`)
}