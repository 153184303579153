import * as React from 'react'
import { Grid, Typography } from '@mui/material'
import { getCurrentLangStrings } from '../../../i18n'
import { PlatformInstructionsDeskSolutionKey } from './LoginAndCompletionDeskSolution';
interface Props {
    loginId: string;
    platform: PlatformInstructionsDeskSolutionKey;
}

const ViewingInstructionsDeskSolution: React.FC <Props> = (props: Props) => {
    const langStrings = getCurrentLangStrings();
    const { platform } =
        props;

    const getInstructionsForPlatform = (
        platform: PlatformInstructionsDeskSolutionKey
    ): string => {
        switch (platform) {
            case "twitch-desktop":
                return `
                1. Download Chrome extension 
                2. Copy login ID 
                3. Read Twitch Collection Information
                4. Login to the extension and complete your 10 minute viewing session`;
            case "yahoo-desktop":
                return `
                1. Read the Yahoo study information 
                2. Take note of your login ID to enter into the extension
                3. Download Chrome extension 
                4. Login to the extension and complete your 10 minute viewing session
                5. Complete the post-viewing survey by accessing the extension pop up`;
            default:
                assertNever(platform);
        }
    };

    return <div>
        <Grid container direction="column" justifyContent="center" alignItems="center" alignContent="center">
            <Grid item>
                <Typography variant="h6" align="left" gutterBottom>{langStrings.Steps.ViewingInstructionsDeskSolution.title}</Typography>
                {platform && (
                     <Typography align="left" sx={{paddingLeft: "1.5rem"}} gutterBottom component="p" whiteSpace="pre-line">{getInstructionsForPlatform(platform)}</Typography>
                )}
            </Grid>
        </Grid>
    </div>
}

export default ViewingInstructionsDeskSolution
