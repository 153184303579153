import { GetLoginIDResponse, requestLoginId, requestValidateCintRespondent, LoginIdApiStage, GetLoginIdRequester, ValidateCintRespondentRequester } from 'loginid-api-toolkit'
import { GroupId } from 'respondent_config_generator/src/types';

export type GetLoginIdRequest = {
    groupId: GroupId,
    panelId: string,
    env: string
};

export type ValidateCintRespondentRequest = {
    groupId: GroupId,
    panelId: string,
    env: string
};

export const getLoginId: GetLoginIdRequester = async (
    opts: GetLoginIdRequest
): Promise<GetLoginIDResponse> => {
    const stage: LoginIdApiStage = process.env.NODE_ENV === 'production' ? 'PROD' : 'DEV'
    return await requestLoginId(opts, stage)
}

export const validateCintRespondent: ValidateCintRespondentRequester = async (
    panelId: string
): Promise<void> => {
    const stage: LoginIdApiStage = process.env.NODE_ENV === 'production' ? 'PROD' : 'DEV'
    return await requestValidateCintRespondent(panelId, stage)
}
