import { AppLanguage, getLanguageStrings } from "internationalisation";
import { LandingPageConfigBuilder } from "../../LandingPage/landingPageConfigBuilder";
import { CAIParams } from "../../queryParams";

import { GCPL0624CollGroupId } from "respondent_config_generator/src/projects/GCPL-0624";
import { setCurrentLang } from "../../i18n";

export type { GCPL0624CollGroupId } from 'respondent_config_generator/src/projects/GCPL-0624'

export const configureGCPL0624Page = (
    landingPageBuilder: LandingPageConfigBuilder,
    params: CAIParams<GCPL0624CollGroupId>
): void => {
    setCurrentLang(AppLanguage.en)
    const lang = getLanguageStrings(AppLanguage.en)

    landingPageBuilder.setSteps(LandingPageConfigBuilder.getStandardViewingSurveySteps("webplatform-synergy"))
    landingPageBuilder.setPageTitle(lang.LandingPage.Title)
}