import update from 'immutability-helper'
import { getType } from 'typesafe-actions'
import { LangStepLabels } from '../i18n'
import { QueryParams } from '../queryParams'
import * as actions from './actions'
import { PlatformInstructionsKey } from './Components/Steps/LoginAndCompletionCode'
import { PlatformInstructionsDeskSolutionKey } from './Components/Steps/LoginAndCompletionDeskSolution'

export enum LandingPageLoadingState {
    loading = "loading",
    loaded = "loaded",
    error = "error"
}

export enum LandingPageSupportEmail {
    help = "https://blinqrewards.com/pages/contact",
    mturk = "mturksupport@amplifiedintelligence.com.au",
    mturkDesktop = "amplifiedmturk@gmail.com"
}

type StepComponentKey =
    | 'MakeupHitInstructions'
    | 'MturkGazeInstructions'
    | 'MturkGazeRequirements'
    | 'MturkSurveyRedirect'
    | 'ViewingInstructions'
    | 'ViewingInstructionsDeskSolution'
    | 'AppDownload'
    | 'AppDownloadDeskSolution'
    | 'LoginAndCompletionCode'
    | 'LoginAndCompletionDeskSolution'
    | 'SessionInformationDeskSolution'
    | 'MturkGazeDesktopInstructions'
    | 'MturkGazeDesktopRequirements'
    | 'MturkGazeDesktopCompletion'
    | 'Setup'
    | 'StartEarning'

type LandingPageStepBuilder<K extends StepComponentKey, P = {}> = {
    stepComponentKey: K
    titleL18nKey: LangStepLabels
    props: P
}

type EmptyDict = Record<string, never>;

type MakeupHitInstructions = LandingPageStepBuilder<'MakeupHitInstructions', EmptyDict>
type MturkGazeInstructions = LandingPageStepBuilder<'MturkGazeInstructions', EmptyDict>
type MturkGazeRequirements = LandingPageStepBuilder<'MturkGazeRequirements', EmptyDict>
type MturkSurveyRedirect = LandingPageStepBuilder<'MturkSurveyRedirect', EmptyDict>
type ViewingInstructions = LandingPageStepBuilder<'ViewingInstructions', EmptyDict>
type AppDownloadStep = LandingPageStepBuilder<'AppDownload', EmptyDict>
type AppDownloadDeskSolutionStep = LandingPageStepBuilder<'AppDownloadDeskSolution', EmptyDict>
type MturkGazeDesktopInstructionsStep = LandingPageStepBuilder<'MturkGazeDesktopInstructions', EmptyDict>
type MturkGazeDesktopRequirementsStep = LandingPageStepBuilder<'MturkGazeDesktopRequirements', EmptyDict>
type SetupStep = LandingPageStepBuilder<'Setup', EmptyDict>

type StartEarningProps =
    | { _type: 'completionCode', completionCode: string }
    | { _type: 'platform', platform: PlatformInstructionsKey }

type LoginAndCompletionCodeProps =
    | { _type: 'completionCode', completionCode: string }
    | { _type: 'platform', platform: PlatformInstructionsKey }

type LoginAndCompletionDeskSolutionProps =
    | { _type: 'completionCodeDeskSolution', completionCode: string }
    | { _type: 'platform', platform: PlatformInstructionsDeskSolutionKey }

type MturkGazeDesktopCompletionProps =
    | { _type: 'completionCode', completionCode: string }
    
type SessionInformationDeskSolutionProps =
    | { _type: 'platform', platform: PlatformInstructionsDeskSolutionKey }

type ViewingInstructionsDeskSolutionProps =
    | { _type: 'platform', platform: PlatformInstructionsDeskSolutionKey }

type ViewingInstructionsDeskSolution = LandingPageStepBuilder<'ViewingInstructionsDeskSolution', ViewingInstructionsDeskSolutionProps>
type SessionInformationDeskSolutionStep = LandingPageStepBuilder<'SessionInformationDeskSolution', SessionInformationDeskSolutionProps>
type LoginAndCompletionCodeStep = LandingPageStepBuilder<'LoginAndCompletionCode', LoginAndCompletionCodeProps>
type LoginAndCompletionDeskSolutionStep = LandingPageStepBuilder<'LoginAndCompletionDeskSolution', LoginAndCompletionDeskSolutionProps>
type MturkGazeDesktopCompletionStep = LandingPageStepBuilder<'MturkGazeDesktopCompletion', MturkGazeDesktopCompletionProps>
type StartEarningStep = LandingPageStepBuilder<'StartEarning', StartEarningProps>

export type LandingPageConfig = {
    readonly pageTitle: string
    readonly loginId: string
    readonly params: QueryParams
    readonly steps: ReadonlyArray<LandingPageStep>
    readonly submitToMturk: boolean
    readonly iframeUrl?: string
    readonly supportEmail: LandingPageSupportEmail
    readonly previewMode: boolean
}

export type LandingPageStep =
    | MakeupHitInstructions
    | MturkGazeInstructions
    | MturkGazeRequirements
    | MturkSurveyRedirect
    | ViewingInstructions
    | ViewingInstructionsDeskSolution
    | AppDownloadStep
    | AppDownloadDeskSolutionStep
    | LoginAndCompletionCodeStep
    | LoginAndCompletionDeskSolutionStep
    | SessionInformationDeskSolutionStep
    | MturkGazeDesktopInstructionsStep
    | MturkGazeDesktopRequirementsStep
    | MturkGazeDesktopCompletionStep
    | SetupStep
    | StartEarningStep

type LandingPageStateBase<State extends LandingPageLoadingState> = {
    readonly loadingState: State
}

type LandingPageStateError = LandingPageStateBase<LandingPageLoadingState.error>
type LandingPageStateLoading = LandingPageStateBase<LandingPageLoadingState.loading>
type LandingPageStateLoaded = LandingPageStateBase<LandingPageLoadingState.loaded> & {
    readonly config: LandingPageConfig
}

export type LandingPageState =
    | LandingPageStateError
    | LandingPageStateLoading
    | LandingPageStateLoaded

const initialState: LandingPageState = { loadingState: LandingPageLoadingState.loading }

const LandingPageReducer = (state: LandingPageState = initialState, action: actions.LandingPageAction) => {
    switch (action.type) {
        case getType(actions.setLocale):
            return state
        case getType(actions.setupFailed):
            return update(state, {
                loadingState: { $set: LandingPageLoadingState.error },
                $unset: ['config']
            })
        case getType(actions.setupSuccess):
            return update(state, {
                loadingState: { $set: LandingPageLoadingState.loaded },
                config: { $set: action.payload }
            })
        default:
            return state
    }
}
export default LandingPageReducer
