import { RespondentTask, RespondentConfig, RespondentFlags as RespondentFlag, ConfigurationError } from "./types";

/**
 * This class exists to build out a partial respondent config.
 * This is done rather than using a Partial<RespondentConfig> due to not
 * wanting to allow accidentally replacing a given map object like flags
 * entirely if it happens to be updated in multiple places.
 */
export class RespondentConfigBuilder<ExtraMetaField extends string = string> {
    private _flags: RespondentFlag = {};
    private _tasks: Array<RespondentTask> = [];
    private _extraMeta: Partial<Record<ExtraMetaField, string>> = {};

    constructor(
        private groupId: string,
        private respondentId: string,
        private assignmentId: string | undefined,
        private env: string,
    ) { }

    public setFlag<T extends keyof RespondentFlag>(flag: T, value: RespondentFlag[T]): RespondentConfigBuilder<ExtraMetaField> {
        this._flags[flag] = value;
        return this;
    }

    public addTask(task: RespondentTask): RespondentConfigBuilder<ExtraMetaField> {
        this._tasks.push(task);
        return this;
    }

    public addExtraMeta(key: ExtraMetaField, value: string): RespondentConfigBuilder<ExtraMetaField> {
        this._extraMeta[key] = value;
        return this;
    }

    public build(): RespondentConfig {
        const numCompletionTasks = this._tasks.filter(t => t.type === 'completionCode').length;
        if (numCompletionTasks > 1) {
            throw new ConfigurationError('Configuration had more than one completion code task provided.');
        }

        const uniqueTaskIds = new Set()
        this._tasks.forEach(t => uniqueTaskIds.add(t.meta.taskID))
        if (uniqueTaskIds.size !== this._tasks.length) {
            throw new ConfigurationError('Configuration had multiple Tasks with the same Id, TaskIds should be unique.');
        }

        let respondentConfig: RespondentConfig = {
            respondentID: this.respondentId,
            groupID: this.groupId,
            env: this.env,
            flags: this._flags,
            extraMeta: this._extraMeta as Record<string, string>,
            tasks: this._tasks,
        }

        if (this.assignmentId) {
            respondentConfig.assignmentID = this.assignmentId
        }

        return respondentConfig;
    }

}
